@import "../../../../../assets/scss/variable.scss";

.team-sports-layout {
  .outrights-wrap {
    padding: 30px 33px 21px;
    margin-bottom: 18px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media only screen and (max-width: 799px) {
      padding: 19px 12px 20px;
    }

    .team-sport-table {
      border-bottom: none;

      .team-sport-table-indicator {
        padding: 11px 0px;
        justify-content: space-between;

        @media only screen and (max-width: 1365px) {
          flex-direction: initial;
        }

        @media only screen and (max-width: 799px) {
          justify-content: center;
        }

        .time-indicator-left {
          display: flex;
        }

        @media only screen and (max-width: 479px) {
          // .time-indicator-left {
          //   flex-direction: column;
          // }
        }
      }

      .teamsports-tablerow:after {
        width: 100%;
      }

      .team-wrap {
        margin: 0px;
      }
    }

    .individual-outrights-wrap {
      margin-bottom: 10px;
      .table-header-wrap .currentbest-seeall .teamsports-seeall h1 {
        font-size: 22.4px;
        line-height: 31.36px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Accent-1;
        text-decoration: underline;
        @media only screen and (max-width: 599px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .teamsports-seeall-tablewrap.outrights-seeall-tablewrap {
    background-color: $color-White;
    .table-seeall-container {
      box-shadow: none;
      .arrow-wrap {
        top: 0px;
      }
    }

    .outrights-individual-table {
      .individual-team-head {
        .MuiTableRow-head {
          .MuiTableCell-head {
            border: none !important;
            border-bottom: 1px solid $color-Accent-1 !important;
          }
        }
      }
      .individual-team-body {
        .MuiTableCell-root {
          padding: 18px 0px;
        }
        .individual-team-row {
          border-bottom: 1px solid $color-Light-grey;
        }
        .individual-team-row:not(:last-child)::after {
          width: 100%;
        }
      }
    }
  }

  .outrights-table-mobileview.individual-table-mobileview {
    .team-wrap {
      .team-name {
        margin-left: 0px;
      }
    }
  }
  .outrights-table-mobileview .individual-table-head .current-best-head {
    width: 100%;
    justify-content: end;
  }
}

.team-sport-table-wrap {
  .MuiAccordion-root.Mui-expanded {
    margin: 0px;
  }

  .MuiCollapse-wrapper {
    margin-top: 10px;
  }

  .team-sport-table-indicator {
    .team-time-market {
      font-family: $regulerFont;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    .sport-indicator-btn {
      width: fit-content;
    }
  }

  .outrights-accordian {
    box-shadow: none;
    margin: 0px;

    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 38px;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0px;
    }

    .outrights-accordian-summary {
      // padding: 6px 0px 6px 12px;
      border: none;
      background-color: $color-Light-grey;
      color: $color-Primary;
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      max-height: 32px;
    }

    .MuiAccordionSummary-root {
      min-height: auto;
    }

    .outrights-accordian-details {
      padding: 0px;
      display: flex;
      flex-direction: column;
      .outrights-table {
        border: 0.6px solid $color-grey;
        border-collapse: initial;
        border-radius: 0px;
      }

      .racing-data .MuiTableCell-head {
        border-bottom: 1px solid $color-grey;
      }

      .bookmaker-thumb {
        margin: 0px auto !important;
      }
      .outright-bookmaker-thumb {
        height: 33px;
        @media only screen and (max-width: 1023px) {
          margin: 0px auto;
        }
      }
      .outright-odds-btn {
        padding: 8px 3px;
        @media only screen and (max-width: 1023px) {
          padding: 8px 0px;
          width: 80px;
          margin: 0px auto;
          .odds-link {
            font-size: 12px;
          }
        }
      }
      .outright-icon-wrap {
        @media only screen and (max-width: 1023px) {
          margin: 0px auto;
        }
      }
      .team-name {
        font-family: $primaryFont;
        font-weight: 400;
        margin-left: 13px;

        @media only screen and (max-width: 1023px) {
          margin-left: 0px;
        }

        @media only screen and (max-width: 799px) {
          padding: 12px 0px 4px;
          display: block;
        }
      }

      .MuiTableCell-head {
        padding: 5px 0px 7px 0px;
      }

      .MuiTableCell-body {
        padding: 18px 0px;
      }

      .track-table-th {
        font-size: 16px;
        padding: 5px 0px 7px 15px;
      }

      .team-wrap {
        padding: 18px 10px;
      }

      .teamsports-tablerow {
        border-left: 0.6px solid $color-grey;
        border-collapse: collapse;
        border-right: 0.6px solid $color-grey;
      }

      .MuiTableRow-head {
        border: 1px solid $color-grey;
      }

      .mobile-table-container {
        width: 100%;

        .outrights-mobile-table-container {
          border-bottom: 1px solid #bdbdbf;
          padding-bottom: 9px;

          .MuiTable-root {
            border: 0.4px solid $color-grey;
            border-collapse: initial;
            .mobile-table-body .mobile-table-data .odds-btn {
              padding: 8px 0px;
              width: 80px;
              margin: 0px auto;
              .odds-link {
                font-size: 12px;
              }
            }
          }
        }

        .show-outrights-mobile {
          border-bottom: none;
          padding-bottom: 0px;
        }

        .MuiTableCell-head {
          background-color: #d6d9f3;

          @media only screen and (max-width: 479px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }

        .sponsored-header {
          color: #d6d9f3;
          background-color: $color-Primary;
        }

        .mobile-table-data {
          text-transform: uppercase;
        }

        .border-right-white {
          border-right: 3px solid $color-White;
        }
      }
    }
  }
}
