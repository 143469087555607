@import "../../../assets/scss/variable.scss";

.smartbook-content-wrap {
  @media (max-width:1023px) {
    margin: 0px -12px;
  }
}

.color-primary-s {
  color: $color-Primary !important;
}

.smartbook-wrap {
  background-color: #fbfbfb;

  .blackbook-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .tab-search-section {
    padding: 18px 18px 19px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;
    margin-bottom: 18px;

    .search_input {
      width: 100%;

      .MuiInputBase-root {
        max-height: 45px;

        svg {
          height: 18px;
          width: 18px;
        }

        .MuiInputBase-input {
          padding: 14px 0px 12px;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #e7e9ec;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $color-Accent-1;
        border-width: 1.5px;
      }

      @media (max-width: 799px) {
        margin-left: 0px !important;
        min-width: auto;
        width: 100%;
      }
    }

    .teamsport-tab-detail {
      margin-top: 28px;
      border-bottom: 2px solid #4455c7;

      .MuiTabs-scroller {
        .MuiTabs-indicator {
          display: none;
        }

        .active {
          border-bottom: 3px solid $color-Primary;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .MuiButtonBase-root.active {
        color: $color-Accent-1;
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .MuiButtonBase-root {
        font-size: 16px;
        font-family: VeneerClean-Soft !important;
        color: #191919;
        line-height: 21px;
      }

      .MuiTab-root {
        min-width: 120px;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: #003764;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }

    .day-tab-details {
      padding: 34px 0px 0px;
      min-height: 38px;

      @media (max-width: 799px) {
        padding: 9px 0px;
        min-height: 32px;
      }

      // .MuiTabScrollButton-root {
      //   // border: 2px solid $color-Primary;
      // }

      .MuiTab-textColorInherit {
        color: $color-Black;
        border: 2px solid $color-Primary;
        margin: 0px 12px;
        border-radius: 8px;
        opacity: 1;
        min-height: 38px;
        padding: 9px 10px;

        @media (max-width: 799px) {
          margin: 0 9px;
          padding: 6px 12px;
          min-height: 32px;
        }
      }

      .active {
        color: $color-White;
        background: $color-Primary;
      }

      .MuiTabs-indicator {
        background: none;
      }

      .MuiTab-textColorInherit:first-child {
        margin-left: 0px;
      }

      .MuiTab-root {
        min-width: initial;
        width: 144px;

        @media (max-width: 1364px) {
          width: 166px;
        }

        @media (max-width: 1279px) {
          width: 159px;
        }

        @media (max-width: 1023px) {
          width: 140px;
        }

        @media (max-width: 799px) {
          width: 157px;
        }

        // @media (max-width: 767px) {
        //   width: 157px;
        // }
        @media (max-width: 599px) {
          width: 145px;
        }

        @media (max-width: 479px) {
          width: 188px;
        }

        @media (max-width: 413px) {
          width: 175px;
        }

        @media (max-width: 389px) {
          width: 171px;
        }

        @media (max-width: 374px) {
          width: 160px;
        }

        @media (max-width: 359px) {
          width: 150px;
        }

        @media (max-width: 339px) {
          width: 140px;
        }
      }

      .MuiButtonBase-root {
        font-size: 16px;
        line-height: 20px;
        font-family: $primaryFont;
      }

      .Mui-disabled {
        opacity: 1;
      }

      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          .MuiBox-root:last-child {
            button {
              margin: 0px;
            }
          }
        }
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .page-deatils-wrap {
    padding: 34px 18px 30px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 799px) {
      padding: 18px 12px 30px;
    }

    .filter-section {
      max-width: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 12px;

      @media only screen and (max-width: 1023px) {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 12px;
        row-gap: 12px;
      }

      @media only screen and (max-width: 479px) {
        grid-template-columns: auto auto;
      }

      .sort-select {
        max-width: 282px;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .mw-none {
        width: 100%;
      }

      .Period-select {
        // max-width: 202px;
        max-width: 228px;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .Entry-select {
        max-width: 152px;
        margin-right: 4px;
        min-width: 152px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .country-select {
        // min-width: 170px;
        // max-width: 170px;
        min-width: 192px;
        max-width: 192px;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }

      .State-select {
        min-width: 152px;
        max-width: 170px;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 2;
          grid-column-end: 4;
        }
      }

      .select__control {
        background-color: $color-Light-grey;
        border-radius: 8px;
        max-height: 36px;

        .select__single-value {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__placeholder {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__input-container {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__indicator-separator {
          width: 0px;
        }

        .select__dropdown-indicator {
          padding: 10px;
        }
      }

      .select__menu-portal {
        z-index: 99;
      }

      .select__menu,
      .react-select__menu {

        .select__menu-list,
        .react-select__menu-list {

          .select__option,
          .react-select__option {
            cursor: pointer;
            font-size: 16px;
            color: $color-Black;
            font-family: $regulerFont;
            line-height: 19px;
            padding: 11px;

            &.select__option--is-focused {
              background-color: #d4d6d8;
              color: #000;
            }

            &.select__option--is-selected {
              background-color: #d4d6d8;
              color: $color-Black;
            }
          }
        }

        .select__menu-list,
        .react-select__menu-list {
          .select__group {
            .select__group-heading {
              margin-bottom: 0.5rem;
              color: green;
              font-weight: bolder;
              font-size: inherit;
            }
          }
        }
      }

      .switch-box {
        margin-right: 30px;

        .MuiFormControlLabel-root {
          margin-left: 5px;
          max-width: 132px;

          .MuiSwitch-root {
            padding: 0px;
            width: 39px;
            height: 20px;
            border-radius: 10px;
            margin-left: 9px;

            .MuiButtonBase-root {
              padding: 0px;
            }

            .Mui-checked {
              color: #4455c7;
            }

            .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
              background-color: #d6d9f3;
            }
          }

          .MuiTypography-root {
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            font-family: $regulerFont;
          }
        }
      }

      .reset-all {
        display: flex;
        align-items: center;

        .reset-all-text {
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          font-family: $regulerFont;
          border-bottom: 1px solid $color-Black;
        }
      }
    }

    .Coming-soon {
      margin: 0px -18px;
      background-color: #fbfbfb;
    }
  }
}

.page-content-wrap {
  margin-top: 34px;
  padding-bottom: 30px;

  .smart-top-header-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;

    @media (max-width: 940px) {
      // flex-direction: column;
      // align-items: inherit;
      margin-bottom: 0;
    }

    .team-details {
      display: flex;
      align-items: center;
      column-gap: 9px;
    }

    .header-details {

      // @media (max-width: 940px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // }

      .flex {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .day-time-details {
        font-size: 16px;
        line-height: 19px;
        color: #707070;
        font-family: $regulerFont;

        @media (max-width: 799px) {
          font-size: 11.42px;
          line-height: 16px;
        }
      }

      .dark-color {
        color: $color-Black;
        font-weight: 600;

        .sport-icon {
          margin-right: 4.4px;
          width: 20px;
          height: 20px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .m-0 {
        margin: 0px;
      }

      .title-txt {
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: $color-Primary;
        margin-left: 2px;
      }

      .title-text {
        display: flex;
        font-weight: 600;
        font-family: $primaryFont;
        color: #000000;
        font-size: 16px;
        line-height: 21px;
        margin-left: 3px;
      }

      .chip {
        background-color: #4455c7;
        border-radius: 23px;
        padding: 3px 6px 3px 6px;
        margin-left: 11px;
      }

      .chip-txt {
        font-weight: 400;
        font-family: $primaryFont;
        color: #fff;
        font-size: 16px;
        line-height: 31.36px;

        @media (max-width: 890px) {
          font-size: 11.42px;
        }
      }

      .gray-color {
        color: #989898;
        font-weight: 400;
      }
    }

    .bb {
      border-bottom: 1px solid #e7e9ec;
    }

    .Updated-date {
      display: flex;
      align-items: center;

      @media (max-width: 940px) {
        justify-content: space-between;
        margin-top: 10px;
      }

      .date {
        font-size: 16px;
        line-height: 19px;
        color: #989898;
        font-weight: 400;
        font-family: $regulerFont;
        margin-right: 13px;

        @media (max-width: 799px) {
          font-size: 11.42px;
          line-height: 16px;
        }
      }

      .delete-box {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background-color: #ccd7e0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .see-all-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      @media (max-width: 869px) {
        justify-content: space-between;
      }

      // .see-all-btn{
      .btn-txt {
        font-weight: 600;
        font-family: $regulerFont;
        color: #4455c7;
        font-size: 16px;
        line-height: 21px;
        text-decoration: underline;
        margin-right: 13.8px;

        @media (max-width: 869px) {
          font-size: 11.42px;
        }
      }

      // }
    }

    .live-box {
      margin-top: 8px;

      .live-indicator {
        padding: 3px 11px;
        background-color: $color-Negative;
        color: $color-White;
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        border-radius: 3px;
        margin-right: 13px;
        // position: absolute;
        left: 0px;
      }
    }
  }

  .blackbook-runner-details {
    padding: 15px 18px 20px;
    box-shadow: 0px 1px 3px 0px #00000033;
    border-radius: 8px;

    @media (max-width: 560px) {
      padding: 15px 12px 20px;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .title-bottom-border {
      @media (max-width: 940px) {
        border-bottom: 1px solid #e7e9ec;
      }
    }

    .bottom-border {
      border-bottom: 1px solid #e7e9ec;
      padding-bottom: 7px;

      @media (max-width: 940px) {
        border-bottom: 0;
        padding-bottom: 0px;
      }
    }

    .flex-grow {
      flex-grow: 1;
    }

    .tdetails {
      font-family: $regulerFont;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #191919;

      @media (max-width: 799px) {
        font-size: 14px !important;
      }

      @media (max-width: 499px) {
        font-size: 12px !important;
      }
    }

    .mt-9 {
      margin-top: 9px;

      @media (max-width: 499px) {
        margin-top: 0px;
      }
    }



    .smart-details {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // margin-bottom: 27px;
      margin-bottom: 18px;

      @media (max-width: 869px) {
        flex-direction: column-reverse;
        align-items: inherit;
      }

      .mr-11 {
        margin-top: 11px !important;
      }

      .detail-row1 {
        display: flex;
        align-items: center;
        column-gap: 71px;

        // @media (max-width: 869px) {
        //   display: block;
        // }

        .detail-row {
          // display: flex;
          // align-items: center;
          // column-gap: 71px;

          .mt-4 {
            @media (max-width: 869px) {
              margin-top: 4px;
            }
          }

          // .tdetails {
          //   font-family: $regulerFont;
          //   font-weight: 400;
          //   font-size: 16px;
          //   line-height: 24px;
          //   color: #191919;

          //   @media (max-width: 799) {
          //     font-size: 14px;
          //   }
          //   @media (max-width: 499) {
          //     font-size: 12px;
          //   }
          // }
        }

        .column-gap-195 {
          column-gap: 195px !important;
        }

        .title-txt {
          font-family: $primaryFont;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: $color-Primary;
          margin-left: 2px;
        }
      }

      .team-img-wrap {
        width: 36px;
        height: 36px;
        border-radius: 50%;

        .team-img {
          border-radius: 50%;
          width: 100%;
          object-fit: contain;
        }

        @media (max-width: 939px) {
          margin: 0px auto;
        }
      }

      .team-details {
        display: flex;
        align-items: center;
        column-gap: 9px;
      }

      .header-details {
        @media (max-width: 869px) {
          border-bottom: 1px solid #e7e9ec !important;

          display: flex !important;
          justify-content: space-between !important;
          align-items: center !important;

          // padding-bottom: 7px;
        }

        .flex {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .day-time-details {
          font-size: 16px;
          line-height: 19px;
          color: #707070;
          font-family: $regulerFont;

          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 16px;
          }
        }

        .dark-color {
          color: $color-Black;
          font-weight: 600;

          .title-txt {
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: $color-Primary;
            margin-left: 2px;
          }
        }

        .title-text {
          display: flex;
          font-weight: 600;
          font-family: $primaryFont;
          color: #000000;
          font-size: 16px;
          line-height: 21px;
          margin-left: 3px;
        }

        .chip {
          background-color: #4455c7;
          border-radius: 23px;
          padding: 3px 6px 3px 6px;
          margin-left: 23px;
        }

        .chip-txt {
          font-weight: 400;
          font-family: $primaryFont;
          color: #fff;
          font-size: 16px;
          line-height: 31.36px;
        }

        .gray-color {
          color: #989898;
          font-weight: 400;
        }
      }

      .Updated-date {
        display: flex;
        align-items: center;

        @media (max-width: 869px) {
          justify-content: space-between;
        }

        .date {
          font-size: 16px;
          line-height: 19px;
          color: #989898;
          font-weight: 400;
          font-family: $regulerFont;
          margin-right: 13px;

          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 16px;
          }
        }

        .delete-box {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background-color: #ccd7e0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .see-all-box {
        display: flex;
        align-items: center;
        cursor: pointer;

        @media (max-width: 869px) {
          justify-content: space-between;
        }

        // .see-all-btn{
        .btn-txt {
          font-weight: 600;
          font-family: $regulerFont;
          color: #4455c7;
          font-size: 16px;
          line-height: 21px;
          text-decoration: underline;
          margin-right: 13.8px;
        }

        // }
      }
    }

    .runner-deatils {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;

      @media (max-width: 939px) {
        flex-direction: column;
      }

      .light {
        opacity: 0.7;
      }

      .runner-info {
        width: 35%;

        @media (max-width: 939px) {
          width: 100%;
        }

        .title-txt {
          font-family: $primaryFont;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: $color-Primary;
          margin-left: 2px;
        }

        .runner-name {
          font-size: 16px;
          line-height: 19px;
          font-family: $regulerFont;
          font-weight: 600;
          color: #003764;
          text-decoration: underline;
          margin-bottom: 10px;

          @media (max-width: 799px) {
            flex-direction: column;
          }
        }

        .track-flag {
          display: flex;
          align-items: flex-end;

          .flag {
            margin-right: 9px;
            width: 37px;
          }
        }

        .info-details {
          @media (max-width: 799px) {
            display: flex;
            justify-content: space-between;
          }
        }

        .runner-info-details {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          font-family: $regulerFont;
          color: #000000;

          @media (max-width: 799px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .distance-track-wrap {
          @media (max-width: 899px) {
            display: flex;
            justify-content: space-between;
            padding-top: 7px;
          }
        }

        .color-purple {
          color: #003764;
        }

        .team-img-wrap {
          width: 36px;
          height: 36px;

          .team-img {
            border-radius: 50%;
            width: 100%;
            object-fit: contain;
          }

          @media (max-width: 939px) {
            margin: 0px auto;
          }
        }

        .runner-tc {
          border-bottom: 0 !important;
          padding: 10px !important;

          display: flex;
          column-gap: 9px;
          align-items: center;

          .live-indicator {
            padding: 3px 11px;
            background-color: $color-Negative;
            color: $color-White;
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;
            border-radius: 3px;
            margin-right: 13px;
            // position: absolute;
            left: 0px;
          }
        }

        .left-box {
          padding: 27px 80px 2px 50px;
          // padding: 27px 80px 27px 50px;
          width: 84%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media (max-width: 560px) {
            padding: 16px 40px 2px 25px;
            // padding: 16px 40px 16px 25px;
          }
        }

        .team-content {
          text-align: center;

          .flag {
            height: 36px;
            width: 36px;
            border-radius: 50%;
          }
        }

        .team-name {
          font-weight: 400;
          font-family: "Veneer Clean Soft" !important;
          color: $color-Primary;
          font-size: 16px;
          line-height: 23px;

          @media (max-width: 560px) {
            font-size: 14px;
            line-height: 18px;
          }
        }

        .size-22 {
          font-size: 22.4px !important;

          @media (max-width: 560px) {
            font-size: 18px !important;
          }
        }

        .img-style {
          display: flex !important;
          align-items: center !important;
          column-gap: 9px !important;
        }
      }

      .race-deatils {
        width: 65%;
        display: flex;
        align-items: end;
        flex-direction: column;

        @media (max-width: 939px) {
          width: 100%;
          align-items: flex-start;
          margin-top: 16px;
        }

        .race-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 14px 12px 13px;
          border: 1px solid #ccd7e0;
          border-radius: 8px;
          max-width: 388px;
          width: 100%;

          @media (max-width: 939px) {
            max-width: 100%;
            width: -webkit-fill-available;
          }

          .race-name {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;

            @media (max-width: 489px) {
              font-size: 12px;
              line-height: 15px;
            }
          }

          .race-time {
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;
            font-family: $regulerFont;
            color: $color-Black;
            display: flex;
            align-items: center;
            min-width: 80px;

            svg {
              margin-left: 12.3px;
            }
          }
        }

        .max-width {
          max-width: 618px !important;
        }

        .odds-deatils {
          margin-top: 9px;
          max-width: 412px;
          width: 100%;

          @media (max-width: 939px) {
            max-width: initial;
          }

          .odds-table {
            border: 1px solid #ccd7e0;

            .MuiTableCell-head {
              border: none;
              padding: 6px 40px 8px;
              text-align: center;
              font-size: 16px;
              line-height: 19px;
              font-family: $regulerFont;
              font-weight: 600;

              @media (max-width: 489px) {
                padding: 6px 0px 8px;
                font-size: 11.42px;
                line-height: 14px;
              }
            }

            .w-89 {
              width: 89px !important;
            }

            .sport-score {
              background-color: #003764;
              color: #ffffff;
              text-align: center;
            }

            .best-at-open {
              background-color: #e2e4f1;
              color: #191919;
            }

            .current-best {
              background-color: #4455c7 !important;
              color: #ffffff;
            }

            .MuiTableCell-body {
              padding: 9px 0px;
            }

            .odds-wrap {
              display: flex;
              align-items: center;
              justify-content: center;

              @media (max-width: 799px) {
                flex-direction: column;
              }

              .win-icon {
                width: 7px;
                height: 11px;
                display: flex;

                svg {
                  width: 100%;
                  height: 100%;
                  transform: rotate(180deg);
                }
              }

              span {
                font-size: 16px;
                line-height: 24px;
                font-family: $regulerFont;
                color: #000000;
                margin-right: 10px;

                @media (max-width: 799px) {
                  margin-right: 0px;
                }
              }

              img {
                border-radius: 6px;
              }
            }

            .best-at-open-cell {
              background-color: rgba(212, 214, 216, 0.13);
              text-align: center;
            }

            .current-best-cell {
              background-color: #eeefff;
              text-align: center;
            }

            .border-bottom {
              border-bottom: 2px solid #ffffff !important;
            }

            .border-right {
              border-right: 2px solid #ffffff !important;
            }
          }
        }

        .f-white {
          color: $color-White !important;
        }

        .ft-txt {
          font-size: 16px !important;
          line-height: 24px !important;
          font-weight: 600 !important;
          font-family: $regulerFont !important;
          color: $color-Primary;
        }



        .des-label {
          text-align: center;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
          margin-left: 9px;
          text-transform: uppercase;

          @media only screen and (max-width: 599px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }

        .des-head {
          background-color: #e7e9ec;
          padding: 9px 0px;
          margin-left: 0px !important;
        }

        .des-current-best {
          background-color: #4455c7;
          color: #ffffff;
        }

        .des-head-to-head {
          background-color: #003764;
          color: #ffffff;
        }

        .odds-label {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          text-align: center;
          max-width: 160px;
          margin: 0px auto;

          @media only screen and (max-width: 599px) {
            font-size: 11.42px;
            line-height: 14px;
            max-width: 130px;
          }
        }

        .odds-wrap {
          display: flex;
          justify-content: center;
          // margin-top: 5px;
          // align-items: flex-end;
          align-items: center;

          .odds {
            display: block;
            // padding: 8px 0px;
            // background: #d6d9f3;
            border-radius: 6px;
            font-size: 16px;
            line-height: 20px;
            color: $color-Black;
            min-width: 80px;
            text-align: center;
            filter: drop-shadow(0px 2px 2px #00000029);

            @media (max-width: 1365px) {
              min-width: 65px;
            }

            // @media (max-width:1120px) {
            //   min-width: 70px;
            // }

            @media only screen and (max-width: 599px) {
              font-size: 11.42px;
              line-height: 14px;
              min-width: 45px;
            }

            .no-odds {
              cursor: pointer;
            }

            .odds-btn {
              padding: 8px 0px;
              display: block;
              background: #d6d9f3;
              border-radius: 6px;
            }
          }

          .odds-left {
            margin-left: 5px;
            margin-right: 5px;

            @media (max-width: 1120px) {
              margin-right: 7px;
            }

            @media (max-width: 1024px) {
              margin-left: 0px;
            }

            @media only screen and (max-width: 599px) {
              margin-right: 7px;
            }
          }

          .bookmaker-long {
            text-align: center;
            min-width: 80px;
            border-radius: 6px;
            max-width: 80px;
            min-height: 36px;
            max-height: 36px;
            height: 100%;

            @media only screen and (max-width: 599px) {
              max-width: 45px;
              min-width: 45px;
              max-height: 30px;
              min-height: 30px;
            }
          }
        }

        .odds-bottom {
          margin-bottom: 21px;
        }
      }

      .track-table {
        max-width: 468px;
        height: 97px;
        border: 1px solid #d4d6d8;

        @media (max-width: 1364px) {
          max-width: initial;
        }

        .MuiTableCell-head {
          padding: 9px 16px 8px;
          border: 1px solid #d4d6d8;

          @media (max-width: 1364px) {
            padding: 9px 5px 8px;
          }
        }

        .MuiTableCell-body {
          padding: 16px 0px 15px 0px;
          border: 1px solid #d4d6d8;
          font-size: 14px;
          line-height: 16px;
          font-family: $regulerFont;
          font-weight: 400;
          color: $color-Black;
          position: relative;
          text-align: center;
        }

        .track-runner {
          width: 34px;
          height: 34px;
          margin: 0 auto;
          border-radius: 100%;
          border: 1px solid #003764;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          line-height: 19px;
          font-weight: 600;
          color: #003764;
          font-family: $regulerFont;
        }

        .paying {
          background-color: #d6d9f3;
        }

        .paying-disble {
          opacity: 0.6;
        }

        .close-secondary {
          background-color: #ffc69a;
        }

        td.fixed::after {
          content: url("../../../assets/images/Sport/fixed_mark.svg");
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    .runner-deatils-v2 {

      .runner-smartbook-table {
        border: none;

        .live-indicator {
          padding: 3px 11px;
          background-color: $color-Negative;
          color: $color-White;
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
          border-radius: 3px;
          margin-right: 13px;
          // position: absolute;
          left: 0px;
        }

        .border-bottom {
          border-bottom: 2px solid #ffffff !important;
        }

        .border-bottom-cell {
          border-bottom: 1px solid #E7E9EC !important;
        }

        .border-right {
          border-right: 2px solid #ffffff !important;
        }

        th {
          border: none;
          padding: 6px 0px;
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
          letter-spacing: 0px;
          text-transform: uppercase;
        }

        td {
          padding: 6px 0px;
          border: none;
          text-align: center;
        }

        .team-img-wrap {
          width: 36px;
          height: 36px;

          .team-img {
            border-radius: 50%;
            width: 100%;
            object-fit: contain;
          }

          @media (max-width: 939px) {
            margin: 0px auto;
          }
        }

        .title-txt {
          font-family: $regulerFont;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: $color-Black;
          margin-left: 2px;
        }

        .text-underline {
          border-bottom: 1px solid $color-Black;
          cursor: pointer;
        }

        .runner-tc {
          padding: 10px !important;
          display: flex;
          column-gap: 9px;
          align-items: center;
          min-height: 74px;
          width: 100%;
          min-width: 300px;

          .live-indicator {
            padding: 3px 11px;
            background-color: $color-Negative;
            color: $color-White;
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;
            border-radius: 3px;
            margin-right: 13px;
            // position: absolute;
            left: 0px;
          }
        }

        .sport-score {
          background-color: $color-Primary;
          color: $color-White;
          text-align: center;
          border-bottom: 1px solid $color-White;
        }

        .best-at-open {
          background-color: #e2e4f1;
          color: #191919;
          text-align: center;
        }

        .current-best {
          background-color: $color-Accent-1 !important;
          color: $color-White;
          text-align: center;
        }

        .current-best-cell {
          border-left: 1px solid #E7E9EC;
          border-right: 1px solid #E7E9EC;
          text-align: center;
          padding: 0px 26px;

          .current-best-odds-value {
            display: block;
            background-color: $color-Lavender;
            padding: 9px 0px;
            width: 80px;
            margin: 0px auto;
            border-radius: 6px 6px 0px 0px;
            text-align: center;
          }

          .current-best-odds-icon {
            border-radius: 0px 0px 6px 6px;
            width: 80px;
            margin: 0px auto;

            .currentbest-bookmaker-thumb {
              margin: 0px auto;
              border-radius: 0px 0px 6px 6px;
              cursor: pointer;
              height: 25px;
              object-fit: cover;
              width: 100%;
              max-width: none;
            }
          }

        }

        .featured-odds {
          background-color: $color-Primary !important;
          color: $color-White;
          text-align: center;
          border-left: 1px solid $color-White;
        }

        .best-at-open-cell {
          background-color: rgba(212, 214, 216, 0.13);
          text-align: center;
        }
      }
    }

    .odds-wrap-win {
      display: flex;
      align-items: center;
      justify-content: center;


      .win-icon {
        width: 7px;
        height: 11px;
        display: flex;

        svg {
          width: 100%;
          height: 100%;
          transform: rotate(180deg);
        }
      }

      span {
        font-size: 16px;
        line-height: 24px;
        font-family: $regulerFont;
        color: #000000;
        margin-right: 10px;

        @media (max-width: 799px) {
          margin-right: 0px;
        }
      }
    }

    .track-details {
      @media (max-width: 1364px) {
        flex-direction: column;
      }
    }

    .comment-section {
      display: flex;
      justify-content: space-between;
      padding-top: 9px;
      border-top: 1px solid #e7e9ec;

      .width-86 {
        width: 86%;
      }

      .width-14 {
        width: 14%;

        @media (max-width: 1210px) {
          width: 16%;
        }

        @media (max-width: 1110px) {
          width: 18%;
        }

        @media (max-width: 1040px) {
          width: 22%;
        }

        @media (max-width: 922px) {
          width: 24%;
        }

        @media (max-width: 890px) {
          width: 26%;
        }

        @media (max-width: 850px) {
          width: 32%;
        }

        @media (max-width: 799px) {
          width: 16%;
        }

        @media (max-width: 674px) {
          width: 24%;
        }

        @media (max-width: 510px) {
          width: 30%;
        }

        @media (max-width: 440px) {
          width: 36%;
        }
      }

      .runner-comment {
        .runner {
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;

          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 16px;
          }
        }
      }

      .edit-comment {
        display: flex;
        align-items: center;
        justify-content: end;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;
        text-decoration-line: underline;

        @media (max-width: 799px) {
          font-size: 11.42px;
          line-height: 22.4px;
        }
      }
    }

    .Mobile-racing-data {
      .racing-data-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0px;
        border: 1px solid $color-Light-grey;

        .track-name {
          font-family: $regulerFont;
          font-size: 15px;
          line-height: 19px;
          color: $color-Primary;
          text-decoration: underline;
          font-weight: 600;
          padding-left: 6px;
        }

        .track-code {
          font-family: $regulerFont;
          font-size: 12px;
          line-height: 12px;
          color: $color-Primary;
        }

        .blackbook-tooltip {
          display: flex;

          .custom-tooltip {
            padding-left: 5px;

            .tooltip {
              transform: translateX(-22%);
              width: max-content;
              top: -43px;

              .add-blackbook {
                border-bottom: none;
              }
            }
          }
        }

        .track-country {
          font-family: $secondaryRegularFont;
          font-size: 12px !important;
          line-height: 14px;
          color: black;
        }

        .track-detail {
          font-family: $secondarySemiboldFont !important;
          font-size: 12px !important;
          font-size: 16px;
          color: #0a1623;
          padding: 16px 0px 15px 0px;

          .MuiTableCell-root {
            padding: 0px;
          }

          span {
            font-family: $secondarySemiboldFont !important;
            font-size: 12px !important;
            font-size: 16px;
            color: #0a1623;
          }
        }

        .MuiIconButton-label {
          svg {
            path {
              fill: $color-Accent-1;
            }
          }
        }
      }

      .racing-menu-collapse {
        background-color: #e7e9ec;
      }

      .collapse-section {
        border-bottom: 1px solid #003764;
      }

      .upcoming-list {
        display: flex;
        align-items: center;

        .collapse-menu {
          border-bottom: none;

          td {
            padding: 0px 10px;
          }
        }
      }

      .collapse-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $color-Light-grey;
        border-left: 1px solid $color-Light-grey;
        border-right: 1px solid $color-Light-grey;
        position: relative;

        &.fixed::after {
          content: url("../../../assets/images/Sport/fixed_mark.svg");
          position: absolute;
          top: 0;
          right: 0;
        }

        .MuiTableCell-root {
          padding: 9px 10px;
        }

        td {
          border-bottom: none;

          @media (max-width: 799px) {
            // width: 80%;
            text-align: start;
          }
        }

        .race-tag {
          padding: 3px 6px;
          width: 44px;
          border-radius: 6px;
          font-size: 11.42px;
          font-weight: 400;
          line-height: 14px;
          font-family: $regulerFont;
          text-align: center;
        }

        .race-tag-result {
          background-color: #d6d9f3;
          color: #191919;
          border: 0.5px solid transparent;
          margin-right: 30px;
        }

        .race-tag-close {
          background-color: #ffc69a;
          color: #191919;
          border: 0.5px solid transparent;
          margin-right: 30px;
        }

        .race-tag-upcoming {
          background-color: #fc4714;
          color: white;
          border: 0.5px solid transparent;
        }

        .race-tag-secondary-close {
          border: 0.5px solid #191919;
          color: #191919;
          margin-right: 30px;
        }
      }

      .collapse-td {
        td {
          @media (max-width: 799px) {
            width: 73%;
            text-align: start;
          }
        }
      }

      td.paying {
        span {
          color: #989898;
          font-weight: 600;
          font-family: $regulerFont;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }

  .mobileview-smartbook-table-wrap {
    width: 100%;
    // padding: 18px 0px 2px;

    .time-market-team-wrap {
      .mobile-temsport-indicator {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 40px;
        position: relative;

        .live-indicator {
          padding: 3px 11px;
          background-color: $color-Negative;
          color: $color-White;
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
          border-radius: 3px;
          margin-right: 13px;
          position: absolute;
          left: 0px;
        }
      }

      .teams-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        line-height: 20px;
        font-family: $primaryFont;
        color: $color-Black;
        padding: 19px 40px 12px;

        .team-wrap {
          display: flex;
          align-items: center;
          flex-direction: column;
          align-self: flex-start;
          width: 45%;

          .team-img-wrap {
            .team-img {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              object-fit: contain;
            }
          }

          span {
            font-size: 16px;
            line-height: 20px;
            font-family: $primaryFont;
            color: $color-Black;
          }

          .team-name {
            padding-top: 16px;
            text-align: center;
          }
        }
      }
    }

    .score {
      background-color: $color-Primary;
      color: $color-White;
    }

    .score-padding-mobile {
      // padding: 17px 158px 21px;

      @media (max-width: 700px) {
        padding: 17px 60px 21px !important;
      }

      @media (max-width: 600px) {
        padding: 17px 50px 21px !important;
      }

      @media (max-width: 400px) {
        padding: 17px 40px 21px !important;
      }
    }

    .odds-detail {
      // padding: 5px 40px 23px;
      padding: 17px 40px 21px;
      // border-bottom: 1px solid $color-grey !important;
      position: relative;

      .odds-body {
        display: flex;
        justify-content: space-between;
        align-items: center;

        // &::after {
        //   content: "";
        //   background-color: $color-grey;
        //   height: 100%;
        //   width: 0.6px;
        //   position: absolute;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   margin: 0 auto;
        // }

        .win-icon {
          width: 7px;
          height: 11px;
          display: flex;

          svg {
            width: 100%;
            height: 100%;
            transform: rotate(180deg);
          }
        }
      }
    }

    .result-odds-details {
      background-color: #FAFAFA;

      .odds-wrap-win {
        display: flex;
        align-items: center;
        column-gap: 7px;
      }
    }

    .odds-details-best {
      background-color: #eeefff !important;
    }

    // .odds-after {
    //   border-bottom: 1px solid #d4d6d8 !important;

    //   &::after {
    //     content: "";
    //     background-color: $color-grey;
    //     height: 0.6px;
    //     width: 100%;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     margin: 0 auto;
    //     top: 50%;
    //   }
    // }

    .odds-detail:last-child {
      border-bottom: none;
    }

    .mobile-chart-deatils {
      position: relative;

      .mobile-hight-chart {
        display: flex;
        align-items: center;
        justify-content: space-around;
        border: 1px solid #d4d6d8;

        &::after {
          content: "";
          background-color: $color-grey;
          height: 100%;
          width: 0.6px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 auto;
        }

        .ptb-10 {
          padding: 10px 0px;
        }

        .nodata {
          font-family: $regulerFont;
        }
      }
    }

    .mobile-tennis-table {
      padding: 0px !important;

      .odds-body {
        display: flex;
        align-items: center;
        justify-content: center;

        .w-50 {
          width: 50%;
        }

        .team-name {
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 500;
          font-family: $regulerFont;
          color: $color-White;
          letter-spacing: 0px;
          text-align: center;
        }

        .border-r {
          border-right: 3px solid $color-White;
        }

        .tennis-table {

          th {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-White;
            letter-spacing: 0px;
            text-align: center;
            padding: 6px 0px;
          }

          td {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            font-family: $regulerFont;
            color: $color-White;
            letter-spacing: 0px;
            text-align: center;
            padding: 6px 0px;
            border-bottom: none;
          }
        }
      }
    }

    .result-tennis-table {
      th {
        color: $color-Primary !important;
        border-bottom: 1px solid $color-Primary !important;
      }

      td {
        color: $color-Primary !important;

        .odds-wrap-win {
          justify-content: center;
        }
      }


    }

    .result-team-name {
      color: $color-Primary !important;
    }
  }

  .mobile-smartbook-upcoming {
    padding: 15px 12px 20px;
    box-shadow: 0px 1px 3px 0px #00000033;
    border-radius: 8px;
    margin-top: 5px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .bottom-border {
      border-bottom: 1px solid #E7E9EC;
    }

    .smart-bottom-header-details {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .chip {
        background-color: #4455c7;
        border-radius: 23px;
        padding: 3px 6px 3px 6px;
      }

      .chip-txt {
        font-weight: 400;
        font-family: $primaryFont;
        color: #fff;
        font-size: 16px;
        line-height: 31.36px;

        @media (max-width: 890px) {
          font-size: 11.42px;
        }
      }

      .btn-txt {
        font-weight: 600;
        font-family: $regulerFont;
        color: #4455c7;
        font-size: 16px;
        line-height: 21px;
        text-decoration: underline;

        @media (max-width: 869px) {
          font-size: 11.42px;
        }
      }
    }

    .teams-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      font-family: $primaryFont;
      color: $color-Black;
      padding: 19px 40px 12px;


      @media (max-width: 1023px) {
        padding: 19px 0px 0px;
      }

      .teams-details-wrap {
        width: 100%;

        .home-team-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 12px 9px;
          border-bottom: 1px solid $color-Light-grey;
        }

        .away-team-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 9px 12px 9px;
        }

        .current-best-odds-value {
          display: block;
          background-color: $color-Lavender;
          padding: 9px 0px;
          width: 80px;
          margin: 0px auto;
          border-radius: 6px 6px 0px 0px;
          text-align: center;
        }

        .current-best-odds-icon {
          border-radius: 0px 0px 6px 6px;
          width: 80px;
          margin: 0px auto;

          .currentbest-bookmaker-thumb {
            margin: 0px auto;
            border-radius: 0px 0px 6px 6px;
            cursor: pointer;
            height: 25px;
            object-fit: cover;
            width: 100%;
            max-width: none;
          }
        }
      }

      .mobile-table-container {
        .odds-table {
          border: 1px solid #ccd7e0;

          .MuiTableCell-head {
            border: none;
            padding: 6px 42px 8px;
            text-align: center;
            font-size: 16px;
            line-height: 19px;
            font-family: $regulerFont;
            font-weight: 600;
            text-transform: uppercase;

            @media (max-width: 489px) {
              padding: 6px 0px 8px;
              font-size: 11.42px;
              line-height: 14px;
            }
          }



          .current-best {
            background-color: #4455c7 !important;
            color: #ffffff;
          }

          .featured-odds {
            background-color: $color-Primary;
            color: $color-White;
          }

          .MuiTableCell-body {
            padding: 9px 0px;
          }

          .odds-wrap {
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 799px) {
              flex-direction: column;
            }

            span {
              font-size: 16px;
              line-height: 24px;
              font-family: $regulerFont;
              color: #000000;
              margin-right: 10px;

              @media (max-width: 799px) {
                margin-right: 0px;
              }

              .no-odds {
                padding: 6px 12px;
                background-color: #989898;
                color: #fff;
                border-radius: 14px;
                font-size: 12px;
                cursor: pointer;
              }
            }

            img {
              border-radius: 6px;
            }
          }

          .best-at-open-cell {
            background-color: rgba(212, 214, 216, 0.13);
          }

          .current-best-cell {
            background-color: #eeefff;
          }

          .border-right {
            border-right: 2px solid #ffffff !important;
          }

          .hide-scroll::-webkit-scrollbar {
            display: none;
          }

          .hide-scroll {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }

          .max-w {
            max-width: 505px;
            width: 100%;
            margin: 0 auto;
            overflow-x: auto;
            overflow-y: hidden;
            padding-top: 9px;
            padding-bottom: 9px;
            overflow-x: auto;
            overflow-y: hidden;

            @media (max-width: 660px) {
              padding: 15px 0px;
              max-width: 354px;
            }

            .odds-flex {
              display: flex;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              column-gap: 29px;

              @media (max-width: 799px) {
                column-gap: 15px;
              }

              @media (max-width: 660px) {
                column-gap: 6px;
              }

              .odds-box-style {
                text-align: center;
                height: 64px;

                .odds-style {
                  cursor: pointer;
                  max-width: none;
                  width: 60px;
                  text-align: center;
                  padding: 6.5px 0px;
                  border-radius: 6px;
                  margin: 0px auto;
                  background-color: $color-Light-grey;
                  box-shadow: 0px 2px 1px 0px #707070;

                  @media (max-width: 660px) {
                    padding: 6.5px 0px;
                    width: 54px;
                  }

                  @media (max-width: 389px) {
                    width: 48px;
                  }

                  .odds {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: $regulerFont;
                    color: $color-Black;
                    letter-spacing: 0px;

                    @media (max-width: 660px) {
                      font-size: 11.42px;
                      line-height: 14px;
                    }
                  }
                }

                .active-odd-style {
                  background-color: $color-Primary;

                  span,
                  .odds {
                    color: $color-White;
                  }
                }

                .odd-img {
                  width: 60px;
                  height: 25px;
                  margin: 0 auto;
                  margin-top: 6px;

                  @media (max-width: 660px) {
                    // max-width: 54px;
                    width: 54px;
                  }

                  @media (max-width: 389px) {
                    width: 48px;
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                    border-radius: 8px;
                  }
                }
              }
            }
          }
        }

        .result-odds-table {
          margin-left: auto;
          max-width: 225px;
        }
      }

      .team-wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 70%;
        min-height: 68px;

        .team-img-wrap {
          margin-right: 8px;

          .team-img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            object-fit: contain;

            @media (max-width: 799px) {
              width: 32px;
              height: 32px;
            }
          }
        }

        .team-name {
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
          font-family: $regulerFont;
          color: $color-Black;
          text-align: left;
          // border-bottom: 1px solid $color-Black;
        }

        .text-underline {
          border-bottom: 1px solid $color-Black;
          cursor: pointer;
        }
      }
    }

    .comment-section {
      display: flex;
      justify-content: space-between;
      padding-top: 9px;
      border-top: 1px solid #e7e9ec;

      .width-86 {
        width: 86%;
      }

      .width-14 {
        width: 14%;

        @media (max-width: 1210px) {
          width: 16%;
        }

        @media (max-width: 1110px) {
          width: 18%;
        }

        @media (max-width: 1040px) {
          width: 22%;
        }

        @media (max-width: 922px) {
          width: 24%;
        }

        @media (max-width: 890px) {
          width: 26%;
        }

        @media (max-width: 850px) {
          width: 32%;
        }

        @media (max-width: 799px) {
          width: 16%;
        }

        @media (max-width: 674px) {
          width: 24%;
        }

        @media (max-width: 510px) {
          width: 30%;
        }

        @media (max-width: 440px) {
          width: 36%;
        }
      }

      .runner-comment {
        .runner {
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;

          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 16px;
          }
        }
      }

      .edit-comment {
        display: flex;
        align-items: center;
        justify-content: end;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;
        text-decoration-line: underline;

        @media (max-width: 799px) {
          font-size: 11.42px;
          line-height: 22.4px;
        }
      }
    }
  }

  .pagination-section {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    @media (max-width: 799px) {
      margin-top: 30px;
    }

    .MuiPagination-root {
      .MuiPagination-ul {
        .MuiButtonBase-root {
          width: 36px;
          height: 36px;

          @media (max-width: 799px) {
            font-size: 14px;
            width: 24px;
            height: 24px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
            width: 22px;
            height: 22px;
          }

          svg {
            width: 24px;
            height: 24px;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }

        .MuiPaginationItem-root {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          border-radius: 50%;

          @media (max-width: 799px) {
            font-size: 14px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
          }
        }
      }
    }
  }
}

// result tab

.result-runner {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .runner-name {
    margin-bottom: 0px !important;
  }

  .postion {
    margin-left: 11px;
    width: 36px;
    height: 21px;
    border-radius: 7px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $color-White;
    font-family: $regulerFont;
  }

  .first-postion {
    background-color: #fc4714;
  }

  .second-postion {
    background-color: #5d5d5d;
  }
}

.track-condition {
  padding: 0 5px;
  border-radius: 10px / 5px;
  color: $color-White;
  background: #4455c7;
}

// blackbook

.blackbook-details-mobile {
  @media (max-width: 939px) {
    flex-direction: row !important;

    .runner-info {
      width: auto !important;
    }
  }

  @media (max-width: 799px) {
    flex-direction: column-reverse !important;

    .track-blackbook-name {
      .track-flag {
        flex-direction: inherit !important;
      }
    }
  }
}

.blackbook-ruuner-info {
  width: auto !important;
}

.blackbook-details {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;

  .details {
    font-size: 16px;
    line-height: 24px;
    font-family: $regulerFont;
    font-weight: 500;
    color: #000000;

    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 16px;
    }

    span {
      font-weight: 400;
    }
  }

  .txt-undurline {
    text-decoration: underline !important;
  }

  .mr57 {
    margin-right: 57px;
  }
}

.black-book-header {
  margin-bottom: 0px !important;
}

.disable-state {
  .select__placeholder {
    opacity: 0.4;
  }
}

.blackBook-dialog.blackbook-wrap {
  width: 1122px;
  max-width: 96%;
  margin: 0 auto;

  .dialog-content-box {
    min-height: 90vh;
    max-height: 90vh;
  }

  .MuiDialog-paper {
    max-width: 100%;
    width: 100%;
    overflow-y: auto;
    // padding: 45px 42px;
    margin: 0px;
  }

  .modal-section {
    padding: 18px 25px 19px;

    @media (max-width: 479px) {
      padding: 12px;
    }

    .teamsport-tab-detail {
      margin-top: 0px;
      border-bottom: 2px solid #4455c7;

      .MuiTabs-scroller {
        .MuiTabs-indicator {
          display: none;
        }

        .active {
          border-bottom: 3px solid $color-Primary;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .MuiButtonBase-root.active {
        color: $color-Accent-1;
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .MuiButtonBase-root {
        font-size: 16px;
        font-family: VeneerClean-Soft !important;
        color: #191919;
        line-height: 21px;
      }

      .MuiTab-root {
        min-width: 120px;

        @media (max-width: 479px) {
          min-width: 85px;
        }
      }

      .MuiTabs-indicator {
        height: 3px;
        color: #003764;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }

    .search_input {
      padding-top: 18px;
    }
  }

  .smartsports-container {
    padding: 18px 25px 19px;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 479px) {
      padding: 18px 12px 19px;
    }

    .trackprofilelist-wrap {
      padding: 0px;

      .scrollableDiv {
        .infinite-scroll-component {
          overflow-x: hidden !important;
        }

        .trackprofile-list {
          width: 100%;
          padding: 8px 12px 8px 0px;
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: #ccd7e0;
          }

          span {
            padding: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .dialog-close {
    padding: 0px;
    position: absolute;
    right: 18px;
    top: 18px;

    @media (max-width: 479px) {
      right: 12px;
      top: 12px;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .blackBook-content-wrap {
    padding: 0px;

    // padding: 18px 42px 27px;
    @media (max-width: 599px) {
      // padding: 9px 18px 27px;
    }

    h1 {
      padding: 0px 25px;
      margin: 18px 0px;

      @media (max-width: 479px) {
        font-size: 35px;
        padding: 0px 12px;
        margin: 12px 0px;
      }
    }
  }
}

.no-odds {
  padding: 6px 12px;
  background-color: $color-grey-Accent-1;
  color: $color-White;
  border-radius: 14px;
  font-size: 12px;
  cursor: pointer;
}