@import "../../../../assets/scss/variable.scss";

.home-ranking {
  .stepper-score-sec {
    padding-top: 15px;
    padding-bottom: 18px;

    .stepper-input {
      // margin-top: 15px;
      display: flex;
      //   border: 1px solid $color-gray-dark-1;
      border-radius: 8px;
      max-height: 44px;
      column-gap: 3px;

      .stepper-input__button {
        padding: 10px 30px;
        min-width: 35px;
        cursor: pointer;
        color: $color-Black;
        border-left: 0px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        background-color: #e8eaec;
        //   background-color: #00000042;

        @media (max-width: 540px) {
          font-size: 12px;
        }
      }

      .Next-btn {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }

      .stepper-input__content {
        border: 1px solid $color-gray-dark-1;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        padding: 8px 26px;

        color: $color-Black;
        width: 100%;
        text-align: center;
      }

      .margin-textfield {
        width: 100%;

        .MuiInputBase-root {
          height: 42px;

          input {
            padding: 9px 10px;
            text-align: center;
            // border-left: 1px solid $color-gray-dark-1;
            // border-right: 1px solid $color-gray-dark-1;
            border: 1px solid $color-gray-dark-1;
          }

          fieldset {
            border: none;
          }
        }
      }

      @media only screen and (max-width: 479px) {
        grid-column-start: 1;
        grid-column-end: 4;
      }

      .tipping-select {
        width: 100%;
        .select__control {
          border-radius: 0px;
          max-height: 44px;
          max-width: 100%;
          border-color: hsl(0, 0%, 80%);
          box-shadow: none;

          .select__single-value {
            font-family: $regulerFont !important;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            color: $color-Black;
            padding: 0px 8px;
            text-align: center;
          }

          .select__placeholder {
            font-family: $regulerFont !important;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            color: $color-Black;
            padding: 0px 8px;
          }

          .select__input-container {
            font-family: $regulerFont !important;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            color: $color-Black;
            padding: 0px 8px;
          }

          .select__indicator-separator {
            width: 0px;
          }

          .select__dropdown-indicator {
            padding: 10px;
          }
        }

        .select__menu {
          margin: 0px;
          border-radius: 0px;
          padding: 0px;
          z-index: 999;
        }

        .select__menu-list {
          padding: 0px;
        }

        // Select Menu
        .select__menu,
        .react-select__menu {
          .select__menu-list,
          .react-select__menu-list {
            .select__option,
            .react-select__option {
              cursor: pointer;
              font-size: 16px;
              color: $color-Black;
              font-family: $regulerFont;
              line-height: 19px;
              padding: 11px 40px 11px 11px;
              text-align: center;

              &.select__option--is-focused {
                background-color: #d4d6d8;
                color: #000;
              }

              &.select__option--is-selected {
                background-color: #d4d6d8;
                color: $color-Black;
              }
            }
          }

          .select__menu-list,
          .react-select__menu-list {
            .select__group {
              .select__group-heading {
                margin-bottom: 0.5rem;
                color: green;
                font-weight: bolder;
                font-size: inherit;
              }
            }
          }
        }
        .select__menu-portal {
          z-index: 99;
        }
      }
    }

    .score-share-sec {
      display: flex;
      justify-content: space-between;
      align-items: end;
      width: 100%;
      margin-top: 16px;

      @media (max-width: 620px) {
        display: block;
      }

      .score-sec {
        width: 80%;

        @media (max-width: 620px) {
          width: 100%;
        }

        .round-score-sec {
          max-width: 460px;
          width: 100%;
          display: flex;
          align-items: center;
          column-gap: 18px;

          @media (max-width: 620px) {
            justify-content: space-between;
            max-width: unset;
          }

          .round-score {
            column-gap: 25px;
            max-width: 193px;
            width: 100%;
            padding: 11px 30px 12px 18px;
            border: 1px solid $color-Light-grey;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 620px) {
              padding: 11px 22px 12px 9px;
              column-gap: 18px;
            }

            .score {
              font-weight: 400;
              font-size: 16px;
              color: $color-Black;
              line-height: 21px;
              font-family: $regulerFont;
            }
          }
        }

        .bottom-line-sec {
          .color-danger {
            color: $color-Negative !important;
          }

          .final-txt {
            margin-top: 8px;
            display: flex;
            align-items: center;
            column-gap: 5px;

            font-weight: 400;
            font-size: 16px;
            color: $color-Accent-1;
            line-height: 21px;
            font-family: $regulerFont;

            @media (max-width: 580px) {
              display: block;
            }

            @media (max-width: 900px) {
              font-size: 14px;
            }

            @media (max-width: 799px) {
              font-size: 16px;
            }

            @media (max-width: 650px) {
              font-size: 15px;
            }

            @media (max-width: 460px) {
              font-size: 12px;
            }

            // .comp-header-details {
            //   .day-time-details {
            //     //   font-weight: 400;
            //     //   font-family: $regulerFont;
            //     //   color: $color-Black;
            //     //   font-size: 16px;
            //     //   line-height: 21px;

            //     //   @media (max-width: 900px) {
            //     //     font-size: 14px;
            //     //   }
            //     //   @media (max-width: 799px) {
            //     //     font-size: 16px;
            //     //   }

            //     //   @media (max-width: 650px) {
            //     //     font-size: 15px;
            //     //   }
            //   }
            // }
          }
        }
      }
    }
  }
  .home-rank-table-wrap {
    border-radius: 8px;
    box-shadow: 0px 1px 9px 0px #0000003b;
    .new-ladder-sec {
      // overflow: scroll;

      overflow-x: auto;
      position: relative;
      .new-ladder-table {
        .primary-font-color {
          color: $color-Primary !important;

          @media (max-width: 799px) {
            font-size: 11.42px !important;
            line-height: 14px !important;
          }
        }

        .highlight-border {
          border-bottom: 1px solid $color-Accent-1;
          color: $color-Accent-1 !important;
          font-weight: 600 !important;
        }

        .table-cell-center {
          text-align: center;
        }

        .MuiTableRow-head {
          .MuiTableCell-head {
            padding: 13px 0px 12px 12px;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0px;
            font-weight: 600;
            color: $color-Black;
            background-color: $color-Light-grey-1;

            @media (max-width: 799px) {
              font-size: 15px;
              line-height: 18px;
            }
          }
        }

        .MuiTableBody-root {
          // .rank-name {
          //   padding-left: 8px;
          // }
          .MuiTableCell-body {
            padding: 6px 0px 5px 15px;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0px;
            font-weight: 400;
            color: $color-Black;

            @media (max-width: 799px) {
              font-size: 15px;
              line-height: 18px;
            }
          }

          .tipper-img-wrap {
            display: flex;
            align-items: center;
            column-gap: 9px;
            @media (max-width: 479px) {
              span {
                width: 100px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .profile-icon-wrap {
              max-width: 36px;
              min-width: 36px;
              height: 36px;
              border-radius: 50%;
              .profile-icon {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 50%;
              }
            }
            .icon-border {
              border: 1px solid $color-Accent-1;
            }
          }
        }
        .sorting-cell {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 5px;
          cursor: pointer;
          .sorting-arrow-wrap {
            display: flex;
            flex-direction: column;
            row-gap: 2px;
            cursor: pointer;
            .active {
              path {
                fill: $color-Accent-1;
              }
            }
            .down-arrow {
              transform: rotate(180deg);
            }
          }
        }
        .sticky-cell-table {
          .MuiTableRow-head {
            .MuiTableCell-head {
              padding: 0px;
              border: none;
            }
            .sticky-cell-table-pos {
              width: 90px;
              text-align: center;
              @media (max-width: 799px) {
                width: 49px;
              }
            }
          }
          .MuiTableRow-root {
            .MuiTableCell-body {
              border: none;
              padding: 0px;
            }
            .sticky-cell-table-pos {
              width: 90px;
              text-align: center;
              @media (max-width: 799px) {
                width: 49px;
              }
            }
          }
        }
        .current-user-stickycell {
          border-bottom: 1px solid $color-Accent-1;
        }
      }
    }
    .view-more {
      padding: 9px 25px;
      text-align: right;

      .view-more-text {
        font-size: 12px;
        line-height: 16px;
        color: #4455c7;
        font-weight: 400;
        font-family: $regulerFont;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .page-deatils-wrap {
    .no-data {
      background-color: $color-White;
      padding: 18px 12px;
      border-radius: 8px;
      margin: 0px 7.5px;
      text-align: center;
      border: 1px solid $color-Light-grey;
      font-family: $regulerFont;
      @media (max-width: 799px) {
        padding: 12px;
      }
    }
  }
}
