@import "../../../../../assets/scss/variable.scss";
.modal {
  max-width: 1153px;
  margin: 50px auto;
  color: #000;
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
  overflow: auto;
  height: auto;
  box-shadow: 10px 10px 5px 0px #e0eff147;

  @media (max-width: 1160px) {
    margin: 50px 50px;
  }

  @media (max-width: 539px) {
    margin: 50px 12px;
  }

  .modal-background {
    background-color: #fff;
    // box-shadow: 0 2px 5px RGB(0 0 0 / 10%);
  }

  .close-icon {
    display: flex;
    // justify-content: flex-end;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    background: #e8eaec;

    .title {
      font-family: $primaryFont;
      font-size: 31.36px;
      line-height: 43.9px;
      color: #191919;
    }
  }

  .admin-close-icon {
    color: #000;
    cursor: pointer;
  }

  .modal-contanier {
    padding: 20px;

    .racer-detail-wrap {
      display: flex;
      justify-content: space-between;

      @media (max-width: 639px) {
        flex-direction: column;
        row-gap: 5px;
      }

      .name-number {
        display: flex;
        align-items: center;
      }
    }

    .racer-number {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 50px;
      // height: 50px;
      margin-right: 0.5rem;
      // background-color: #f9f9f9;
      font-size: 16px;
      // font-size: 1.5rem;
      // font-weight: 600;
    }

    h6 {
      // margin-bottom: 2px;
    }

    .racer-List {
      margin-left: 20px;
      display: flex;

      @media (max-width: 639px) {
        margin-left: 0px;
      }

      .left-sec {
        margin-right: 120px;

        .player-detail {
          @media (max-width: 460px) {
            font-size: 12px;
          }
        }
      }

      .right-sec {
        .player-detail {
          @media (max-width: 460px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .info-box {
    // padding: 0px 0px 18px 0px;
    padding: 0px 18px 18px 18px;
    .runner-ul {
      position: relative;
      border-top: 5px solid #4455c7;
      padding: 1rem;
      // margin-bottom: 1rem;
      background-color: #003764;
      // background-color: #f9f9f9;

      .triangle {
        position: absolute;
        top: 0%;
        left: 6%;
      }

      ul {
        display: flex;
        list-style-type: none;

        padding: 0px;
        margin: 0 auto;
        // list-style-type: none;
        // display: flex;
        justify-content: space-between;
        max-width: 1030px;
        flex-wrap: wrap;

        @media (max-width: 767px) {
          flex-direction: column;
          row-gap: 5px;
        }

        @media (max-width: 639px) {
          padding: 0px;
        }

        // li {
        //   width: 24%;
        // }

        li:not(:last-child) {
          // margin-right: 35px;
          // margin-right: 15px;
        }

        li span {
          // display: block;
          text-align: left;
          // color: #000;
          color: #ffc69a;
          font-size: 16px;
          line-height: 24px;
          text-transform: capitalize;
          @media (max-width: 460px) {
            font-size: 12px;
          }
        }

        li {
          .title {
            color: #fff;
          }
        }
      }
    }

    .runner-info-wrap {
      padding: 0px 39px 0px 40px;
      // padding: 41px 39px 32px 40px;
      border-bottom: 2px solid #989898;
      // background-color: #f9f9f9;
      background-color: #003764;
      box-shadow: 0 2px 5px RGB(0 0 0 / 10%);

      @media (max-width: 767px) {
        // padding: 41px 22px 32px;
        padding: 0px 12px 0px 12px;
      }
    }
  }

  .runner-comment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 28.4px;
    position: relative;
  }

  .runner-comment::after {
    content: "";
    height: 2px;
    background: #989898;
    width: 100%;
    position: absolute;
    bottom: 0;
    // max-width: 856px;
  }

  .advance-race-detail-wrap {
    padding: 31px 0px;
    // border-bottom: 2px solid #989898;
    border-top: 2px solid #707070;

    @media (max-width: 639px) {
      padding: 24px 0px;
    }

    // ul:not(:last-child) {
    //   margin-bottom: 33px;

    //   @media (max-width: 639px) {
    //     margin-bottom: 24px;
    //   }
    // }
    // ul:not(:first-child) {
    //   margin-top: 2px;

    //   // @media (max-width: 639px) {
    //   //   margin-bottom: 24px;
    //   // }
    // }

    ul {
      // padding: 0px;
      // margin: 0px;
      // list-style-type: none;
      // display: flex;
      // justify-content: space-between;
      // max-width: 856px;

      margin: 0px;
      padding: 0px;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      column-gap: 2px;
      row-gap: 2px;
      background: #e7e9ec;

      // @media (max-width: 639px) {
      //   display: block;
      //   row-gap: 5px;
      // }

      @media (max-width: 799px) {
        display: grid;
        grid-template-columns: auto auto;
        width: unset !important;
      }
      li {
        // width: 24%;
        // background-color: #d6d9f3;

        flex: 1 0 15%;
        display: flex;
        justify-content: space-between;
        // border-radius: 22px;
        padding: 12px 14px;
        // background-color: #595959;
        background-color: #d6d9f3;

        // @media (max-width: 639px) {
        //   display: flex;
        //   align-items: center;
        //   justify-content: space-between;
        //   width: 100%;
        // }
      }

      span:first-child {
        margin-bottom: 5px;

        @media (max-width: 639px) {
          margin-bottom: 0px;
        }
      }

      span.title {
        color: #4455c7;
        // color: #000;
        font-weight: 600;
      }

      span {
        display: block;
        text-align: left;
        // color: #000;
        color: #4455c7;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;

        @media (max-width: 799px) {
          font-size: 12px;
        }
      }
    }

    .runner-detail-tab-wrap {
      padding-top: 30px;

      .MuiBox-root {
        padding: 33px 0px 0px 0px;
      }

      .MuiTabs-flexContainer {
        width: 100%;
        margin: 0 auto;
        background: #303135;
        padding: 4px;
        border-radius: 5px;
        max-width: 260px;
      }

      .MuiTabs-indicator {
        display: none;
      }

      button.Mui-selected {
        background: #4455c7;
        border-radius: 5px;
      }

      .MuiButtonBase-root {
        color: #000;
        opacity: 1;
        width: 100%;
        min-width: auto;
        max-width: 130px;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 19px;
      }

      .short-form ul {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        column-gap: 3px;
        row-gap: 22px;

        li {
          flex: 1 0 15%;
          display: flex;
          justify-content: space-between;
          border-radius: 22px;
          padding: 12px 14px;
          background-color: #595959;
        }

        li.active {
          background-color: #2d2424;

          span {
            color: #ffc69a;
          }
        }
      }

      .runner-tab-table {
        max-width: 1007px;

        table {
          white-space: nowrap;
          border-radius: 10px;
          margin-bottom: 5px;
          overflow: hidden;
        }
      }

      .runner-tab-table-head {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0px;

        .MuiBox-root {
          padding: 0px;
        }

        h6 {
          font-size: 12px;
          font-weight: 600;
        }

        svg {
          cursor: pointer;
        }
      }

      .runner-tab-table {
        .MuiTableHead-root {
          .MuiTableCell-root {
            font-size: 12px;
            font-weight: 600;
            padding: 12px 0 12px 15px;
            white-space: nowrap;
            line-height: 13px;
            background-color: #47494d;
            color: #d5d5d5;
          }

          .MuiTableCell-root:last-child {
            padding-right: 5px !important;
          }
        }

        .MuiTableBody-root {
          .MuiTableCell-root {
            font-size: 12px;
            font-weight: 600;
            padding: 12px 0 12px 15px;
            white-space: nowrap;
            line-height: 13px;
            background-color: #363535;
            border-top: 2px solid #252525;
            color: #fff;
            border-bottom: 0px;
            text-align: left;
          }

          .result {
            span {
              margin-right: 8px;
            }
          }

          span.disable {
            color: #a4a4a4;
          }

          span.highlight {
            background-color: #47494d;
            border-radius: 10px;
            color: #fc4714;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            padding: 3px 6px;
          }

          .MuiTableCell-root:last-child {
            padding-right: 15px;
          }
        }
      }
    }
  }
}
