@import "../../../../assets/scss/variable.scss";


.homepage-landing-page-wrap {

    .homepage-landing-header {
        display: flex;
        align-items: center;
        column-gap: 9px;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-grey;

        @media (max-width: 559px) {
            flex-direction: column-reverse;
            row-gap: 8px;
        }

        .team-select-wrap {
            max-width: 257px;
            width: 100%;

            @media (max-width:559px) {
                max-width: 100%;
            }
        }
    }

    .homepage-landing-content {
        margin-top: 36px;
        display: flex;
        align-items: flex-start;
        column-gap: 23px;

        .homepage-left-side {
            width: calc(100% - 418px);
            /* 418px rightside + column gap :- 23px */

            @media (max-width:1080px) {
                width: 100%;
            }

        }

        .homepage-right-side {
            width: 395px;
        }
    }

}

.bottom-sheet-wrap {
    position: fixed;
    bottom: 0px;
    z-index: 99;
    right: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
}

.homepage-bottom-sheet {

    .MuiDrawer-paper {
        height: calc(100vh - 100px);
        border-radius: 16px 16px 0px 0px;


        .bottom-sheet-content-wrap {

            .bottom-sheet-top-content {
                background-color: $color-Primary;
                border-radius: 16px 16px 0px 0px;
                text-align: center;

                .team-sports-tab {
                    padding: 5px 12px 10px;

                    .teamsport-tab-detail {
                        border-bottom: 2px solid #4455c7;

                        .MuiTabs-scroller {
                            .MuiTabs-indicator {
                                display: none;
                            }

                            .active {
                                border-bottom: 3px solid $color-White;
                            }
                        }

                        @media (max-width: 799px) {
                            margin-top: 6px;
                        }



                        .MuiTab-textColorInherit {
                            opacity: 1;
                        }

                        .tab-label {
                            display: flex;
                            align-items: center;

                            .label-name {
                                font-size: 16px;
                                font-family: $primaryFont;
                                color: #C2CDD6;
                                line-height: 21px;
                                font-weight: 400;
                            }
                        }

                        .active {
                            .label-name {
                                color: $color-White;
                                font-weight: 500;
                            }
                        }

                        .MuiBox-root {
                            min-width: max-content;
                        }

                        .MuiTab-root {
                            min-width: max-content;
                            border-bottom: 3px solid transparent;
                        }

                        .MuiTabs-indicator {
                            height: 3px;
                            color: $color-White;
                        }

                        .MuiTouchRipple-root {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}