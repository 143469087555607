@import "../../../../assets/scss/variable.scss";

.news-landing-page-wrap {
  .news-team-select-wrap {
    .tournament-team {
      .series-select {
        margin-top: 0px;
      }
    }
  }
}
