@import "../../../../assets/scss/variable.scss";

.d-flex {
  display: flex;
}

.text-align {
  align-items: center;
}

.content-space-betwen {
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.mt-44 {
  margin-top: 44px;

  @media (max-width: 799px) {
    margin-top: 31px;
  }
}

.mobile-banner {
  display: none;

  @media (max-width: 799px) {
    display: block;
  }

  .banner {
    width: 100%;
  }
}

//Info Page CSS
.bookmakersinfo-data {
  // padding-left: 18px;
  padding-top: 28px;

  .bredcrumn-wrap {
    padding: 0px 18px;
    margin-bottom: 3px;

    li.MuiBreadcrumbs-separator {
      color: $color-Black;
    }

    li {
      font-size: 11.42px;
      line-height: 14px;

      p {
        font-size: 11.42px;
        line-height: 14px;
        color: $color-Accent-1;
        text-transform: uppercase;
      }

      a {
        color: $color-Black;
        font-size: 11.42px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }
  }

  .page-title {
    padding-left: 18px;
    // padding-top: 28px;
    font-family: $primaryFont;
    font-size: 43.9px;
    line-height: 58px;
    font-weight: 400;
    color: $color-Black;
  }

  .bookmakers-info-tab {
    padding: 19px 18px 13px;
    margin-bottom: 11px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 599px) {
      padding: 9px 12px 12px;
      margin-bottom: 18px;
    }

    .bookmakersinfo-tab-detail {
      border-bottom: 2px solid #4455c7;

      .MuiTabs-scroller {
        .MuiTabs-indicator {
          display: none;
        }

        .active {
          border-bottom: 3px solid $color-Primary;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .tab-label {
        display: flex;
        align-items: center;

        .label-name {
          font-size: 16px;
          font-family: "VeneerClean-Soft" !important;
          color: #191919;
          line-height: 21px;
          font-weight: 400;
        }

        .label-icon {
          margin-left: 3px;
          display: flex;
          align-items: center;

          svg {
            line {
              stroke: $color-Black;
            }

            circle {
              stroke: $color-Black;
            }

            #Rectangle_18598 {
              stroke: $color-Black;
            }
          }
        }
      }

      .active {
        .label-name {
          color: $color-Accent-1;
        }

        .label-icon {
          svg {
            #Group_40284 {
              path {
                stroke: $color-Accent-1;
                fill: transparent;
              }
            }

            path {
              fill: $color-Accent-1;
            }

            line {
              stroke: $color-Accent-1;
            }

            circle {
              stroke: $color-Accent-1;
            }

            #Rectangle_18598 {
              stroke: $color-Accent-1;
            }
          }
        }
      }

      .MuiTab-root {
        min-width: max-content;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: #003764;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .tab-panel {
    padding: 18px 18px 60px 18px;
    background: $color-White;

    @media (max-width: 480px) {
      padding: 18px 12px 25px 12px;
    }

    .mt-18 {
      margin-top: 18px;

      @media (max-width: 480px) {
        margin-top: 30px;
      }
    }

    .mt-17 {
      margin-top: 17px;

      @media (max-width: 480px) {
        margin-top: 8px;
      }
    }

    .mt-15 {
      margin-top: 15px;

      @media (max-width: 480px) {
        margin-top: 8px;
      }
    }

    .heading {
      font-family: $regulerFont;
      font-size: 22.4px;
      line-height: 31.36px;
      font-weight: 600;
      color: $color-Black;

      @media (max-width: 480px) {
        font-size: 16px;
      }
    }

    // .subheading {
    //   font-family: $regulerFont;
    //   font-size: 16px;
    //   line-height: 22.4px;
    // }
    .description {
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 22.4px;
      color: $color-Black;
    }

    .heading-box {
      font-family: $regulerFont;
      // margin-top: 18px;

      // @media (max-width: 480px) {
      //   margin-top: 40px;
      // }

      ul.h2-list {
        list-style-type: square;
        padding-left: 18px;
      }

      table {
        // border: 1px solid #e7e9ec;
        overflow: hidden;
        border-radius: 8px;
        width: 100%;

        // text-align: left;
        thead {
          width: 100%;

          tr {
            border-top: 1px solid #003764;

            th {
              // border-top: 1px solid #003764;
              // border-bottom: 1px solid #003764;
              font-size: 22.4px;
              font-family: $primaryFont;
              text-transform: uppercase;
              padding: 9px 0px;

              line-height: 28px;
              font-weight: 400;
              color: $color-Black;
              letter-spacing: 0px;
              background-color: #e7e9ec;

              @media (max-width: 1000px) {
                font-size: 16px;
                padding: 4px 0px;
                line-height: 15px;
              }

              @media (max-width: 799px) {
                font-size: 14px;
                padding: 4px 0px;
                line-height: 15px;
              }
            }
          }
        }

        tbody {
          width: 100%;

          tr {
            // border-bottom: 1px solid #e7e9ec;

            td {
              padding: 9px 21px;
              font-size: 16px;
              line-height: 19px;
              font-weight: 400;
              color: $color-Black;
              font-family: $regulerFont;
              letter-spacing: 0px;
              border-bottom: 1px solid $color-Light-grey;

              @media (max-width: 1000px) {
                font-size: 14px;
                padding: 2px 12px;
                line-height: 15px;
              }

              @media (max-width: 799px) {
                // font-size: 10px;
                padding: 2px 6px;
                line-height: 15px;
              }

              @media (max-width: 599px) {
                font-size: 10px;
                padding: 2px 6px;
                line-height: 15px;
              }
            }

            .runner-details {
              h6 {
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                color: $color-Black;
                font-family: $regulerFont;
              }
            }

            .odds-details {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
        }
      }

      .se-image-container {
        figure {
          margin: 0 !important;
        }
      }

      .se-video-container {
        width: unset !important;

        figure {
          margin: 0 !important;
          padding-bottom: unset !important;
          width: unset !important;
          height: auto !important;

          iframe {
            width: 100% !important;
          }
        }
      }
    }

    .subheading-box {
      margin-top: 40px;

      @media (max-width: 480px) {
        margin-top: 14px;
      }
    }

    .table-box {
      margin-top: 44px;

      @media (max-width: 480px) {
        margin-top: 39px;
      }

      .table-title {}

      .table-container {
        // border: 1px solid $color-grey;
        width: 100%;
        margin-top: 15px;
      }

      .text-center {
        text-align: center !important;
      }

      .table-header {
        background-color: $color-Accent-1; // Light blue header background color
        font-weight: bold;

        .border-right {
          border-right: 1px solid $color-grey;
        }

        .width65 {
          width: 65%;
        }

        .table-header-title {
          padding: 15px 32px;
          font-family: $regulerFont;
          font-size: 16px;
          line-height: 21px;
          color: $color-White;
          text-transform: uppercase;

          @media (max-width: 480px) {
            padding: 15px 16px;
          }
        }
      }

      .table-row {
        // &:nth-child(odd) {
        //   background-color: #f2f2f2; // Alternate row background color
        // }

        // &:hover {
        //   background-color: #e0e0e0; // Hover row background color
        // }
      }

      .text-left {
        text-align: left !important;
      }

      .table-cell {
        padding: 15px 32px;
        text-align: center;
        border: 1px solid $color-grey;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 21px;
        color: $color-Black;

        @media (max-width: 480px) {
          padding: 9px 7px 7px 12px;
        }
      }

      .link {
        font-family: $regulerFont;
        color: #4455c7;
        text-decoration: underline;
        font-size: 16px;
        line-height: 22.4px;

        @media (max-width: 480px) {
          font-size: 16px;
        }
      }
    }

    .table-box2 {
      margin-top: 44px;

      @media (max-width: 480px) {
        font-size: 77px;
      }

      .table-container {
        width: 100%;
        margin-top: 15px;
      }

      .text-center {
        text-align: center !important;
      }

      .width23 {
        width: 23%;
      }

      .table-row {
        &:nth-child(even) {
          background-color: $color-Light-grey;
        }

        &:nth-child(odd) {
          background-color: $color-White;
        }

        // &:hover {
        //   background-color: #e0e0e0; // Hover row background color
        // }
      }

      .text-left {
        text-align: left !important;
      }

      .border-right {
        border-right: 1px solid $color-grey;
      }

      .table-cell {
        padding: 14px 18px;
        // padding: 8px;
        text-align: center;
        // Add borders to cells
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 21px;
        color: $color-Black;
        border-bottom: 0;

        @media (max-width: 480px) {
          padding: 14px 10px 15px 18px;
        }
      }

      // .link {
      // align-self: end;
      .link {
        font-family: $regulerFont;
        color: #4455c7;
        text-decoration: underline;
        font-size: 16px;
        line-height: 22.4px;
        //   font-family: "Inter", sans-serif !important;

        @media (max-width: 440px) {
          // margin-left: 12px;
          font-size: 11.42px;
        }
      }

      // }
    }
  }

  .bookmaker-header-info-review {
    @media (max-width: 1180px) {
      width: 100%;
    }
  }

  .bookmaker-header-info-rating {
    min-width: 265px;
    width: 100%;
    background: #e8eaec;
    border-radius: 8px;

    @media (max-width: 1180px) {}
  }

  .rating-review-box {
    // margin-top: 44px;
    // display: flex;
    // justify-content: space-between;
    // column-gap: 18px;

    // @media (max-width: 1266px) {
    //   flex-direction: column;
    // }

    .rating-box {
      // padding: 16px 27.7px 18px 18px;
      // background-color: $color-White;
      // max-height: 335px;
      // height: 100%;
      // width: 25.5%;

      // @media (max-width: 1266px) {
      //   max-width: 265px;
      //   width: 100%;
      //   padding: 16px 0px 18px 18px;
      // }
      padding: 12px;

      .bookmaker-total-review-emoji {
        display: flex;
        align-items: center;
        margin-top: 7px;
      }

      .rr-title {
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
      }

      .rating {
        color: #4455c7;
        column-gap: 3.7px;
        border: 1px solid #d3d7e8;
        border-radius: 0px 8px 8px 0px;
        padding: 2px 3px;
        border-left: 0;

        .MuiRating-icon {
          font-size: 12px;
        }
      }

      .rating-info-box {
        // max-width: 197px;

        // @media (max-width: 1266px) {
        //   max-width: 198px;
        // }
        margin-top: 19px;

        .rating-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 9px;

          .w20 {
            width: 20%;
          }

          .stars {
            font-family: $regulerFont;
            font-size: 11.42px;
            line-height: 14px;
            color: $color-Black;
            margin-left: 2px;
          }

          .progress-bar {
            height: 14px;
            width: 149px;

            @media (max-width: 1180px) {
              width: 80%;
            }

            @media (max-width: 1180px) {
              width: 65%;
            }
          }
        }
      }

      .review-btn {
        margin-top: 44px;
        padding: 0;
        color: $color-Accent-1;
        text-decoration: underline;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 22.4px;
        text-transform: inherit;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .review-box {
      // max-height: 465px;
      // max-width: -webkit-fill-available;
      // width: 74.5%;
      // overflow: scroll;
      // overflow-x: hidden;

      // @media (max-width: 1266px) {
      //   margin-top: 35px;
      //   width: 100%;
      // }

      // @media (max-width: 440px) {
      //   margin-left: 0px;
      //   margin-top: 35px;
      // }

      .reviews {
        margin-top: 18px;
        margin-right: 30px;
        display: flex;
        padding-bottom: 17.8px;
        border-bottom: 1px solid $color-Light-grey;

        .avtar-box {
          margin-top: 3px;
          width: 60px;
          height: 60px;
          border-radius: 50%;

          .avtar {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .icon-border {
          border: 1px solid $color-Accent-1;
        }

        .review-data {
          margin-left: 18px;

          .name-rating-box {
            display: flex;
            align-items: center;

            .name {
              font-family: $regulerFont;
              font-size: 16px;
              line-height: 21px;
              font-weight: 400;
              color: $color-Black;
            }

            .rating {
              margin-left: 18px;
              color: #4455c7;

              .MuiRating-icon {
                font-size: 16px;
                margin-right: 6.4px;
              }
            }
          }

          .des {
            margin-top: 12px;
            font-family: $regulerFont;
            font-size: 16px;
            line-height: 21px;
            color: $color-Black;
            white-space: pre-wrap;
            overflow-wrap: break-word;
            max-width: 520px;
          }

          .date {
            font-size: 11.42px;
            margin-top: 12px;
            font-family: $regulerFont;
            line-height: 15px;
            color: $color-gray-dark;
          }
        }
      }

      .reviews:first-child {
        margin-top: 0px;
      }

      .reviews:last-child {
        border-bottom: none;
      }
    }
  }

  .teamsport-data-tab {
    margin-bottom: 0px;
  }
}

//Dialogue Box CSS
.dialog-box {
  max-width: 894px;
  width: 100%;

  @media (max-width: 1080px) {
    max-width: 500px;
    width: 100%;
  }

  @media (max-width: 510px) {
    max-width: 366px;
    width: 100%;
  }
}

.review-modal-details {
  padding: 16.6px 27px 18px 27px !important;

  @media (max-width: 768px) {
    padding: 14.6px 18px 18px 18px !important;
  }

  .smartb-dialog {
    .title {
      color: $color-Black;
      font-family: $regulerFont;
      font-size: 31.36px;
      font-weight: 600;
      line-height: 43.9px;

      @media (max-width: 440px) {
        font-size: 28px;
      }
    }

    .modal-review-star {
      flex-wrap: wrap;
      row-gap: 15px;
    }

    // .rating {
    //   margin-top: 28px;
    //   color: #4455c7;

    //   // column-gap: 27px;
    //   @media (max-width: 440px) {
    //     // column-gap: 22px;
    //   }

    //   .MuiRating-icon {
    //     font-size: 31px;
    //     margin-right: 26px;

    //     // @media (max-width: 440px) {
    //     //   font-size: 30px;
    //     // }
    //   }

    //   // .MuiRating-iconHover:hover {
    //   //   opacity: 1; /* Set opacity to 1 to prevent the hover effect */
    //   //   transform: scale(1); /* Remove scaling effect on hover */
    //   //   transition: none; /* Remove any transition effect on hover */
    //   //   fill: inherit !important; /* Preserve the fill color */
    //   // }
    // }

    .txt-fld {
      margin-top: 25.6px;

      @media (max-width: 768px) {
        margin-top: 21.6px;
      }
    }

    .mr-18 {
      margin-right: 18px;
    }

    .bookmaker-total-review-emoji {
      display: flex;
      align-items: center;
      margin-top: 7px;

      @media (max-width: 768px) {
        margin-right: 15px;
        margin-top: 0px;
      }

      .rating {
        color: #4455c7;
        column-gap: 3.7px;
        border: 1px solid #d3d7e8;
        border-radius: 0px 8px 8px 0px;
        padding: 2px 3px;
        border-left: 0;

        @media (max-width: 768px) {
          column-gap: 0px;
        }

        .MuiRating-icon {
          font-size: 12px;
        }
      }

      .select-rating {
        border: 1px solid #4455c7;
        border-left: 0;
      }
    }

    .submit-btn-box {
      margin-top: 18px;
      text-align: right;
      width: 100%;

      @media (max-width: 440px) {
        text-align: center;
      }

      .submit-btn {
        padding: 12px 24px;
        font-family: $regulerFont;
        font-size: 18px;
        line-height: 24px;
        color: $color-White;
        text-transform: capitalize;
        background-color: $color-Accent-1;
        border-radius: 8px;

        @media (max-width: 440px) {
          width: 100%;
          padding: 10px;
        }
      }
    }
  }
}

.no-data-wrap-bookmakers {
  .no-data {
    padding: 100px 0px 9px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: $regulerFont;
  }
}

.bookmaker-header-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;

  // .img-btn-box {
  //   display: flex;
  //   padding-top: 23px;
  @media (max-width: 1180px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .bookmaker-header-info-details {
    display: flex;
    align-items: center;

    .bookmaker-header-info-data {
      margin: 0px 24px;

      @media (max-width: 1180px) {
        margin: 0px 12px;
      }

      .bookmaker-header-info-title {
        font-size: 31.36px;
        font-weight: bold;
        line-height: 43.9px;

        @media (max-width: 1180px) {
          font-size: 16px;
          font-weight: 600;
          line-height: 22.4px;
        }
      }

      .bookmaker-header-info-desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;

        @media (max-width: 1180px) {
          font-size: 11.42px;
          font-weight: 400;
          line-height: 14px;
        }
      }
    }
  }

  .desktop-join-btn-view {
    display: inline-block;

    @media (max-width: 1180px) {
      display: none;
    }
  }

  .mobile-join-btn-view {
    @media (max-width: 1180px) {
      display: flex;
      width: 100%;
    }
  }

  .bookmaker-header-info-join-btn {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    // border: 1px solid #4455c7;
    border-radius: 8px;
    letter-spacing: 0;
    max-width: 150px;
    width: 100%;
    background: #4455c7;
    color: #ffffff;
    padding: 12px;
    text-transform: capitalize;
    margin-top: 15px;

    &:hover {
      background: #4455c7;
      color: #ffffff;
    }

    @media (max-width: 1180px) {
      max-width: 100%;
      margin-top: 12px;
      margin-bottom: 18px;
      padding: 9px;
    }
  }

  .bookmaker-header-info-add-review-btn {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    border: 1px solid #4455c7;
    border-radius: 8px;
    letter-spacing: 0;
    color: #4455c7;
    background: #ffffff;
    max-width: 150px;
    width: 100%;
    padding: 12px;
    text-transform: initial;
    margin-top: 9px;

    @media (max-width: 1180px) {
      max-width: 100%;
      margin-left: 14px;
      margin-top: 12px;
      margin-bottom: 18px;
      padding: 9px;
    }
  }

  .img-box {
    width: 230px;
    height: 196px;
    border-radius: 8px;

    @media (max-width: 1180px) {
      width: 138px;
      height: 86px;
    }

    .bookmaker-image {
      width: inherit;
      height: inherit;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  // .join-btn-box {
  //   align-self: end;
  //   background-color: $color-attention;
  //   margin-bottom: 4px;
  //   margin-left: 18px;
  //   border-radius: 2px;

  //   @media (max-width: 480px) {
  //     margin-left: 0;
  //     text-align: center;
  //     margin-top: 15px;
  //   }

  //   .join-btn {
  //     padding: 11px 29px;
  //     font-family: $regulerFont;
  //     color: $color-White;
  //     text-transform: capitalize;
  //   }
  // }
  // }
}