@import "src/assets/scss/variable.scss";


.new-sport-Header-wrapper {
    min-height: 57px;
    background-color: $color-Primary;
    display: flex;
    align-items: center;
    padding: 0px 45px;
    column-gap: 125px;
    position: sticky;
    top: 202px;
    z-index: 99;

    @media(max-width:1560px) {
        column-gap: 75px;
    }

    @media (max-width:1120px) {
        column-gap: 40px;
        padding: 0px 20px;
    }

    .header-left-section {
        display: flex;
        align-items: center;
        column-gap: 3px;

        // width: 10%;

        .sport-name-box {

            .sport-name {
                font-size: 31.36px;
                line-height: 40px;
                font-weight: 400;
                color: $color-White;
                font-family: $primaryFont;
                letter-spacing: 0px;

                @media (max-width:1324px) {
                    font-size: 22.4px;
                    line-height: 28px;
                }
            }
        }
    }


    .header-right-section {
        display: flex;
        align-items: center;
        height: 57px;
        justify-content: center;

        // width: 90%;

        .all-menu-wrap {
            padding: 0px 33px;
            height: 100%;
            cursor: pointer;

            @media (max-width:1700px) {
                padding: 0px 25px;
            }

            @media(max-width:1560px) {
                padding: 0px 15px;
            }

            @media (max-width:1120px) {
                padding: 0px 12px;
            }
        }

        .all-menu-collapse-wrap {
            position: relative;

        }


        .all-menu-collapse {
            position: absolute;
            z-index: 999;
            background-color: $color-White;
            box-shadow: 0px 3px 6px 0px #00000033;
            max-height: 295px;
            height: auto;
            width: 100%;
            top: 50px;
            overflow-y: auto;
            max-width: 310px;
            min-width: 310px;
            border-radius: 8px;
            left: 33px;

            @media (max-width:559px) {
                left: -140px;
            }

            .tournament-menu-wrap {

                .set-default-wrap {
                    padding: 9px 18px;
                    border-bottom: 1px solid $color-Light-grey;

                    .MuiFormControl-root {

                        .MuiButtonBase-root {
                            padding: 0px;
                            margin-right: 6px;
                        }

                        label {
                            font-size: 16px;
                            line-height: 19px;
                            color: $color-Black;
                            font-family: $regulerFont;
                            letter-spacing: 0px;
                            font-family: 400;
                        }
                    }
                }

                .tournament-menu-list-wrap {
                    padding: 9px;

                    .tournament-menu-list-item {
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 400;
                        font-family: $regulerFont;
                        color: $color-Black;
                        letter-spacing: 0px;
                        padding: 6px 12px;
                        cursor: pointer;
                    }

                    .active {
                        background-color: $color-Light-grey-4;
                        border-radius: 5px;
                    }
                }
            }
        }



        .all-menu-name {
            font-size: 20.9px;
            line-height: 22.4px;
            font-weight: 700;
            font-family: $regulerFont;
            letter-spacing: 0px;
            color: $color-White;
            display: flex;
            align-items: center;
            column-gap: 5px;

            @media (max-width:1324px) {
                font-size: 14px;
                line-height: 16px;
            }
        }


        .menu-item-wrap {
            padding: 0px 50px;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;

            @media (max-width:1700px) {
                padding: 0px 35px;
            }

            @media(max-width:1560px) {
                padding: 0px 25px;
            }

            @media (max-width:1120px) {
                padding: 0px 12px;
            }



            .menu-name {
                font-size: 20.9px;
                line-height: 22.4px;
                font-weight: 700;
                font-family: $regulerFont;
                letter-spacing: 0px;
                color: $color-White;

                @media (max-width:1324px) {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }

        .active {
            background-color: #2E5B84;
        }
    }

    .mobile-down-arrow {
        svg {
            fill: $color-White
        }
    }

    .all-mobile-menu-collapse {
        position: absolute;
        z-index: 999;
        background-color: $color-Primary;
        left: 0px;
        width: 100%;
        top: 57px;

        .mobile-menu {
            padding: 12px;

            .menu-item-wrap {
                padding: 0px 50px;
                height: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;

                @media (max-width:1700px) {
                    padding: 0px 35px;
                }

                @media(max-width:1560px) {
                    padding: 0px 25px;
                }

                @media (max-width:1120px) {
                    padding: 0px 12px;
                }

                @media (max-width:824px) {
                    padding: 12px;
                }



                .menu-name {
                    font-size: 20.9px;
                    line-height: 22.4px;
                    font-weight: 700;
                    font-family: $regulerFont;
                    letter-spacing: 0px;
                    color: $color-White;

                    @media (max-width:1324px) {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }

            .active {
                background-color: $color-White;
                border-radius: 6px;

                .menu-name {
                    color: #FC4714;
                }
            }
        }


    }

}

.without-sigup-header {
    top: 194px;
}