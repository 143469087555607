@import "../../../assets/scss/variable.scss";

.news-section {
  margin-top: -5px;

  .left-section {
    max-width: calc(100% - 400px);
    flex-basis: 100%;

    @media (max-width: 1023px) {
      max-width: 100%;
    }
  }

  .all-cat-section {
    max-width: 100%;
  }

  .right-section {
    // background-color: #e6eefa;
    max-width: 400px;
    min-width: 400px;

    @media (max-width: 1023px) {
      display: none;
      max-width: none;
      min-width: none;
    }
  }

  // left section css

  .left-section-wrap {
    // width: 894px;

    .news-header {
      padding: 33px 0px 5px;

      @media (max-width: 799px) {
        padding: 18px 0px 3px;
      }

      .bredcrumn-wrap {
        margin-bottom: 3px;

        li.MuiBreadcrumbs-separator {
          color: $color-Black;
        }

        li {
          font-size: 11.42px;
          line-height: 14px;

          p {
            font-size: 11.42px;
            line-height: 14px;
            color: $color-Accent-1;
            text-transform: uppercase;
          }

          a {
            color: $color-Black;
            font-size: 11.42px;
            line-height: 14px;
            text-transform: uppercase;
          }
        }
      }

      h1 {
        font-size: 31.36px;
        line-height: 40x;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Primary;

        // @media (max-width: 799px) {
        //   font-size: 31.36px;
        //   line-height: 40px;
        // }
      }
    }

    .news-details {
      padding: 18px 18px 0px;
      background-color: #ffffff;
      box-shadow: 0px 3px 9px 0px #0000000d;
      margin-bottom: 26px;

      @media (max-width: 799px) {
        padding: 9px 12px 0px;
      }

      .search_input {
        width: 100%;

        .MuiInputAdornment-root {
          cursor: pointer;
        }

        .MuiInputBase-root {
          min-height: 45px;
          border-radius: 4px;

          svg {
            height: 18px;
            width: 18px;
          }

          .MuiInputBase-input {
            padding: 9px 9px;
          }
        }

        @media (max-width: 799px) {
          margin-left: 0px !important;
        }
      }

      .custom-news-wrap {
        padding: 18px 0px;

        .custom-news-tabs {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          flex-wrap: wrap;
          row-gap: 9px;
          column-gap: 21px;

          @media (max-width: 799px) {
            column-gap: 18px;
          }

          .tab {
            font-size: 16px;
            line-height: 23px;
            font-family: $primaryFont;
            font-weight: 400;
            color: $color-Primary;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            border-bottom: 1px solid transparent;
          }

          .tab-more {
            display: flex;
            align-items: center;
            column-gap: 4.6px;
          }

          .active {
            // border-bottom: 1px solid $color-Primary;
            text-decoration: underline;
          }
        }

        .more-news-tab {
          position: relative;

          .MuiCollapse-wrapperInner {
            position: absolute;
            top: 10px;
            right: 0px;
            z-index: 99;
            display: grid;
            grid-template-columns: auto auto;
            width: 100%;
            max-width: 426px;
            background-color: $color-White;
            border: 1px solid #e7e9ec;
            border-radius: 8px;
            // padding: 0px 11px;
            box-shadow: 0px 3px 9px 0px #0000000d;
            overflow: hidden;

            @media (max-width: 1023px) {
              left: 0;
            }

            .tab {
              font-size: 16px;
              line-height: 20px;
              font-family: $primaryFont;
              font-weight: 400;
              color: $color-Black;
              cursor: pointer;
              -webkit-tap-highlight-color: transparent;
              padding: 12px 11px 10px;

              &:hover {
                background-color: #d4d6d8;

                &:first-child {
                  border-radius: 8px 0px 0px 0px;
                }

                &:nth-child(2) {
                  border-radius: 0px 8px 0px 0px;
                }

                &:last-child.last-second {
                  border-radius: 0px 0px 0px 8px;
                }

                &:nth-last-child(2).last-active {
                  border-radius: 0px 0px 0px 8px;
                }

                &:last-child.last-active {
                  border-radius: 0px 0px 8px 0px;
                }
              }
            }

            .active {
              background-color: #d4d6d8;

              &:first-child {
                border-radius: 8px 0px 0px 0px;
              }

              &:nth-child(2) {
                border-radius: 0px 8px 0px 0px;
              }

              &:last-child.last-second {
                border-radius: 0px 0px 0px 8px;
              }

              &:nth-last-child(2).last-active {
                border-radius: 0px 0px 0px 8px;
              }

              &:last-child.last-active {
                border-radius: 0px 0px 8px 0px;
              }
            }
          }
        }
      }
    }
  }

  // All News section

  .news-layout-section {
    // padding: 27px 33px 36px;
    // background-color: #ffffff;
    // box-shadow: 0px 3px 9px 0px #0000000d;

    // @media (max-width: 799px) {
    //   padding: 18px 12px;
    // }

    .news-slider {
      .news-slider-comp {
        padding-top: 12px;

        @media (max-width: 799px) {
          padding-top: 5px;
        }
      }

      .slick-list {
        width: 99.99%;
      }

      .slick-arrow {
        // width: 45px;
        // height: 37px;
        background: $color-Primary;
        display: flex !important;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 32px;
        height: 26px;
        // background-color: #5d5d5d;
        text-align: center;

        svg {
          path {
            stroke: $color-White;
          }
        }
      }

      .slick-prev {
        position: absolute;
        right: 36px !important;
        top: -5px;
        left: auto;

        @media (max-width: 799px) {
          top: -12px;
        }

        svg {
          transform: rotateZ(-180deg);
        }

        &::before {
          content: "";
        }
      }

      .slick-next {
        top: -5px;
        right: 0px !important;

        @media (max-width: 799px) {
          right: 0px !important;
          z-index: 1;
          top: -12px;
        }

        &::before {
          content: "";
        }
      }

      .slide-cont {
        // max-width: 409px;
        max-width: calc(100% - 6.5px);
        // column-gap: 13px;

        @media (max-width: 610px) {
          max-width: calc(100% - 0px);
        }

        // @media (max-width: 1440px) {
        //   max-width: 350px;
        // }

        // @media (max-width: 1260px) {
        //   max-width: 300px;
        // }

        // @media (max-width: 930px) {
        //   max-width: 250;
        // }

        // @media (max-width: 799px) {
        //   max-width: 330px;
        // }

        // @media (max-width: 690px) {
        //   max-width: 280px;
        // }

        // @media (max-width: 610px) {
        //   max-width: 100%;
        // }

        .slide-single-box {
          // max-width: 409px;
          width: 100%;
          max-width: 100%;

          // @media (max-width: 1440px) {
          //   max-width: 350px;
          // }

          // @media (max-width: 1260px) {
          //   max-width: 300px;
          // }

          // @media (max-width: 930px) {
          //   max-width: 250px;
          // }

          // @media (max-width: 799px) {
          //   max-width: 330px;
          // }

          // @media (max-width: 690px) {
          //   max-width: 280px;
          // }

          // @media (max-width: 610px) {
          //   max-width: 100%;
          // }

          .single-silder {
            // width: 510px;
            // height: 190px;
            position: relative;
            cursor: pointer;
            overflow: hidden;

            // @media (max-width: 1440px) {
            //   width: 480px;
            // }

            // @media (max-width: 1260px) {
            //   width: 400px;
            // }

            // @media (max-width: 1120px) {
            //   width: 380px;
            // }

            // @media (max-width: 1060px) {
            //   width: 350px;
            // }

            // @media (max-width: 999px) {
            //   width: 300px;
            // }

            // @media (max-width: 930px) {
            //   width: 250px;
            // }

            // @media (max-width: 799px) {
            //   width: 330px;
            // }

            // @media (max-width: 690px) {
            //   width: 280px;
            // }

            // @media (max-width: 610px) {
            //   width: 100%;
            //   background-size: cover;
            //   background-repeat: no-repeat;
            // }

            .slider-img {
              height: 300px;

              @media (max-width: 1180px) {
                height: 230px;
              }

              @media (max-width: 980px) {
                height: 200px;
              }

              @media (max-width: 860px) {
                height: 180px;
              }

              @media (max-width: 610px) {
                background-size: cover;
                background-repeat: no-repeat;
                height: 230px;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                // object-position: top;
              }
            }

            .details-back {
              position: absolute;
              bottom: 0px;
              left: 0px;
              background: linear-gradient(to bottom,
                  rgba(255, 255, 255, 0%),
                  rgba(34, 34, 34, 87%));
              width: -webkit-fill-available;

              .news-tag {
                padding: 3px 6px;
                background-color: #fc4714;
                color: #ffffff;
                font-size: 11.42px;
                line-height: 14px;
                font-weight: 400;
                font-family: $regulerFont;
                max-width: fit-content;
                margin-bottom: 5px;
                display: block;
                text-transform: capitalize;
              }

              .slider-news-deatils {
                padding: 21px 12px 9px;

                a {
                  font-size: 16px;
                  line-height: 22.4px;
                  font-weight: 600;
                  font-family: $regulerFont;
                  color: #ffffff;
                  margin-bottom: 5px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                }

                p {
                  font-size: 11.42px;
                  line-height: 14px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  color: #c9c9c9;
                }
              }
            }
          }

          .single-silder-object {
            img {
              object-fit: contain;

              @media (max-width: 539px) {
                object-fit: initial;
              }
            }
          }
        }
      }

      .news-time-comment-wrapper {
        // position: absolute;
        // bottom: 12px;
        // left: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 24px);

        @media (max-width: 799px) {
          bottom: 0;
          left: 0;
          width: 100%;
        }

        .news-time {
          color: $color-White;
        }

        .bookmark-chat-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          .chat-details {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 6px;

            svg {
              margin-right: 3px;

              path {
                stroke: $color-White;
              }
            }
          }

          .saved-bookmark-icon {
            path {
              fill: $color-White;
              stroke: $color-White;
            }
          }

          .unsaved-bookmark-icon {
            path {
              stroke: $color-White;
            }
          }
        }
      }
    }
  }

  // Top stories

  .all-news {
    // margin-top: 34px;

    // @media (max-width: 799px) {
    //   margin-top: 18px;
    // }
    // padding: 27px 33px 36px;
    // background-color: #ffffff;
    // box-shadow: 0px 3px 9px 0px #0000000d;

    // @media (max-width: 799px) {
    //   padding: 18px 12px;
    // }

    h4 {
      font-size: 31.36px;
      line-height: 40px;
      color: $color-Primary;
      font-weight: 400;
      font-family: $primaryFont;
      margin-bottom: 9px;
      position: relative;
      margin-top: 7px;
      display: block;

      @media (max-width: 439px) {
        font-size: 22.4px;
        line-height: 28px;
        margin-bottom: 12px;
        // margin-top: 18px;
      }
    }

    .article-heading-a a {
      font-size: 31.36px;
      line-height: 40px;
      color: $color-Primary;
      font-weight: 400;
      font-family: $primaryFont;
      margin-bottom: 9px;
      position: relative;
      margin-top: 7px;
      display: block;

      @media (max-width: 439px) {
        font-size: 22.4px;
        line-height: 28px;
        margin-bottom: 12px;
        // margin-top: 18px;
      }
    }

    .top-stories-cont {
      row-gap: 27px;
      column-gap: 12px;
      margin-bottom: 7px;

      @media (max-width: 1350px) {
        justify-content: space-around;
      }

      @media (max-width: 860px) {
        justify-content: space-between;
      }

      .top-stories-details {
        // max-width: 344px;
        max-width: calc(33% - 6px);
        background-color: #ffffff;
        box-shadow: 0px 3px 9px 6px #0000000d;
        border-radius: 8px;
        overflow: hidden;

        @media (max-width: 999px) {
          max-width: 300px;
        }

        @media (max-width: 900px) {
          max-width: 280px;
        }

        @media (max-width: 860px) {
          max-width: 100%;
        }

        .single-top-stories {
          cursor: pointer;

          @media (max-width: 860px) {
            display: flex;
            padding: 12px;
          }

          .news-tag-relative {
            position: relative;

            .news-img-container {
              position: relative;
              overflow: hidden;
            }
          }

          .news-tag {
            padding: 3px 6px;
            background-color: #fc4714;
            color: #ffffff;
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            font-family: "Inter", sans-serif;
            max-width: -moz-fit-content;
            max-width: fit-content;
            position: absolute;
            left: 12px;
            top: 13px;
            text-transform: capitalize;

            @media (max-width: 1024px) {
              bottom: 15px;
              top: unset;
            }

            @media (max-width: 560px) {
              left: 6px;
            }

            @media (max-width: 440px) {
              left: 10px;
            }
          }

          .top-stories-img {
            height: 158px;

            @media (max-width: 860px) {
              margin-right: 12px;
              min-width: 180px;
              height: 125px;
            }

            @media (max-width: 439px) {
              min-width: 140px;
              min-height: 105px;
              height: 105px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top;
            }
          }

          .stories-details {
            // margin-top: 12px;
            padding: 12px 6px;

            @media (max-width: 860px) {
              margin-top: 0px;
              width: 100%;
              padding: 0px;
            }

            .details {
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 600;
              font-family: $regulerFont;
              color: $color-Black;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;

              @media (max-width: 860px) {
                -webkit-line-clamp: 2;
              }

              @media (max-width: 439px) {
                font-size: 14px;
                line-height: 16px;
              }
            }

            .sub-details-wrap {
              .sub-details {
                font-size: 14px;
                line-height: 19px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-gray-dark;
                margin-top: 4px;

                @media (max-width: 439px) {
                  font-size: 11.42px;
                  line-height: 14px;
                }

                .read-more {
                  color: $color-Accent-1;
                }
              }
            }

            .text-details {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 9px;

              // @media (max-width: 799px) {
              //   margin-top: 9px;
              // }

              .stories-tag {
                padding: 3px 6px;
                background-color: #989898;
                color: #ffffff;
                font-size: 11.42px;
                line-height: 14px;
                font-weight: 400;
                font-family: $regulerFont;
                max-width: fit-content;
                border-radius: 3px;
              }

              .stories-time {
                font-size: 11.42px;
                line-height: 14px;
                font-family: $regulerFont;
                font-weight: 400;
                color: #5d5d5d;

                .watch {
                  margin-right: 4px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }

    .article-heading {
      cursor: pointer;
    }

    .individual-news-cont {
      margin-top: 7px;
      padding: 5px;

      @media (max-width: 799px) {
        margin-top: 0px;
      }

      .individual-single-news {
        margin-bottom: 21px;
        padding: 15px 12px;
        background-color: #ffffff;
        box-shadow: 0px 1px 9px 0px #0000001f;
        border-radius: 8px;
        overflow: hidden;

        @media (max-width: 799px) {
          padding: 12px;
          margin-bottom: 12px;
        }

        // &:not(:last-child) {
        //   border-bottom: 1px solid #c9c9c9;
        // }

        .news-box {
          display: flex;
          column-gap: 12px;

          .news-img {
            position: relative;
            min-width: 312px;
            // min-height: 164px;
            max-width: 312px;
            height: 164px;
            // margin-bottom: 18px;
            cursor: pointer;

            @media (max-width: 1280px) {
              max-width: 200px;
              min-width: 200px;
              // margin-right: 12px;
              height: 140px;
            }

            @media (max-width: 1120px) {
              max-width: 180px;
              min-width: 180px;
              height: 120px;
            }

            @media (max-width: 439px) {
              max-width: 140px;
              min-width: 140px;
              height: 105px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;

              @media (max-width: 1280px) {
                width: 200px;
                height: 140px;
              }

              @media (max-width: 1120px) {
                width: 180px;
                height: 120px;
              }

              @media (max-width: 439px) {
                width: 140px;
                height: 105px;
              }
            }
          }

          .individual-news-details {
            width: 100%;
            position: relative;

            .exclusive {
              padding: 3px 11px;
              background-color: #d84727;
              color: #ffffff;
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 400;
              font-family: $regulerFont;
              max-width: fit-content;
              border-radius: 3px;
              margin-bottom: 9px;
            }

            .details {
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 600;
              font-family: $regulerFont;
              color: $color-Black;
              // margin-bottom: 24px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              cursor: pointer;

              @media (max-width: 1440px) {
                -webkit-line-clamp: 2;
              }

              @media (max-width: 1024px) {
                margin-bottom: 4px;
                font-size: 14px;
                line-height: 18px;
              }

              // @media (max-width: 439px) {
              //   font-size: 14px;
              //   line-height: 18px;
              // }
            }

            .sub-details-wrap {
              .sub-details {
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-gray-dark;
                margin-top: 4px;

                @media (max-width: 1024px) {
                  font-size: 11.42px;
                  line-height: 14px;
                }

                .read-more {
                  color: $color-Accent-1;
                }
              }
            }

            .tag-time-details {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 13px;

              @media (max-width: 599px) {
                flex-direction: column;
                row-gap: 4px;
                margin-top: 6px;
              }

              .news-tags {
                display: flex;
                flex-wrap: wrap;
                row-gap: 5px;
                width: 70%;

                @media (max-width: 599px) {
                  width: 100%;
                }
              }

              .individual-news-tag {
                padding: 3px 6px;
                background-color: #989898;
                color: #ffffff;
                font-size: 11.42px;
                line-height: 14px;
                font-weight: 400;
                font-family: $regulerFont;
                max-width: fit-content;
                border-radius: 23px;

                &:not(:last-child) {
                  margin-right: 12px;

                  @media (max-width: 1140px) {
                    margin-right: 6px;
                  }
                }
              }

              .stories-time {
                font-size: 11.42px;
                line-height: 14px;
                font-family: $regulerFont;
                font-weight: 400;
                color: #5d5d5d;
                // width: 30%;
                // text-align: end;

                @media (max-width: 599px) {
                  width: 100%;
                }

                .watch {
                  margin-right: 4px;
                  vertical-align: middle;
                }
              }
            }

            .news-time-comment-wrapper {
              position: absolute;
              left: 0px;
              bottom: 0px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: calc(100% - 24px);

              @media (max-width: 799px) {
                bottom: 0;
                left: 0;
                width: 100%;
              }

              .news-time {
                font-size: 11.42px;
                line-height: 14px;
                font-family: $regulerFont;
                font-weight: 400;
                color: #5d5d5d;
              }

              .bookmark-chat-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;

                .chat-details {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 6px;

                  svg {
                    margin-right: 3px;
                  }
                }

                .saved-bookmark-icon {
                  path {
                    fill: $color-gray-dark;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // category news

  .category-news {
    .news-Pagination {
      display: flex;
      justify-content: center;

      ul {
        li {
          margin-right: 4px;

          @media (max-width: 379px) {
            margin-right: 0px;
          }

          .MuiPaginationItem-root {
            font-size: 18px;
            line-height: 21px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
            width: 36px;
            height: 36px;
            border-radius: 50%;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }

            svg {
              width: 36px;
              height: 36px;
              font-size: 18px;

              @media (max-width: 799px) {
                font-size: 14px;
                width: 24px;
                height: 24px;
              }

              @media (max-width: 379px) {
                font-size: 11px;
                width: 22px;
                height: 22px;
              }
            }
          }

          .Mui-selected {
            background-color: #d4d6d8;
            width: 45px;
            height: 45px;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }

  // News Full Details

  .individual-news-layout {
    // padding: 27px 29px 37px 33px;
    // background-color: #ffffff;
    // box-shadow: 0px 3px 9px 0px #0000000d;

    // @media (max-width: 799px) {
    //   padding: 18px 12px;
    // }

    .news-full-details {
      padding: 0px 75px 37px 0px;
      // background-color: #ffffff;
      // box-shadow: 0px 3px 9px 0px #0000000d;

      @media (max-width: 1023px) {
        padding: 18px 0px;
      }

      h4 {
        font-size: 31.36px;
        line-height: 37px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-Black;
        letter-spacing: 0px;
        margin-bottom: 8px;

        @media (max-width: 799px) {
          font-size: 22.4px;
          line-height: 31.6px;
        }
      }

      .news-date-time {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-bottom: 15px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          font-family: $regulerFont;
          color: $color-gray-dark;
        }
      }

      .news-sub-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;

        .news-sub-left {
          display: flex;
          align-items: center;

          .news-logo {
            width: 51px;
            height: 51px;
            border-radius: 50%;
            margin-right: 17px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: contain;
            }
          }

          .name {
            font-weight: 600;
            line-height: 19px;
            margin-bottom: 6px;

            @media (max-width: 799px) {
              margin-bottom: 4px;
            }
          }
        }

        .news-sub-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 30px;

          .comments-section {
            display: flex;
            align-items: center;
            column-gap: 7px;

            .comments-count {
              font-size: 14px;
              line-height: 16px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-gray-dark;
              letter-spacing: 0px;
            }
          }
        }
      }

      p {
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;
      }

      span {
        font-size: 11.42px;
        line-height: 14px;
        font-weight: 400;
        font-family: $regulerFont;
        color: #5d5d5d;
      }

      .news-img {
        // max-width: 828px;
        max-height: 500px;
        // margin-bottom: 30px;

        // @media (max-width: 799px) {
        //   margin-bottom: 7px;
        // }

        img {
          width: 100%;
          max-height: 500px;
          object-fit: contain;
        }
      }

      a {
        font-size: 16px;
        line-height: 22.4px;
        font-family: $regulerFont;
        font-weight: 400;
        color: #003764;
      }

      .img-alt {
        font-size: 12px;
        line-height: 16px;
        font-family: $regulerFont;
        font-weight: 400;
        color: #003764;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
      }

      .img-alt-bottom {
        margin-bottom: 0px;
        margin-top: 9px;

        @media (max-width: 799px) {
          margin-bottom: 0px;
          margin-top: 9px;
        }
      }

      .para-1 {
        margin-top: 19px;

        @media (max-width: 799px) {
          margin-top: 12px;
        }

        h1 {
          font-size: 43.9px;
          line-height: 56px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-Black;

          @media (max-width: 799px) {
            font-size: 31.36px;
            line-height: 40px;
          }

          span {
            font-size: 43.9px;
            line-height: 56px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-Black;

            @media (max-width: 799px) {
              font-size: 31.36px;
              line-height: 40px;
            }
          }
        }

        h2 {
          font-size: 22.4px;
          font-family: $regulerFont !important;
          line-height: 27px;
          color: $color-Black;

          span {
            font-size: 22.4px;
            font-family: $regulerFont !important;
            line-height: 27px;
            color: $color-Black !important;
            font-weight: 600;
          }

          @media (max-width: 799px) {
            font-size: 16px;

            span {
              font-size: 16px;
            }
          }
        }
      }

      .ifeame-section {
        display: flex;

        .twitter-tag {
          margin: 0px auto;
          min-height: 800px;
        }
      }

      .heading-1 {
        font-weight: 600;
        margin: 19px 0px;
      }

      .heading-2 {
        font-size: 22.4px;
        font-weight: 600;
        font-family: $regulerFont;
        line-height: 27px;
        color: $color-Black;
        margin: 28px 0px 18px;

        @media (max-width: 799px) {
          font-size: 16px;
          margin: 28px 0px 10px;
        }
      }

      .news-img-2 {
        // max-width: 828px;
        // max-height: 354px;
        margin: 20px 0px 19px;

        @media (max-width: 799px) {
          margin: 19px 0px 18px;
        }

        img {
          width: 100%;
        }
      }

      .heading-3 {
        margin-bottom: 19px;
        font-weight: 600;
      }

      ul {
        // list-style-type: none;

        li {
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;
          font-family: $regulerFont;
          color: $color-Black;
        }
      }

      .ul-first {
        padding: 0px;
        margin: 0px;
      }

      .ul-second {
        margin-top: 19px;

        @media (max-width: 799px) {
          margin-top: 10px;
        }
      }

      .tages-details {
        display: flex;
        align-items: baseline;
        margin-top: 20px;

        .tage-box {
          display: flex;
          flex-wrap: wrap;
          row-gap: 5px;
        }

        .tages {
          font-weight: 600;
          margin-right: 18px;

          @media (max-width: 379px) {
            margin-right: 8px;
          }
        }

        .news-tages {
          padding: 3px 6px;
          background-color: #989898;
          color: #ffffff;
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          font-family: $regulerFont;
          max-width: fit-content;
          border-radius: 23px;

          &:not(:last-child) {
            margin-right: 12px;

            @media (max-width: 379px) {
              margin-right: 8px;
            }
          }
        }
      }
    }

    .news-comments-section {
      padding: 23px 75px 0px 0px;

      @media (max-width: 1023px) {
        padding: 18px 0px 0px 0px;
        margin: 0px -12px;
      }

      .news-comments-wrap {
        background-color: $color-White;

        .comment-email-check {
          margin: 0px;

          .comment-email-checkbox {
            padding: 0px;
            margin-right: 9px;
          }

          .MuiTypography-root {
            font-size: 11.42px;
            line-height: 14px;
            font-family: $regulerFont;
            font-weight: 400;
            color: $color-gray-dark;
            letter-spacing: 0px;
          }
        }

        .leave-comments-section {
          padding: 19px 18px 27px;
          border-bottom: 1px solid #e7e9ec;

          @media (max-width: 799px) {
            padding: 15px 12px 12px;
          }
        }

        .total-comments {
          font-size: 22.4px;
          line-height: 27px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
          letter-spacing: 0px;
          margin-bottom: 28px;

          @media (max-width: 799px) {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 20px;
          }
        }

        .leave-a-comment {
          font-size: 16px;
          line-height: 19px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
          letter-spacing: 0px;
          margin-bottom: 12px;
        }

        .comment-textarea-field {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          font-family: "Inter", sans-serif;
          padding: 13px 11px;
          border-radius: 8px;
          border-color: $color-grey;
          outline: none;
          width: calc(100% - 24px);

          &:focus {
            border-color: $color-Accent-1;
          }
        }

        .comments-button {
          padding: 13px 24px;
          background-color: $color-Accent-1;
          color: $color-White;
          text-transform: capitalize;
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          font-family: $regulerFont;
          letter-spacing: 0px;
          border-radius: 8px;

          .MuiTouchRipple-root {
            display: none;
          }

          @media (max-width: 799px) {
            font-size: 12px;
            line-height: 15px;
          }
        }

        .mb-12 {
          margin-bottom: 12px;
        }

        .reply-comments-section {
          padding: 24px 18px 30px;

          @media (max-width: 799px) {
            padding: 0px 12px 12px;
          }

          .parent-comment-section {
            .parent-comment {
              border-bottom: 1px solid $color-Light-grey;
              padding-top: 16px;

              @media (max-width: 799px) {
                padding-top: 12px;
              }
            }

            .comment-wrap {
              display: flex;
              align-items: flex-start;
              column-gap: 18px;

              @media (max-width: 799px) {
                column-gap: 12px;
              }

              .comments-author {
                width: 60px;
                height: 60px;
                min-width: 60px;
                border-radius: 50%;

                @media (max-width: 799px) {
                  width: 50px;
                  height: 50px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  border-radius: 50%;
                }
              }

              .comments-details-wrap {
                width: 100%;
                // border-bottom: 1px solid $color-Light-grey ;

                .comments-details-author {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 12px;

                  @media (max-width: 799px) {
                    margin-bottom: 6px;
                  }

                  .author {
                    .author-name {
                      font-size: 16px;
                      line-height: 19px;
                      font-weight: 700;
                      font-family: $regulerFont;
                      color: $color-Black;
                      letter-spacing: 0px;
                      margin-right: 12px;
                    }

                    .comment-time {
                      font-size: 11.42px;
                      line-height: 14px;
                      font-weight: 400;
                      font-family: $regulerFont;
                      color: $color-grey-Accent-1;
                      letter-spacing: 0px;
                    }
                  }
                }

                .comments {
                  font-size: 16px;
                  line-height: 19px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  color: $color-Black;
                  letter-spacing: 0px;
                  margin-bottom: 14px;

                  @media (max-width: 799px) {
                    font-size: 11.42px;
                    line-height: 17px;
                    margin-bottom: 6px;
                  }
                }

                .action-comments {
                  display: flex;
                  align-items: center;
                  column-gap: 12px;

                  .action {
                    display: flex;
                    align-items: center;

                    span {
                      font-size: 12px;
                      line-height: 15px;
                      font-weight: 400;
                      font-family: $regulerFont;
                      color: $color-gray-dark;
                      letter-spacing: 0px;
                    }

                    .active {
                      path {
                        fill: #5d5d5d;
                      }
                    }

                    .light {
                      opacity: 0.5;
                    }
                  }
                }

                .mobile-reply-btn {
                  @media (max-width: 799px) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 12px;
                  }
                }

                .reply-button {
                  font-size: 16px;
                  line-height: 19px;
                  font-weight: 600;
                  font-family: $regulerFont;
                  color: $color-gray-dark;
                  letter-spacing: 0px;
                  margin-bottom: 15px;

                  @media (max-width: 799px) {
                    font-size: 11.42px;
                    line-height: 14px;
                    margin-bottom: 0px;
                  }

                  .reply {
                    margin-right: 18px;

                    @media (max-width: 799px) {
                      margin-right: 12px;
                    }
                  }

                  .light {
                    opacity: 0.7;
                  }
                }
              }

              .comment-border-top {
                border-top: 1px solid $color-Light-grey;
                padding-top: 16px;

                @media (max-width: 799px) {
                  padding-top: 12px;
                }
              }
            }

            .reply-post-comment {
              padding-left: 30px;
            }

            .reply-box {
              .replying-to {
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-gray-dark;
                letter-spacing: 0px;
                margin-bottom: 7px;

                @media (max-width: 799px) {
                  font-size: 11.42px;
                  line-height: 14px;
                }

                span {
                  font-weight: 600;
                }
              }

              .reply-box-button {
                display: flex;
                align-items: center;
                column-gap: 18px;
                margin-bottom: 15px;

                .Cancel {
                  background-color: transparent;
                  color: $color-Accent-1;
                  border: 1px solid $color-Accent-1;
                }
              }
            }
          }

          .show-all-replies {
            display: flex;
            align-items: center;
            column-gap: 3px;
            margin-bottom: 15px;
            border-top: 1px solid #e7e9ec;
            cursor: pointer;

            .show-all-text {
              font-size: 16px;
              line-height: 19px;
              font-weight: 500;
              font-family: $regulerFont;
              letter-spacing: 0px;
              color: $color-Accent-1;
              display: flex;
              padding-top: 9px;

              span {
                margin-right: 4px;
              }
            }
          }
        }
      }

      .no-comments-Available {
        text-align: center;
        padding: 30px 0px 15px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        text-transform: capitalize;
        font-weight: 400;
        color: $color-Black;
        letter-spacing: 0px;
        font-family: $regulerFont;
      }

      .pagination-section {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 18px;

        @media (max-width: 799px) {
          margin-top: 30px;
          padding-bottom: 12px;
        }

        .MuiPagination-root {
          .MuiPagination-ul {
            .MuiButtonBase-root {
              width: 36px;
              height: 36px;

              @media (max-width: 799px) {
                font-size: 14px;
                width: 24px;
                height: 24px;
              }

              @media (max-width: 379px) {
                font-size: 11px;
                width: 22px;
                height: 22px;
              }

              svg {
                width: 24px;
                height: 24px;

                @media (max-width: 799px) {
                  font-size: 14px;
                  width: 24px;
                  height: 24px;
                }

                @media (max-width: 379px) {
                  font-size: 11px;
                  width: 22px;
                  height: 22px;
                }
              }

              .MuiTouchRipple-root {
                display: none;
              }
            }

            .MuiPaginationItem-root {
              font-size: 18px;
              line-height: 21px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-Black;
              border-radius: 50%;

              @media (max-width: 799px) {
                font-size: 14px;
              }

              @media (max-width: 379px) {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }

  // right section css

  .right-sider-bar-section {
    border-radius: 8px;
    box-shadow: 0px 1px 9px 0px #0000002e;
    background-color: $color-White;

    .news-heading {
      background-color: #e8eaec;
      border-radius: 8px 8px 0px 0px;
      padding: 15px 18px 8px;
      font-size: 31.6px;
      line-height: 40px;
      font-family: $primaryFont;
      font-weight: 400;
      color: $color-Primary;
    }

    // related right side

    .related-articles {
      padding: 12px;
      border-bottom: 1px solid #e7e9ec;

      &:last-child {
        padding-bottom: 24px;
      }
    }

    .single-related-stories {
      display: flex;
      column-gap: 12px;
      cursor: pointer;

      .related-stories-img {
        max-width: 125px;
        max-height: 74;
        border-radius: 2px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 2px;
        }
      }

      .stories-details {
        width: 100%;

        .details {
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }

        .text-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;

          .stories-time {
            font-size: 11.42px;
            line-height: 14px;
            font-family: $regulerFont;
            font-weight: 400;
            color: #5d5d5d;
          }

          .bookmark-chat-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .chat-details {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 6px;

              svg {
                margin-right: 3px;
              }
            }

            .saved-bookmark-icon {
              path {
                fill: $color-gray-dark;
              }
            }
          }
        }
      }
    }

    .top-bookmaker-detail-wrap {
      display: flex;
      align-items: center;
      padding: 12px;
      border-bottom: 1px solid #e7e9ec;

      .top-bookmaker-left {
        width: 125px;
        min-width: 125px;
        height: 74px;
        margin-right: 15px;
        // background-color: $color-Black;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .top-bookmaker-right {
        // width: calc(100% - 80px);
        height: 74px;
        background: #ffffff;
        position: relative;

        .top-bookmaker-right-details-wrap {
          // padding: 6px;

          .top-bookmaker-description {
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            font-family: $regulerFont;
            color: $color-Black;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .claim-btn {
            font-size: 12px;
            padding: 3px 20px;
            line-height: 15px;
            font-weight: 600;
            text-align: center;
            width: 231px;
            padding: 8px 0px 7px;
            border-radius: 8px;
            background-color: $color-Black;
            color: $color-White;
            position: absolute;
            display: block;
            bottom: 0px;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .mt-30 {
    margin-top: 30px;
  }

  .right-section-wrap {
    padding: 29px 17.9px 22.5px 18px;

    .news-heading {
      font-size: 22.4px;
      line-height: 27px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-Black;
      position: relative;
      margin-bottom: 30px;
      cursor: pointer;

      &::after {
        content: "";
        background-color: #4455c7;
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -15px;
      }
    }

    // Latest right side

    .first-slider {
      cursor: pointer;
    }

    .second-slider {
      margin-top: 9px;
      cursor: pointer;

      .slick-arrow {
        position: absolute;
        text-align: center;

        svg {
          path {
            fill: #5d5d5d;
          }
        }
      }

      .slick-prev {
        position: absolute;
        left: -20px !important;
        top: 15px;

        &::before {
          content: "";
        }
      }

      .slick-next {
        top: 15px;
        right: -10px !important;

        &::before {
          content: "";
        }
      }
    }
  }

  .related-articles-layout {
    padding: 18px;
    background-color: #e6eefa;
    margin-top: 18px;

    .related-articles-header {
      h4 {
        font-size: 22.4px;
        line-height: 27px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;
        position: relative;
        margin-bottom: 30px;
        cursor: pointer;

        &::after {
          content: "";
          background-color: #4455c7;
          height: 1px;
          width: 100%;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -15px;
        }
      }
    }

    .slick-arrow {
      position: absolute;
      // width: 32px;
      // height: 26px;
      // background-color: #5D5D5D;
      text-align: center;

      svg {
        path {
          fill: $color-Black;
        }
      }
    }

    .slick-prev {
      position: absolute;

      &::before {
        content: "";
      }
    }

    .slick-next {
      right: 0px !important;

      @media (max-width: 539px) {
        right: -13px !important;
      }

      &::before {
        content: "";
      }
    }

    .slick-slide {
      @media (max-width: 439px) {
        width: 270px;
      }
    }

    .single-top-stories {
      cursor: pointer;
      max-width: 260px;

      @media (max-width: 639px) {
        max-width: 238px;
      }

      @media (max-width: 539px) {
        max-width: 195px;
      }

      @media (max-width: 439px) {
        max-width: 238px;
      }

      .top-stories-img {
        max-width: 260px;

        @media (max-width: 639px) {
          max-width: 238px;
        }

        @media (max-width: 539px) {
          max-width: 195px;
        }

        @media (max-width: 439px) {
          max-width: 238px;
        }

        img {
          width: 100%;
        }
      }

      .stories-details {
        margin-top: 12px;

        @media (max-width: 799px) {
          margin-top: 0px;
        }

        .details {
          font-size: 22.4px;
          line-height: 31.36px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          @media (max-width: 639px) {
            font-size: 16px;
            line-height: 22.4px;
          }
        }

        .text-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 9px;

          @media (max-width: 799px) {
            margin-top: 18px;
          }

          .stories-tag {
            padding: 3px 6px;
            background-color: #989898;
            color: #ffffff;
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            font-family: $regulerFont;
            max-width: fit-content;
            border-radius: 3px;
          }

          .stories-time {
            font-size: 11.42px;
            line-height: 14px;
            font-family: $regulerFont;
            font-weight: 400;
            color: #5d5d5d;

            .watch {
              margin-right: 4px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.no-data {
  text-align: center;
}

.instagram-post {
  display: flex;
  justify-content: center;
}

.custom-news {
  img {
    width: 100%;
  }

  // overflow-x: scroll;
  // overflow-y: auto;

  // p {
  //   margin: 0px;
  // }

  table {
    // border: 1px solid #e7e9ec;
    overflow: hidden;
    border-radius: 8px;
    width: 100%;

    // text-align: left;
    thead {
      width: 100%;

      tr {
        border-top: 1px solid #003764;

        th {
          // border-top: 1px solid #003764;
          // border-bottom: 1px solid #003764;
          font-size: 22.4px;
          font-family: $primaryFont;
          text-transform: uppercase;
          padding: 9px 0px;

          line-height: 28px;
          font-weight: 400;
          color: $color-Black;
          letter-spacing: 0px;
          background-color: #e7e9ec;

          @media (max-width: 1000px) {
            font-size: 16px;
            padding: 4px 0px;
            line-height: 15px;
          }

          @media (max-width: 799px) {
            font-size: 14px;
            padding: 4px 0px;
            line-height: 15px;
          }
        }
      }
    }

    tbody {
      width: 100%;

      tr {
        // border-bottom: 1px solid #e7e9ec;

        td {
          padding: 9px 21px;
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          color: $color-Black;
          font-family: $regulerFont;
          letter-spacing: 0px;
          border-bottom: 1px solid $color-Light-grey;

          @media (max-width: 1000px) {
            font-size: 14px;
            padding: 2px 12px;
            line-height: 15px;
          }

          @media (max-width: 799px) {
            // font-size: 10px;
            padding: 2px 6px;
            line-height: 15px;
          }

          @media (max-width: 599px) {
            font-size: 10px;
            padding: 2px 6px;
            line-height: 15px;
          }
        }

        .runner-details {
          h6 {
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            color: $color-Black;
            font-family: $regulerFont;
          }
        }

        .odds-details {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .se-video-container {
    width: unset !important;

    figure {
      margin: 0 !important;
      padding-bottom: unset !important;
      width: unset !important;
      height: auto !important;

      iframe {
        width: 100% !important;
      }
    }
  }

  iframe {
    width: 100% !important;
    height: 400px !important;

    // @media (max-width: 1200px) {
    //   height: 250px !important;
    // }
    // @media (max-width: 920px) {
    //   height: 220px !important;
    //   // width: 400px !important;
    // }
    // @media (max-width: 834px) {
    //   height: 200px !important;
    //   // width: 400px !important;
    // }
    // @media (max-width: 799px) {
    //   height: 250px !important;
    //   // width: 400px !important;
    // }
    // @media (max-width: 630px) {
    //   height: 200px !important;
    //   width: 250px !important;
    // }
    // @media (max-width: 560px) {
    //   height: 150px !important;
    //   width: 200px !important;
    // }
  }
}

.News-wrap {
  padding: 29px 18px 29px 17.9px;

  .news-heading {
    font-size: 22.4px;
    line-height: 30px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-Black;
    position: relative;
    margin-bottom: 21px;
    cursor: pointer;

    .right-arrow {
      margin-left: 10px;
    }

    &::after {
      content: "";
      background-color: #4455c7;
      height: 1px;
      width: 95%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
    }
  }

  .top-bookmaker-wrap {
    .top-bookmaker-heading {
      text-align: center;
      font-size: 11.42px;
      line-height: 14px;
      font-weight: 700;
      color: #d6d9f3;
      background: #003764;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .gabling-wrap {
    background-color: white;
    padding: 30px 12px;

    .gabling-header {
      font-family: "Arial" !important;
      text-align: center;
      font-size: 11.42px;
      line-height: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .gabling-discription {
      font-size: 7px;
      line-height: 10px;
      font-weight: 400;
      font-family: "Arial" !important;
      text-align: center;
      margin-top: 4px;

      span {
        font-family: "Arial" !important;
      }

      .responsible-gambling-helpline-no,
      .responsible-gambling-logo {
        color: $color-Primary;
        text-decoration: underline;
        font-family: "Arial" !important;
      }
    }
  }
}

.news-tooltip {
  background: #003764 !important;

  .MuiTooltip-arrow {
    color: #003764;
  }
}

.news-share-tooltip {
  padding: 15px !important;
  background-color: $color-White !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 9px 0px #00000038;

  .MuiTooltip-arrow {
    color: $color-White;
  }

  .social-option-box {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 10px;
    row-gap: 10px;

    .option {
      text-align: center;
    }

    .option-name {
      font-family: $regulerFont;
      font-size: 11.42px;
      line-height: 15px;
      color: #5d5d5d;

      @media (max-width: 560px) {
        font-size: 11.42px;
      }
    }
  }
}

.comment-delete-dialogbox {
  .delete-title {
    margin-bottom: 16px;
    font-weight: 600;

    @media (max-width: 799px) {
      font-size: 22.4px;
      line-height: 27px;
      margin-bottom: 12px;
    }
  }

  .sub-title {
    font-size: 16px;
    line-height: 22.4px;
    font-weight: 400;
    font-family: $regulerFont;
    color: $color-Black;
    letter-spacing: 0px;
    margin-bottom: 15px;
  }

  .delete-btn {
    @media (max-width: 799px) {
      button {
        font-size: 14px !important;
        line-height: 16px;
      }

      .btn-cancel {
        margin-bottom: 0px !important;
      }
    }
  }
}