@import "../../../assets/scss/variable.scss";

.manage-email-subscription-wrap {
  background-color: $color-off-White;

  .manage-email-subscription-header {
    padding: 46px 18px 27px;
    margin-top: -5px;
    @media (max-width: 799px) {
      padding: 18px 12px 23px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }
    .email-header-sub-text {
      color: $color-Primary;
      font-size: 22.4px;
      font-weight: 600;
      line-height: 31.36px;
      font-family: $regulerFont;
    }
    .header-selectall-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      @media (max-width: 639px) {
        align-items: flex-start;
        flex-direction: column;
        row-gap: 7px;
      }
      .select-all-switch {
        display: flex;
        align-items: center;
        @media (max-width: 639px) {
          justify-content: flex-end;
          width: 100%;
        }
        p {
          color: $color-Black;
          font-family: $regulerFont;
        }
      }
      .switch-box {
        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
        .MuiFormControlLabel-root {
          margin-left: 5px;
          max-width: max-content;
          .MuiSwitch-root {
            padding: 0px;
            width: 39px;
            height: 20px;
            border-radius: 10px;
            margin-left: 9px;
            .MuiButtonBase-root {
              padding: 0px;
            }
            .Mui-checked {
              color: $color-Accent-1;
            }
            .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
              background-color: $color-Lavender;
            }
            .MuiSwitch-track {
              background-color: $color-grey;
              opacity: 0.8;
            }
          }
          .MuiTypography-root {
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            font-family: $regulerFont;
            width: max-content;
          }
        }
      }
    }
  }

  .loader_center {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .page-deatils-wrap {
    padding: 0px 18px 18px 18px;
    position: relative;
    @media (max-width: 799px) {
      padding: 0px 12px 12px 12px;
    }
    .manage-subscription-contant {
      .subscription-contant {
        border-bottom: 1px solid $color-Light-grey;
        margin: 12px 0px;
        :first-child {
          margin-top: 0px;
        }
        .subscription-contant-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          .subscription-title {
            font-family: $regulerFont;
            font-size: 16px;
            font-weight: 600;
            .subscription-title-other-text {
              font-weight: 400;
            }
          }
        }
        .subscription-checkbox {
          display: flex;
          flex-wrap: wrap;
          row-gap: 9px;
          font-family: $regulerFont;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 12px;
          @media (max-width: 559px) {
            flex-direction: column;
          }
          label {
            .MuiButtonBase-root {
              padding: 0px 9px;
            }
          }
        }
      }
      .switch-box {
        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
        .MuiFormControlLabel-root {
          margin-left: 5px;
          max-width: max-content;
          .MuiSwitch-root {
            padding: 0px;
            width: 39px;
            height: 20px;
            border-radius: 10px;
            margin-left: 9px;
            .MuiButtonBase-root {
              padding: 0px;
            }
            .Mui-checked {
              color: $color-Accent-1;
            }
            .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
              background-color: $color-Lavender;
            }
            .MuiSwitch-track {
              background-color: $color-grey;
              opacity: 0.8;
            }
          }
          .MuiTypography-root {
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            font-family: $regulerFont;
            width: max-content;
          }
        }
      }
    }
    .subscription-update-btn {
      display: flex;
      align-items: center;
      margin-top: 24px;
      .update-btn {
        padding: 13px 24px 12px;
        background-color: $color-Accent-1;
        color: $color-White;
        border-radius: 8px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        letter-spacing: 0px;
        text-transform: capitalize;
        cursor: pointer;
        .MuiTouchRipple-root {
          display: none;
        }

        @media (max-width: 599px) {
          margin-bottom: 10px;
        }
      }

      .cancel-btn {
        padding: 13px 24px 10px;
        background-color: $color-White;
        color: $color-Accent-1;
        border-radius: 8px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        letter-spacing: 0px;
        text-transform: capitalize;
        border: 1px solid $color-Accent-1;
        margin-left: 33px;
        cursor: pointer;
        .MuiTouchRipple-root {
          display: none;
        }

        @media (max-width: 599px) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
