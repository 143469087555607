@import "../../../assets/scss/variable.scss";

.membership-data-header {
    padding: 21px 0px 0px;
    margin-bottom: 27px;

    @media (max-width: 799px) {
        padding: 12px 0px 6px;
        margin-bottom: 12px;
    }

    .bredcrumn-details {
        overflow-x: scroll;

        .bredcrumn-wrap {
            margin-bottom: 3px;
            min-width: max-content;

            li.MuiBreadcrumbs-separator {
                color: $color-Black;
            }

            li {
                font-size: 11.42px;
                line-height: 14px;

                p {
                    font-size: 11.42px;
                    line-height: 14px;
                    color: $color-Accent-1;
                    text-transform: uppercase;
                }

                a {
                    color: $color-Black;
                    font-size: 11.42px;
                    line-height: 14px;
                    text-transform: uppercase;
                }
            }
        }
    }

    .bredcrumn-details::-webkit-scrollbar {
        height: 0px;
    }

    .bredcrumn-details::-webkit-scrollbar-thumb {
        display: none;
    }

    h1 {
        font-size: 31.6px;
        line-height: 40px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Black;
    }
}

.profile-membership-page-wrap {
    column-gap: 30px !important;

    @media (max-width:1023px) {
        column-gap: 12px;
    }
}

.membership-page-wrap {
    display: flex;
    align-items: flex-start;
    column-gap: 55px;

    @media (max-width:1023px) {
        column-gap: 18px;
    }

    @media (max-width:799px) {
        flex-direction: column;
        row-gap: 13px;
    }

    .plan-details-wrap {
        width: 50%;

        @media (max-width:799px) {
            width: 100%;
        }
    }

    .membership-from-details-wrap {
        width: 50%;

        @media (max-width:799px) {
            width: 100%;
        }
    }
}