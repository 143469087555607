@import "../../../../assets/scss/variable.scss";

.sport-data-wrap {
  padding: 18px;
  background-color: $color-White;
  box-shadow: 0px 3px 9px 0px #0000000d;

  @media (max-width: 799px) {
    padding: 18px 12px;
  }

  .d-flex {
    display: flex;
  }

  .align-center {
    align-items: center;
  }

  .content-space-between {
    justify-content: space-between;
  }

  .text-align-center {
    text-align: center;
  }

  .col-gap-5 {
    column-gap: 5px;
  }

  .mb-18 {
    margin-bottom: 18px;
  }

  .mt-18 {
    margin-top: 18px;
  }

  .plr-5 {
    padding: 0px 5px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .no-match {
    padding: 10px;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-family: $regulerFont;
    color: $color-Black;
    font-weight: 400;
  }

  .no-match-boxshadow {
    padding: 18px 33px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;
    border-radius: 8px;
  }

  .overview-details-wrap {
    .short-name-details {
      padding: 10px 0px 0px 0px;
    }

    .overview-title {
      font-size: 22.4px;
      line-height: 28px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-Black;
      border-bottom: 1px solid $color-Accent-1;
      margin-bottom: 12px;
    }
  }

  .short-name-details {
    padding: 10px 0px 0px 0px;
  }

  .light-blue {
    background-color: #0075ff;
  }

  .medium-orange {
    background-color: #ab5e03;
  }

  .dark-red {
    background-color: #961a3d;
  }

  .league-indicator {
    width: 50%;
    height: 10px;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    bottom: 0px;
  }

  .league-name-indicator {
    column-gap: 15px;
    padding: 15px 0px 0px;
    flex-wrap: wrap;
    row-gap: 12px;

    .league-name-color {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    .league {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      color: $color-Black;
      font-family: $regulerFont;
    }
  }

  .info-table-cont-wrap {
    &:not(:first-child) {
      margin-top: 18px;
    }

    .data-table {
      box-shadow: inset 1px 2px 9px 0px #0000001f;
      background-color: $color-White;
      border: 1px solid #c9c9c9;

      .table-header {
        .MuiTableCell-head {
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
          font-family: $regulerFont;
          padding: 17px 24px 8px;
        }
      }

      .table-body {
        .MuiTableCell-body {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          font-family: $regulerFont;
          padding: 17px 24px 8px;
        }

        .table-body-column {
          // padding: 0px 24px 0px 0px;
          border-bottom: none;
          font-size: 16px;
          line-height: 19px;
        }

        .table-team-logo {
          .logo-wrap {
            width: 33px;
            height: 33px;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: contain;
            }
          }

          .soccer-tema-name {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            font-family: $regulerFont;
          }
        }

        .body-cell-indicator {
          position: relative;
        }


      }
    }
  }

  .mobile-info-table {
    padding: 12px;
    background-color: $color-White;
    border-radius: 8px;
    box-shadow: 0px 3px 9px 0px #0000000d;

    &:not(:first-child) {
      margin-top: 12px;
    }

    .mobile-table-heading {
      column-gap: 15px;
      border-bottom: 1px solid #c9c9c9;
      padding-bottom: 5px;

      .table-heading {
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;
        color: $color-Black;
        font-weight: $regulerFont;
        letter-spacing: 0px;
      }

      .heading-pos {
        width: 35px;
        text-align: center;
      }
    }

    .info-table-deatils {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px 9px;

      .info-table-mobile-header {
        column-gap: 15px;

        .details-name {
          font-size: 15px;
          line-height: 33px;
          color: $color-Black;
          font-weight: 400;
          font-family: $regulerFont;
          letter-spacing: 0px;
          width: 35px;
          position: relative;
          padding-bottom: 5px;
          text-align: center;

          .league-indicator {
            width: 100%;
          }
        }

        .table-body-column {
          .team-wrap-body {
            column-gap: 5px;

            .img-wrap {
              width: 33px;
              height: 33px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }

            .team-name {
              font-size: 15px;
              line-height: 18px;
              font-weight: 400;
              font-family: $regulerFont;
              color: #1a73e8;
              letter-spacing: 0px;
            }
          }
        }
      }

      .arrow-details {
        width: 22px;
        height: 22px;

        .MuiButtonBase-root {
          width: 100%;
          height: 100%;

          svg {
            path {
              fill: #e2662c;
            }
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }
      }
    }

    .info-table-border-bottom {
      &:not(:last-child) {
        border-bottom: 1px solid #bdbdbf;
      }
    }

    .collapse-box {
      &:not(:last-child) {
        border-bottom: 1px solid #bdbdbf;
      }

      .collapse-deatils {
        box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.16);

        .collapse-table {
          .collapse-table-row {
            border-bottom: 1px solid rgba(189, 189, 191, 50%);

            .MuiTableCell-root {
              padding: 16px 31px 11px 27px;
              font-size: 16px;
              line-height: 16px;
              font-family: $regulerFont;
            }

            .td-left {
              color: #003764;
              font-weight: 600;
            }

            .td-right {
              color: #191919;
              font-weight: 500;
              text-align: end;
            }
          }
        }
      }

      .view-profile-box {
        text-align: end;

        .view-profile {
          font-size: 12px;
          line-height: 16px;
          color: #4455c7;
          font-weight: 400;
          font-family: $regulerFont;
          text-decoration: underline;
          padding: 9px 0px;
        }
      }
    }

    .collapse-box-bottom {
      padding-bottom: 9px;
    }

    .no-data-mobile {
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      color: $color-Black;
      font-weight: 600;
      font-family: $regulerFont;
      padding: 10px 0px 9px;
    }
  }





  // match details css

  .soccer-details-tab {
    .MuiTabs-scroller {
      .MuiTabs-indicator {
        display: none;
      }

      .active {
        background-color: $color-Primary;

        .match-tab {
          span {
            color: $color-White !important;
          }
        }
      }
    }

    @media (max-width: 799px) {
      margin-top: 6px;
    }

    .MuiTabs-flexContainer {
      column-gap: 12px;
    }

    .MuiBox-root {
      min-width: 105px;

      .MuiButtonBase-root {
        border: 2px solid $color-Primary;
        border-radius: 22px;
        min-height: 45px;

        @media (max-width:799px) {
          min-height: 40px;
        }

        .match-tab {
          span {
            font-family: $primaryFont;
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            color: $color-Black;
            width: 100%;
          }
        }
      }
    }

    .MuiTab-textColorInherit {
      opacity: 1;
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }

  .soccer-details-tab-table {
    padding-bottom: 10px;

    .MuiBox-root {
      min-width: 80px;
    }
  }
}

.divider-indicator {
  height: 100%;
  width: 5px;
  background: $color-Primary;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 8px;
}

.match-details-wrap {
  padding: 18px 33px;
  box-shadow: 0px 3px 9px 0px #0000000d;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 3px 9px 0px #00000052;
  }

  @media (max-width: 799px) {
    padding: 18px 12px;
  }

  .date-time-text {
    font-weight: 600;
  }

  .sport-team-img {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .sport-team-name-text {
    font-weight: 600;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .left-section {
    column-gap: 15px;

    .date-time-wrap {
      width: 65px;
    }

    .sport-team-name {
      column-gap: 10px;
    }
  }

  .team-score {
    font-size: 16px;
    line-height: 19px;
    font-family: $regulerFont;
    color: $color-Black;
    font-weight: 600;
  }

  .fs-16 {
    font-size: 16px;
    line-height: 19px;
    font-family: $regulerFont;
    color: $color-Black;
  }

  .card-wrap-live {
    column-gap: 15px;
  }
}

.light-theme {
  background-color: #ffffff;
}

.dark-theme {
  background-color: #003764;

  .fs-16 {
    color: $color-White;
  }
}