@import "../../../../assets/scss/variable.scss";
@import "../../../../assets/scss/global.scss";

.no-data-container {
  //   max-width: 670px;
  //   width: 100%;
  //   margin: 0 auto;
  margin-top: 34.4px;
  padding-bottom: 18px;

  .no-data-img-box {
    text-align: center;
  }

  .bold-font-22 {
    text-align: center;
    font-weight: 600;
    font-family: $regulerFont;
    font-size: 22.4px;
    line-height: 31.36px;
    color: #191919;
  }

  .no-data-txt {
    text-align: center;
    max-width: 662px;
    margin: 0 auto;
    width: 100%;
    margin-top: 9.2px;
    font-weight: 300;
    font-family: $regulerFont;
    font-size: 16px;
    line-height: 21px;
    color: $color-gray-dark;
  }

  .button-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 8px;
    column-gap: 16px;
    margin-top: 18px;

    @media (max-width: 410px) {
      column-gap: 8px;
    }
    .nav-button {
      background-color: #003764;
      //   padding: 9px 28px;
      padding: 12px 27px;
      color: $color-White;
      border-radius: 8px;
      font-weight: 400;
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 21px;
      text-transform: capitalize;
    }
    .mr-16 {
      margin-right: 16px;
    }
  }
}
