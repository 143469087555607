@import "../../../../../../assets/scss/variable.scss";

.landing-home-featured-fixture-slider-wrap {
    margin-top: 50px;

    @media (max-width:1080px) {
        margin-top: 30px;
    }

    .landing-home-slider-header {
        text-align: center;
    }
}