@import "../../../../assets/scss/variable.scss";

.post-sec {
  display: flex;
  align-items: center;
  column-gap: 18px;
  // margin-left: 50px;
  // margin-top: 12px;
  max-width: 850px;
  width: 100%;

  @media (max-width: 1160px) {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    // margin-right: 12px;
  }

  .post-component {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 18px;
    @media (max-width: 1160px) {
      width: 96%;
      display: flex;
      align-items: center;
      column-gap: 12px;
    }

    .user-img {
      align-self: start;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      // margin-top: -60px;

      @media (max-width: 560px) {
        height: 50px;
        width: 50px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .mention-input {
      .mention-input__highlighter {
        overflow-wrap: unset !important;
        max-height: 98px;
      }
    }

    .details-textarea-field {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      font-family: $regulerFont;
      // padding: 13px 11px;
      border-radius: 8px;
      border-color: #d4d6d8;
      max-width: calc(100% - 176px) !important;

      @media (max-width: 1160px) {
        max-width: calc(100% - 78px) !important;
      }

      @media (max-width: 799px) {
        width: 96% !important;
        // height: 174px !important;
        // margin-top: 5px;
      }

      @media (max-width: 539px) {
        font-size: 15px;
      }

      @media (max-width: 439px) {
        width: 91% !important;
        max-width: calc(100% - 64px) !important;
      }

      &:focus-visible {
        outline: none;
      }

      .details-textarea-field__control {
        font-family: $regulerFont !important;

        .details-textarea-field__input {
        }
      }
    }

    .post-btn {
      align-self: start;
      max-width: 82px;
      padding: 11px 26px 12px 26px;
      background-color: #4455c7;
      color: $color-White;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $regulerFont;
      border-radius: 8px;
      text-transform: capitalize;
      cursor: pointer;
      // @media (max-width: 579px) {
      //   font-size: 11.42px;
      // }
    }
  }
}

.right-btn {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 3px;

  @media (max-width: 1160px) {
    width: 96%;
    margin-top: 3px;
  }

  .MuiButton-root.Mui-disabled {
    color: $color-White !important;
  }

  .post-btn {
    align-self: start;
    max-width: 78px;
    padding: 11px 26px 12px 26px;
    background-color: #4455c7;
    color: $color-White;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $regulerFont;
    border-radius: 8px;
    text-transform: capitalize;
    cursor: pointer;
    // @media (max-width: 579px) {
    //   font-size: 11.42px;
    // }
  }
}

.wall-sec {
  // max-width: 100%;
  // width: 1022px;
  margin: 0 auto;
  padding-top: 27px;
  margin-top: 24px;
  border-top: 1px solid #e7e9ec;

  .reply-padding {
    padding: 0px !important;
  }

  .post-message {
    padding: 18px;
    background-color: $color-White;
    border-radius: 8px;
    column-gap: 18px;
    display: flex;

    .user-img {
      align-self: start;
      width: 14%;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      // margin-top: -60px;

      @media (max-width: 1160px) {
        height: 40px;
        width: 40px;
      }
      @media (max-width: 560px) {
        height: 38px;
        width: 38px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .post-details {
      width: 91%;

      .name-delete-sec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          color: #191919;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          font-family: $regulerFont;
        }
        .delete-box {
          cursor: pointer;
          width: 33px;
          height: 33px;
          // border-radius: 50%;
          // background-color: #ccd7e0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .comment {
        margin-top: 12px;
        color: #191919;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        font-family: $regulerFont;
      }
      .time {
        margin-top: 33px;
        color: #989898;
        font-size: 11.42px;
        line-height: 15px;
        font-weight: 400;
        font-family: $regulerFont;
      }

      .post-btn {
        align-self: start;
        margin-left: 18px;
        max-width: 78px;
        padding: 11px 26px 12px 26px;
        background-color: #4455c7;
        color: $color-White;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        cursor: pointer;
        @media (max-width: 579px) {
          margin-top: 6px;
          padding: 7px 20px 8px 20px;
        }
      }

      .reply-btn-sec {
        display: flex !important;
        justify-content: end !important;
        .reply-btn {
          max-width: 86px !important;
        }
      }

      .bottom-comp {
        margin-top: 18px;
        display: flex;
        align-items: center;

        .mention-input {
          .mention-input__highlighter {
            overflow-wrap: unset !important;
            max-height: 98px;
          }
        }
        .details-textarea-field {
          margin-left: 12px;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          font-family: $regulerFont;
          // padding: 13px 11px;
          border-radius: 8px;
          border-color: #d4d6d8;
          max-width: calc(100% - 176px) !important;

          @media (max-width: 1160px) {
            max-width: calc(100% - 78px) !important;
          }
          @media (max-width: 799px) {
            width: 96% !important;
            // height: 174px !important;
            // margin-top: 5px;
          }

          @media (max-width: 530px) {
            font-size: 15px;
            max-width: calc(100% - 68px) !important;
          }

          @media (max-width: 439px) {
            width: 91% !important;
          }

          &:focus-visible {
            outline: none;
          }

          .details-textarea-field__control {
            font-family: $regulerFont !important;

            .details-textarea-field__input {
            }
          }
        }
      }
    }
  }
}
