@import "../../../../assets/scss/variable.scss";

.payment-add-edit-modal {

    .MuiDialog-paper {
        max-width: 570px;
        width: 100%;
        margin: 12px;
        border-radius: 8px;

        .modal-title {
            background-color: #E8EAEC;
            padding: 21px 18px 9px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 8px;

            .title {
                font-size: 31.36px;
                line-height: 31.36px;
                font-weight: 400;
                font-family: $primaryFont;
                color: $color-Black;
            }

            .MuiButtonBase-root {
                padding: 0px;

                svg {
                    path {
                        fill: #989898;
                    }
                }
            }

            .close-icon {
                position: absolute;
                top: 18px;
                right: 18px;

                @media (max-width:799px) {
                    top: 12px;
                    right: 12px;
                }
            }

        }

        .payment-details {
            padding: 18px 18px 21px;

            @media (max-width:799px) {
                padding: 18px 12px;
            }

            .mb-19 {
                margin-bottom: 19px;
            }

            .date-cvv-wrap {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                column-gap: 18px;

                @media (max-width:799px) {
                    flex-direction: column;
                    row-gap: 18px;

                    .card-info {
                        width: 100%;
                    }
                }
            }

            .card-info {

                .textfield-text {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 600;
                    font-family: $regulerFont;
                    margin-bottom: 4px;
                    color: $color-Black;

                    @media (max-width:799px) {
                        font-size: 11.42px;
                        line-height: 14px;
                    }

                    .star {
                        color: #D84727;
                    }
                }

                .cvv-text {
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                }

                .details-textfield {
                    width: 100%;

                    .MuiInputBase-root {
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 400;
                        font-family: $regulerFont;
                        color: $color-Black;
                        border-radius: 8px;

                        .MuiInputBase-input {
                            padding: 13px 14px;
                        }

                        .MuiOutlinedInput-notchedOutline {
                            border-color: #D4D6D8;
                        }

                        input[type="number"]::-webkit-inner-spin-button,
                        input[type="number"]::-webkit-outer-spin-button {
                            display: none;
                        }
                    }

                    .Mui-focused {
                        .MuiOutlinedInput-notchedOutline {
                            border-color: $color-Accent-1;
                            border-width: 1px;
                        }
                    }
                }
            }

            .date-card-info {
                width: 50%;
            }

            .cvv-card-info {
                width: 50%;
            }

            .card-checkBox-wrap {
                padding-bottom: 18px;

                .documentsRead-check {
                    .MuiButtonBase-root {
                        margin-right: 12px;
                        padding: 0px 0px 0px 10px;

                        &:hover {
                            background-color: transparent;
                        }

                        .MuiSvgIcon-root {
                            path {
                                fill: #D4D6D8;
                            }
                        }

                        .MuiTouchRipple-root {
                            display: none;
                        }
                    }

                    .Mui-checked {
                        .MuiSvgIcon-root {
                            path {
                                fill: $color-Primary;
                            }
                        }
                    }

                    .left-card-details {
                        display: flex;
                        align-items: center;
                        column-gap: 11px;
                    }

                    .card-text {
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 400;
                        font-family: $regulerFont;
                        letter-spacing: 0px;
                        color: $color-Black;
                    }

                    .card-section {
                        display: flex;
                        align-items: center;
                        column-gap: 8px;
                    }
                }
            }

            .checkBox-wrap {
                border-bottom: 1px solid #4455C7;
                padding-bottom: 18px;

                .documentsRead-check {
                    .MuiButtonBase-root {
                        margin-right: 12px;
                        padding: 0px 0px 0px 10px;

                        &:hover {
                            background-color: transparent;
                        }

                        .MuiSvgIcon-root {
                            path {
                                fill: #D4D6D8;
                            }
                        }

                        .MuiTouchRipple-root {
                            display: none;
                        }
                    }

                    .Mui-checked {
                        .MuiSvgIcon-root {
                            path {
                                fill: $color-Primary;
                            }
                        }
                    }

                    .MuiTypography-root {
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 400;
                        font-family: $regulerFont;
                        letter-spacing: 0px;
                        color: #989898;

                    }
                }
            }

            .subscribe-wrap-btn {
                text-align: center;
                margin-top: 19px;
                margin-bottom: 8px;

                .subscribe-btn {
                    padding: 13px 24px 12px;
                    background-color: $color-Accent-1;
                    color: $color-White;
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 400;
                    font-family: $regulerFont;
                    border-radius: 8px;
                    text-transform: capitalize;
                    box-shadow: none;

                    .MuiTouchRipple-root {
                        display: none;
                    }
                }
            }

            .subscribe-note {
                text-align: center;

                span {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 400;
                    font-family: $regulerFont;
                    color: $color-Accent-1;
                    text-decoration: underline;
                    cursor: pointer;

                    @media (max-width:799px) {
                        font-size: 11.42px;
                        line-height: 14px;
                    }
                }
            }
        }
    }
}