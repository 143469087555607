@import "../../../../assets/scss/variable.scss";

.crop-image-wrap {
  .crop-image {
    padding: 72px 0px;

    @media (max-width:799px) {
      padding: 24px 18px;
    }

    .cropper-modal {
      background-color: transparent;
    }

    .without-crop {
      width: 100%;
      overflow: hidden;
      margin: 0 auto;

      .cropper-container {
        margin: 0 auto !important;
      }
    }

    .cropped-image {
      width: 300px;
      overflow: hidden;
      margin: 0 auto;

      .cropper-crop-box {
        transform: none !important;
      }

      .cropper-container {
        max-width: 100% !important;

        .cropper-canvas {
          display: none !important;
        }

        .cropper-crop-box {
          left: 50% !important;
          top: 50% !important;
          transform: translate(-50%, -50%) !important;
        }
      }

      // .cropper-container .cropper-crop-box {
      //     left: 50% !important;
      //     top: 50% !important;
      //     transform: translate(-50%, -50%) !important;
      // }
    }
  }

  .crop-save-btn {
    display: flex;
    justify-content: space-between;
    padding: 0px 42px 30px;

    @media (max-width:799px) {
      padding: 0px 12px 18px;
      flex-direction: column;
      row-gap: 12px;
    }

    .upload-button {
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      border: 1px solid $color-Accent-1;
      border-radius: 8px;
      letter-spacing: 0px;
      // width: 100%;
      padding: 12px 40px;
      text-transform: capitalize;

      @media (max-width:799px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .w-50 {
      @media (max-width:799px) {
        width: 50%;
      }
    }

    .all-block-wrap {
      display: flex;
      column-gap: 10px;

      @media (max-width:799px) {
        justify-content: space-between;
      }
    }

    .upload-button-blue {
      background-color: $color-Accent-1;
      color: $color-White;
    }

    .upload-button-white {
      background-color: transparent;
      color: $color-Accent-1;
    }

    //   .btn-white {
    //   }

    //   @media (max-width: 768px) {
    //     display: flex;
    //     flex-direction: column;

    //     .btn {
    //       min-width: 100%;
    //     }

    //     .b-btn-blue {
    //       margin-top: 18px;
    //     }

    //     .ml-18 {
    //       margin-top: 18px;
    //       margin-left: 0 !important;
    //     }
    //   }
  }

  .crop-image-top {
    width: 60%;
    margin: 0 auto;
    margin-top: 24px;
    margin-bottom: 31px;

    @media (max-width:799px) {
      width: 100%;
      margin: 12px 0px 15px;
    }
  }

  .zoom-plus-minus {
    display: flex;
    max-height: 18px;

    .disabled-btn {
      cursor: default;
      background-color: $color-White !important;
    }

    .icon-btn {
      width: 12%;
      padding: 0;
      text-align: center;

      .MuiButtonBase-root {
        padding: 0px;
        max-height: 18px;
      }
    }

    .zoom-bar {
      width: 80%;
      align-self: center;
      max-height: 18px;
      accent-color: $color-Accent-1;

      input {
        width: 100%;
      }
    }
  }

  .profile-cropper {
    .cropper-view-box {
      border-radius: 50%;
      overflow: hidden;
    }

    .cropper-face {
      border-radius: 50%;
    }
  }
}