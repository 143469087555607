@import "../../../../assets/scss/variable.scss";

.list-page-wrap {

    .round-view-details {
        padding-bottom: 0px !important;
    }

    .details-header {
        padding: 18px 64px 18px 29px !important;

        @media (max-width:799px) {
            padding: 18px 12px 15px;
        }
    }

    .mt-34 {
        margin-top: 34px;

        @media (max-width:799px) {
            margin-top: 15px;
        }
    }

    .mb-9 {
        margin-bottom: 9px;
    }

    .light-blue {
        color: #1A73E8;
    }

    .teamsport-header-details {
        box-shadow: none !important;
    }

    .list-tab {
        min-height: 31px;

        .MuiTabs-flexContainer {
            max-width: 267px;
            justify-content: space-between;
        }

        .MuiButtonBase-root {
            min-height: 31px;
            padding: 7px 6px 6px;
            line-height: 19px;
            font-size: 16px;
            font-weight: 500;
            color: $color-White;
            font-family: $regulerFont;
            opacity: 1;
            min-width: 110px;

            .MuiTouchRipple-root {
                display: none;
            }
        }

        .active {
            background-color: $color-Accent-1;
        }

        .MuiTabs-indicator {
            display: none;
        }
    }

    .view-more {
        padding: 9px 15px;
    }
}