@import "../../../../assets/scss/variable.scss";

.tips-card {
  border-radius: 8px;
  background-color: $color-White;
  box-shadow: 0px 3px 9px 0px #0000000d;
  // padding: 33px 12px 33px 18px;

  .tips-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 33px 18px 18px 18px;
    border-bottom: 1px solid #d4d6d8;

    @media (max-width: 799px) {
      padding: 12px;
    }

    .tips-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;

      @media (max-width: 799px) {
        width: 50px;
        height: 50px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .header-wrap-text {
      margin-left: 12px;

      @media (max-width: 799px) {
        margin-left: 11px;
      }
    }

    .follow-btn {
      width: 50px;
      height: 50px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .mb-5 {
      margin-bottom: 5px;
    }
  }

  .graph {
    padding: 84px 0px 34px;
    display: flex;
    justify-content: center;

    @media (max-width: 799px) {
      padding: 21px 0px 18px;
    }
  }

  .profit-wrap {
    padding: 0px 33px 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 799px) {
      padding: 0px 12px 15px;
    }

    .profit-section {
      width: max-content;

      .profit-text {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        color: #989898;

        @media (max-width: 799px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .profit-text-bold {
        font-weight: 700;
        color: #191919;
      }
    }
  }

  .footer-wrap-text {
    padding: 15px 12px 33px 18px;
    display: flex;
    align-items: center;
    border-top: 1px solid #d4d6d8;
    column-gap: 5px;

    @media (max-width: 1080px) {
      padding: 15px 10px 33px;
    }

    @media (max-width: 799px) {
      column-gap: 2px;
      align-items: baseline;
      padding: 15px 12px 12px;
    }

    .comments-wrap {
      width: 60%;

      p {
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: #989898;

        @media (max-width: 799px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }
    }

    .buy-now-wrap {
      width: 40%;
      text-align: center;

      .tips-today {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: $color-Black;
        margin-bottom: 10px;

        @media (max-width: 1080px) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      .tips-number {
        font-weight: 700;
      }

      .buy-now {
        padding: 13px 24px 12px;
        border-radius: 8px;
        color: $color-White;
        background-color: #4455c7;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $regulerFont;
        text-transform: capitalize;

        @media (max-width: 799px) {
          font-size: 11.42px;
          line-height: 14px;
          padding: 12px 17px 12px;
        }

        .price {
          margin-left: 5px;
        }

        .MuiTouchRipple-root {
          display: none;
        }
      }

      .review-btn {
        padding: 13px 24px 12px;
        color: $color-Accent-1;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        text-transform: inherit;
        border: 1px solid $color-Accent-1;
        border-radius: 8px;

        &:hover {
          background-color: transparent;
        }

        @media (max-width: 799px) {
          font-size: 11.42px;
          line-height: 14px;
          padding: 12px 17px 12px;
        }
      }
    }
  }

  .header-text {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: $regulerFont;
    letter-spacing: 0px;
    color: $color-Black;

    @media (max-width: 799px) {
      font-size: 11.42px;
      line-height: 14px;
    }
  }

  .rating {
    font-size: 18px;
    color: #1a73e8;
    margin-left: 9.5px;
  }

  .bold {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }

  .d-flex {
    display: flex;
  }

  .align-center {
    align-items: center;
  }

  .custom-tooltip {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .sport-tooltip {
    position: absolute;
    background-color: #d6d9f3;
    // width: 227px;
    height: auto;
    border: 1.5px solid #d6d9f3;
    padding: 9px;
    // padding: 4px 12px 6px;
    border-radius: 3px;
    z-index: 999;
    // top: calc(100% + 5px);
    // left: 130px;
    transform: translateX(-20%);
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    text-align: left;
    // top: -30px;

    width: 95px;
    left: 40px;
    top: -44px;

    @media (max-width: 1180px) {
      left: 30px;
    }
    @media (max-width: 1024px) {
      left: 74px;
    }
    @media (max-width: 910px) {
      left: 60px;
    }
    @media (max-width: 799px) {
      transform: translateX(-35%);
      left: 90px;
      top: -42px;
    }
    @media (max-width: 660px) {
      left: 75px;
    }
    @media (max-width: 580px) {
      left: 64px;
    }
    @media (max-width: 480px) {
      left: 40px;
    }

    .tooltip-arrow {
      position: absolute;
      //   top: 30px;
      //   left: -12px;
      //   transform: rotate(90deg);
      transform: rotate(0deg);
      top: 34px;
      left: 50px;

      @media (max-width: 799px) {
        left: initial;
        right: 50px;
        transform: rotate(0deg);
      }
    }

    .add-blackbook {
      padding: 6px 10px 6px 8px;
      font-size: 11.42px;
      line-height: 14px;
      font-weight: 600;
      color: #003764;
      font-family: $regulerFont;
      border-bottom: 1px solid #e7e9ec;
      display: flex;
      align-items: center;
    }

    .details {
      font-size: 11px;
      line-height: 15px;
      font-weight: 600;
      color: $color-Black;

      &:hover {
        background-color: #d4d6d8;
      }
    }
  }
}
