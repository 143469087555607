@import "../../../../assets/scss/variable.scss";

.days-tab {
  padding: 19px 18px 9px 18px;
  box-shadow: 0px 3px 9px 0px #0000000d;
  background: $color-White;

  .racing-tab-detail {
    border-bottom: 2px solid #4455c7;

    .active {
      .MuiTab-wrapper {
        color: $color-Accent-1;
      }
    }

    .MuiTab-wrapper {
      font-size: 16px;
      font-family: VeneerClean-Soft !important;
      color: $color-Black;
      line-height: 23px;
    }

    .MuiTab-root {
      min-width: 120px;
      opacity: 1;
    }

    .MuiTabs-indicator {
      height: 3px;
      color: $color-Primary;
    }
  }

  .Filteritemlist-wrap {
    display: flex;
    align-items: center;
    margin-top: 9px;

    @media (max-width: 560px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .filter-list-racing {
      display: flex;
      align-items: center;
    }

    .Filteritemlist-racing {
      display: flex;
      list-style-type: none;
      padding: 0px;
      margin: 0px;

      li {
        margin-right: 14px;

        label {
          display: flex;
          column-gap: 5.2px;
          font-size: 12px;
          line-height: 15px;
        }

        .MuiButtonBase-root {
          padding: 0px;
        }
      }
    }

    .Filteritemlist-datepicker {
      display: contents;

      .details-search-picker {
        @media (max-width: 560px) {
          min-width: 365px;
        }
      }

      .MuiFormControl-marginNormal {
        margin: 0px;

        @media (max-width: 560px) {
          margin-top: 9px;
        }
      }

      .MuiOutlinedInput-input {
        padding: 10.5px 14px;

        @media (max-width: 560px) {
          padding: 13px 14px;
        }
      }
    }
  }
}

.mt-18 {
  margin-top: 18px;
}

.tip-data-wrap {
  margin-top: 18px;
  padding: 33px 18px 33px 18px;
  background-color: $color-White;
  @media (max-width: 799px) {
    margin-top: 0px;
    padding: 9px 12px;
  }
  .header-sec {
    border-bottom: 1px solid $color-Accent-1;
    .header {
      color: #191919;
      font-size: 22.4px;
      line-height: 31.36px;
      font-family: $primaryFont;
    }
  }

  .tip-data-sec {
    margin-top: 21px;
    @media (max-width: 799px) {
      margin-top: 9px;
    }
    .tip-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $color-Light-grey;
      padding: 18px;

      @media (max-width: 1080px) {
        display: block;
      }
      @media (max-width: 799px) {
        padding: 12px 0px 9px;
        border: none;
        border-bottom: 1px solid $color-Light-grey;
      }
      .left-sec {
        // display: flex;
        // align-items: center;
        // column-gap: 28px;

        .title-sec {
          display: flex;
          column-gap: 28px;
          align-items: center;
          @media (max-width: 1080px) {
            justify-content: space-between;
          }
          .title {
            color: $color-Black;
            font-size: 16px;
            line-height: 21px;
            font-weight: 600;
            font-family: $regulerFont;
          }
          .racing-id {
            color: #989898;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: $regulerFont;
          }
        }
        .player-wrap {
          display: flex;
          column-gap: 36px;
          @media (max-width: 799px) {
            column-gap: initial;
          }
          justify-content: space-between;

          span {
            line-height: 24px;
            color: #191919;
          }
        }

        .left-insights {
          margin-top: 8px;
          display: flex;
          align-items: baseline;
          column-gap: 43px;
          @media (max-width: 1080px) {
            justify-content: space-between;
          }
          .insights-wrap {
            .insights-section {
              display: flex;
              .insights-text {
                color: $color-Black;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                font-family: $regulerFont;
                @media (max-width: 460px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .racer-detail-wrap {
        .scratched {
          color: #979797;

          .scratched-runner-name {
            text-decoration: line-through;
            font-size: 16px;
          }
        }

        .scratched-data {
          color: #979797;
          margin-top: 10px;
          .scratched-runner-text,
          .scratched-runner-time,
          .scratched-runner-date {
            font-size: 16px;
          }
        }
      }
      .right-sec {
        display: flex;
        align-items: center;
        width: 60%;
        justify-content: space-around;

        @media (max-width: 1080px) {
          justify-content: space-between;
          width: 100%;
        }
        .odd-btn {
          display: flex;
          align-items: center;
          column-gap: 55px;

          @media (max-width: 1160px) {
            column-gap: 18px;
          }
          @media (max-width: 1080px) {
            display: block;
          }
          .odd-data {
            .odds-wrap {
              display: flex;
              flex-direction: column;
              align-items: center;
              row-gap: 5px;

              @media (max-width: 1080px) {
                flex-direction: row;
                justify-content: space-around;
              }

              .odds-left {
                color: $color-Black;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                font-family: $regulerFont;
              }
            }
          }
        }

        .tip-btn {
          @media (max-width: 1080px) {
            margin-top: 8px;
          }
          .add-tip-btn {
            padding: 11px 26px 12px 25px;
            background-color: #4455c7;
            color: $color-White;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: $regulerFont;
            border-radius: 8px;
            text-transform: capitalize;

            @media (max-width: 579px) {
              font-size: 11.42px;
            }
          }
          .tipped-btn {
            padding: 11px 26px 12px 24px;
            background-color: #4455c7;
            color: $color-White;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: $regulerFont;
            border-radius: 8px;
            text-transform: capitalize;
            cursor: not-allowed;
            @media (max-width: 579px) {
              font-size: 11.42px;
            }
          }
          .tipped-disable {
            padding: 11px 26px 12px 24px;
            background-color: #e7e9ec;
            color: #999999;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: $regulerFont;
            border-radius: 8px;
            text-transform: capitalize;
            cursor: not-allowed;
            @media (max-width: 579px) {
              font-size: 11.42px;
            }
          }
        }
      }
    }
  }
}

.tip-table-sec {
  margin-top: 18px;
  padding: 33px 18px 33px 18px;
  background-color: $color-White;
  .header-sec {
    border-bottom: 1px solid $color-Accent-1;
    .header {
      color: #191919;
      font-size: 22.4px;
      line-height: 31.36px;
      font-family: $primaryFont;
    }
  }
  .table-content-sec {
    margin-top: 21px;

    .table-content {
      border: 1px solid #d4d6d8;

      .table-title {
        border-right: 1px solid #d4d6d8;
        color: #003764;
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
      }
      .table-detail {
        border-right: 1px solid #d4d6d8;
        color: #191919;
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
      }
    }

    .event-details {
      margin-top: 26px;
      padding-bottom: 9px;
      border-bottom: 1px solid #d4d6d8;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-txt {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          font-family: $regulerFont;
          color: #191919;

          @media (max-width: 600px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .date-txt {
          font-size: 13.42px;
          line-height: 26px;
          font-weight: 400;
          font-family: $regulerFont;
          color: #191919;
          @media (max-width: 600px) {
            font-size: 11.42px;
            line-height: 24px;
          }
        }
      }

      .profit-wrap {
        padding: 10px 0px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .profit-section {
          width: max-content;

          .profit-text {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            font-family: $regulerFont;
            color: #989898;

            @media (max-width: 600px) {
              font-size: 11.42px;
              line-height: 24px;
            }
          }

          .profit-text-bold {
            font-weight: 700;
            color: #191919;
          }
        }
      }

      // .pie-chart {
      //   display: flex;
      //   align-items: center;
      //   .success-rate-chart {
      //     display: flex;
      //     // justify-content: center;
      //     align-items: center;
      //     flex-direction: column;
      //     // height: 100vh; // Adjust height as needed

      //     .opactity {
      //       opacity: 0.5;
      //     }
      //     .prof-details {
      //       text-align: center;
      //       font-size: 16px;
      //       line-height: 22.4px;
      //       font-weight: 400;
      //       font-family: $regulerFont;
      //       color: #4455c7;
      //     }
      //   }
      // }
    }
  }
}
