@import "../../../../assets/scss/variable.scss";

.purchased-tip-table-sec {
  margin-top: 18px;
  padding: 33px 18px 33px 18px;
  background-color: $color-White;
  .top-info {
    padding-bottom: 3px;
    border-bottom: 1px solid $color-Accent-1;
    .top-head {
      color: #191919;
      font-size: 22.4px;
      line-height: 31.36px;
      font-family: $primaryFont;
    }
    .top-des {
      color: #8d8d8d;
      font-size: 14px;
      line-height: 19px;
      font-family: $regulerFont;
    }
  }
  .header-sec {
    border-bottom: 1px solid $color-Accent-1;
    .header {
      color: #191919;
      font-size: 22.4px;
      line-height: 31.36px;
      font-family: $primaryFont;
    }
  }
  .table-content-sec {
    margin-top: 21px;

    .table-content {
      // border: 1px solid #d4d6d8;

      .table-title {
        // border-right: 1px solid #d4d6d8;
        // color: #003764;
        // font-size: 14px;
        // line-height: 19px;
        // font-weight: 600;
        // font-family: $regulerFont;

        color: #003764;
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
        font-family: $regulerFont;
        background-color: #e8eaec;
      }
      .table-detail {
        // border-right: 1px solid #d4d6d8;
        color: #191919;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        font-family: $regulerFont;

        span {
          color: #4455c7;
        }

        .chip-sec {
          text-align: center;
          max-width: fit-content;
          margin: 0 auto;
          background-color: #4455c7;
          border-radius: 23px;
          padding: 3px 6px 3px 6px;
          //   margin-left: 23px;
          .chip-txt {
            text-align: center;
            font-weight: 400;
            font-family: "VeneerClean-Soft" !important;
            color: #fff !important;
            font-size: 16px;
            line-height: 21px;
            @media (max-width: 580px) {
              font-size: 11.42px;
            }
          }
        }
      }

      .continue-btn-sec {
        margin-top: 20px;
        text-align: center;
        width: 100%;

        .continue-btn {
          padding: 11px 24px 13px;
          border-radius: 8px;
          color: $color-White;
          background-color: #4455c7;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          text-transform: capitalize;

          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
            padding: 12px 17px 12px;
          }
        }
      }
    }

    .collapse {
      .MuiCollapse-wrapper {
        padding: 10px;
      }
      .MuiCollapse-wrapperInner {
        display: flex;
        column-gap: 6px;
      }
      .dashed-border {
        border: 1px dashed #4455c7 !important;
      }
      .blue-border {
        border: 1px solid #4455c7 !important;
      }

      .inner-table-content {
        border-radius: 8px;
        padding: 2px;
        border: 1px solid #d4d6d8;
        box-shadow: 10px 10px 5px 0px #0000000f;
        -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
        -moz-box-shadow: 10px 10px 5px 0px #0000000f;
        border-collapse: separate;

        .border-right-none {
          border-right: 0px !important;
        }

        .table-title {
          padding: 10px 18px 9px 18px;
          border-right: 1px solid #d4d6d8;
          color: #191919;
          font-size: 16px;
          line-height: 21px;
          font-weight: 600;
          font-family: $regulerFont;
          background-color: $color-White;
        }

        .text-center {
          text-align: center !important;
        }
        .table-detail {
          padding: 8px 18px 9px 18px;
          border-right: 1px solid #d4d6d8;
          color: #191919;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;

          span {
            color: #191919 !important;
          }

          .time-count-txt {
            display: flex;
            justify-content: space-between;
            align-items: center;

            color: #191919;
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            font-family: $regulerFont;
          }

          .learn-more-box {
            margin-top: 6px;
            // padding: 11px 33px 0px 5px;
            .learn-more {
              text-align: start;
              text-transform: capitalize;
              color: #4455c7;
              border: none;
              padding: 0;
              cursor: pointer;
              outline: none;
              text-decoration: underline;
              font-size: 14px;

              &:hover {
                background-color: transparent;
              }
            }
          }
        }
        .continue-btn-sec {
          margin-top: 20px;
          text-align: center;
          width: 100%;

          .continue-btn {
            padding: 11px 24px 13px;
            border-radius: 8px;
            color: $color-White;
            background-color: #4455c7;
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            font-family: $regulerFont;
            text-transform: capitalize;

            @media (max-width: 799px) {
              font-size: 11.42px;
              line-height: 14px;
              padding: 12px 17px 12px;
            }
          }
        }
      }
    }

    .left-section-details {
      .MuiAccordion-root {
        box-shadow: none;

        // &::before {
        //   display: none;
      }

      .MuiAccordionSummary-root {
        padding: 0px;
        // background: #4455c7;
        min-height: 45px;

        @media (max-width: 799px) {
          // padding: 0px 12px;
        }

        .MuiSvgIcon-root {
          fill: $color-White;
        }
      }

      .MuiAccordionSummary-content {
        margin: 0px;
        // column-gap: 11px;

        // p {
        //   font-size: 22.4px;
        //   font-family: $primaryFont;
        //   color: #fff;
        //   line-height: 31.36px;
        // }
      }

      //   .MuiIconButton-root {
      //     padding-top: 0px;
      //     padding-bottom: 0px;
      //   }
      // }

      .MuiAccordionDetails-root {
        padding: 8px 0px 16px;
        // padding: 9px 9px 0px;
        // width: 100%;
        // display: block;

        // .sub-accordion {
        //   .MuiAccordion-root {
        //     margin-bottom: 9px;
        //     box-shadow: 0px 3px 9px 0px #0000000d;

        //     &::before {
        //       display: none;
        //     }

        //     .MuiAccordionSummary-root {
        //       padding: 0px 24px;
        //       background: #e7e9ec;
        //       min-height: 45px;

        //       @media (max-width: 799px) {
        //         padding: 0px 9px;
        //       }

        //       .MuiSvgIcon-root {
        //         fill: #000000;
        //       }
        //     }

        //     .MuiAccordionSummary-content {
        //       margin: 0px;
        //       column-gap: 11px;

        //       p {
        //         font-size: 16px;
        //         font-family: $regulerFont !important;
        //         color: #000000;
        //         line-height: 19px;
        //         font-weight: 600;
        //       }
        //     }

        //     .MuiIconButton-root {
        //       padding-top: 0px;
        //       padding-bottom: 0px;
        //     }
        //   }

        //   .MuiAccordionDetails-root {
        //     padding: 9px 24px 9px;
        //     width: auto;
        //     display: block;
        //     background: #e7e9ec;

        //     @media (max-width: 1160px) {
        //       padding: 9px;
        //     }
        //   }
        // }
      }

      .event-details {
        width: 100%;
        margin-top: 26px;
        padding-bottom: 9px;
        border-bottom: 1px solid #d4d6d8;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title-txt {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            font-family: $regulerFont;
            color: #191919;

            @media (max-width: 600px) {
              font-size: 14px;
              line-height: 20px;
            }

            span {
              color: #4455c7;
            }
          }
        }

        .profit-wrap {
          padding: 10px 0px 0px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .align-self {
            align-self: self-start;
          }
          .profit-section {
            width: max-content;

            .profit-text {
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              font-family: $regulerFont;
              color: #989898;

              @media (max-width: 560px) {
                font-size: 11.42px;
                line-height: 24px;
              }
            }

            .profit-text-bold {
              font-weight: 700;
              color: #191919;
            }

            .date-txt {
              font-size: 13.42px;
              line-height: 26px;
              font-weight: 400;
              font-family: $regulerFont;
              color: #191919;
              @media (max-width: 560px) {
                font-size: 11.42px;
                line-height: 24px;
              }
            }
          }
        }

        .continue-btn-sec {
          text-align: center;

          .continue-btn {
            padding: 11px 24px 13px;
            border-radius: 8px;
            color: $color-White;
            background-color: #4455c7;
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            font-family: $regulerFont;
            text-transform: capitalize;

            @media (max-width: 799px) {
              font-size: 11.42px;
              line-height: 14px;
              padding: 12px 17px 12px;
            }
          }
        }
      }

      .accordion-details {
        width: 100%;
        padding: 8px 15px;
        box-shadow: 1px 1px 5px 5px #0000000d;
        display: flex;
        justify-content: space-between;
        align-items: start;
        border: 1px solid #4455c7;
        border-radius: 8px;

        .first-sec {
          .detail {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            font-family: $regulerFont;
            color: #191919;

            @media (max-width: 560px) {
              font-size: 12px;
            }
          }

          .chip-sec {
            text-align: center;
            max-width: fit-content;
            // margin: 0 auto;
            background-color: #4455c7;
            border-radius: 23px;
            padding: 3px 6px 3px 6px;
            //   margin-left: 23px;
            .chip-txt {
              text-align: center;
              font-weight: 400;
              font-family: "VeneerClean-Soft" !important;
              color: #fff !important;
              font-size: 16px;
              line-height: 21px;
              @media (max-width: 580px) {
                font-size: 11.42px;
              }
            }
          }

          .txt-white {
            color: $color-White !important;
          }

          .fw-500 {
            font-weight: 500;
          }
          .fw-400 {
            font-weight: 400;
          }

          .result-chip-sec {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 6px;
            margin-top: 9px;
            padding-top: 5px;
            border-top: 1px dashed #e7e9ec;

            .chip {
              width: 34px;
              height: 22px;
              border-radius: 10px;
              background-color: #003764;
              display: flex;
              justify-content: center;
              align-items: center;

              @media (max-width: 560px) {
                width: 30px;
                height: 17px;
                border-radius: 7px;
              }
            }
          }

          .blue-txt {
            color: #003764 !important;
          }

          .learn-more-box {
            margin-top: 6px;
            // padding: 11px 33px 0px 5px;
            .learn-more {
              text-align: start;
              text-transform: capitalize;
              color: #4455c7;
              border: none;
              padding: 0;
              cursor: pointer;
              outline: none;
              text-decoration: underline;
              font-size: 14px;

              &:hover {
                background-color: transparent;
              }

              @media (max-width: 560px) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.basic-flex {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.mw-150 {
  max-width: 150px !important;
}
