@import "../../../../../assets//scss/variable.scss";

.racing-type-wrap {
  margin-top: 19px;

  .racing-colleps {
    margin-bottom: 18px;

    .MuiAccordion-root {
      margin-bottom: 18px;
      box-shadow: 0px 3px 9px 0px #0000000d;

      &::before {
        display: none;
      }

      .MuiAccordionSummary-root {
        padding: 0px 33px;
        background: $bg-linear;
        min-height: 45px;

        .MuiSvgIcon-root {
          fill: $color-White;
        }
      }

      .MuiAccordionSummary-content {
        margin: 0px;
        column-gap: 11px;

        p {
          font-size: 22px;
          font-family: $primaryFont;
          color: #fff;
          line-height: 31.36px;
        }
      }

      .MuiIconButton-root {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }

    .MuiAccordionDetails-root {
      padding: 33px 65px 45px 34px;
      width: auto;
      display: block;
    }
  }

  .race-detail:last-child {
    .tracklist-container {
      margin-bottom: 0px;
    }
  }

  .race-detail {
    .MuiTable-root {
      margin-bottom: 1px;
    }

    .country-title {
      font-family: $primaryFont;
      font-size: 22.4px;
      line-height: 31.36px;
      border-bottom: 1px solid $color-Accent-1;
    }

    .MuiTableCell-head {
      color: $color-Primary;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      padding: 7px 0px;
      text-align: center;
      border-width: 0px 0px 1px 0px;
      border-color: $color-grey;
      font-family: $regulerFont;
    }

    td.fixed::after {
      content: url("../../../../../assets/images/Sport/fixed_mark.svg");
      position: absolute;
      top: 0;
      right: 0;
    }

    .racing-table-head {
      tr {
        th {
          text-transform: uppercase;
        }
      }
    }

    .tracklist-container {
      margin: 20px 0px 27px 0px;
    }

    td.paying {
      background-color: #d6d9f3;
    }

    td.interim {
      background-color: #bfccd8;
    }

    td.close-secondary {
      background-color: $color-Accent-2;
    }

    td.close {
      background-color: #78c2a7;
    }

    td.MuiTableCell-body {
      font-family: $regulerFont;
      line-height: 16px;
      border-left: 1px solid $color-grey;
      width: 65px;
      padding-left: 0px;
      padding-right: 0px;
      text-align: center;
      position: relative;
      border-bottom: 1px solid $color-grey;
    }

    td.MuiTableCell-body:last-child {
      border-right: 1px solid $color-grey;
    }

    .track-title {
      line-height: 19px;
      display: flex;
      width: auto;
      align-items: center;
      @media (max-width: 1139px) {
        width: min-content;
      }
      @media (max-width: 799px) {
        flex-direction: column;
        width: initial;
        align-items: flex-start;
      }
    }

    th.racing-data {
      // cursor: pointer;
      display: flex;
      padding: 13px 5px 13px 0px;
      column-gap: 9px;
      border: none;
      text-align: left;
      align-items: center;
    }

    .blackbook-tooltip {
      display: flex;

      .custom-tooltip {
        // padding-left: 5px;
        // padding-right: 10px;

        .tooltip {
          transform: translateX(-22%);
          width: max-content;
          top: -50px;
          left: 8px;

          .add-blackbook {
            border-bottom: none;
            padding: 6px 10px 6px 8px;
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 600;
            color: #003764;
            font-family: $regulerFont;
            display: flex;
            align-items: center;
          }

          .tooltip-bottom-arrow {
            position: absolute;
            bottom: -9px;
            left: 30px;
          }
        }
      }
    }

    th.rtable-hc1 {
      border: none;
    }

    .MuiChip-labelSmall {
      padding: 0px;
      font-size: 14px;
    }

    .singlerace-count-chip {
      height: auto;
      cursor: pointer;
    }

    .flag-icon {
      max-width: 37px;
      height: 21.58px;
      object-fit: cover;
    }

    .coutry-code {
      font-size: 11.42px;
      line-height: 14px;
      padding-left: 5px;
    }

    .mobileview-racing-tab-detail {
      .MuiTabs-flexContainer {
        .Mui-selected {
          background-color: $color-Secondary;
          border-radius: 6px;

          span {
            color: $color-White !important;
          }
        }

        .Mui-selected::after,
        .Mui-selected::before {
          content: "";
          background: none;
        }

        button {
          padding: 0px;

          span {
            font-family: $secondaryRegularFont !important;
            font-size: 12px;
            line-height: 14px;
            color: #0a1623;
            text-transform: capitalize;
          }
        }

        button::after {
          content: "";
          background-color: #0a1623;
          height: 19px;
          width: 1px;
          border-radius: 79px;
        }
      }

      .MuiTabs-indicator {
        background: none;
      }
    }

    .Mobile-racing-data {
      .racing-data-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0px;

        .track-name {
          font-family: $secondarySemiboldFont;
          font-size: 16px;
          line-height: 19px;
          color: $color-Accent-1;

          @media (max-width: 799px) {
            font-size: 12px;
            line-height: 15px;
          }
        }

        .blackbook-tooltip {
          display: flex;

          @media (max-width: 799px) {
            align-items: center;
            column-gap: 5px;
          }

          .custom-tooltip {
            padding-left: 5px;

            .tooltip {
              transform: translateX(-22%);
              width: max-content;
              top: -50px;
              left: 8px;

              .add-blackbook {
                border-bottom: none;
              }
            }
          }
        }

        .track-country {
          font-family: $secondaryRegularFont;
          font-size: 12px !important;
          line-height: 14px;
          color: black;
        }

        .track-detail {
          font-family: $secondarySemiboldFont !important;
          font-size: 12px !important;
          font-size: 16px;
          color: #0a1623;
          padding: 16px 0px 15px 0px;

          .MuiTableCell-root {
            padding: 0px;
          }

          span {
            font-family: $secondarySemiboldFont !important;
            font-size: 12px !important;
            font-size: 16px;
            color: #0a1623;
          }
        }

        .MuiIconButton-label {
          svg {
            path {
              fill: $color-Accent-1;
            }
          }
        }
      }

      .racing-menu-collapse {
        background-color: #e7e9ec;
      }

      .collapse-section {
        border-bottom: 1px solid #003764;
      }

      .upcoming-list {
        display: flex;
        align-items: center;

        .collapse-menu {
          border-bottom: none;

          td {
            padding: 0px 10px;
          }
        }
      }

      .collapse-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #bdbdbf;
        position: relative;

        &.fixed::after {
          content: url("../../../../../assets/images/Sport/fixed_mark.svg");
          position: absolute;
          top: 0;
          right: 0;
        }

        td {
          border-bottom: none;

          @media (max-width: 799px) {
            // width: 80%;
            text-align: start;
          }
        }

        .race-tag {
          padding: 3px 6px;
          width: 44px;
          border-radius: 6px;
          font-size: 11.42px;
          font-weight: 400;
          line-height: 14px;
          font-family: $regulerFont;
          text-align: center;
        }

        .race-tag-result {
          background-color: #d6d9f3;
          color: #191919;
          border: 0.5px solid transparent;
          margin-right: 30px;
        }
        .race-tag-interim {
          background-color: #bfccd8;
          color: #191919;
          border: 0.5px solid transparent;
          margin-right: 30px;
        }
        .race-tag-close {
          background-color: #ffc69a;
          color: #191919;
          border: 0.5px solid transparent;
          margin-right: 30px;
        }
        .race-tag-green {
          background-color: #1c9a6c;
          color: #191919;
          border: 0.5px solid transparent;
          margin-right: 30px;
        }
        .race-tag-upcoming {
          background-color: #fc4714;
          color: white;
          border: 0.5px solid transparent;
        }

        .race-tag-secondary-close {
          border: 0.5px solid #191919;
          color: #191919;
          margin-right: 30px;
        }
      }

      .collapse-td {
        td {
          @media (max-width: 799px) {
            width: 73%;
            text-align: start;
          }
        }
      }
    }

    .future-table-container {
      .racing-table-head {
        background-color: $color-Light-grey-1;
        .MuiTableCell-head {
          color: $color-Black;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          padding: 6px 0px 6px 23px;
          text-align: left;
          border: none;
          text-transform: capitalize;
        }
        .event-header {
          width: 50%;
          @media (max-width: 799px) {
            width: 75%;
          }
          @media (max-width: 479px) {
            width: 85%;
          }
        }
        .time-header {
          .sorting-cell {
            min-width: 140px;
          }
        }
        .odds-header {
          min-width: 120px;
        }
        .sorting-cell {
          display: flex;
          align-items: center;
          justify-content: start;
          column-gap: 5px;
          cursor: pointer;
          .sorting-arrow-wrap {
            display: flex;
            flex-direction: column;
            row-gap: 2px;
            cursor: pointer;
            .active {
              path {
                fill: $color-Accent-1;
              }
            }
            .down-arrow {
              transform: rotate(180deg);
            }
          }
        }
      }
      .future-table-body {
        .MuiTableCell-root {
          @media (max-width: 799px) {
            padding: 9px;
          }
        }
        .flag-date-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 5px;
          font-size: 14px;
          line-height: 17px;
          color: $color-gray-dark;
          @media (max-width: 799px) {
            flex-direction: column-reverse;
            align-items: flex-start;
            row-gap: 6px;
            font-size: 12px;
            line-height: 15px;
          }
        }
        .future-name {
          text-align: left;
          padding-left: 23px;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          color: $color-Primary;
          @media (max-width: 799px) {
            padding-left: 0px;
            font-size: 12px;
            line-height: 15px;
          }
        }
        .time-remaining {
          font-size: 14px;
          line-height: 17px;
          color: $color-gray-dark;
          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }
        .compare-odds {
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          color: $color-Accent-1;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 10px;
          cursor: pointer;
          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
            column-gap: 4px;
            text-decoration: underline;
            svg {
              height: 11px;
            }
          }
        }
        .responsive-eventdetail {
          display: flex;
          flex-direction: column;
          row-gap: 9px;
          .time-odds-wrap {
            display: flex;
            justify-content: space-between;
          }
        }
      }
      td.MuiTableCell-body {
        border-left: none;
      }
      td.MuiTableCell-body:last-child {
        border-right: none;
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .racing-type-wrap {
    .racing-colleps {
      .MuiAccordion-root {
        .MuiAccordionDetails-root {
          padding: 33px 30px 45px 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 799px) {
  .racing-type-wrap {
    .race-detail {
      td.MuiTableCell-body {
        border-left: none;
        border-bottom: none;
      }

      th.racing-data {
        display: table-cell;
        border-bottom: 1px solid $color-grey;
        padding: 6px 0px;
      }

      td.MuiTableCell-body:last-child {
        border-right: none;
      }

      .racing-data-row {
        border-bottom: 1px solid $color-grey;
      }

      td.paying {
        background: none;
        font-weight: 600;
        font-family: $regulerFont;
        font-size: 12px;
        line-height: 16px;

        span {
          color: #989898;
        }
      }

      td.interim {
        background: none;
      }

      td.close-secondary {
        background: none;
        font-weight: 600;
        font-family: $regulerFont;
        font-size: 12px;
        line-height: 16px;
      }

      td.white {
        font-weight: 600;
        font-family: $regulerFont;
        font-size: 12px;
        line-height: 16px;
      }

      td.close {
        background: none;
      }

      .tabs-wrap {
        padding: 12px 0px 0px 0px;
      }
    }

    .racing-colleps {
      .MuiAccordion-root {
        .MuiAccordionDetails-root {
          padding: 20px 20px 30px 20px;
        }
      }
    }
  }

  .racing-futures-table {
    .racing-futures-head {
      tr {
        th {
          text-transform: uppercase;
        }
      }
    }

    .racing-futures-body {
      tr {
        .MuiTableCell-root {
          padding: 12px 0px;
          border-bottom: 1px solid #bdbdbf;
        }
      }

      .futures-date-location {
        display: flex;
        flex-direction: column;

        .futures-date {
          font-weight: 600;
          margin-bottom: 3px;
        }
      }

      tr {
        td:not(:last-child) {
          text-align: left;
        }
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .racing-type-wrap {
    margin-top: 17px;

    .racing-colleps {
      margin-bottom: 27px;

      .MuiAccordion-root {
        margin-bottom: 27px;

        .MuiAccordionDetails-root {
          padding: 13px 12px 6px 12px;
        }
      }
    }

    .race-detail {
      .tracklist-container {
        margin: 9px 0px 27px 0px;

        .Mobile-racing-data {
          .Mobile-racing-data {
            .track-detail {
              span {
                padding: 0px;
              }
            }
          }
        }
      }
    }
  }

  .racing-colleps:last-child {
    margin-bottom: 14px;
  }
}

.box-cilck {
  display: flex;
  align-items: center;
  column-gap: 9px;
  cursor: pointer;
}

.get-tips {
  border: 1px solid $color-Accent-1;
  border-radius: 23px;
  padding: 3px 6px;
  width: max-content;
  cursor: pointer;
  z-index: 999;
  min-width: 33px;
  height: 22px;

  @media (max-width: 799px) {
    height: 15px;
  }

  span {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-Accent-1;

    @media (max-width: 799px) {
      font-size: 11.42px;
      line-height: 15px;
    }
  }
}

.get-tips-modal {
  .modal-details {
    padding: 5px;

    .user-name {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      font-family: $regulerFont;
      color: $color-Accent-1;
      cursor: pointer;

      span {
        text-transform: capitalize;
      }
    }
  }

  .back-btn {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border-radius: 8px;
    letter-spacing: 0;
    color: $color-Black;
    padding: 8px 20px;
    margin-top: 18px;
    box-shadow: none;

    &:hover {
      background-color: #e0e0e0;
      box-shadow: none;
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
}
