@import "../../../../assets/scss/variable.scss";

.category-news {
  .news-Pagination {
    display: flex;
    justify-content: center;

    ul {
      li {
        margin-right: 4px;

        @media (max-width: 379px) {
          margin-right: 0px;
        }

        .MuiPaginationItem-root {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          width: 36px;
          height: 36px;
          border-radius: 50%;

          @media (max-width: 799px) {
            font-size: 14px;
            width: 24px;
            height: 24px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
            width: 22px;
            height: 22px;
          }

          svg {
            width: 36px;
            height: 36px;
            font-size: 18px;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }
          }
        }

        .Mui-selected {
          background-color: #d4d6d8;
          width: 45px;
          height: 45px;

          @media (max-width: 799px) {
            font-size: 14px;
            width: 24px;
            height: 24px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }

  .news-box {
    .news-tag-relative {
      position: relative;

      @media (max-width: 439px) {
        max-height: none;
      }
    }

    .news-tag {
      padding: 3px 6px;
      background-color: #fc4714;
      color: #ffffff;
      font-size: 11.42px;
      line-height: 14px;
      font-weight: 400;
      font-family: "Inter", sans-serif;
      max-width: -moz-fit-content;
      max-width: fit-content;
      position: absolute;
      left: 25px;
      top: 13px;
      text-transform: capitalize;

      @media (max-width: 1280px) {
        left: 12px;
      }

      @media (max-width: 1024px) {
        bottom: 15px;
        top: unset;
      }

      @media (max-width: 560px) {
        bottom: 15px;
        left: 6px;
      }

      @media (max-width: 440px) {
        left: 10px;
      }
    }

    .sub-news-tag {
      @media (max-width: 1024px) {
        bottom: 25px;
        top: unset;
      }
    }
  }
}

.featurenews-wrap {
  padding: 18px 0px 0px;

  @media (max-width: 639px) {
    padding: 18px 0px 0px;
  }
}
