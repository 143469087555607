@import "../../../../assets/scss/variable.scss";

.sports-current-best-odds-wrap {
  // @media (max-width: 1179px) {
  //   padding: 0px 15px;
  // }
  flex-direction: column;
  .odds-point {
    text-align: center;
    display: block;
    font-size: 14px;
    line-height: 17px;
  }
  .current-best-odds-value {
    display: block;
    background-color: $color-Lavender;
    padding: 6px 0px;
    width: 70px;
    margin: 0px auto;
    border-radius: 6px 6px 0px 0px;
    text-align: center;
  }

  .current-best-odds-icon {
    border-radius: 0px 0px 6px 6px;
    width: 70px;
    margin: 0px auto;

    .currentbest-bookmaker-thumb {
      margin: 0px auto;
      border-radius: 0px 0px 6px 6px;
      cursor: pointer;
      height: 25px;
      // object-fit: cover;
      width: 100%;
      max-width: none;
    }
  }

  .active-odd-style {
    background-color: $color-Primary;

    span,
    .odds {
      color: $color-White;
    }
  }
  .odds-container {
    position: relative;
    .odds-lock-wrap {
      position: absolute;
      bottom: -4px;
      right: 0px;
    }
    .lock-betslip-added {
      svg {
        path {
          stroke: $color-White;
        }
      }
    }
  }
}
