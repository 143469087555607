@import "../../../../assets/scss/variable.scss";


// combat sport css 

.combat-sport-layout {

    .layout-header {
        padding: 45px 18px 3px;

        @media only screen and (max-width: 599px) {
            padding: 22px 9px 6px;
        }

        .bredcrumn-wrap {
            margin-bottom: 3px;

            @media only screen and (max-width: 599px) {
                margin-bottom: 5px;
            }

            li.MuiBreadcrumbs-separator {
                color: $color-Black;
            }

            li {
                font-size: 11.42px;
                line-height: 14px;

                p {
                    font-size: 11.42px;
                    line-height: 14px;
                    color: $color-Accent-1;
                    text-transform: uppercase;
                }

                a {
                    color: $color-Black;
                    font-size: 11.42px;
                    line-height: 14px;
                    text-transform: uppercase;
                }
            }
        }

        h1 {
            line-height: 56px;
            color: $color-Black;

            @media only screen and (max-width: 599px) {
                font-size: 31.36px;
                line-height: 40px;
            }
        }
    }

    .combat-sports-tab {
        padding: 19px 18px 13px;
        margin-bottom: 11px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000000d;

        @media (max-width: 599px) {
            padding: 9px 12px 12px;
            margin-bottom: 18px;
        }

        .combat-tab-detail {
            border-bottom: 2px solid #4455c7;

            @media (max-width: 799px) {
                margin-top: 6px;
            }

            .active {
                .MuiTab-wrapper {
                    color: $color-Accent-1;
                }
            }

            .MuiTab-textColorInherit {
                opacity: 1;
            }

            .MuiTab-wrapper {
                font-size: 16px;
                font-family: VeneerClean-Soft !important;
                color: #191919;
                line-height: 21px;
            }

            .MuiTab-root {
                min-width: 120px;
            }

            .MuiTabs-indicator {
                height: 3px;
                color: #003764;
            }
        }

        .select-wrap {
            max-width: none;
            margin: 13px 0px 0px;
            display: flex;

            @media only screen and (max-width: 1023px) {
                display: grid;
                grid-template-columns: auto auto;
                column-gap: 12px;
                row-gap: 12px;
            }

            @media only screen and (max-width: 479px) {
                grid-template-columns: auto auto auto;
            }

            .series-select {
                min-width: 257px;
                margin-right: 4px;

                @media only screen and (max-width: 1023px) {
                    margin-right: 0px;
                }

                @media only screen and (max-width: 479px) {
                    grid-column-start: 1;
                    grid-column-end: 4;
                }
            }

            // .year-select {
            //   min-width: 140px;
            //   margin-right: 4px;
            //   @media only screen and (max-width: 1023px) {
            //     margin-right: 0px;
            //   }
            //   @media only screen and (max-width: 479px) {
            //     grid-column-start: 1;
            //     grid-column-end: 3;
            //   }
            // }
            .details-search-picker {
                margin: 0;

                @media only screen and (max-width: 479px) {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }

                .MuiOutlinedInput-root {
                    background: #e7e9ec;
                    border-radius: 8px;
                    padding: 0;
                    max-width: 180px;
                    margin-right: 4px;

                    @media only screen and (max-width: 1023px) {
                        max-width: initial;
                        width: 100%;
                        margin-right: 0px;
                    }

                    input {
                        padding: 9px 8px;
                        font-family: $regulerFont !important;
                        font-size: 16px;
                        line-height: 22.4px;
                        font-weight: 600;
                        color: $color-Black;
                    }

                    fieldset {
                        border: none;
                    }
                }
            }

            #date-picker-inline::placeholder {
                color: #191919;
                opacity: 1;
            }

            .team-select {
                min-width: 140px;
                margin-right: 4px;

                @media only screen and (max-width: 1023px) {
                    margin-right: 0px;
                }

                @media only screen and (max-width: 479px) {
                    grid-column-start: 3;
                    grid-column-end: 4;
                }
            }

            .stepper-input {
                display: flex;
                border: 1px solid #c9c9c9;
                border-radius: 8px;
                max-height: 36px;

                .stepper-input__button {
                    padding: 10px 14px;
                    min-width: 35px;
                    cursor: pointer;
                    border-radius: 0px;

                    border-left: 1px solid #c9c9c9;
                    border-collapse: collapse;

                    &:first-child {
                        border-left: 0px;
                        border-right: 1px solid #c9c9c9;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }
                }

                .stepper-input__content {
                    font-family: $regulerFont;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                    padding: 8px 26px;

                    color: $color-Black;
                    width: 100%;
                    text-align: center;
                }

                @media only screen and (max-width: 479px) {
                    grid-column-start: 1;
                    grid-column-end: 4;
                }
            }

            .select__control {
                background-color: $color-Light-grey;
                border-radius: 8px;
                max-height: 36px;

                .select__single-value {
                    font-family: $regulerFont !important;
                    font-size: 16px;
                    line-height: 22.4px;
                    font-weight: 600;
                    color: $color-Black;
                    padding: 0px 8px;
                }

                .select__placeholder {
                    font-family: $regulerFont !important;
                    font-size: 16px;
                    line-height: 22.4px;
                    font-weight: 600;
                    color: $color-Black;
                    padding: 0px 8px;
                }

                .select__input-container {
                    font-family: $regulerFont !important;
                    font-size: 16px;
                    line-height: 22.4px;
                    font-weight: 600;
                    color: $color-Black;
                    padding: 0px 8px;
                }

                .select__indicator-separator {
                    width: 0px;
                }

                .select__dropdown-indicator {
                    padding: 10px;
                }
            }
        }

        .disclaimer {
            margin-top: 5px;
            font-size: 8pt;
            font-style: italic;
            font-family: "Inter", sans-serif;
        }
    }

    .combat-sport-table-wrap {
        padding: 30px 33px 0px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000000d;

        @media only screen and (max-width: 599px) {
            padding: 19px 12px 2px;
        }

        .table-header-wrap {
            border-bottom: 1px solid $color-Accent-1;
            display: flex;
            justify-content: space-between;

            h1 {
                font-size: 22.4px;
                line-height: 31.36px;
                font-weight: 400;
                font-family: $primaryFont;
                color: $color-Black;

                @media only screen and (max-width: 599px) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            .currentbest-seeall {
                display: flex;

                .teamsports-seeall {
                    margin-left: 13px;

                    cursor: pointer;

                    h1 {
                        color: $color-Accent-1;
                        text-decoration: underline;
                    }
                }
            }
        }

        .team-sport-table {
            .team-sport-table-indicator {
                display: flex;
                align-items: center;

                .team-time-market {
                    width: 188px !important;
                }
            }

            .MuiTableCell-root {
                border: none;
            }

            .MuiTableCell-head {
                font-family: $regulerFont;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                color: $color-Black;
                text-align: center;
                padding: 14px 0px 5px;
            }

            .MuiTableCell-body {
                text-align: center;
                font-family: $regulerFont;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                color: $color-Black;
                padding: 11.5px 0px 44px;
            }

            .MuiTableRow-head :first-child {
                text-align: left;
                width: 350px;
            }

            .teamsports-tablerow {
                position: relative;
            }

            .team-wrap {
                display: flex;
                align-items: center;
                margin-top: 14.5px;
                width: 300px;

                .team-img-wrap {
                    width: 45px;
                    height: 45px;

                    .team-img {
                        border-radius: 50%;
                        width: 45px;
                        height: 45px;
                    }
                }

                .team-name {
                    margin-left: 15px;
                    font-family: $primaryFont;
                    font-size: 16px;
                    line-height: 23px;
                    font-weight: 400;
                    color: $color-Black;

                    @media only screen and (max-width: 599px) {
                        text-align: center;
                    }
                }
            }

            .hometeam-row {
                margin: 5.5px 0px 0px;
            }

            .awayteam-row {
                .team-wrap {
                    margin-top: 20px;
                }

                .MuiTableCell-body {
                    padding: 11.5px 0px 34px;
                }
            }

            .teamsports-tablerow:after {
                content: "";
                background-color: $color-grey;
                height: 1px;
                width: 80%;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
            }
        }

        .odds-label {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
            text-align: center;

            @media only screen and (max-width: 599px) {
                font-size: 11.42px;
                line-height: 14px;
                max-width: 125px;
            }
        }

        .odds-wrap {
            display: flex;
            justify-content: center;
            margin-top: 5px;

            .odds {
                display: block;
                padding: 8px 0px;
                background: #d6d9f3;
                border-radius: 6px;
                font-size: 16px;
                line-height: 20px;
                color: $color-Black;
                min-width: 80px;
                text-align: center;
                filter: drop-shadow(0px 2px 2px #00000029);

                @media only screen and (max-width: 599px) {
                    font-size: 11.42px;
                    line-height: 14px;
                    min-width: 45px;
                }

                .no-odds {
                    cursor: pointer;
                }
            }

            .odds-left {
                margin-right: 15px;

                @media only screen and (max-width: 599px) {
                    margin-right: 7px;
                }
            }

            .bookmaker-long {
                text-align: center;
                min-width: 80px;
                border-radius: 6px;
                max-width: 80px;
                min-height: 36px;
                max-height: 36px;
                height: 100%;

                @media only screen and (max-width: 599px) {
                    max-width: 45px;
                    min-width: 45px;
                    max-height: 30px;
                    min-height: 30px;
                }
            }
        }

    }

    .live-section {
        .combat-sport-table-live {
            margin-bottom: 27px;

            .MuiTableCell-root {
                border: none;
            }

            .MuiTableCell-head {
                font-family: $regulerFont;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                color: $color-Black;
                text-align: center;
                padding: 14px 0px 5px;
            }

            .MuiTableCell-body {
                text-align: center;
                font-family: $regulerFont;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                color: $color-Black;
                padding: 15px 0px 12px;
            }

            .MuiTableRow-head :first-child {
                text-align: left;
                width: 60%;
            }

            .text-left {
                text-align: left;
            }

            .combat-sport-indicator-live {
                display: flex;
                align-items: center;

                .team-time-market {
                    width: 188px !important;
                }
            }

            .live {
                padding: 3px 11px;
                background-color: #d84727;
                color: #ffffff;
                font-size: 11.42px;
                line-height: 21px;
                font-weight: 400;
                font-family: $regulerFont;
                max-width: fit-content;
                border-radius: 3px;
                margin-right: 13px;
                text-transform: uppercase;
            }

        }

        .win-odds-live {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .odds-left {
                min-width: 220px;
            }
        }

        .sponsored-wrap {
            border: 0.6px solid #D4D6D8;
            box-shadow: 0px 3px 9px 0px #0000000d;
            margin-top: 27px;

            .sponsored-head-live {
                background-color: #E7E9EC;

                .head-title {
                    background: linear-gradient(180deg, rgba(252, 71, 20, 1) 0%, rgba(243, 70, 23, 1) 15%, rgba(142, 64, 55, 1) 40%, rgba(100, 61, 68, 1) 60%, rgba(53, 58, 83, 1) 70%, rgba(106, 62, 66, 1) 85%, rgba(0, 55, 100, 1) 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 48px;
                }
            }

            .sponsored-odds-wrap {
                padding: 18px 0px 33px;

                .sponsored-odds-live {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;

                    .sponsored-odd {
                        padding: 18px 0px 15px;

                        .odds-left {
                            min-width: 220px;
                        }

                    }
                }

                .border-bottom-odds {
                    border-bottom: 0.6px solid #D4D6D8;
                }
            }
        }
    }

    .mobileview-teamsport-table-wrap {
        padding: 18px 0px 2px;

        .time-market-team-wrap {
            .mobile-temsport-indicator {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .teams-details {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 16px;
                line-height: 20px;
                font-family: $primaryFont;
                color: $color-Black;
                padding: 19px 40px 12px;

                .team-wrap {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    align-self: flex-start;
                    width: 45%;

                    .team-img-wrap {
                        .team-img {
                            width: 45px;
                            height: 45px;
                            border-radius: 50%;
                        }
                    }

                    span {
                        font-size: 16px;
                        line-height: 20px;
                        font-family: $primaryFont;
                        color: #003763;
                    }

                    .team-name {
                        // padding-top: 16px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .odds-detail {
        padding: 5px 40px 23px;
        border-bottom: 1px solid $color-grey;

        .odds-body {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .odds-detail:last-child {
        border-bottom: none;
    }

    .des-label {
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-Black;
        margin-left: 9px;

        @media only screen and (max-width: 599px) {
            font-size: 11px;
            line-height: 14px;
        }
    }

    .des-label-head {
        background-color: #E7E9EC;
        min-height: 32px;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .odds-label {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;
        text-align: center;

        @media only screen and (max-width: 599px) {
            font-size: 11.42px;
            line-height: 14px;
            max-width: 125px;
        }
    }

    .odds-wrap {
        display: flex;
        justify-content: center;
        margin-top: 5px;

        .odds {
            display: block;
            padding: 8px 0px;
            background: #d6d9f3;
            border-radius: 6px;
            font-size: 16px;
            line-height: 20px;
            color: $color-Black;
            min-width: 80px;
            text-align: center;
            filter: drop-shadow(0px 2px 2px #00000029);

            @media only screen and (max-width: 599px) {
                font-size: 11.42px;
                line-height: 14px;
                min-width: 45px;
            }

            .no-odds {
                cursor: pointer;
            }
        }

        .odds-left {
            margin-right: 15px;

            @media only screen and (max-width: 599px) {
                margin-right: 7px;
            }
        }

        .bookmaker-long {
            text-align: center;
            min-width: 80px;
            border-radius: 6px;
            max-width: 80px;
            min-height: 36px;
            max-height: 36px;
            height: 100%;

            @media only screen and (max-width: 599px) {
                max-width: 45px;
                min-width: 45px;
                max-height: 30px;
                min-height: 30px;
            }
        }
    }


    .mobile-live-section {

        .mobile-temsport-indicator {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 15px;

            .live {
                padding: 3px 11px;
                background-color: #d84727;
                color: #ffffff;
                font-size: 11.42px;
                line-height: 21px;
                font-weight: 600;
                font-family: $regulerFont;
                max-width: fit-content;
                border-radius: 3px;
                margin-right: 13px;
                text-transform: uppercase;
            }
        }

        .mobile-live-table {
            .MuiTableCell-root {
                border: none;
            }

            .MuiTableCell-head {
                font-family: $regulerFont;
                font-size: 11px;
                font-weight: 600;
                line-height: 20px;
                color: $color-Black;
                text-align: center;
                padding: 24px 0px 5px;
            }

            .MuiTableCell-body {
                text-align: center;
                font-family: $regulerFont;
                font-size: 11.42px;
                font-weight: 400;
                line-height: 20px;
                color: $color-Black;
                padding: 11.5px 0px 15px;
            }

            .table-head {
                font-size: 16px;
                text-align: left;
                color: #003764;
                font-family: $primaryFont;

            }
        }

        .win-odds-live {
            margin: 10px 0px 18px;

            .odds-label {
                max-width: 100%;
                margin-top: 15px;
            }

            @media (max-width:599px) {
                .odds-wrap {
                    justify-content: space-between;
                }
            }


            .odds-left {
                min-width: 275px;
            }
        }
    }

    .teamsports-seeall-tablewrap {
        padding: 0px 33px;
        background-color: $color-White;
        position: relative;

        .table-seeall-container {
            .overlay::after {
                content: "";
                position: absolute;
                width: 90px;
                height: calc(100%);
                z-index: 9;
                right: 0;
                background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
            }

            .see-best {
                position: absolute;
                color: $color-Accent-1;
                z-index: 99;
                right: 8px;
                top: -65px;
                text-decoration: underline;
                font-family: $regulerFont;
                font-size: 11.42px;
                line-height: 14px;
                font-weight: 400;
                cursor: pointer;

                @media only screen and (max-width: 1023px) {
                    top: -10px;
                }
            }

            .individual-table {
                .MuiTableCell-root {
                    border-bottom: none;
                }

                .individual-team-head {
                    .oddstype {
                        .select__single-value {
                            color: $color-Black;
                        }

                        // .select__menu{
                        //   z-index: 99;
                        // }
                    }

                    .MuiTableCell-head {
                        padding: 20px 0px 6px;
                        line-height: 0px;
                        border-bottom: 1px solid $color-Accent-1;
                        text-align: center;
                        color: $color-Black;
                    }

                    .MuiTableCell-head:first-child {
                        text-align: left;
                    }
                }

                .individual-team-body {
                    .MuiTableCell-root {
                        padding: 39.5px 0px;
                    }

                    .time-market {
                        font-family: $regulerFont;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 600;
                        padding: 14px 0px 2px;
                        color: $color-Black;

                        @media only screen and (max-width: 599px) {
                            font-size: 11px;
                            line-height: 14px;
                        }
                    }

                    .team-wrap {
                        display: flex;
                        align-items: center;

                        .team-img-wrap {
                            width: 45px;
                            height: 45px;

                            .team-img {
                                border-radius: 50%;
                                width: 45px;
                                height: 45px;
                            }
                        }

                        .team-name {
                            margin-left: 15px;
                            font-family: $primaryFont;
                            font-size: 16px;
                            line-height: 23px;
                            font-weight: 400;
                            color: $color-Black;
                        }
                    }

                    .odds {
                        padding: 8px 0px;
                        background: #d6d9f3;
                        border-radius: 6px;
                        margin: 0px 3.5px;
                        font-size: 16px;
                        line-height: 20px;
                        color: $color-Black;
                        // min-width: 80px;
                        width: 80px;
                        margin: 0 auto;
                        display: block;
                        text-align: center;
                        filter: drop-shadow(0px 2px 2px #00000029);

                        @media (max-width: 1090px) {
                            width: 55px;
                            max-height: 30px;
                            font-size: 11.42px;
                            line-height: 14px;
                            padding: 8px 0px;
                        }

                        @media only screen and (max-width: 599px) {
                            // padding: 8px 0px;
                            // min-width: 45px;
                            width: 45px;
                            // max-height: 30px;
                            // font-size: 11.42px;
                            // line-height: 14px;
                        }

                        .no-odds {
                            cursor: pointer;
                        }
                    }

                    .individual-team-row {
                        position: relative;
                    }

                    .individual-team-row:not(:last-child)::after {
                        content: "";
                        background-color: $color-grey;
                        height: 1px;
                        width: 80%;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: 0 auto;
                        z-index: 91;
                    }
                }
            }
        }
    }
}

.cursor {
    cursor: pointer;
}

// Combat Sports Markets page css

.sport-market-wrap {
    .market-heading-wrap {
        padding: 45px 18px 3px;

        @media (max-width:799px) {
            padding: 22px 12px 3px;
        }

        .bredcrumn-wrap {
            margin-bottom: 3px;

            @media only screen and (max-width: 599px) {
                margin-bottom: 5px;
                overflow-x: scroll;

                ol {
                    flex-wrap: nowrap;
                }
            }

            li.MuiBreadcrumbs-separator {
                color: $color-Black;
            }

            li {
                font-size: 11.42px;
                line-height: 14px;

                p {
                    font-size: 11.42px;
                    line-height: 14px;
                    color: $color-Accent-1;
                    text-transform: uppercase;

                    @media (max-width:599px) {
                        width: max-content;
                    }
                }

                a {
                    color: $color-Black;
                    font-size: 11.42px;
                    line-height: 14px;
                    text-transform: uppercase;
                }
            }
        }

        .bredcrumn-wrap::-webkit-scrollbar {
            height: 0px;
        }

        .bredcrumn-wrap::-webkit-scrollbar-thumb {
            display: none;
        }


        .market-heading {
            display: flex;
            align-items: center;


            h1 {
                line-height: 56px;
                color: $color-Black;

                @media only screen and (max-width: 599px) {
                    font-size: 31.36px;
                    line-height: 40px;
                }
            }

            .live {
                padding: 3px 11px;
                background-color: #d84727;
                color: #ffffff;
                font-size: 12px;
                line-height: 15px;
                font-weight: 600;
                font-family: $regulerFont;
                max-width: fit-content;
                border-radius: 3px;
                text-transform: uppercase;
                margin-left: 30px;
            }
        }
    }

    .match-point-table {
        .MuiTableCell-root {
            border: none;
        }

        .MuiTableRow-head {
            border-bottom: 1px solid #FBFBFB;
        }

        .MuiTableCell-head {
            font-family: $regulerFont;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            background-color: #003764;
            color: #E2662C;
            text-align: center;
            padding: 10px 0px 5px;
        }

        .MuiTableCell-body {
            text-align: center;
            font-family: $regulerFont;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            background-color: #003764;
            color: $color-White;
            padding: 7px 0px 19px;
        }

        .player-name {
            text-align: left;
            font-family: $primaryFont;
            padding-left: 33px;
        }
    }

    .market-section {
        padding-top: 15px;
        display: flex;

        @media (max-width:1023px) {
            flex-direction: column;
        }

        .left-side-market {
            width: calc(100% - 388px);

            @media (max-width:1023px) {
                width: 100%;
            }

            .left-section-details {
                .MuiAccordion-root {
                    margin-bottom: 9px;
                    box-shadow: 0px 3px 9px 0px #0000000d;

                    &::before {
                        display: none;
                    }

                    .MuiAccordionSummary-root {
                        padding: 0px 33px;
                        background: #4455C7;
                        min-height: 45px;

                        @media (max-width:799px) {
                            padding: 0px 12px;
                        }

                        .MuiSvgIcon-root {
                            fill: $color-White;
                        }
                    }

                    .MuiAccordionSummary-content {
                        margin: 0px;
                        column-gap: 11px;

                        p {
                            font-size: 22.4px;
                            font-family: $primaryFont;
                            color: #fff;
                            line-height: 31.36px;
                        }
                    }

                    .MuiIconButton-root {
                        padding-top: 0px;
                        padding-bottom: 0px;
                    }
                }

                .MuiAccordionDetails-root {
                    padding: 9px 9px 0px;
                    width: auto;
                    display: block;

                    .sub-accordion {
                        .MuiAccordion-root {
                            margin-bottom: 9px;
                            box-shadow: 0px 3px 9px 0px #0000000d;

                            &::before {
                                display: none;
                            }

                            .MuiAccordionSummary-root {
                                padding: 0px 24px;
                                background: #E7E9EC;
                                min-height: 45px;

                                @media (max-width:799px) {
                                    padding: 0px 9px;
                                }

                                .MuiSvgIcon-root {
                                    fill: #000000;
                                }
                            }

                            .MuiAccordionSummary-content {
                                margin: 0px;
                                column-gap: 11px;

                                p {
                                    font-size: 16px;
                                    font-family: $regulerFont !important;
                                    color: #000000;
                                    line-height: 19px;
                                    font-weight: 600;
                                }
                            }

                            .MuiIconButton-root {
                                padding-top: 0px;
                                padding-bottom: 0px;
                            }
                        }

                        .MuiAccordionDetails-root {
                            padding: 9px 24px 9px;
                            width: auto;
                            display: block;
                            background: #E7E9EC;

                            @media (max-width:1160px) {
                                padding: 9px;
                            }
                        }

                        .match-betting {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            @media (max-width:1130px)and (min-width:1024px) {
                                flex-direction: column;
                                row-gap: 10px;
                            }

                            @media (max-width:1023px) {
                                justify-content: space-around;
                            }

                            @media (max-width:479px) {
                                flex-direction: column;
                                row-gap: 10px;
                            }
                        }

                        .set-betting {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            @media (max-width:1130px)and (min-width:1024px) {
                                flex-direction: column;
                                row-gap: 10px;
                            }

                            @media (max-width:1023px) {
                                justify-content: space-around;
                            }

                            @media (max-width:479px) {
                                flex-direction: column;
                                row-gap: 10px;
                            }

                            &:not(:last-child) {
                                margin-bottom: 9px;
                                border-bottom: 1px solid #D4D6D8;
                                padding-bottom: 9px;
                            }
                        }

                        .odds-label {
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 400;
                            font-family: $regulerFont;
                            color: $color-Black;
                            text-align: center;

                            @media only screen and (max-width: 599px) {
                                font-size: 11.42px;
                                line-height: 14px;
                                max-width: 125px;
                            }
                        }

                        .odds-wrap {
                            display: flex;
                            justify-content: center;
                            margin-top: 5px;

                            .odds {
                                display: block;
                                padding: 8px 0px;
                                background: #d6d9f3;
                                border-radius: 6px;
                                font-size: 16px;
                                line-height: 20px;
                                color: $color-Black;
                                min-width: 80px;
                                text-align: center;
                                filter: drop-shadow(0px 2px 2px #00000029);

                                @media only screen and (max-width: 599px) {
                                    font-size: 11.42px;
                                    line-height: 14px;
                                    min-width: 45px;
                                }

                                .no-odds {
                                    cursor: pointer;
                                }
                            }

                            .odds-left {
                                margin-right: 15px;
                                width: 220px;

                                @media (max-width:1440px) {
                                    width: 180px;
                                }

                                @media (max-width:1279px) {
                                    width: 140px;
                                }

                                @media only screen and (max-width: 599px) {
                                    margin-right: 7px;
                                }

                                @media (max-width:479px) {
                                    width: 256px;
                                }

                                @media (max-width:379px) {
                                    width: 225px;
                                }
                            }

                            .bookmaker-long {
                                text-align: center;
                                min-width: 80px;
                                border-radius: 6px;
                                max-width: 80px;
                                min-height: 36px;
                                max-height: 36px;
                                height: 100%;

                                @media only screen and (max-width: 599px) {
                                    max-width: 45px;
                                    min-width: 45px;
                                    max-height: 30px;
                                    min-height: 30px;
                                }

                                @media (max-width:479px) {
                                    max-width: 80px;
                                    min-width: 80px;
                                    max-height: 30px;
                                    min-height: 30px;
                                }

                                @media only screen and (max-width: 379px) {
                                    max-width: 45px;
                                    min-width: 45px;
                                    max-height: 30px;
                                    min-height: 30px;
                                }
                            }
                        }
                    }
                }


            }
        }

        .right-side-market {
            width: 388px;

            @media (max-width:1023px) {
                width: 100%;
            }

            .right-side-section-wrap {
                padding: 8px 18px 9px;

                @media (max-width:799px) {
                    padding: 8px 12px 9px;
                }

                .right-section-heading {
                    font-size: 22.4px;
                    line-height: 31.36px;
                    font-weight: 600;
                    font-family: $regulerFont;
                    color: $color-Black;
                }

                .right-section-sub-heading {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 600;
                    font-family: $regulerFont;
                    color: $color-Black;
                }

                .will-win-section {
                    padding: 10px 9px 9px;
                    background-color: #FFFFFF;
                    box-shadow: 0px 3px 9px 0px #0000000d;

                    .vote-section {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .vote {
                            display: flex;
                            align-items: center;

                            span {
                                font-size: 11.42px;
                                line-height: 14px;
                                font-weight: 400;
                                font-family: $regulerFont;
                                color: $color-Black;
                            }
                        }

                        .vote-section-left {
                            span {
                                margin-left: 6px;
                            }
                        }

                        .vote-section-right {
                            span {
                                margin-right: 6px;
                            }
                        }

                    }

                    .Percentage-section {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;

                        .vote-Percentage {
                            height: 15px;
                            font-size: 11.42px;
                            line-height: 14px;
                            font-family: $regulerFont;
                            color: $color-White;
                            font-weight: 400;
                        }

                        .Percentage-left {
                            padding-left: 6px;
                            border-radius: 2px 0px 0px 2px;
                        }

                        .Percentage-right {
                            text-align: end;
                            padding-right: 6px;
                            border-radius: 0px 2px 2px 0px;
                        }
                    }

                }

                .tips-match-insights {
                    padding: 10px 9px 9px;
                    background-color: #FFFFFF;
                    box-shadow: 0px 3px 9px 0px #0000000d;
                    margin-top: 9px;

                    h6 {
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 600;
                        font-family: $regulerFont;
                        color: #000000;
                    }

                    .tips-match-section {
                        margin-top: 12px;

                        .img-box {
                            margin-right: 9px;
                            // width: 23px;

                            img {
                                width: 23px;
                                margin-top: 6px;
                            }
                        }

                        .mactch-tips {
                            @media (max-width:1023px) {
                                width: 100%;
                            }

                            .match {
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 6px;

                                p {
                                    font-size: 16px;
                                    line-height: 19px;
                                    font-weight: 600;
                                    font-family: $regulerFont;
                                    color: #191919;
                                }
                            }

                            .content {
                                font-size: 11.42px;
                                line-height: 14px;
                                font-weight: 400;
                                font-family: $regulerFont;
                                color: #191919;
                            }
                        }
                    }

                }

                .results-section {
                    background-color: #FFFFFF;
                    box-shadow: 0px 3px 9px 0px #0000000d;
                    margin-top: 9px;

                    .results-section-1 {
                        padding: 10px 9px 9px;

                        h6 {
                            font-size: 16px;
                            line-height: 19px;
                            font-weight: 600;
                            font-family: $regulerFont;
                            color: #000000;
                        }

                        .results-radioGroup {
                            margin-top: 13px;

                            .MuiFormControlLabel-root {
                                span {
                                    font-size: 16px;
                                    line-height: 19px;
                                    font-weight: 600;
                                    font-family: $regulerFont;
                                    color: #000000;
                                }
                            }
                        }
                    }

                    .results {
                        margin-top: 10px;

                        h6 {
                            font-size: 16px;
                            line-height: 19px;
                            font-weight: 600;
                            font-family: $regulerFont;
                            color: #000000;
                            padding: 0px 9px;
                        }

                        .results-table {
                            tr {
                                &:not(:last-child) {
                                    border-bottom: 1px solid #E7E9EC;
                                }
                            }

                            td {
                                padding: 9px;
                                font-size: 11.42px;
                                line-height: 14px;
                                font-family: $regulerFont;
                                font-weight: 600;
                                color: #1A73E8;
                                border-bottom: none;
                            }

                            .collapse-tr {
                                padding: 0px;
                                border-bottom: none !important;

                                td {
                                    padding: 0px;
                                    color: #FFFFFF;
                                    border-bottom: none;
                                }

                                .result-box {
                                    background-color: #393B47;
                                    padding: 9px 9px 0px;

                                    .result-head {
                                        justify-content: space-between;
                                        align-items: center;
                                        margin-bottom: 3px;

                                        p {
                                            font-size: 11.42px;
                                            line-height: 14px;
                                            font-family: $regulerFont;
                                            font-weight: 500;
                                        }
                                    }

                                    .score-table {
                                        tr {
                                            border: none;
                                        }

                                        .MuiTableRow-root :first-child {
                                            text-align: left;
                                        }

                                        .MuiTableCell-head {
                                            padding: 6px 0px;
                                            color: #FC4714;
                                            font-size: 11.42px;
                                            line-height: 14px;
                                            font-weight: 500;
                                            font-family: $regulerFont;
                                            border: none;
                                            text-align: center;
                                        }

                                        .MuiTableCell-body {
                                            padding: 6px 0px;
                                            font-size: 11.42px;
                                            line-height: 14px;
                                            font-weight: 500;
                                            font-family: $regulerFont;
                                            border: none;
                                            text-align: center;
                                        }
                                    }

                                    .market-result {

                                        .single-market-result {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            padding: 9px 0px;

                                            &:not(:last-child) {
                                                border-bottom: 1px solid #C9C9C9;
                                            }

                                            p {
                                                font-size: 11.42px;
                                                line-height: 14px;
                                                font-weight: 500;
                                                font-family: $regulerFont;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .indian-wells {
                            margin-top: 22px;
                        }

                        .no-data {
                            font-size: 16px;
                            line-height: 19px;
                            font-weight: 600;
                            color: $color-Black;
                            font-family: $regulerFont;
                            text-align: center;
                            margin-top: 13px;
                        }

                        .read-more {
                            margin-top: 13px;
                            padding-bottom: 9px;
                            text-align: center;

                            button {
                                color: #989898;
                                font-size: 16px;
                                line-height: 19px;
                                font-weight: 400;
                                text-transform: capitalize;

                                &:hover {
                                    background-color: transparent;
                                }

                                .MuiTouchRipple-root {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .mobile-right-section {
            .MuiAccordion-root {
                margin-bottom: 9px;
                box-shadow: 0px 3px 9px 0px #0000000d;

                &::before {
                    display: none;
                }

                .MuiAccordionSummary-root {
                    padding: 0px 33px;
                    background: #5D5D5D;
                    min-height: 45px;

                    @media (max-width:799px) {
                        padding: 0px 12px;
                    }

                    .MuiSvgIcon-root {
                        fill: $color-White;
                    }

                    .MuiAccordionSummary-content {
                        margin: 0px;
                        column-gap: 11px;

                        p {
                            font-size: 22.4px;
                            font-family: $primaryFont;
                            color: #fff;
                            line-height: 31.36px;
                        }
                    }
                }
            }

            .MuiAccordionDetails-root {
                padding: 0px;
            }
        }
    }
}

.d-flex {
    display: flex;
}