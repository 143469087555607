@import "src/assets/scss/variable.scss";

.auth-sec {
  display: flex;
  min-width: 894px;

  .auth-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99;
  }

  .textfield-sec {
    text-align: left;
    padding-top: 18px;

    .text-field {
      width: 100%;

      .MuiInputBase-input {
        padding: 10px 10px;
        max-width: 514px;
        width: 100%;
        min-height: 25px;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 20px;
      }

      .MuiInputBase-root {
        background-color: $color-White !important;
        border-radius: 8px;
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        display: none;
      }

      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: #4455c7;
          border-width: 1;
        }
      }

      .MuiOutlinedInput-adornedEnd {
        justify-content: space-between;
      }
    }

    .email-text-field {
      .MuiInputBase-input {
        max-width: none;
      }
    }

    .textfield-text {
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 21px;
      color: $color-Black;
      text-align: left;
      font-weight: 600;
    }

    svg {
      cursor: pointer;
    }
  }

  .MuiGrid-spacing-xs-3 {
    margin: 0px -10px;

    .MuiGrid-item {
      padding: 0px 9px !important;
    }
  }

  .left-sec {
    width: 35%;

    .image-sec {
      width: 100%;
      height: 100%;
    }
  }

  .right-sec {
    width: 65%;
    padding: 54px 36px;
    box-sizing: border-box;
    text-align: center;
    background: $pop-up-background;
    position: relative;

    .dialog-close {
      position: absolute;
      right: 0;
      top: 0;

      &:hover {
        background-color: transparent;
      }
    }

    .login-title {
      font-family: $regulerFont;
      font-size: 22.4px;
      line-height: 31.36px;
      font-weight: 600;
      color: $color-Black;
      padding: 26.9px 0px 18px 0px;

      @media (max-width: 599px) {
        padding: 0px !important;
        padding-bottom: 8px !important;
      }
    }

    .login-heading {
      font-size: 22.4px;
      font-weight: 700;
      padding: 26.9px 0px 0px 0px;
    }

    .otp-content {
      font-family: $regulerFont;
      font-size: 18px;
      line-height: 31.36px;
      font-weight: 500;
      color: $color-Black;
      padding: 26.9px 0px 18px 0px;

      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .forgot-password-link {
      padding-top: 9px;
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 21px;
      color: $color-gray-dark;
      text-decoration: underline;
      text-align: left;
      cursor: pointer;
    }

    .prev-next-btn {
      .submit-btn:first-child {
        background-color: $color-White;
        color: $color-Accent-1;
      }
    }

    .sign-in-account-sec {
      display: flex;
      justify-content: center;

      .account-text {
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 21px;
        color: $color-Primary;
      }

      .redirect-link {
        padding-left: 16px;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 21px;
        color: $color-Accent-1;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .submit-btn {
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      border: 1px solid $color-Accent-1;
      border-radius: 8px;
      letter-spacing: 0px;
      background: $color-Accent-1;
      color: $color-White;
      width: 100%;
      margin-top: 27px;
      padding: 15px 0;
      text-transform: none;
    }

    .stepper-label-wrap {
      background: transparent;

      // svg {
      //   color: $color-Accent-1;
      // }
      .MuiStepIcon-completed {
        color: $color-Accent-1;
      }

      .MuiStepIcon-active {
        color: $color-Accent-1;
      }

      MuiStepConnector-active {
        .MuiStepConnector-line {
          border-color: $color-Accent-1;
        }
      }
    }

    .stepper-main-wrap {
      padding: 0px;

      .signup-header {
        font-size: 22.4px;
        line-height: 31.36px;
      }

      .textfield-text {
        text-align: left;
        line-height: 22.4px;
        color: #191919;
      }

      .signup-que {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #191919;
        margin-top: 18px;
        margin-bottom: 9px;
        text-align: left;
      }

      .step1-container {
        .MuiInputBase-input {
          max-width: none;
          // width: 100%;
        }

        .MuiInput-underline:before {
          border-bottom: none;
        }

        .MuiOutlinedInput-root {
          border-radius: 8px;
          max-width: 270px;
          width: 100%;
          min-height: 45px;
          background-color: #ffff !important;
        }

        .select__control {
          box-shadow: none;
          // border: 1px solid red;
          padding: 0px;
          margin: 0px;
        }

        .select__control,
        .react-select__control {
          // &.select__control--is-disabled {
          //   // border-color: $border-color;
          //   // .select__indicator-separator {
          //   //   //   background-color: $border-color;
          //   // }
          // }

          &.select__control--is-focused,
          &.react-select__control--is-focused {
            border-color: $color-Accent-1;
          }

          &.select__control--menu-is-open {
            box-shadow: none;
          }

          .select__indicator svg {
            cursor: pointer;
          }

          .select__indicator-separator {
            display: none;
          }

          .select__single-value {
            border: none;
            font-size: 16px;
            line-height: 20px;
            padding-left: 10px;
            font-family: $regulerFont;
            outline: none;
            cursor: pointer;
            margin: 0px;
          }

          .select__value-container {
            border: none;
            padding: 0px;
            margin: 0px;

            .select__input-container {
              padding-left: 10px;
              font-size: 16px;
              line-height: 20px;
              font-family: $regulerFont;
            }
          }

          .select__placeholder {
            font-size: 16px;
            line-height: 20px;
            padding-left: 10px;
            cursor: pointer;
            font-family: $regulerFont;
          }
        }

        .select__menu {
          margin: 0px;
          border-radius: 0px;
          padding: 0px;
          z-index: 999;
        }

        .select__menu-list {
          padding: 0px;
        }

        // Select Menu
        .select__menu,
        .react-select__menu {

          .select__menu-list,
          .react-select__menu-list {

            .select__option,
            .react-select__option {
              cursor: pointer;
              font-size: 16px;
              color: $color-Black;
              font-family: $regulerFont;
              line-height: 19px;
              padding: 11px;

              &.select__option--is-focused {
                background-color: #d4d6d8;
                color: #000;
              }

              &.select__option--is-selected {
                background-color: #d4d6d8;
                color: $color-Black;
              }
            }
          }
        }

        .select {
          .select__control {
            min-height: 45px;
            border-radius: 8px;
            max-width: 270px;
            width: 100%;
          }
        }

        .select-title {
          max-width: 134px;

          .select__control {
            max-width: 134px;
            width: 100%;
          }
        }

        .text-error {
          .select__control {
            border: 1px solid red;
          }
        }

        // .details-search-picker {
        // }

        .MuiInput-underline:after {
          border-bottom: none;
        }

        .MuiFormControl-marginDense {
          margin-top: 0px;
        }

        .MuiOutlinedInput-adornedEnd {
          padding-right: 0px;
        }

        .step1-btn {
          margin-bottom: 171px;
        }

        .prev-next-btn {
          .submit-btn:first-child {
            background-color: $color-White;
            color: $color-Accent-1;
          }
        }

        .details-search-picker {
          .MuiInputBase-input {
            font-size: 16px;
            line-height: 20px;
            font-family: $regulerFont;
          }

          // .MuiFormHelperText-root.Mui-error {
          //   display: none;
          // }
        }

        .hear-about-list {
          .checkbox-list-1 {
            display: grid;
            grid-template-columns: auto auto auto;
            row-gap: 5px;
            margin-top: 9px;

            .MuiTouchRipple-root {
              display: none;
            }
          }

          .other-filed {
            display: grid;
            grid-template-columns: 33.33% 29.33% 36.33%;
          }
        }
      }

      .step2-container {
        .prev-next-btn {
          display: flex;
          justify-content: space-between;

          .submit-btn {
            width: 49%;
          }

          .submit-btn:first-child {
            background-color: $color-White;
            color: $color-Accent-1;
          }
        }
      }

      .step3-container {
        .bookmakers-text {
          text-align: left;
          margin-top: 18px;

          .bookmakers-label {
            font-size: 16px;
            font-weight: 600;
            color: #191919;
          }

          .bookmakers-radioGroup {
            flex-direction: row;

            label:nth-child(n) {
              margin: 0px;
              margin-right: 18px;
            }

            .Mui-checked {
              color: #003764;
            }
          }
        }

        .bookmakers-list {
          text-align: left;

          .bookmakers-list-label {
            font-size: 16px;
            font-weight: 600;
            color: #191919;
          }

          .checkbox-list {
            display: flex;

            .checkbox-list-1 {
              display: grid;
              grid-template-columns: auto auto;
              column-gap: 38px;

              .bookmakers-check {
                .Mui-checked {
                  color: #003764;
                }

                .bookmakers-checkbox {
                  .MuiSvgIcon-root {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }

            .checkbox-list-1:not(:last-child) {
              margin-right: 144px;
            }
          }

          .other-filed {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 135px;

            .text-field {
              background-color: #ffffff;

              .MuiOutlinedInput-root {
                border-radius: 8px;
              }
            }
          }
        }

        .PrivateSwitchBase-root-16 {
          padding: 0px;
          padding: 9.5px;
        }

        .signup-btn-wrap {
          position: absolute;
          width: 70%;
          bottom: 50px;

          .prev-next-btn {
            display: flex;
            justify-content: space-between;

            .submit-btn {
              width: 49%;
            }

            .submit-btn:first-child {
              background-color: $color-White;
              color: $color-Accent-1;
            }
          }

          .finish-btn {
            font-weight: 600;
            color: $color-Accent-1;
            text-decoration: underline;
          }
        }
      }

      .step4-container {
        .checkbox-list {
          display: flex;

          .checkbox-list-1 {
            display: grid;
            grid-template-columns: auto auto auto;
            column-gap: 0px;

            .bookmakers-check {
              margin-left: 0px;
              margin-right: 0px;

              .Mui-checked {
                color: #003764;
              }

              .bookmakers-checkbox {
                .MuiSvgIcon-root {
                  width: 16px;
                  height: 16px;
                }
              }

              span {
                text-align: left;
              }
            }
          }
        }

        .other-filed {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 135px;
          text-align: left;

          .text-field {
            background-color: #ffffff;

            .MuiOutlinedInput-root {
              border-radius: 8px;
            }
          }
        }

        .signup-btn-wrap {
          position: absolute;
          width: 70%;
          bottom: 10px;

          .prev-next-btn {
            display: flex;
            justify-content: space-between;

            .submit-btn {
              width: 49%;
            }

            .submit-btn:first-child {
              background-color: $color-White;
              color: $color-Accent-1;
            }
          }

          .finish-btn {
            font-weight: 600;
            color: $color-Accent-1;
            text-decoration: underline;
          }
        }
      }

      .step5-container {
        height: 600px;
        overflow-y: auto;
        padding-left: 2px;

        .checkbox-list {
          display: flex;

          .checkbox-list-1 {
            display: grid;
            grid-template-columns: auto;
            column-gap: 0px;

            .bookmakers-check {
              margin-left: 0px;
              margin-right: 0px;

              .Mui-checked {
                color: #003764;
              }

              .bookmakers-checkbox {
                .MuiSvgIcon-root {
                  width: 16px;
                  height: 16px;
                }
              }

              span {
                text-align: left;
              }
            }
          }
        }

        .other-filed {
          text-align: left;

          .text-field {
            background-color: #ffffff;

            .MuiOutlinedInput-root {
              border-radius: 8px;
            }
          }
        }

        .signup-btn-wrap {
          position: absolute;
          width: 70%;
          bottom: 50px;

          .prev-next-btn {
            display: flex;
            justify-content: space-between;

            .submit-btn {
              width: 49%;
            }

            .submit-btn:first-child {
              background-color: $color-White;
              color: $color-Accent-1;
            }
          }

          .finish-btn {
            font-weight: 600;
            color: $color-Accent-1;
            text-decoration: underline;
          }
        }

        .notification-wrap {
          display: grid;
          grid-template-columns: 50% 50%;
          row-gap: 18px;
          justify-content: space-between;

          .parent-checkbox {
            text-align: left;
            width: 100%;

            .p-25 {
              padding-left: 25px;
            }

            .sport-icon-wrap {
              display: flex;
              align-items: center;

              .sports-name {
                color: $color-Primary;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;

                @media (max-width: 479px) {
                  font-size: 11.42px;
                  line-height: 14px;
                }
              }

              .sport-icon {
                display: flex;
                padding-left: 3px;

                .storke-svg {
                  path {
                    stroke: $color-Primary;
                  }
                }
              }
            }

            .child-checkbox {
              .MuiFormControlLabel-label {
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                color: $color-Black;

                @media (max-width: 479px) {
                  font-size: 11.42px;
                  line-height: 14px;
                }
              }
            }
          }
        }
      }

      .step5-signup-btn-wrap {
        @media (max-width: 1279px) {
          .prev-next-btn {
            display: flex;
            column-gap: 7px;
          }
        }
      }
    }
  }

  .finish-msg-wrap {
    padding-top: 180px;
    padding-bottom: 143px;

    .finish-done {
      font-size: 22.4px;
      line-height: 31.36px;
      font-weight: 600;
      padding-top: 18px;
      padding-bottom: 12px;
    }

    .finish-msg {
      font-size: 16px;
      line-height: 22.4px;
    }

    .submit-btn {
      margin-top: 143px;
      cursor: pointer;
    }
  }

  .otp-msg-wrap {
    padding-top: 24px;
    padding-bottom: 24px;

    .submit-btn {
      margin-top: 54px;
      cursor: pointer;
    }
  }

  @media (max-width: 799px) {
    .right-sec {
      width: 100%;
      padding: 10px 24px;
    }
  }
}

.signup-sec {
  max-height: 1080px;
  height: 100%;
  min-width: 1350px;
  max-width: 1350px;

  .left-sec {
    width: 40%;
  }

  .right-sec {
    width: 60%;
    padding: 135px 128px 70px;
  }
}

.error-msg {
  display: none;
}

.text-danger {
  display: block;
  color: #f44336;
  margin-left: 14px;
  margin-right: 14px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.ml-4 {
  margin-left: 4px !important;
  margin-top: 6px !important;
}

.text-danger-width {
  max-width: 514px;
}

.MuiPickersToolbar-toolbar {
  background-color: $color-Accent-1 !important;
}

// .signup-loader{
//   position: absolute;
//   left: 50%;
//   top: 50%
// }
@media (max-width: 1279px) {
  .signup-sec {
    display: block;
    min-width: auto;
    max-width: none;

    .left-sec {
      display: none;
    }

    .right-sec {
      padding: 54px 70px;
      margin: 0 auto;
      width: auto !important;

      .stepper-main-wrap {
        .step1-container {
          .MuiOutlinedInput-root {
            max-width: none !important;
          }

          .details-search-picker {
            width: 100%;
          }

          .MuiOutlinedInput-adornedEnd {
            justify-content: space-between;
          }

          .select__control {
            max-width: none !important;
          }

          .step1-btn {
            margin-bottom: 4px !important;
          }
        }

        .step3-container {
          .signup-btn-wrap {
            position: initial;
            width: 100%;

            .submit-btn {
              width: 100%;
            }
          }

          .bookmakers-list {
            .checkbox-list {
              display: block;
            }

            .other-filed {
              .text-field {
                margin-left: 70px;
              }
            }
          }
        }

        .step4-container {
          .signup-btn-wrap {
            position: initial;
            width: 100%;

            .submit-btn {
              width: 100%;
            }
          }

          .checkbox-list {
            display: block;
          }
        }

        .step5-container {
          .signup-btn-wrap {
            position: initial;
            width: 100%;

            .submit-btn {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .auth-sec {
    min-width: auto;
    justify-content: center;

    .left-sec {
      display: none;
    }
  }
}

@media (min-width: 599px) {
  .step4-container {
    .sport-field {
      display: none;
    }
  }
}

@media (max-width: 599px) {
  .signup-sec {
    max-height: none;

    .right-sec {
      padding: 39px 12px 54px !important;

      .stepper-main-wrap {
        .textfield-text {
          font-size: 15px;
          line-height: 16px;
          color: #191919;
        }

        .step4-container {
          .checkbox-list {
            .checkbox-list-1 {
              grid-template-columns: auto auto;
            }
          }

          .other-filed {
            column-gap: 175px;

            .text-field {
              margin-right: 20px;
            }
          }
        }

        .prev-next-btn {
          flex-direction: column;

          .submit-btn {
            width: 100% !important;
          }
        }
      }
    }
  }

  .auth-sec {
    .textfield-sec {
      padding-top: 15px;
    }

    .right-sec {
      padding: 39px 12px 61px 12px;

      .submit-btn {
        margin-top: 20px;
      }
    }

    .finish-msg-wrap {
      padding-top: 75px;
      padding-bottom: 0px;

      .finish-msg {
        padding-bottom: 240px;
      }
    }
  }
}

@media (max-width: 479px) {
  .signup-sec {
    .right-sec {

      // padding: 180px 12px 54px !important;
      .MuiStepper-root {
        padding: 15px 0px 30px;
      }
    }
  }

  // .auth-sec {
  //   .right-sec {
  //     // padding: 180px 12px 61px 12px;
  //   }
  // }
}