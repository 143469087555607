@import "../../../../assets/scss/variable.scss";

.high-chart {
    margin: 0px auto;

    @media (max-width:799px) {
        margin: 0px auto;
    }

    .recharts-legend-wrapper {
        width: 100% !important;

        @media (max-width:439px) {
            width: 130px !important;
        }

        svg {
            display: none !important;
        }

        .recharts-legend-item {
            margin-right: 0px !important;
        }

        .recharts-legend-item-text {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 500;
            font-family: $regulerFont;
        }
    }

    .recharts-dot {
        cursor: pointer;
    }

    .tool-tip-wrap {
        background-color: #D6D9F3;
        color: #4455C7;
        padding: 3px 6.1px;
        border-radius: 3px;
        font-size: 11.42px;
        line-height: 14px;
        font-weight: 500;
        font-family: $regulerFont;
    }

    .recharts-active-dot {
        circle {
            fill: transparent;
        }
    }

    .recharts-tooltip-cursor {
        display: none !important;
    }


}