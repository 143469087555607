@import "../../../../assets/scss/variable.scss";

.trackprofile-wrap {
  .trackprofile-header {
    padding: 45px 18px 0px;

    @media (max-width: 799px) {
      padding: 22px 9px 6px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .trackprofile-detail {
    .select-search-container {
      background-color: $color-White;
      padding: 18px;
      box-shadow: 0px 3px 9px 0px #0000000d;

      @media (max-width: 479px) {
        padding: 9px 12px;
      }
    }

    .statistics-select-wrap {
      display: flex;
      max-width: initial;
      border-bottom: 1px solid $color-Accent-1;
      margin-bottom: 21px;

      .statistics-select {
        min-width: 200px;
        margin-right: 5px;

        @media (max-width: 479px) {
          min-width: 145px;
        }

        .select__input {
          font-size: 22.4px !important;
          line-height: 31.36px;
          color: #4455c7 !important;
          font-family: "VeneerClean-Soft" !important;
          min-width: 160px !important;

          @media (max-width: 479px) {
            min-width: 100px !important;
          }
        }
        .select__control--is-disabled {
          background-color: transparent;
          .select__placeholder {
            color: $color-Light-grey;
          }
        }
      }
    }

    .search_input {
      width: 100%;

      .MuiInputBase-root {
        max-height: 45px;

        svg {
          height: 18px;
          width: 18px;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #e7e9ec;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $color-Accent-1;
        border-width: 1.5px;
      }

      @media (max-width: 799px) {
        margin-left: 0px !important;
        min-width: auto;
        width: 100%;
      }
    }

    .Filteritemlist-racing {
      display: flex;
      align-items: center;
      padding-left: 0px;
      margin: 10px 0px 0px;

      li {
        list-style: none;

        label {
          display: flex;
        }
      }
    }

    .trackprofile-tab-detail {
      padding: 18px 0px;
      min-height: 38px;
      border-bottom: 1px solid $color-Accent-1;

      @media (max-width: 799px) {
        padding: 9px 0px;
      }

      .MuiTab-textColorInherit {
        color: $color-Black;
        border: 2px solid $color-Primary;
        margin: 0px 18px;
        border-radius: 4px;
        opacity: 1;
        min-height: 38px;

        @media (max-width: 799px) {
          margin: 0 4.5px;
        }
      }

      .active {
        color: $color-White;
        background: $color-Primary;
      }

      .MuiTabs-indicator {
        background: none;
      }

      .MuiTab-textColorInherit:first-child {
        margin-left: 0px;
      }

      .MuiTab-root {
        min-width: 120px;

        @media (max-width: 479px) {
          min-width: 58px;
        }
      }

      .MuiTab-wrapper {
        font-size: 16px;
        line-height: 20px;
        font-family: $primaryFont;
      }
    }

    .tracklist-container {
      margin-top: 18px;
      background-color: $color-White;
      padding: 18px;
      box-shadow: 0px 3px 9px 0px #0000000d;

      @media (max-width: 479px) {
        padding: 12px;
      }
    }

    .trackprofilelist-wrap {
      list-style: none;
      padding: 0px;
      margin: 0px;

      .trackprofile-list {
        padding-bottom: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #e7e9ec;
        justify-content: space-between;
        padding: 8px 0px;
        .sport-icon-img {
          padding-right: 5px;
          width: 30px;
          height: 30px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .statistics-name {
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          cursor: pointer;
          color: $color-Black;

          @media (max-width: 799px) {
            font-size: 15px;
            line-height: 18px;
          }
        }

        span:hover {
          color: $color-Primary;
          text-decoration: underline;
        }

        .add-to-blackbook {
          color: #9ca5e1;
          display: flex;
          align-items: center;
          cursor: pointer;
          svg {
            margin-right: 3px;
          }

          &:hover {
            color: #9ca5e1;
            text-decoration: underline;
          }
        }
        .view-Blackbook {
          color: $color-Accent-1;
          &:hover {
            color: $color-Accent-1;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .track-filter-section {
    display: flex;
    align-items: baseline;
    column-gap: 16px;
    padding-top: 11px;

    @media (max-width: 799px) {
      flex-direction: column;
      row-gap: 9px;
    }

    .alphabet-filter {
      display: flex;
      align-items: center;
      column-gap: 26px;
      row-gap: 9px;
      flex-wrap: wrap;

      @media (max-width: 799px) {
        column-gap: 10px;
      }

      .letters-alphabet {
        padding: 6px 0px;
        text-align: center;
        width: 40px;
        border-radius: 8px;
        border: 1px solid #e7e9ec;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        font-family: $primaryFont;
        color: #989898;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        @media (max-width: 799px) {
          width: 30px;
        }
      }

      .selected {
        background-color: $color-Primary;
        color: $color-White;
      }
    }
    .nosport-filter {
      column-gap: 42px;
      justify-content: center;
      @media (max-width: 799px) {
        column-gap: 10px;
      }
    }
  }

  .track-accordion {
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;
    margin-bottom: 18px;

    .MuiAccordion-root {
      margin-bottom: 10px;
      box-shadow: none;

      &::before {
        display: none;
      }

      .MuiAccordionSummary-root {
        padding: 0px 33px 0px 63px;
        background: #e7e9ec;
        min-height: 45px;

        @media (max-width: 799px) {
          padding: 0px 12px;
        }

        .MuiSvgIcon-root {
          fill: $color-Black;
        }
      }

      .MuiAccordionSummary-content {
        margin: 0px;
        column-gap: 11px;

        p {
          font-size: 22.4px;
          font-family: $primaryFont;
          color: $color-Black;
          line-height: 31.36px;

          @media (max-width: 799px) {
            font-size: 16px;
          }
        }
      }

      .MuiIconButton-root {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }

    .MuiAccordionDetails-root {
      padding: 9px 33px;
      width: auto;
      display: block;

      @media (max-width: 799px) {
        padding: 9px 12px;
      }
    }
    .view-all {
      text-align: end;
      padding-top: 13px;

      @media (max-width: 799px) {
        padding-top: 9px;
      }

      .view-all-text {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Accent-1;
        border-bottom: 1px solid $color-Accent-1;
        cursor: pointer;

        @media (max-width: 799px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }
    }
  }
  .track-profile-accordian {
    .MuiAccordion-root {
      .MuiAccordionSummary-root {
        @media (max-width: 799px) {
          padding: 0px 20px;
        }
      }
    }
  }
}

.races-filter-wrapper {
  .select-wrap {
    margin-top: 12px;
    max-width: 100%;
    // display: flex;
    // justify-content: left;
    // align-items: center;
    margin-top: 12px;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 5fr;
    gap: 5px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 560px) {
      grid-template-columns: 1fr;
    }
    .series-select {
      margin-right: 0px;
      // @media (max-width: 1023px) {
      //   margin-right: 4px;
      // }
    }
    .Filteritemlist-racing {
      margin-top: 0px;
    }
  }
  .track-filter-section {
    display: grid;
    grid-template-columns: 5fr 1fr;
    text-align: right;
    align-items: flex-end;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      text-align: left;
    }
    .reset-all {
      .reset-all-text {
        color: $color-Accent-1;
        text-decoration: underline;
      }
    }
  }
}
