@import "../../../../../../assets/scss/variable.scss";

.landing-home-tipping-comp {
    margin-top: 50px;

    @media (max-width:1080px) {
        margin-top: 30px;
    }

    .landing-home-tipping-comp-header {
        margin-bottom: 12px;
    }
}