@import "../../../../assets/scss/variable.scss";

.race-result-home-component-wrap {
  border-radius: 8px;
  box-shadow: 0px 1px 9px 0px #0000003b;
  @media (max-width: 799px) {
    border-radius: 0px;
    margin: 0px -12px;
  }

  .race-result-home-container {
    .race-result-home-table {
      min-width: 700px;
      @media (max-width: 799px) {
        min-width: 475px;
      }
      @media (max-width: 539px) {
        min-width: 390px;
      }
      .race-result-home-table-header-wrap {
        background-color: $color-Light-grey;
        .race-result-home-header {
          font-size: 16px;
          line-height: 20px;
          color: $color-Primary;
          font-weight: 600;
          font-family: $regulerFont;
          text-align: center;
          padding: 12px;
          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
            padding: 6px 12px;
          }
        }
        .race-result-home-name-header {
          text-align: left;
          padding: 12px 56px;
          @media (max-width: 799px) {
            padding: 6px 12px;
          }
        }
        .align-left {
          text-align: left;
        }
      }
      .race-result-home-table-body-wrap {
        .MuiTableCell-body {
          padding: 6px;
          text-align: center;
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          color: $color-Black;
          font-family: $regulerFont;
          border-bottom: 1px solid $color-Light-grey-1;
        }
        // .MuiTableRow-root:not(:last-child) {
        //   border-bottom: 1px solid $color-Light-grey-1;
        // }
        .MuiTableRow-root:nth-child(odd) {
          background-color: #fdfdfd;
        }
        .MuiTableRow-root:nth-child(even) {
          background-color: #f7f7f8;
        }
        .race-result-home-name-wrap {
          text-align: left;
          padding: 6px 56px;
          @media (max-width: 1023px) {
            padding: 6px 12px;
          }
        }
        .race-result-home-name {
          font-size: 16px;
          line-height: 20px;
          @media (max-width: 799px) {
            font-size: 14px;
            line-height: 17px;
          }
        }
        .race-name {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          text-align: left;
          text-transform: capitalize;
          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }
        .name-img-wrap {
          display: flex;
          align-items: center;
          column-gap: 9px;
          @media (max-width: 799px) {
            column-gap: 6px;
            align-items: flex-start;
          }

          .race-result-home-img-wrap {
            width: 37px;
            height: 22px;
            @media (max-width: 799px) {
              width: 26px;
              height: 15px;
            }
            img {
              width: 100%;
              height: 100%;

              object-fit: cover;
            }
          }
          .track-name-number {
            display: flex;
            align-items: center;
            column-gap: 5px;
            .race-number {
              border: 0.5px solid $color-Black;
              border-radius: 8px;

              width: 29px;
              height: 23px;
              display: flex;
              align-items: center;
              justify-content: center;
              @media (max-width: 799px) {
                font-size: 11.42px;
                line-height: 14px;
                width: 26px;
                height: 20px;
              }
            }
            .track-name {
              font-size: 16px;
              line-height: 20px;
              @media (max-width: 799px) {
                font-size: 14px;
                line-height: 17px;
              }
            }
            .country-code {
              font-size: 11.42px;
              line-height: 14px;
            }
          }
          .race-result-home-owner-wrap {
            @media (max-width: 799px) {
              display: flex;
              flex-direction: column;
              row-gap: 5px;
            }
          }
          .race-details-wrap {
            .res-race-name {
              font-size: 11.42px;
              line-height: 14px;
              margin-top: 6px;
            }
          }
        }
        .last-result-cell {
          padding-right: 57px;
          @media (max-width: 1023px) {
            padding-right: 12px;
          }
          .result-final-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 799px) {
              flex-direction: column;
            }
            .result-display {
              font-weight: 600;
              color: $color-Accent-1;
              padding: 11px 20px;
              @media (max-width: 799px) {
                padding: 6px 16px;
              }
            }
            .result-final {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 11px 20px;
              background-color: $color-Lavender;
              color: $color-Accent-1;
              text-decoration: underline;
              cursor: pointer;
              @media (max-width: 799px) {
                padding: 6px 16px;
              }
            }
          }
        }
      }
    }
  }
  .view-more {
    padding: 9px 25px;
    text-align: right;

    .view-more-text {
      font-size: 12px;
      line-height: 16px;
      color: #4455c7;
      font-weight: 400;
      font-family: $regulerFont;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
