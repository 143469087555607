@import "../../../../assets/scss/variable.scss";

.grid-container {
  // margin-top: 21px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 799px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 25px;
  }
}

.grid-item {
  //   background-color: #ddd;
  //   padding: 20px;
  //   text-align: center;
  position: relative;
  width: 391px;
  height: 269px;
  background: $color-White;
  border-radius: 8px;
  box-shadow: 0 1px 9px RGB(0 0 0 / 16%);
  z-index: 1;
  overflow: hidden;

  @media (max-width: 1400px) {
    // height: 100%;
    width: 100%;
  }

  // @media (max-width: 1200px) {
  //   // height: 100%;
  //   width: 411px;
  // }
  @media (max-width: 1080px) {
    width: 100%;
    height: 100%;
  }

  .news-img {
    height: 158px;
    width: 100%;

    @media (max-width: 425px) {
      // width: 180px;
      height: 97px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
      border-radius: 8px;

      @media (max-width: 799px) {
        object-position: center;
      }
    }
  }

  .details-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    background: $color-White;
    padding: 12px;
    min-height: 87px;
    width: calc(100% - 24px);
    border-radius: 0px 0px 8px 8px;

    @media (max-width: 799px) {
      position: inherit;
      min-height: 100%;
      width: 100%;
      border-radius: 0px 0px 8px 0px;
    }

    .news-deatils {
      @media (max-width: 799px) {
        position: relative;
        height: 100%;
      }

      .title-news {
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-Black;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;

        @media (max-width: 799px) {
          font-size: 11.42px;
          line-height: 14px;
          -webkit-line-clamp: 6;
        }

        @media (max-width: 425px) {
          -webkit-line-clamp: 3;
        }
      }

      .desc-news {
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-White;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      .sub-details-wrap {
        .sub-details {
          font-size: 14px;
          line-height: 19px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-White;

          // position: relative;
          @media (max-width: 1080px) {
            font-size: 11.42px;
            line-height: 14px;
          }

          .read-more {
            color: $color-White;
            // position: absolute;
            // right: 23px;
            // bottom: 0px;
          }
        }
      }

      p {
        font-size: 11.42px;
        line-height: 14px;
        font-weight: 400;
        font-family: $regulerFont;
        color: #5d5d5d;
      }

      .news-time-comment-wrapper {
        position: absolute;
        bottom: 12px;
        left: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 24px);

        @media (max-width: 799px) {
          bottom: 0;
          left: 0;
          width: 100%;
        }

        .bookmark-chat-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          .chat-details {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 6px;

            svg {
              margin-right: 3px;
            }
          }

          .saved-bookmark-icon {
            path {
              fill: $color-gray-dark;
            }
          }
        }
      }
    }
  }

  .news-tag {
    padding: 3px 6px;
    background-color: #fc4714;
    color: #ffffff;
    font-size: 11.42px;
    line-height: 14px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    max-width: -moz-fit-content;
    max-width: fit-content;
    position: absolute;
    left: 12px;
    top: -30px;
    text-transform: capitalize;
    max-height: fit-content;
    border-radius: 3px;

    @media (max-width: 799px) {
      top: auto;
      bottom: 12px;
    }

    @media (max-width: 560px) {
      bottom: 10px;
      left: 6px;
    }

    @media (max-width: 440px) {
      left: 10px;
    }
  }

  .responsive-news-view {
    cursor: pointer;

    @media (max-width: 799px) {
      display: flex;
    }
  }

  .responsive-main-news-view {
    cursor: pointer;
    height: 100%;
    // @media (max-width: 559px) {
    // }
  }
}

.grid-item1 {
  grid-column: 1;
  grid-row: 1 / 3;
  width: 522px;
  height: 561px;
  overflow: initial;

  // @media (max-width: 1400px) {
  //   // height: 100%;
  //   width: 100%;
  // }
  @media (max-width: 1200px) {
    // height: 100%;
    width: 411px;
  }

  @media (max-width: 1080px) {
    width: calc(100vw - 24px);
  }

  @media (max-width: 799px) {
    grid-column: auto;
    grid-row: auto;
    height: auto;
  }

  @media (max-width: 559px) {
    height: 333px;
  }

  .news-img {
    // @media (max-width: 559px) {
    height: 100%;

    // }
    img {
      object-position: center;
      // @media (max-width: 559px) {
      // object-fit: cover;
      // }
    }
  }

  .details-wrapper {
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #222);
    width: -webkit-fill-available;
    min-height: auto;

    @media (max-width: 799px) {
      position: absolute;
    }

    .news-deatils {
      margin: 0px 11px;

      .title-news {
        color: $color-White;
        font-size: 22.4px;
        line-height: 31.36px;
        margin-bottom: 12px;

        @media (max-width: 1080px) {
          font-size: 16px;
          line-height: 22.4px;
        }

        @media (max-width: 799px) {
          -webkit-line-clamp: 3;
        }
      }

      p {
        color: $color-White;
      }

      .news-time-comment-wrapper {
        position: relative;
        padding-top: 18px;
        bottom: 11px;
        left: 0px;

        .bookmark-chat-wrapper {
          svg {
            width: 100%;
            height: 100%;

            path {
              stroke: $color-White;
            }
          }

          .saved-bookmark-icon {
            path {
              fill: $color-White;
            }
          }
        }
      }
    }
  }

  .news-tag {
    top: -15px;
  }

  .featured-news-slick-slider {
    height: 100%;

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;

        .slick-slide>div:first-child {
          height: 100%;
          position: relative;
          border-radius: 8px;
          overflow: hidden;
        }

        .slick-slide {
          @media (max-width: 799px) {
            width: calc(100vw - 24px);
          }
        }
      }
    }

    .slick-dots {
      bottom: -18px;

      li {
        width: 8px;
        height: 8px;

        button {
          width: 8px;
          height: 8px;

          &::before {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            color: transparent;
            background-color: $color-Accent-1;
            opacity: 0.5;
          }
        }
      }

      .slick-active {
        button {
          &::before {
            transform: scale(1.5);
            opacity: 1;
            background-color: #4455c7;
          }
        }
      }
    }
  }
}

.news-tooltip {
  background: #003764 !important;

  .MuiTooltip-arrow {
    color: #003764;
  }
}

.no-data-wrapper {
  background-color: $color-White;
  padding: 18px 12px;
  border-radius: 8px;
  margin: 0px 7.5px;
  text-align: center;
  border: 1px solid $color-Light-grey;

  .no-data-text {
    font-size: 16px;
    line-height: 20px;
    font-family: $regulerFont;
    color: $color-Black;
  }

  @media (max-width: 799px) {
    padding: 12px;
  }
}

.news-view-more {
  padding: 9px 0px;
  text-align: right;

  .view-more-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    line-height: 16px;
    color: #4455c7;
    font-weight: 400;
    font-family: $regulerFont;
    text-decoration: underline;
    cursor: pointer;
  }
}