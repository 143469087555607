@import "../../../assets/scss/variable.scss";

.sport-tips-wrap {
  background-color: #fbfbfb;

  .sport-tips-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .tab-search-section {
    padding: 18px 18px 19px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    // margin-bottom: 18px;
    .filter-section {
      max-width: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 12px;

      @media only screen and (max-width: 1023px) {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 12px;
        row-gap: 12px;
      }

      @media only screen and (max-width: 479px) {
        grid-template-columns: auto auto;
      }

      .sort-select {
        min-width: 282px;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .country-select {
        min-width: 170px;
        max-width: 170px;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }

      .select__control {
        background-color: $color-Light-grey;
        border-radius: 8px;
        max-height: 36px;

        .select__single-value {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__placeholder {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__input-container {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__indicator-separator {
          width: 0px;
        }

        .select__dropdown-indicator {
          padding: 10px;
        }
      }

      .select__menu-portal {
        z-index: 99;
      }

      .select__menu,
      .react-select__menu {

        .select__menu-list,
        .react-select__menu-list {

          .select__option,
          .react-select__option {
            cursor: pointer;
            font-size: 16px;
            color: $color-Black;
            font-family: $regulerFont;
            line-height: 19px;
            padding: 11px;

            &.select__option--is-focused {
              background-color: #d4d6d8;
              color: #000;
            }

            &.select__option--is-selected {
              background-color: #d4d6d8;
              color: $color-Black;
            }
          }
        }

        .select__menu-list,
        .react-select__menu-list {
          .select__group {
            .select__group-heading {
              margin-bottom: 0.5rem;
              color: green;
              font-weight: bolder;
              font-size: inherit;
            }
          }
        }
      }
    }
  }

  .d-block {
    display: block;
  }

  .page-deatils-wrap {
    padding: 18px 33px;
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 18px;
    row-gap: 28px;

    @media (max-width: 1340px) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      padding: 21px 12px 27px 12px;
    }

    .tip-card-box {
      cursor: pointer;
      border-radius: 8px;
      background-color: $color-White;
      box-shadow: 0px 3px 9px 0px #0000000d;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid #4455c7;
      }

      &:active {
        border: 1px solid #4455c7;
      }

      @media (max-width: 911px) {
        max-width: 340px;
        width: 100%;
        margin: 0 auto;
      }

      .img-box {
        width: 100%;
        height: 172px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        .sport-img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }

      .content-box {
        padding: 15px;

        .chip-sec {
          text-align: center;
          max-width: max-content;
          background-color: #4455c7;
          border-radius: 23px;
          padding: 0px 4px 0px 4px;

          //   margin-left: 23px;
          .chip-txt {
            font-weight: 400;
            font-family: "VeneerClean-Soft" !important;
            color: #fff;
            font-size: 16px;
            line-height: 31.36px;

            @media (max-width: 580px) {
              font-size: 11.42px;
              line-height: 15px;
            }
          }
        }

        .title {
          margin-top: 8px;
          font-size: 22.4px;
          line-height: 31.36px;
          color: $color-Black;
          font-weight: 600;
          font-family: $regulerFont;

          @media (max-width: 580px) {
            font-size: 16px;
            line-height: 19px;
          }
        }

        .time {
          margin-top: 8px;
          font-size: 16px;
          line-height: 22.4px;
          color: #5d5d5d;
          font-weight: 400;
          font-family: $regulerFont;

          @media (max-width: 580px) {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
  }

  .pagination-section {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .MuiPagination-root {
      .MuiPagination-ul {
        .MuiButtonBase-root {
          width: 36px;
          height: 36px;

          svg {
            width: 24px;
            height: 24px;
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }

        .MuiPaginationItem-root {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          border-radius: 50%;
        }
      }
    }
  }
}