@import "../../../../../assets/scss/variable.scss";

.soccer-fixture-info-wrap {
  .d-flex {
    display: flex;
  }

  .align-center {
    align-items: center;
  }

  .content-space-between {
    justify-content: space-between;
  }

  .text-align-center {
    text-align: center;
  }

  .col-gap-5 {
    column-gap: 5px;
  }

  .no-match {
    padding: 10px;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-family: $regulerFont;
    color: $color-Black;
    font-weight: 400;
  }

  .no-match-boxshadow {
    padding: 18px 33px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;
  }

  .nodata {
    padding: 10px 0px 9px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: $regulerFont;
    text-align: center;
  }

  .fixture-info-header {
    padding: 46px 18px 3px;
    margin-top: -5px;
    background-color: #fbfbfb;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;

      @media (max-width: 1060px) {
        font-size: 38.6px;
      }

      @media (max-width: 1023px) {
        line-height: 31.36px;
        font-size: 31.36px;
      }
    }

    .page-bredcrumn-wrap {
      overflow-x: scroll;

      .bredcrumn-wrap {
        margin-bottom: 5px;
        width: max-content;

        li.MuiBreadcrumbs-separator {
          color: $color-Black;
        }

        li {
          font-size: 11.42px;
          line-height: 14px;

          p {
            font-size: 11.42px;
            line-height: 14px;
            color: $color-Accent-1;
            text-transform: uppercase;
          }

          a {
            color: $color-Black;
            font-size: 11.42px;
            line-height: 14px;
            text-transform: uppercase;
          }
        }
      }
    }

    .page-bredcrumn-wrap::-webkit-scrollbar {
      height: 0px;
    }

    .page-bredcrumn-wrap::-webkit-scrollbar-thumb {
      display: none;
    }

    .header-wrap {
      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: baseline;
      }

      .header-text {
        column-gap: 30px;

        @media (max-width: 1023px) {
          width: 100%;
          justify-content: space-between;
          align-items: flex-start;
        }

        .live-indicator {
          padding: 3px 11px;
          background-color: $color-Negative;
          color: $color-White;
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
          border-radius: 3px;
          cursor: pointer;
        }
      }

      .samrtBook-button-wrap {
        @media (max-width: 1023px) {
          width: 100%;
          text-align: end;
        }

        .samrtBook-button {
          padding: 12px 24px;
          border: 1px solid $color-Accent-1;
          border-radius: 8px;

          @media (max-width: 799px) {
            padding: 11px 12px;
          }

          span {
            font-size: 16px;
            line-height: 20px;
            font-family: $regulerFont;
            color: $color-Accent-1;
            text-transform: initial;

            @media (max-width: 1023px) {
              font-size: 11.42px;
              line-height: 14px;
            }

            svg {
              path {
                stroke: $color-Accent-1;
              }
            }
          }

          &:hover {
            background-color: $color-Accent-1;

            span {
              color: $color-White;

              svg {
                path {
                  stroke: $color-White;
                }
              }
            }
          }
        }
      }
    }
  }

  .team-sports-tab {
    padding: 19px 18px 13px;
    margin-bottom: 18px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;
    margin-top: 18px;

    @media (max-width: 599px) {
      padding: 9px 12px 12px;
      margin-bottom: 18px;
    }

    .teamsport-tab-detail {
      border-bottom: 2px solid #4455c7;

      .MuiTabs-scroller {
        .MuiTabs-indicator {
          display: none;
        }

        .active {
          border-bottom: 3px solid $color-Primary;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      // .active {
      //   .MuiTab-wrapper {
      //     color: $color-Accent-1;
      //   }
      // }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .tab-label {
        display: flex;
        align-items: center;

        .label-name {
          font-size: 16px;
          font-family: VeneerClean-Soft !important;
          color: #191919;
          line-height: 21px;
          font-weight: 400;
        }

        .label-icon {
          margin-left: 3px;
          display: flex;
          align-items: center;

          svg {
            line {
              stroke: $color-Black;
            }

            circle {
              stroke: $color-Black;
            }

            #Rectangle_18598 {
              stroke: $color-Black;
            }
          }
        }
      }

      .active {
        .label-name {
          color: $color-Accent-1;
        }

        .label-icon {
          svg {
            #Group_40284 {
              path {
                stroke: $color-Accent-1;
                fill: transparent;
              }
            }

            #Group_40777 {
              #Path_32557 {
                stroke: $color-Accent-1;
              }

              #Path_32560 {
                fill: $color-Accent-1;
              }

              #Path_32561 {
                stroke: $color-Accent-1;
              }

              #Path_32562 {
                fill: $color-Accent-1;
              }

              #Path_32564 {
                fill: $color-Accent-1;
              }

              #Path_32566 {
                fill: $color-Accent-1;
              }
            }

            #Group_34085 {
              path {
                fill: $color-Accent-1;
              }
            }

            line {
              stroke: $color-Accent-1;
            }

            circle {
              stroke: $color-Accent-1;
            }

            #Rectangle_18598 {
              stroke: $color-Accent-1;
            }
          }
        }
      }

      .MuiBox-root {
        min-width: max-content;
      }

      .MuiTab-root {
        min-width: max-content;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: #003764;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .fixture-wrap {
    padding: 18px 33px 9px;
    background-color: $color-White;
    margin-bottom: 18px;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 1023px) {
      padding: 18px 12px 9px;
    }
  }

  .fixture-heading {
    font-size: 22.4px;
    line-height: 31.36px;
    letter-spacing: 0px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-Black;
    border-bottom: 1px solid $color-Accent-1;

    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .light {
    font-weight: 400;
  }

  .bold {
    font-weight: 600;
  }

  .medium {
    font-weight: 500;
  }

  .team-img-wrap {
    width: 36px;
    height: 36px;
    display: block;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .team-name {
    font-size: 16px;
    line-height: 19px;
    font-family: $regulerFont;
    letter-spacing: 0px;
    color: $color-Black;

    @media (max-width: 1023px) {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }

  .match-point-Score-board {
    overflow: hidden;
    padding: 12px;

    .divider-indicator {
      height: 100%;
      width: 5px;
      background: $color-Negative;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 8px;
    }

    .card-wrap {
      .justify-space-around {
        justify-content: space-around;
      }

      .sport-team-name {
        flex-direction: column;
        row-gap: 10px;
      }

      .sport-score-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        row-gap: 10px;
        flex-direction: column;

        .fs-18 {
          font-size: 18px;
          line-height: 22px;
          font-weight: 600;
          font-family: $regulerFont;
          letter-spacing: 0px;
          color: $color-White;
        }
      }
    }
  }

  // info fixture

  .match-info-wrap {
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 1px solid $color-Light-grey;
    padding: 13px 0px;

    .match-info-text {
      font-size: 16px;
      line-height: 19px;
      font-family: $regulerFont;
      color: $color-Black;
      letter-spacing: 0px;

      @media (max-width: 439px) {
        font-size: 12px;
        line-height: 15px;
      }
    }

    .w-60 {
      width: 60%;

      @media (max-width: 439px) {
        width: 55%;
      }
    }

    .w-15 {
      width: 15%;

      @media (max-width: 439px) {
        width: 25%;
      }
    }
  }

  .winning-probability-wrap {
    display: flex;
    align-items: center;
    column-gap: 42px;
    margin-top: 18px;

    @media (max-width: 1023px) {
      column-gap: 25px;
    }

    .team-wrap {
      column-gap: 4px;

      @media (max-width: 1023px) {
        flex-direction: column;
      }
    }

    .win-progress {
      width: 245px;
      height: 8px;
      border-radius: 4px;
      background-color: #d4d6d8;

      @media (max-width: 539px) {
        width: 185px;
      }

      span {
        height: 8px;
        display: block;
      }
    }

    .win-value {
      margin-bottom: 4px;
      width: 245px;

      @media (max-width: 539px) {
        width: 185px;
      }

      .value-text {
        font-size: 11.42px;
        line-height: 14px;
        font-weight: 500;
        font-family: $regulerFont;
        letter-spacing: 0px;
        text-align: center;
      }
    }
  }

  // summary details

  .fixture-summary-wrap {
    padding: 4px 33px 8px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 1023px) {
      padding: 4px 12px 8px;
    }

    .summary-details {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 12px 0px;
      border-bottom: 1px solid #e7e9ec;

      @media (max-width: 1023px) {
        justify-content: space-between;
        padding: 12px 15px;
      }

      .details-text {
        font-size: 16px;
        line-height: 19px;
        font-family: $regulerFont;
        color: $color-Black;
        letter-spacing: 0px;

        @media (max-width: 1023px) {
          font-size: 12px;
          line-height: 15px;
        }
      }

      .dark {
        font-weight: 600;
      }

      .light {
        font-weight: 400;
      }

      .w-20 {
        width: 20%;
      }

      .w-60 {
        width: 60%;
      }
    }
  }

  // match stats

  .match-stats-wrap {
    .match-team-heading {
      column-gap: 19px;
      justify-content: center;
      padding: 18px 0px;

      .team-wrap {
        column-gap: 4px;
      }

      .divider {
        span {
          height: 30px;
          width: 1.5px;
          display: block;
          background-color: $color-Black;
        }
      }
    }

    .match-stats-details {
      &:not(:last-child) {
        margin-bottom: 25px;
      }

      &:last-child {
        margin-bottom: 16px;
      }

      .stats-value-wrap {
        justify-content: space-between;
        margin-bottom: 6px;

        .details-value {
          font-size: 15px;
          line-height: 18px;
          font-family: $regulerFont;

          font-weight: 600;
        }

        .drak {
          color: $color-Primary;
        }

        .light {
          color: $color-gray-dark;
        }

        .details-name {
          font-size: 12px;
          line-height: 15px;
          font-family: $regulerFont;
          color: $color-Black;
          font-weight: 600;
          text-transform: capitalize;
        }
      }

      .stats-progress-bar {
        width: 100%;
        height: 8px;
        border-radius: 4px;
        background-color: $color-grey;
        display: flex;
        align-items: center;
        overflow: hidden;

        .progress-bar {
          height: 8px;
        }
      }
    }
  }

  .scorecard-wrap {
    .wicket-tab-details {
      .MuiBox-root {
        min-width: 75px;

        .MuiButtonBase-root {
          min-width: auto;
          min-height: 40px;
        }
      }
    }
  }

  .scorecard-tab-detail {
    padding: 12px 0px;

    @media (max-width: 1023px) {
      padding: 6px 0px;
    }

    .MuiTabs-scroller {
      .MuiTabs-indicator {
        display: none;
      }

      .active {
        background-color: $color-Primary;

        .team-wrap {
          span {
            color: $color-White !important;
          }
        }
      }
    }

    @media (max-width: 799px) {
      margin-top: 6px;
    }

    .MuiTabs-flexContainer {
      column-gap: 12px;
    }

    .MuiBox-root {
      min-width: fit-content;

      .MuiButtonBase-root {
        border: 2px solid $color-Primary;
        border-radius: 22px;
        min-height: 45px;

        @media (max-width: 799px) {
          min-height: 35px;
        }

        .team-wrap {
          span {
            font-family: $primaryFont;
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            color: $color-Black;
            width: 100%;

            @media (max-width: 799px) {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }

    .MuiTab-textColorInherit {
      opacity: 1;
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }

  // line up

  .lineup-info-wrap {
    background-color: $color-White;
    margin-bottom: 18px;
    box-shadow: 0px 3px 9px 0px #0000000d;
    padding: 12px 33px;

    @media (max-width: 1023px) {
      padding: 12px;
    }

    .lineup-info-inner-wrap {
      display: flex;
      column-gap: 5px;

      @media (max-width: 1023px) {
        display: block;
      }
    }

    .team-card {
      width: 50%;
      border: 1px solid #e7e9ec;
      border-radius: 8px;
      overflow: hidden;

      .card-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #e7e9ec;
        padding: 9px 12px 9px 18px;

        .team-wrap {
          column-gap: 5px;

          .lineup-team {
            font-family: $primaryFont;
          }
        }
      }
    }

    .team-score {
      @media (max-width: 1023px) {
        padding: 11px 12px 0px;
      }

      p {
        font-size: 16px;
        line-height: 19px;
        font-family: $regulerFont;
        font-weight: 400;
        letter-spacing: 0px;
        color: $color-Black;

        @media (max-width: 1023px) {
          color: $color-Primary;
          font-weight: 600;
        }
      }
    }

    .player-details-wrap {
      padding: 15px 18px 12px;

      @media (max-width: 1023px) {
        padding: 12px 12px 12px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e7e9ec;
      }

      .player-postion {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #191919;
        margin-bottom: 10px;
      }

      .player-details {
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 9px;
        }

        .player-name {
          font-size: 16px;
          line-height: 19px;
          font-weight: 500;
          letter-spacing: 0px;
          color: $color-Black;
          font-family: $color-Black;
        }

        .player-score {
          width: 40px;
          text-align: center;
          border: 1px solid #d6d9f3;
          border-radius: 8px;
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          font-family: "VeneerClean-Soft" !important;
          padding: 3px 0px;
          color: #4455c7;
        }
      }

      .cricket-player-details {
        border-bottom: 1px solid #e7e9ec;
        padding: 9px;
      }
    }

    .cricket-player-details-wrap {
      padding: 9px 0px 0px;
    }

    .lineup-tab-detail {
      width: 100%;

      .MuiTabs-scroller {
        .MuiTabs-indicator {
          display: none;
        }

        .active {
          background-color: #e7e9ec;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .MuiBox-root {
        width: 50%;

        .MuiButtonBase-root {
          width: 100%;
          min-width: -webkit-fill-available;

          .team-wrap {
            width: 100%;
            column-gap: 4px;

            .lineup-team {
              font-family: $primaryFont;
              font-size: 16px;
              line-height: 19px;
              font-weight: 400;
              color: $color-Black;
            }
          }
        }
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }

    .substitutes-wrap {
      margin-top: 12px;

      .substitutes-details-wrap {
        display: flex;
        column-gap: 5px;
        padding: 12px 0px;

        .substitutes-card {
          width: 50%;
          border: 1px solid #e7e9ec;
          border-radius: 8px;
          overflow: hidden;
          height: fit-content;

          @media (max-width: 1023px) {
            width: 100%;
          }

          .card-details {
            display: flex;
            align-items: center;
            column-gap: 8px;
            padding: 12px;

            &:not(:last-child) {
              border-bottom: 1px solid #e7e9ec;
            }

            .substitutes-time-wrap {
              .time {
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-Black;

                @media (max-width: 799px) {
                  font-size: 11.42px;
                  line-height: 14px;
                }
              }
            }

            .icon-wrap {
              margin-right: 8px;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 100%;
                height: 100%;
              }

              .card-center {
                rect {
                  transform: translate(5px, 5px);
                }
              }
            }

            .substitutes-player-details-wrap {
              .mb-4 {
                margin-bottom: 4px;
              }

              .player-wrap {
                display: flex;
                align-items: center;

                .player {
                  font-size: 14px;
                  line-height: 16px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  color: $color-Black;

                  @media (max-width: 799px) {
                    font-size: 11.42px;
                    line-height: 14px;
                  }
                }

                .fw-700 {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }

  // info team

  .info-table-wrap {
    background-color: $color-White;
    margin-bottom: 18px;
    box-shadow: 0px 3px 9px 0px #0000000d;
    padding: 12px;

    .short-name-details {
      padding: 10px 0px 0px 0px;
    }

    .light-blue {
      background-color: #0075ff;
    }

    .medium-orange {
      background-color: #ab5e03;
    }

    .dark-red {
      background-color: #961a3d;
    }

    .league-indicator {
      width: 50%;
      height: 10px;
      border-radius: 10px 10px 0px 0px;
      position: absolute;
      bottom: 0px;
    }

    .league-name-indicator {
      column-gap: 15px;
      padding: 15px 0px 0px;
      flex-wrap: wrap;
      row-gap: 12px;

      .league-name-color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      .league {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        color: $color-Black;
        font-family: $regulerFont;
      }
    }

    .info-table-cont-wrap {
      &:not(:first-child) {
        margin-top: 18px;
      }

      .data-table {
        box-shadow: inset 1px 2px 9px 0px #0000001f;
        background-color: $color-White;
        border: 1px solid #c9c9c9;

        .table-header {
          .MuiTableCell-head {
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            font-family: $regulerFont;
            padding: 17px 24px 8px;
          }
        }

        .table-body {
          .live-row {
            .MuiTableCell-body {
              font-weight: 700;

              .soccer-tema-name {
                font-weight: 700;
              }
            }
          }

          .MuiTableCell-body {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            font-family: $regulerFont;
            padding: 17px 24px 8px;
          }

          .table-body-column {
            // padding: 0px 24px 0px 0px;
            border-bottom: none;
            font-size: 16px;
            line-height: 19px;
          }

          .table-team-logo {
            .logo-wrap {
              width: 33px;
              height: 33px;
              border-radius: 50%;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: contain;
              }
            }

            .soccer-tema-name {
              font-size: 14px;
              line-height: 16px;
              font-weight: 400;
              font-family: $regulerFont;
            }
          }

          .body-cell-indicator {
            position: relative;
          }
        }
      }
    }

    .mobile-info-table {
      padding: 12px;
      background-color: $color-White;
      border-radius: 8px;
      box-shadow: 0px 3px 9px 0px #0000000d;

      &:not(:first-child) {
        margin-top: 12px;
      }

      .mobile-table-heading {
        column-gap: 15px;
        border-bottom: 1px solid #c9c9c9;
        padding-bottom: 5px;

        .table-heading {
          font-size: 15px;
          line-height: 18px;
          font-weight: 600;
          color: $color-Black;
          font-weight: $regulerFont;
          letter-spacing: 0px;
        }

        .heading-pos {
          width: 35px;
          text-align: center;
        }
      }

      .info-table-deatils {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px 9px;

        .mobile-live-row {
          .details-name {
            font-weight: 700 !important;
          }

          .table-body-column {
            .team-name {
              font-weight: 700 !important;
            }
          }
        }

        .info-table-mobile-header {
          column-gap: 15px;

          .details-name {
            font-size: 15px;
            line-height: 33px;
            color: $color-Black;
            font-weight: 400;
            font-family: $regulerFont;
            letter-spacing: 0px;
            width: 35px;
            position: relative;
            padding-bottom: 5px;
            text-align: center;

            .league-indicator {
              width: 100%;
            }
          }

          .table-body-column {
            .team-wrap-body {
              column-gap: 5px;

              .img-wrap {
                width: 33px;
                height: 33px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }

              .team-name {
                font-size: 15px;
                line-height: 18px;
                font-weight: 400;
                font-family: $regulerFont;
                color: #1a73e8;
                letter-spacing: 0px;
              }
            }
          }
        }

        .arrow-details {
          width: 22px;
          height: 22px;

          .MuiButtonBase-root {
            width: 100%;
            height: 100%;

            svg {
              path {
                fill: #e2662c;
              }
            }

            .MuiTouchRipple-root {
              display: none;
            }
          }
        }
      }

      .info-table-border-bottom {
        &:not(:last-child) {
          border-bottom: 1px solid #bdbdbf;
        }
      }

      .collapse-box {
        &:not(:last-child) {
          border-bottom: 1px solid #bdbdbf;
        }

        .collapse-deatils {
          box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.16);

          .collapse-table {
            .collapse-table-row {
              border-bottom: 1px solid rgba(189, 189, 191, 50%);

              .MuiTableCell-root {
                padding: 16px 31px 11px 27px;
                font-size: 16px;
                line-height: 16px;
                font-family: $regulerFont;
              }

              .td-left {
                color: #003764;
                font-weight: 600;
              }

              .td-right {
                color: #191919;
                font-weight: 500;
                text-align: end;
              }
            }
          }
        }

        .view-profile-box {
          text-align: end;

          .view-profile {
            font-size: 12px;
            line-height: 16px;
            color: #4455c7;
            font-weight: 400;
            font-family: $regulerFont;
            text-decoration: underline;
            padding: 9px 0px;
          }
        }
      }

      .collapse-box-bottom {
        padding-bottom: 9px;
      }

      .no-data-mobile {
        text-align: center;
        font-size: 16px;
        line-height: 19px;
        color: $color-Black;
        font-weight: 600;
        font-family: $regulerFont;
        padding: 10px 0px 9px;
      }
    }
  }

  // news

  // .individual-news-cont {
  //   margin-top: 34px;

  //   @media (max-width: 799px) {
  //     margin-top: 18px;
  //   }

  //   .individual-single-news {
  //     margin-bottom: 27px;

  //     &:not(:last-child) {
  //       border-bottom: 1px solid #c9c9c9;
  //     }

  //     .news-box {
  //       display: flex;

  //       .news-img {
  //         min-width: 330px;
  //         min-height: 164px;
  //         max-width: 330px;
  //         margin-right: 27px;
  //         cursor: pointer;

  //         @media (max-width: 1280px) {
  //           max-width: 200px;
  //           min-width: 200px;
  //           margin-right: 12px;
  //           min-height: auto;
  //         }

  //         @media (max-width: 1120px) {
  //           max-width: 180px;
  //           min-width: 180px;
  //         }

  //         @media (max-width: 439px) {
  //           max-width: 140px;
  //           min-width: 140px;
  //           max-height: 85px;
  //         }

  //         img {
  //           width: 100%;
  //           height: 100%;
  //           object-fit: contain;

  //           @media (max-width: 1280px) {
  //             width: 200px;
  //             height: auto;
  //           }

  //           @media (max-width: 1120px) {
  //             width: 180px;
  //           }

  //           @media (max-width: 439px) {
  //             width: 140px;
  //             height: 85px;
  //           }
  //         }
  //       }

  //       .individual-news-details {
  //         width: 100%;

  //         .exclusive {
  //           padding: 3px 11px;
  //           background-color: #d84727;
  //           color: #ffffff;
  //           font-size: 11.42px;
  //           line-height: 14px;
  //           font-weight: 400;
  //           font-family: $regulerFont;
  //           max-width: fit-content;
  //           border-radius: 3px;
  //           margin-bottom: 9px;
  //         }

  //         .details {
  //           font-size: 22.4px;
  //           line-height: 31.36px;
  //           font-weight: 600;
  //           font-family: $regulerFont;
  //           color: $color-Black;
  //           // margin-bottom: 24px;
  //           overflow: hidden;
  //           text-overflow: ellipsis;
  //           display: -webkit-box;
  //           -webkit-box-orient: vertical;
  //           -webkit-line-clamp: 3;
  //           cursor: pointer;

  //           @media (max-width: 1440px) {
  //             -webkit-line-clamp: 2;
  //           }

  //           @media (max-width: 1024px) {
  //             margin-bottom: 4px;
  //           }

  //           @media (max-width: 439px) {
  //             font-size: 14px;
  //             line-height: 18px;
  //           }
  //         }

  //         .sub-details-wrap {
  //           .sub-details {
  //             font-size: 16px;
  //             line-height: 22.4px;
  //             font-weight: 400;
  //             font-family: $regulerFont;
  //             color: $color-gray-dark;
  //             margin-top: 4px;

  //             @media (max-width: 439px) {
  //               font-size: 11.42px;
  //               line-height: 14px;
  //             }

  //             .read-more {
  //               color: $color-Accent-1;
  //             }
  //           }
  //         }

  //         .tag-time-details {
  //           display: flex;
  //           justify-content: space-between;
  //           align-items: center;
  //           margin-top: 13px;

  //           @media (max-width: 599px) {
  //             flex-direction: column;
  //             row-gap: 4px;
  //           }

  //           .news-tags {
  //             display: flex;
  //             flex-wrap: wrap;
  //             row-gap: 5px;
  //             width: 70%;

  //             @media (max-width: 599px) {
  //               width: 100%;
  //             }
  //           }

  //           .individual-news-tag {
  //             padding: 3px 6px;
  //             background-color: #989898;
  //             color: #ffffff;
  //             font-size: 11.42px;
  //             line-height: 14px;
  //             font-weight: 400;
  //             font-family: $regulerFont;
  //             max-width: fit-content;
  //             border-radius: 23px;

  //             &:not(:last-child) {
  //               margin-right: 12px;

  //               @media (max-width: 1140px) {
  //                 margin-right: 6px;
  //               }
  //             }
  //           }

  //           .stories-time {
  //             font-size: 11.42px;
  //             line-height: 14px;
  //             font-family: $regulerFont;
  //             font-weight: 400;
  //             color: #5d5d5d;
  //             // width: 30%;
  //             // text-align: end;

  //             @media (max-width: 599px) {
  //               width: 100%;
  //             }

  //             .watch {
  //               margin-right: 4px;
  //               vertical-align: middle;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // .all-news {
  //   h4 {
  //     font-size: 31.36px;
  //     line-height: 40px;
  //     color: $color-Primary;
  //     font-weight: 400;
  //     font-family: $primaryFont;
  //     margin-bottom: 0px;
  //     position: relative;
  //     margin-top: 7px;
  //     display: block;

  //     @media (max-width: 439px) {
  //       font-size: 22.4px;
  //       line-height: 28px;
  //       margin-bottom: 12px;
  //       // margin-top: 18px;
  //     }
  //   }
  // }
  .individual-news-cont {
    margin-top: 12px;
    padding: 5px;

    @media (max-width: 799px) {
      margin-top: 0px;
    }

    .individual-single-news {
      margin-bottom: 21px;
      padding: 15px 12px;
      background-color: #ffffff;
      box-shadow: 0px 1px 9px 0px #0000001f;
      border-radius: 8px;
      overflow: hidden;

      @media (max-width: 799px) {
        padding: 12px;
        margin-bottom: 12px;
      }

      // &:not(:last-child) {
      //   border-bottom: 1px solid #c9c9c9;
      // }

      .news-box {
        display: flex;
        column-gap: 12px;

        .news-img {
          position: relative;
          min-width: 312px;
          // min-height: 164px;
          max-width: 312px;
          height: 164px;
          // margin-bottom: 18px;
          cursor: pointer;

          @media (max-width: 1280px) {
            max-width: 200px;
            min-width: 200px;
            // margin-right: 12px;
            height: 140px;
          }

          @media (max-width: 1120px) {
            max-width: 180px;
            min-width: 180px;
            height: 120px;
          }

          @media (max-width: 439px) {
            max-width: 140px;
            min-width: 140px;
            height: 105px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;

            @media (max-width: 1280px) {
              width: 200px;
              height: 140px;
            }

            @media (max-width: 1120px) {
              width: 180px;
              height: 120px;
            }

            @media (max-width: 439px) {
              width: 140px;
              height: 105px;
            }
          }
        }

        .individual-news-details {
          width: 100%;
          position: relative;

          .exclusive {
            padding: 3px 11px;
            background-color: #d84727;
            color: #ffffff;
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            font-family: $regulerFont;
            max-width: fit-content;
            border-radius: 3px;
            margin-bottom: 9px;
          }

          .details {
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            font-family: $regulerFont;
            color: $color-Black;
            // margin-bottom: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            cursor: pointer;

            @media (max-width: 1440px) {
              -webkit-line-clamp: 2;
            }

            @media (max-width: 1024px) {
              margin-bottom: 4px;
              font-size: 14px;
              line-height: 18px;
            }

            // @media (max-width: 439px) {
            //   font-size: 14px;
            //   line-height: 18px;
            // }
          }

          .sub-details-wrap {
            .sub-details {
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-gray-dark;
              margin-top: 4px;

              @media (max-width: 1024px) {
                font-size: 11.42px;
                line-height: 14px;
              }

              .read-more {
                color: $color-Accent-1;
              }
            }
          }

          .tag-time-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 13px;

            @media (max-width: 599px) {
              flex-direction: column;
              row-gap: 4px;
              margin-top: 6px;
            }

            .news-tags {
              display: flex;
              flex-wrap: wrap;
              row-gap: 5px;
              width: 70%;

              @media (max-width: 599px) {
                width: 100%;
              }
            }

            .individual-news-tag {
              padding: 3px 6px;
              background-color: #989898;
              color: #ffffff;
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 400;
              font-family: $regulerFont;
              max-width: fit-content;
              border-radius: 23px;

              &:not(:last-child) {
                margin-right: 12px;

                @media (max-width: 1140px) {
                  margin-right: 6px;
                }
              }
            }

            .stories-time {
              font-size: 11.42px;
              line-height: 14px;
              font-family: $regulerFont;
              font-weight: 400;
              color: #5d5d5d;
              // width: 30%;
              // text-align: end;

              @media (max-width: 599px) {
                width: 100%;
              }

              .watch {
                margin-right: 4px;
                vertical-align: middle;
              }
            }
          }

          .news-time-comment-wrapper {
            position: absolute;
            left: 0px;
            bottom: 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 24px);

            @media (max-width: 799px) {
              bottom: 0;
              left: 0;
              width: 100%;
            }

            .news-time {
              font-size: 11.42px;
              line-height: 14px;
              font-family: $regulerFont;
              font-weight: 400;
              color: #5d5d5d;
            }

            .bookmark-chat-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;

              .chat-details {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 6px;

                svg {
                  margin-right: 3px;
                }
              }

              .saved-bookmark-icon {
                path {
                  fill: $color-gray-dark;
                }
              }
            }
          }
        }
      }
    }
  }

  .news-Pagination {
    display: flex;
    justify-content: center;

    ul {
      li {
        margin-right: 4px;

        @media (max-width: 379px) {
          margin-right: 0px;
        }

        .MuiPaginationItem-root {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          width: 36px;
          height: 36px;
          border-radius: 50%;

          @media (max-width: 799px) {
            font-size: 14px;
            width: 24px;
            height: 24px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
            width: 22px;
            height: 22px;
          }

          svg {
            width: 36px;
            height: 36px;
            font-size: 18px;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }
          }
        }

        .Mui-selected {
          background-color: #d4d6d8;
          width: 45px;
          height: 45px;

          @media (max-width: 799px) {
            font-size: 14px;
            width: 24px;
            height: 24px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }

  // h2h info

  .h2h-wrap {
    background-color: transparent;
    box-shadow: none;
  }

  .h2h-sport-table-wrap {
    padding: 18px 33px 0px;
    // background-color: $color-White;
    // box-shadow: 0px 3px 9px 0px #0000000d;
    // margin: 0px 12px 15px;
    // border-radius: 8px;
    margin: 0px;

    @media (max-width: 1023px) {
      padding: 9px 0px;
    }

    .table-header-wrap {
      // border-bottom: 1px solid $color-Accent-1;
      padding-bottom: 2px;

      .start-time-date {
        font-size: 14px;
        line-height: 17px;
        color: $color-Black;
        font-weight: 500;
      }
    }

    .h2h-sport-table {
      background-color: $color-White;
      box-shadow: 0px 3px 9px 0px #0000000d;
      border-radius: 8px;

      .MuiTableCell-head {
        padding: 5px 0px;
        border-bottom: none;
        font-size: 14px;
        line-height: 17px;
        font-weight: 700;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: $color-Black;
      }

      .table-head {
        text-align: center;
        text-transform: uppercase;
        width: 160px;
      }

      .table-indicator-wrap {
        font-size: 11.42px;
        line-height: 15px;
        padding: 5px 10px;
        font-family: $primaryFont;
        font-weight: 400;
        color: $color-White;
        background-color: $color-Accent-1;
        border-radius: 23px;
        min-width: 30px;
        text-align: center;
        width: max-content;
        margin-left: 33px;
      }

      .MuiTableCell-body {
        padding: 19px 0px 11px;
        border-bottom: none;
      }

      .border-bottom {
        border-bottom: 1px solid $color-Light-grey !important;
      }

      .team-wrap {
        .team-wrap-box {
          column-gap: 12px;
          padding-left: 33px;

          @media (max-width: 1023px) {
            padding: 0px 18px;
            justify-content: space-between;
          }

          @media (max-width: 799px) {
            padding: 0px 12px;
          }

          .team-name {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-Black;
          }
        }
      }

      .hometeam-row {
        .team-wrap {
          border-bottom: 1px solid $color-Light-grey;
        }
      }

      .odds-wrap-info {
        column-gap: 12px;
        justify-content: center;

        .points {
          span {
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            color: $color-Black;
            font-family: $regulerFont;
            letter-spacing: 0px;
          }
        }

        .win-loss {
          padding: 4px 0px;
          border-radius: 4px;
          color: $color-White;
          font-size: 16px;
          line-height: 19px;
          font-weight: 500;
          letter-spacing: 0px;
          font-family: $regulerFont;
          min-width: 28px;
          text-align: center;
        }

        .win {
          background-color: #1c9a6c;
        }

        .loss {
          background-color: #d84727;
        }

        .draw {
          background-color: #ff6b00;
        }
      }
    }

    .mobileview-h2h-table-wrap {
      background-color: $color-White;
      box-shadow: 0px 3px 9px 0px #0000000d;
      border-radius: 8px;

      .match-time-date {
        .h2h-des-label {
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
          letter-spacing: 0px;
          margin-top: 4px;
        }
      }

      .teams-details {
        justify-content: space-around;
        padding: 22.4px 0px 17px;

        .team-wrap {
          .team-img-wrap {
            display: block;
            margin: 0px auto;
          }

          .teams-details-title {
            .team-name {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0px;
              font-weight: 400;
              font-family: $primaryFont;
              color: $color-Black;
              margin-top: 11.8px;
            }
          }
        }

        .vs {
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-Black;
        }
      }

      .h2h-full-time-wrap {
        .full-time-wrap {
          padding: 6px 0px;
          text-align: center;
          background-color: #e7e9ec;
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 600;
          text-transform: uppercase;
          color: $color-Black;
          font-family: $regulerFont;
        }

        .mobile-h2h-odds {
          justify-content: space-between;

          .h2h-odds-left {
            width: 50%;
            justify-content: center;
            padding: 19px 0px 14px;
            border-right: 0.6px solid #d4d6d8;
          }

          .h2h-odds-right {
            width: 50%;
            justify-content: center;
            padding: 19px 0px 14px;
          }

          .points {
            span {
              font-size: 16px;
              line-height: 19px;
              font-weight: 600;
              color: $color-Black;
              font-family: $regulerFont;
              letter-spacing: 0px;
              margin-right: 12px;
            }
          }

          .win-loss {
            padding: 4px 0px;
            border-radius: 4px;
            color: $color-White;
            font-size: 16px;
            line-height: 19px;
            font-weight: 500;
            letter-spacing: 0px;
            font-family: $regulerFont;
            min-width: 28px;
            text-align: center;
          }

          .win {
            background-color: #1c9a6c;
          }

          .loss {
            background-color: #d84727;
          }
        }
      }
    }
  }

  // odds

  .teamsports-seeall-tablewrap {
    padding: 0px 33px;
    // background-color: $color-White;
    // box-shadow: 0px 3px 9px 0px #0000000d;
    position: relative;

    @media only screen and (max-width: 599px) {
      padding: 0px 12px;
    }

    .responsive-table-wrap {
      @media only screen and (max-width: 799px) {
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000000d;
        border-radius: 8px;
        overflow: hidden;
      }
    }

    .table-header-wrap {
      // border-bottom: 1px solid $color-Accent-1;
      display: flex;
      justify-content: space-between;
      height: 28px;
      align-items: center;
      padding-bottom: 2px;

      @media (max-width: 799px) {
        height: 25px;

        padding: 7px 6px 5px 12px;
        margin-bottom: 0px;
      }

      @media (max-width: 479px) {
        flex-wrap: wrap;
        margin-bottom: 5px;
      }

      .table-indicator-wrap {
        display: flex;
        align-items: center;
      }

      h1 {
        font-size: 22.4px;
        line-height: 31.36px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Black;

        @media only screen and (max-width: 599px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .markets {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: $color-Black;
        padding-left: 3px;
        font-family: $regulerFont;

        @media only screen and (max-width: 479px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .show-update {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: $color-Black;
        cursor: pointer;

        @media only screen and (max-width: 479px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .start-time-date {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        font-family: $regulerFont;

        @media only screen and (max-width: 479px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .currentbest-seeall {
        display: flex;

        .teamsports-seeall {
          margin-left: 13px;

          cursor: pointer;

          h1 {
            color: $color-Accent-1;
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            text-decoration: underline;
            font-family: $regulerFont !important;

            @media (max-width: 1023px) {
              font-size: 12px;
              line-height: 15px;
            }
          }
        }

        .seeall-arrow-wrap {
          display: flex;
          column-gap: 4px;
          margin-left: 15px;

          .seeall-arrow {
            cursor: pointer;
            height: 17px;
            width: 17px;
          }

          .left-arrow {
            transform: rotate(180deg);
          }

          .disable {
            opacity: 0.4;
          }
        }
      }

      .show-update-wrap {
        display: flex;
        align-items: center;
        column-gap: 9px;

        .show-update-checkbox {
          margin: 0px;

          .MuiIconButton-root {
            padding: 0px;
          }
        }
      }
    }

    .table-seeall-container {
      background-color: $color-White;
      box-shadow: 0px 3px 9px 0px #0000000d;
      border-radius: 8px;

      .overlay::after {
        content: "";
        position: absolute;
        width: 65px;
        height: calc(100%);
        z-index: 9;
        right: 0;
        background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
      }

      .see-best {
        position: absolute;
        color: $color-Accent-1;
        z-index: 99;
        right: 8px;
        top: -72px;
        text-decoration: underline;
        font-family: $primaryFont;
        font-size: 22.4px;
        line-height: 14px;
        font-weight: 400;
        cursor: pointer;

        @media only screen and (max-width: 1023px) {
          top: -10px;
        }
      }

      .individual-table {
        min-width: 700px;

        @media (max-width: 799px) {
          min-width: 500px;
        }

        .MuiTableCell-root {
          border-bottom: none;
        }

        .individual-team-head {
          .oddstype {
            .select__single-value {
              color: $color-Black;
            }

            // .select__menu{
            //   z-index: 99;
            // }
          }

          .MuiTableCell-head {
            padding: 7px 12px 4px;
            line-height: 0px;
            border: 1px solid $color-Light-grey;
            border-collapse: collapse;
            text-align: center;
            color: $color-Black;

            .current-best-odds-icon {
              .odd-img {
                width: 60px;
                height: 25px;
                margin: 0 auto;

                @media (max-width: 660px) {
                  // max-width: 54px;
                  width: 54px;
                }

                @media (max-width: 389px) {
                  width: 48px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                  border-radius: 8px;
                }
              }
            }
          }

          .MuiTableCell-head:first-child {
            text-align: left;
            padding: 7px 12px 4px 33px;
            border-right: none;
            border-left: none;
            border-bottom: none;

            .live-indicator {
              padding: 3px 11px;
              background-color: $color-Negative;
              color: $color-White;
              font-size: 12px;
              line-height: 15px;
              font-weight: 600;
              border-radius: 3px;
              margin-right: 13px;
            }

            @media (max-width: 799px) {
              padding: 7px 12px 4px 12px;
            }
          }
        }

        .individual-team-body {
          .MuiTableCell-root {
            padding: 10px 0px;
          }

          .multiple-team-row {
            .MuiTableCell-root {
              padding: 18px 3.5px;
            }
          }

          .time-market {
            font-family: $regulerFont;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            padding: 14px 0px 2px;
            color: $color-Black;

            @media only screen and (max-width: 599px) {
              font-size: 11px;
              line-height: 14px;
            }
          }

          .team-wrap {
            display: flex;
            align-items: center;
            padding-left: 33px;

            @media only screen and (max-width: 599px) {
              padding-left: 12px;
            }

            .team-img-wrap {
              width: 45px;
              height: 45px;

              @media (max-width: 799px) {
                width: 32px;
                height: 32px;
              }

              .team-img {
                border-radius: 50%;
                width: 45px;
                height: 45px;
                object-fit: contain;

                @media (max-width: 799px) {
                  width: 32px;
                  height: 32px;
                }
              }
            }

            .mr-6 {
              margin-right: 6px;
            }

            .team-name {
              margin-left: 4px;
              font-family: $regulerFont;
              font-size: 16px;
              line-height: 20px;
              font-weight: 600;
              color: $color-Black;
              margin-right: 6px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;

              @media only screen and (max-width: 799px) {
                font-size: 14px;
                line-height: 17px;
              }
            }
          }

          .individual-tablecell {
            padding: 0px 3.5px;
            border: 1px solid $color-grey;
            border-collapse: collapse;

            .odds-point {
              text-align: center;
              font-size: 16px;
              line-height: 20px;
              color: $color-Black;
              padding-bottom: 5px;

              @media only screen and (max-width: 599px) {
                font-size: 11.42px;
                line-height: 14px;
              }
            }

            .odds-style {
              display: block;
              cursor: pointer;
              max-width: none;
              width: 60px;
              text-align: center;
              padding: 6.5px 0px;
              border-radius: 6px;
              margin: 0px auto;
              background-color: $color-Light-grey;
              box-shadow: 0px 2px 1px 0px #707070;

              @media (max-width: 660px) {
                padding: 6.5px 0px;
                width: 54px;
              }

              @media (max-width: 389px) {
                width: 48px;
              }

              .odds {
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-Black;
                letter-spacing: 0px;

                @media (max-width: 660px) {
                  font-size: 11.42px;
                  line-height: 14px;
                }
              }
            }
          }

          .odds {
            // padding: 8px 0px;
            // background: #d6d9f3;
            border-radius: 6px;
            margin: 0px 3.5px;
            font-size: 16px;
            line-height: 20px;
            color: $color-Black;
            // min-width: 80px;
            max-width: 80px;
            width: auto;
            margin: 0 auto;
            display: block;
            text-align: center;
            filter: drop-shadow(0px 2px 2px #00000029);
            // margin-right: 7px;

            // @media (max-width: 1090px) {
            //   width: 55px;
            //   max-height: 30px;
            //   font-size: 11.42px;
            //   line-height: 14px;
            //   padding: 8px 0px;
            // }

            @media only screen and (max-width: 599px) {
              // padding: 8px 0px;
              // min-width: 45px;
              width: 45px;
              // max-height: 30px;
              // font-size: 11.42px;
              // line-height: 14px;
            }

            .no-odds {
              cursor: pointer;
            }

            .odds-btn {
              padding: 8px 0px;
              display: block;
              background: #d6d9f3;
              border-radius: 6px;
            }

            .odds-box-style {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;

              .odds-style {
                cursor: pointer;
                max-width: none;
                width: 60px;
                text-align: center;
                padding: 6.5px 0px;
                border-radius: 6px;
                margin: 0px auto;
                background-color: $color-Light-grey;
                box-shadow: 0px 2px 1px 0px #707070;

                @media (max-width: 660px) {
                  padding: 6.5px 0px;
                  width: 54px;
                }

                @media (max-width: 389px) {
                  width: 48px;
                }

                .odds {
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  color: $color-Black;
                  letter-spacing: 0px;

                  @media (max-width: 660px) {
                    font-size: 11.42px;
                    line-height: 14px;
                  }
                }
              }

              .active-odd-style {
                background-color: $color-Primary;

                span,
                .odds {
                  color: $color-White;
                }
              }

              .odd-img {
                width: 60px;
                height: 25px;
                margin: 0 auto;
                margin-top: 6px;

                @media (max-width: 660px) {
                  // max-width: 54px;
                  width: 54px;
                }

                @media (max-width: 389px) {
                  width: 48px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                  border-radius: 8px;
                }
              }
            }
          }

          .individual-team-row {
            position: relative;

            .show-tooltip {
              box-shadow: 10px 10px 5px 0px #0000000f;
              -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
              -moz-box-shadow: 10px 10px 5px 0px #0000000f;
              right: 0;
              // left: 134px;
              left: 133px;

              @media (max-width: 799px) {
                width: 185px;
                transform: translateX(-118%);
                // left: 35px;
                // top: -28px;
                left: 133px;
                top: 36px;
              }

              .tooltip-arrow {
                right: -10px;
                left: -12px;
                transform: rotate(90deg);

                // left: -12px;
                // transform: rotate(90deg);

                @media (max-width: 799px) {
                  left: initial;
                  // right: -12px;
                  // transform: rotate(270deg);

                  right: 50px;
                  transform: rotate(540deg);
                  top: -9px;
                }
              }
            }
          }
        }
      }
    }
  }

  .individual-head-wrap {
    column-gap: 10px;

    @media (max-width: 1023px) {
      border-bottom: 1px solid #4455c7;
      padding-bottom: 4px;
    }

    .table-indicator-wrap {
      font-size: 11.42px;
      line-height: 15px;
      padding: 5px 10px;
      font-family: $primaryFont;
      font-weight: 400;
      color: $color-White;
      background-color: $color-Accent-1;
      border-radius: 23px;
      min-width: 30px;
      text-align: center;
      width: max-content;
    }
  }

  .individual-table-mobileview {
    background: #ffffff;
    box-shadow: 0 3px 9px 0 RGB(0 0 0/5%);
    padding: 19px 18px 11px;

    .individual-table-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #4455c7;

      .current-best-head {
        display: flex;
        align-items: center;

        .current-best-mobile {
          font-size: 16px;
          line-height: 20px;
          font-family: $primaryFont;
          color: #4455c7;
          font-weight: 400;
          text-decoration: underline;
          margin-right: 6px;
        }

        .see-best {
          color: $color-Black;
          font-family: $primaryFont;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          cursor: pointer;

          // @media only screen and (max-width: 1023px) {
          //   top: -10px;
          // }
        }
      }
    }

    .oddstype {
      .select__single-value {
        color: $color-Black;
      }

      // .select__menu{
      //   z-index: 99;
      // }
    }

    .info-date-markets-section {
      font-size: 12px;
      font-family: $regulerFont;
      color: #191919;
      font-weight: 600;
      line-height: 15px;
      margin-top: 4px;
    }

    .home-team-wrap {
      padding: 28px 0px 9px;
      border-bottom: 1px solid #bdbdbf;
    }

    .away-team-wrap {
      padding: 18px 0px 11px;
    }

    .team-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      .team-img-wrap {
        width: 45px;
        height: 45px;

        .team-img {
          border-radius: 50%;
          width: 45px;
          height: 45px;
          object-fit: contain;
        }
      }

      .mr-6 {
        margin-right: 6px;
      }

      .team-name {
        margin-left: 15px;
        font-family: $primaryFont;
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        color: $color-Black;
      }

      .multipleteam-name {
        margin-left: 0px;
      }
    }

    .mobile-best5odds-wrap {
      .mobile-best5odds-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $color-attention;
        border-radius: 8px;
        color: $color-attention;
        padding: 8px;

        button {
          color: $color-attention;
        }
      }

      .next5odds-icon {
        border-bottom: none;
        padding: 21px 0px 0px 0px;
      }

      .bookmaker-thumb {
        margin: 4px auto 10px auto;
      }
    }

    .individual-team-mobile-table {
      margin-top: 21px;
    }

    .individual-team-mobile-row {
      td {
        padding: 0px 16px;
        border-bottom: none;
        text-align: center;

        .odds-point {
          padding-bottom: 5px;

          @media only screen and (max-width: 599px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }

        .odds {
          // padding: 5px 0px;
          background: #d6d9f3;
          border-radius: 6px;
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Black;
          // min-width: 45px;
          width: 45px;
          margin: 0 auto;
          display: block;
          text-align: center;
          filter: drop-shadow(0px 2px 2px #00000029);

          .no-odds {
            cursor: pointer;
          }
        }

        .odds-btn {
          padding: 5px 0px;
          display: block;
          background: #d6d9f3;
          border-radius: 6px;
        }
      }
    }

    .odds-icon-seeall {
      td {
        padding-bottom: 18px;
      }
    }
  }

  // fluctuation

  .fluctuation-wrap {
    background-color: $color-White;
    margin-bottom: 18px;
    box-shadow: 0px 3px 9px 0px #0000000d;
    padding: 0px 33px;

    @media (max-width: 1023px) {
      padding: 0px 12px;
    }

    .card-heading {
      column-gap: 6px;
      border-bottom: 1px solid #4455c7;

      .fluctuation-card-heading {
        width: 50%;
        background-color: #e7e9ec;
        padding: 5px 12px;

        .team-wrap {
          column-gap: 5px;
        }

        .fluctuation-team {
          font-family: $primaryFont;
        }
      }
    }

    .fluctuation-graph-wrap {
      width: 100%;
      padding: 27px 0px 12px;

      .fluctuation-graph {
        width: 50%;

        @media (max-width: 1023px) {
          width: 100%;
        }

        .recharts-wrapper {
          width: 100% !important;
          height: 100% !important;

          .recharts-surface {
            width: 100%;
            height: 100%;

            .recharts-cartesian-grid {
              .recharts-cartesian-grid-vertical {
                line {
                  stroke-dasharray: 0 0;
                }
              }
            }

            .recharts-yAxis {
              line {
                stroke: #d3d3d3;
              }

              .recharts-cartesian-axis-ticks {
                .recharts-layer {
                  .recharts-cartesian-axis-tick-line {
                    display: none;
                  }

                  .recharts-text {
                    fill: #191919;
                  }
                }
              }
            }
          }
        }

        .tool-tip-wrap {
          background-color: #d6d9f3;
          color: #4455c7;
          padding: 3px 6.1px;
          border-radius: 3px;
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 500;
          font-family: $regulerFont;
        }

        .odds-value-wrap {
          column-gap: 9px;
          justify-content: center;
          padding: 9px 0px 0px;

          .odds-value {
            padding: 7.5px 25px;
            background-color: #d6d9f3;
            border-radius: 6px;
            box-shadow: 0px 2px 2px 0px #0000000d;

            span {
              font-size: 16px;
              line-height: 19px;
              font-weight: 400;
              font-family: $regulerFont;
              color: #191919;
              letter-spacing: 0px;
            }

            .betslip-added {
              span {
                font-weight: 700;
                color: $color-Accent-1;
              }
            }
          }

          .odds-icon {
            width: 33px;
            height: 33px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 6px;
            }
          }
        }
      }
    }

    .lineup-tab-detail {
      width: 100%;

      .MuiTabs-scroller {
        .MuiTabs-indicator {
          display: none;
        }

        .active {
          background-color: #e7e9ec;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .MuiBox-root {
        width: 50%;

        .MuiButtonBase-root {
          width: 100%;
          min-width: -webkit-fill-available;

          .team-wrap {
            width: 100%;
            column-gap: 4px;

            .lineup-team {
              font-family: $primaryFont;
              font-size: 16px;
              line-height: 19px;
              font-weight: 400;
              color: $color-Black;
            }
          }
        }
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  // scorecard details

  .scorecard-details-wrap {
    .stats-wrap {
      padding: 12px 0px;

      .stats-text {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0px;
        font-weight: 600;
        font-family: $regulerFont;
        color: #191919;
        margin-bottom: 8px;
      }

      .stats-details {
        padding: 12px;
        border: 1px solid #d4d6d8;

        .details-warp {
          column-gap: 18px;

          &:not(:last-child) {
            margin-bottom: 2px;
          }

          .details-head {
            width: 10%;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            color: $color-Accent-1;
            font-family: $regulerFont;
          }

          .details-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: $color-Black;
            font-family: $regulerFont;

            .bold-right {
              font-weight: 600;
              margin-right: 5px;
            }
          }

          .extra-wrap {
            display: flex;
            column-gap: 15px;
          }
        }
      }
    }
  }

  .scorecard-table {
    .data-table {
      border: 1px solid #d4d6d8;

      .table-header {
        .table-head-row {
          .MuiTableCell-head {
            padding: 10px 12px;
            font-family: $regulerFont;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            color: #003764;
          }
        }
      }

      .table-body {
        .MuiTableRow-root {
          .MuiTableCell-body {
            padding: 10px 12px;
            font-family: $regulerFont;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: $color-Black;
            // position: relative;

            .player-name {
              color: $color-Accent-1;
              font-weight: 600;
            }

            .divider-indicator {
              height: 100%;
              width: 5px;
              background: $color-Primary;
              position: absolute;
              top: 0px;
              left: 0px;
              border-radius: 0px 20px 20px 0px;
            }

            .bat {
              path {
                fill: $color-White;
                stroke: $color-White;
              }
            }

            .ball {
              path {
                fill: $color-White;
                stroke: $color-White;
              }
            }

            .batsmen-name {
              align-items: flex-start;
              justify-content: space-between;
            }
          }

          .bold {
            font-weight: 600;
          }
        }
      }
    }
  }

  .summary-commentary-wrap {
    .commentary-section {
      border-radius: 12px;
      border: 1px solid #d4d6d8;
      overflow: hidden;

      .commentary-wrap {
        align-items: center;
        padding: 12px;

        &:not(:last-child) {
          border-bottom: 1px solid #d4d6d8;
        }

        .w-25 {
          width: 25%;
        }

        .w-50 {
          width: 50%;
        }

        .w-75 {
          width: 75%;
        }

        .fs-14 {
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          color: $color-Black;
          letter-spacing: 0px;

          @media (max-width: 799px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .incident {
          font-weight: 700;
        }

        @media (max-width: 799px) {
          .w-10 {
            width: 10%;
          }

          .w-35 {
            width: 35%;
          }

          .w-20 {
            width: 20%;
          }
        }

        .football-icon {
          width: 30px;
          height: 30px;
          flex-direction: column;
          justify-content: center;

          // svg {
          //   width: 100%;
          //   height: 100%;
          // }
          span {
            font-size: 10px;
            font-family: $regulerFont;
            font-weight: 400;
          }
        }

        // .type-position {
        //   position: relative;
        // }

        // .type-home {
        //   position: absolute;
        //   right: 25px;
        //   top: 50%;
        // }

        // .type-away {
        //   position: absolute;
        //   left: -25px;
        //   top: 50%;
        // }
      }

      .football-commentary-wrap {
        .width-15 {
          width: 15%;
        }

        .width-85 {
          width: 85%;
        }
      }

      .break-time {
        background-color: #d4d6d8;
      }
    }
  }
}