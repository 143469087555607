@import "../../../../assets/scss/variable.scss";

// league Accordion css
.league-data-details {
  .league-accordion-details {
    padding: 0px 0px 21px !important;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 799px) {
      padding: 0px !important;
    }

    .summary-table {
      margin-bottom: 27px;
    }

    .past-team-name {
      margin-top: 27px;
      font-size: 13px;
      line-height: 18.2px;
      font-weight: 400;
      font-family: $regulerFont;
      color: #1a73e8;

      @media (max-width: 799px) {
        margin-top: 15px;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .team-view-more {
    position: relative;
    z-index: 999;
    padding: 9px 15px;
  }
}
.series-select {
  min-width: 257px;
  margin-right: 4px;
  max-width: 257px;
  margin-top: 15px;

  @media only screen and (max-width: 1023px) {
    margin-right: 0px;
  }

  @media only screen and (max-width: 799px) {
    max-width: 100%;
  }
  .select__control {
    background-color: $color-Light-grey;
    border-radius: 8px;
    max-height: 36px;
    max-width: 400px;

    @media (max-width: 799px) {
      max-width: none;
    }

    .select__single-value {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__placeholder {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__input-container {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__indicator-separator {
      width: 0px;
    }

    .select__dropdown-indicator {
      padding: 10px;
    }
  }
  .select__menu,
  .react-select__menu {
    .select__menu-list,
    .react-select__menu-list {
      .select__option,
      .react-select__option {
        cursor: pointer;
        font-size: 16px;
        color: $color-Black;
        font-family: $regulerFont;
        line-height: 19px;
        padding: 11px;

        &.select__option--is-focused {
          background-color: #d4d6d8;
          color: #000;
        }

        &.select__option--is-selected {
          background-color: #d4d6d8;
          color: $color-Black;
        }
      }
    }

    .select__menu-list,
    .react-select__menu-list {
      .select__group {
        .select__group-heading {
          margin-bottom: 0.5rem;
          color: green;
          font-weight: bolder;
          font-size: inherit;
        }
      }
    }
  }
  .select__menu-portal {
    z-index: 99;
  }
}
