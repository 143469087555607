@import "../../../../../../assets/scss/variable.scss";


.landing-news-section {
    margin-top: 50px;

    @media (max-width:1080px) {
        margin-top: 30px;
    }


    .landing-news-img {
        min-width: 197px !important;
        max-width: 197px !important;
        height: 103px !important;
    }
}