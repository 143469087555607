@import "../../../assets/scss/variable.scss";

.comp-wrap {
  background-color: $color-off-White;

  .comp-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .page-deatils-wrap {
    padding: 18px 33px 84px 33px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 799px) {
      padding: 21px 12px 27px 12px;
      margin: 0px -12px;
    }
    .title {
      font-weight: 400;
      font-size: 22.4px;
      color: $color-Primary;
      line-height: 31.36px;
      font-family: $primaryFont;
    }

    .useredit-details {
      display: flex;
      align-items: center;

      .back-arrow {
        transform: rotate(90deg);
        margin-right: 10px;
        cursor: pointer;
      }

      h4 {
        text-decoration: underline;
      }
    }

    .comp-field-container {
      margin-top: 21px;
      width: 100%;

      .comp-image-name-wrap {
        display: flex;
        align-items: center;
        column-gap: 9px;
        margin-bottom: 50px;
        cursor: pointer;
        .comp-image-wrap {
          height: 70px;
          width: 70px;
          border-radius: 50%;
          position: relative;
          .comp-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 50%;
          }
          .add-button {
            position: absolute;
            bottom: 0px;
            right: 0px;
          }
        }
        .comp-image-text-wrap {
          margin-top: 28px;
          .image-text {
            font-size: 16px;
            line-height: 20px;
            color: $color-Accent-1;
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }
      .danger {
        color: #d84727;
      }

      .comp-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        @media (max-width: 1023px) {
          align-items: baseline;
        }

        @media (max-width: 490px) {
          display: block;
        }

        .textfield-text {
          font-size: 16px;
          font-weight: 400;
          font-family: $regulerFont;
          max-width: 221px;
          width: 100%;
        }

        .info-icon {
          height: 18px;
          width: 18px;
          cursor: pointer;
        }
        .tipping-type {
          display: flex;
          align-items: center;
        }

        .field-container {
          max-width: 957px;
          width: 100%;
          .sort-select {
            // max-width: 282px;
            // max-width: 100%;
            // width: 835px;
            // width: 748px;

            // @media only screen and (max-width: 1023px) {
            //   margin-right: 0px;
            //   max-width: none;
            // }

            // @media (max-width: 799px) {
            //   max-width: none;
            // }

            // @media only screen and (max-width: 479px) {
            //   grid-column-start: 1;
            //   grid-column-end: 4;
            // }
          }

          .comp-textfield {
            max-width: 957px;
            width: 100%;
            @media (max-width: 490px) {
              //   max-width: none;
              margin-top: 5px;
            }

            .MuiOutlinedInput-root {
              border-radius: 8px;
              font-family: "Inter", sans-serif;

              .MuiOutlinedInput-input {
                padding: 13px 11px;
              }
            }

            .Mui-focused {
              .MuiOutlinedInput-notchedOutline {
                border-color: $color-Accent-1;
                border-width: 1;
              }
            }

            svg {
              cursor: pointer;
            }
          }
          .details-textarea-field {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            font-family: "Inter", sans-serif;
            padding: 13px 11px;
            border-radius: 8px;
            border-color: $color-grey;
            outline: none;
            width: calc(100% - 22px);
            &:focus {
              border-color: $color-Accent-1;
            }
          }
        }

        .title-select-wrap {
          display: flex;
          flex-direction: column;
        }

        .select-title {
          max-width: 134px;
          width: 100%;

          @media (max-width: 490px) {
            max-width: none;
          }

          .css-1s2u09g-control {
            padding: 5px 0;
            border-radius: 8px;
            background-color: $color-off-White;
          }

          .css-1okebmr-indicatorSeparator {
            width: 0px;
          }
        }

        .select-country-state {
          max-width: 269px;
          width: 100%;

          @media (max-width: 490px) {
            max-width: none;
          }

          .css-1s2u09g-control {
            padding: 5px 0;
            border-radius: 8px;
            background-color: $color-off-White;
          }

          .css-1okebmr-indicatorSeparator {
            width: 0px;
          }
        }

        .select__control {
          box-shadow: none;

          padding: 5px 0px;
          margin: 0px;
          border-radius: 8px;

          @media (max-width: 490px) {
            margin-top: 5px;
          }
        }

        .select__control,
        .react-select__control {
          &.select__control--is-disabled {
            // border-color: $border-color;
            .select__indicator-separator {
              //   background-color: $border-color;
            }
          }

          // &.select__control--is-focused,
          // &.react-select__control--is-focused {

          // }

          &.select__control--menu-is-open {
            box-shadow: none;
            // border: none;
          }

          .select__indicator svg {
            cursor: pointer;
          }

          .select__indicator-separator {
            display: none;
          }

          .select__single-value {
            // border: none;
            font-size: 16px;
            line-height: 20px;
            padding-left: 10px;
            outline: none;
            cursor: pointer;
            margin: 0px;
            font-family: "Inter", sans-serif;
          }

          .select__value-container {
            // border: none;
            padding: 0px;
            margin: 0px;

            .select__input-container {
              font-size: 16px;
              font-family: "Inter", sans-serif;
              margin-left: 10px;
            }
          }

          .select__placeholder {
            font-size: 16px;
            line-height: 20px;
            padding-left: 10px;
            cursor: pointer;
            font-family: $regulerFont;
          }
        }

        .select__menu {
          margin: 0px;
          border-radius: 0px;
          padding: 0px;
          z-index: 999;
        }

        .select__menu-list {
          padding: 0px;
        }

        // Select Menu
        .select__menu,
        .react-select__menu {
          .select__menu-list,
          .react-select__menu-list {
            .select__option,
            .react-select__option {
              cursor: pointer;
              font-size: 16px;
              color: $color-Black;
              font-family: $regulerFont;
              line-height: 19px;
              padding: 11px;

              &.select__option--is-focused {
                background-color: $color-grey;
                color: #000;
              }

              &.select__option--is-selected {
                background-color: $color-grey;
                color: $color-Black;
              }
            }
          }

          .select__menu-list,
          .react-select__menu-list {
            .select__group {
              .select__group-heading {
                margin-bottom: 0.5rem;
                color: green;
                font-weight: bolder;
                font-size: inherit;
              }
            }
          }
        }

        .Filteritemlist-wrap {
          display: flex;
          align-items: center;
          margin-top: 9px;
          max-width: 957px;
          width: 100%;

          @media (max-width: 560px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .filter-list-racing {
            display: flex;
            align-items: center;
          }

          .purchase-info {
            font-size: 12px;
            line-height: 15px;
            @media (max-width: 799px) {
              font-size: 11.42px;
              line-height: 14px;
            }
          }

          .Filteritemlist-racing {
            display: flex;
            list-style-type: none;
            padding: 0px;
            margin: 0px;
            column-gap: 49px;
            @media (max-width: 1079px) {
              column-gap: 10px;
            }

            li {
              margin-right: 14px;

              label {
                color: $color-Black;
                font-size: 16px;
                font-weight: 400;
                font-family: $regulerFont;
                line-height: 21px;
                display: flex;
                column-gap: 12px;
                width: 210px;
                @media (max-width: 1023px) {
                  width: 150px;
                }
              }

              .MuiButtonBase-root {
                padding: 0px;
              }
            }
            .badge-icon-wrap {
              display: flex;
              align-items: center;
              column-gap: 4px;
              .subs-badge {
                height: 30px;
                width: 30px;
              }
            }
          }
          .tipping-type-racing {
            @media (max-width: 1023px) {
              display: flex;
              flex-wrap: wrap;
              row-gap: 10px;
              li {
                label {
                  width: 210px;
                }
              }
            }
          }

          // .Filteritemlist-datepicker {
          //   display: contents;

          //   .details-search-picker {
          //     @media (max-width: 560px) {
          //       min-width: 365px;
          //     }
          //   }

          //   .MuiFormControl-marginNormal {
          //     margin: 0px;

          //     @media (max-width: 560px) {
          //       margin-top: 9px;
          //     }
          //   }

          //   .MuiOutlinedInput-input {
          //     padding: 10.5px 14px;

          //     @media (max-width: 560px) {
          //       padding: 13px 14px;
          //     }
          //   }
          // }
        }
      }
      .about-comp-title {
        align-items: flex-start;
      }

      .day-time-section {
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
        margin-bottom: 18px;

        @media (max-width: 799px) {
          justify-content: flex-start;
        }

        .day-time-box {
          max-width: 957px;
          width: 100%;
          .cutoff-txt {
            color: #999999;
            font-size: 16px;
            font-weight: 400;
            font-family: $regulerFont;
            line-height: 21px;
          }

          .day-time {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 18px;
            margin-top: 6px;
            // .time-picker-class {
            //
            // }

            .border-gray {
              .MuiInputBase-root {
                border: 1px solid #cccccc !important;
              }
            }

            .comp-date-selected-wrap {
              width: 100%;
              max-width: 464px;

              @media (max-width: 1160px) {
                max-width: none;
              }

              .MuiFormControl-root {
                margin: 0px;
                width: 100%;

                @media (max-width: 1160px) {
                  width: 100%;
                }
              }

              .MuiInputBase-root {
                max-height: 40px;
                padding-right: 0px;
                border-radius: 5px;
                font-size: 14px;
                line-height: 16px;
                font-family: $regulerFont;
                font-weight: 400;
                // color: #999999;
                border-radius: 8px;
                border-color: hsl(0, 0%, 80%) !important;

                .MuiInputBase-input {
                  padding: 15px 0px 14px 8px;
                }

                .MuiOutlinedInput-notchedOutline {
                  border-color: hsl(0, 0%, 80%);
                  border-width: 1px;
                }

                &:hover {
                  border-color: #c9c9c9;
                }
              }

              .Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                  border-width: 2px;
                }
              }

              .MuiInput-underline:before {
                border-bottom: none !important;
              }

              .MuiInput-underline:after {
                border-bottom: none !important;
              }
            }

            .timePickerRoot {
              border: none !important;
            }

            .details-search-picker {
              margin: 0;

              @media only screen and (max-width: 479px) {
                grid-column-start: 1;
                grid-column-end: 3;
              }

              .MuiOutlinedInput-root {
                background: $color-Light-grey;
                border-radius: 8px;
                padding: 0;
                max-width: 180px;
                margin-right: 4px;

                @media only screen and (max-width: 1023px) {
                  max-width: initial;
                  width: 100%;
                  margin-right: 0px;
                }

                input {
                  padding: 9px 8px;
                  font-family: $regulerFont !important;
                  font-size: 16px;
                  line-height: 22.4px;
                  font-weight: 600;
                  color: $color-Black;
                }

                fieldset {
                  border: none;
                }

                .MuiIconButton-root {
                  padding: 0px 8px 0px 0px;

                  &:hover {
                    background-color: transparent;
                  }

                  .MuiTouchRipple-root {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .field-container {
          max-width: 957px;
          width: 100%;
          .sort-select {
            // max-width: 282px;
            // max-width: 100%;
            // width: 835px;
            // width: 748px;

            // @media only screen and (max-width: 1023px) {
            //   margin-right: 0px;
            //   max-width: none;
            // }

            // @media (max-width: 799px) {
            //   max-width: none;
            // }

            // @media only screen and (max-width: 479px) {
            //   grid-column-start: 1;
            //   grid-column-end: 4;
            // }
          }

          .select__control {
            // background-color: $color-Light-grey;
            border-radius: 8px;
            // max-height: 36px;

            .select__single-value {
              font-family: $regulerFont !important;
              font-size: 16px;
              line-height: 22.4px;
              // font-weight: 600;
              color: $color-Black;
              padding: 0px 8px;
            }

            .select__placeholder {
              font-family: $regulerFont !important;
              font-size: 16px;
              line-height: 22.4px;
              // font-weight: 600;
              // color: $color-Black;
              color: hsl(0, 0%, 50%);
              padding: 0px 8px;
            }

            .select__input-container {
              font-family: $regulerFont !important;
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 600;
              color: $color-Black;
              padding: 0px 8px;
            }

            .select__indicator-separator {
              width: 0px;
            }

            .select__dropdown-indicator {
              padding: 10px;
            }
          }

          .select__menu-portal {
            z-index: 99;
          }

          .select__menu,
          .react-select__menu {
            .select__menu-list,
            .react-select__menu-list {
              .select__option,
              .react-select__option {
                cursor: pointer;
                font-size: 16px;
                color: $color-Black;
                font-family: $regulerFont;
                line-height: 19px;
                padding: 11px;

                &.select__option--is-focused {
                  background-color: $color-grey;
                  color: #000;
                }

                &.select__option--is-selected {
                  background-color: $color-grey;
                  color: $color-Black;
                }
              }
            }

            .select__menu-list,
            .react-select__menu-list {
              .select__group {
                .select__group-heading {
                  margin-bottom: 0.5rem;
                  color: green;
                  font-weight: bolder;
                  font-size: inherit;
                }
              }
            }
          }
        }
      }

      .comp-button {
        display: flex;
        margin-top: 26px;

        .comp-btn {
          display: flex;
          width: 100%;
          @media (max-width: 600px) {
            display: block;
            width: 100%;
          }

          .btn-save {
            // max-width: 835px;
            width: 100%;
            background-color: $color-Accent-1;
            color: $color-White;
            border-radius: 8px;
            padding: 12px 0px;
            max-height: 45px;
            text-transform: capitalize;
            font-size: 18px;

            @media (max-width: 600px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.info-tooltip {
  background-color: $color-White !important;
  color: $color-Black;
  box-shadow: 0px 1px 9px #0000002e;
  padding: 12px !important;
  max-width: 362px !important;
  margin: 0px 14px !important;
  @media (max-width: 799px) {
    padding: 9px !important;
  }
  @media (max-width: 579px) {
    max-width: 180px !important;
  }
  .comp-type-wrap {
    font-size: 14px;
    line-height: 17px;
    margin: 7px 0px;
    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 15px;
    }
    .comp-type {
      color: $color-Primary;
      font-weight: 500;
    }
    .comp-des {
      color: $color-Black !important;
      font-weight: 400;
    }
  }
  .MuiTooltip-arrow {
    color: $color-White;
    top: 18px !important;
  }
}

div[popper="info-tooltip"] {
  top: -18px !important;
}
