@import "../../../assets/scss/variable.scss";

.d-flex {
  display: flex;
}

.text-align {
  align-items: center;
}

.content-space-betwen {
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.hide-1180 {
  display: block;

  @media (max-width: 1180px) {
    display: none;
  }
}

.show-1180 {
  display: none;

  @media (max-width: 1180px) {
    display: block;
  }
}

.mt-44 {
  margin-top: 44px;

  @media (max-width: 799px) {
    margin-top: 31px;
  }
}

.mobile-banner {
  display: none;

  @media (max-width: 799px) {
    display: block;
  }

  .banner {
    width: 100%;
  }
}

.bookmakers-data {
  padding: 28px 23px 28px 18px;

  @media (max-width: 559px) {
    padding: 37px 0px 20px;
  }

  .bredcrumn-wrap {
    margin-bottom: 3px;

    li.MuiBreadcrumbs-separator {
      color: $color-Black;
    }

    li {
      font-size: 11.42px;
      line-height: 14px;

      p {
        font-size: 11.42px;
        line-height: 14px;
        color: $color-Accent-1;
        text-transform: uppercase;
      }

      a {
        color: $color-Black;
        font-size: 11.42px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }
  }

  .bookmaker-sub-desc {
    color: $color-Primary;
    margin-bottom: 9px;
    font-weight: 600;
    letter-spacing: 0;
  }

  .page-title {
    font-size: 43.9px;
    line-height: 56px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-Black;

    @media (max-width: 799px) {
      font-size: 31.36px;
      line-height: 40px;
    }
  }

  .bookmakers-list-box {
    margin-top: 40px;

    @media (max-width: 390px) {
      margin-top: 20px;
    }

    .bookmaker-header-content {
      flex-wrap: wrap;
      gap: 10px;

      @media (max-width: 1180px) {
        flex-direction: column;
        align-items: start;
      }
    }

    .field-container {
      max-width: 200px;
      width: 100%;

      @media (max-width: 1180px) {
        max-width: 100%;
      }

      .comp-textfield {
        width: 100%;

        .MuiOutlinedInput-root {
          border-radius: 8px;
          font-family: "Inter", sans-serif;

          .MuiOutlinedInput-input {
            padding: 10px 10px 10px 0px;
          }
        }

        .Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: $color-Accent-1;
            // border-width: 1;
          }
        }

        svg {
          cursor: pointer;
        }

        .MuiInputAdornment-root {
          height: 15px;
          width: 15px;
        }
      }
    }

    .bookmakers-list {
      // margin-top: 30px;
      // padding-bottom: 6px;
      display: flex;
      justify-content: space-between;
      border: 1px solid $color-Light-grey;
      max-height: 105px;
      height: 100%;
      margin-bottom: 22px;
      border-radius: 8px;
      box-shadow: 0px 3px 9px 0px #0000000d;
      position: relative;

      @media (max-width: 1180px) {
        flex-direction: column;
        max-height: 100%;
      }

      .bookmakers-featured {
        position: absolute;
        background: $color-Secondary;
        border-radius: 14px;
        padding: 3px 9px;
        top: -13px;
        left: 32px;
        color: $color-White;
        font-weight: 500;

        @media (max-width: 1180px) {
          font-size: 12px;
        }
      }

      .image-title-box {
        display: flex;
        align-items: center;

        @media (max-width: 1180px) {
          border: 1px solid #e7e9ec;
          border-radius: 8px;
          box-shadow: 0px 3px 9px 0px #0000000d;
        }

        @media (max-width: 768px) {
          height: 70px;
        }

        .img-box {
          width: 150px;
          height: 105px;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          // overflow: hidden;
          cursor: pointer;

          @media (max-width: 768px) {
            width: 129px;
            height: 70px;
          }

          .img {
            width: 150px;
            height: 100%;
            object-fit: cover;
            border-radius: 8px 0px 0px 8px;

            @media (max-width: 768px) {
              width: 129px;
              height: 100%;
              object-fit: inherit;
            }
          }
        }

        .title {
          margin-left: 15px;
          font-size: 22.4px;
          line-height: 31.6px;
          color: $color-Primary;
          font-weight: 600;

          @media (max-width: 1180px) {
            font-size: 16px;
            line-height: 22.4px;
          }
        }

        .bookmakers-list-details {
          width: 375px;
          padding: 18px 0px;
          cursor: pointer;

          @media (max-width: 1180px) {
            width: auto;
          }
        }

        .bookmakers-list-desc {
          margin: 0px 15px;
          // word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          @media (max-width: 1180px) {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
          }
        }
      }

      .bookmakers-join-btn {
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        border-radius: 8px;
        letter-spacing: 0px;
        background: #4455c7;
        color: #fff;
        padding: 12px 24px;
        margin-bottom: 10px;
        text-transform: capitalize;

        @media (max-width: 768px) {
          padding: 8px 24px;
        }
      }

      .review-box {
        // align-self: end;
        text-align: center;
        padding: 18px 0px 18px 0px;
        width: 100%;

        @media (max-width: 1180px) {
          display: flex;
          justify-content: space-evenly;
          align-items: flex-end;
          padding: 8px 0px;
        }

        .bookmakers-review-count {
          font-size: 43.9px;
          line-height: 53.47px;
          font-weight: 500;

          @media (max-width: 1180px) {
            font-size: 31.36px;
            font-weight: 600;
            line-height: 43.9px;
          }
        }

        .review-txt {
          color: #4455c7;
          text-decoration: underline;
          font-size: 12px;
          text-transform: capitalize;
          padding: 0px;
          word-break: break-all;

          //   font-family: "Inter", sans-serif !important;
          .MuiButton-label {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }

          &:hover {
            background-color: transparent;
          }

          @media (max-width: 440px) {
            // margin-left: 12px;
            font-size: 11.42px;
          }
        }
      }

      .join-btn-wrap {
        max-width: 100px;
        width: 100%;

        @media (max-width: 1180px) {
          max-width: 100%;
          padding: 0;
        }
      }
    }

    .pagination-result-show {
      margin-left: 21px;
      color: $color-gray-dark-2;

      @media (max-width: 499px) {
        margin-left: 12px;
        font-size: 12px;
      }
    }

    .pagination-select {
      min-width: 180px;

      @media (max-width: 479px) {
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }

    .select__control {
      background-color: $color-Light-grey;
      border-radius: 8px;
      max-height: 36px;
      max-width: 180px;

      @media (max-width: 479px) {
        max-width: none;
      }

      .select__single-value {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__placeholder {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__input-container {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__indicator-separator {
        width: 0px;
      }

      .select__dropdown-indicator {
        padding: 10px;
      }
    }

    .select__menu {
      margin: 0px;
      border-radius: 0px;
      padding: 0px;
      z-index: 999;
      max-width: 180px;
      width: 100%;
    }

    .select__menu-list {
      padding: 0px;
    }

    // Select Menu
    .select__menu,
    .react-select__menu {
      .select__menu-list,
      .react-select__menu-list {
        .select__option,
        .react-select__option {
          cursor: pointer;
          font-size: 16px;
          color: $color-Black;
          font-family: $regulerFont;
          line-height: 19px;
          padding: 11px;

          &.select__option--is-focused {
            background-color: $color-grey;
            color: #000;
          }

          &.select__option--is-selected {
            background-color: $color-grey;
            color: $color-Black;
          }
        }
      }

      .select__menu-list,
      .react-select__menu-list {
        .select__group {
          .select__group-heading {
            margin-bottom: 0.5rem;
            color: green;
            font-weight: bolder;
            font-size: inherit;
          }
        }
      }
    }

    .divider {
      margin-top: 17px;
      margin-bottom: 31px;
      border-bottom: 1px solid $color-Light-grey;
    }

    .bookmakers-list:first-child {
      margin-top: 0px;
    }
  }
}

.no-data-wrap {
  .no-data {
    padding: 10px 0px 9px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: $regulerFont;
  }
}

.bookmaker-Pagination {
  display: flex;
  justify-content: center;

  ul {
    li {
      margin-right: 4px;

      @media (max-width: 379px) {
        margin-right: 0px;
      }

      .MuiPaginationItem-root {
        font-size: 18px;
        line-height: 21px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;
        width: 36px;
        height: 36px;
        border-radius: 50%;

        @media (max-width: 799px) {
          font-size: 14px;
          width: 24px;
          height: 24px;
        }

        @media (max-width: 379px) {
          font-size: 11px;
          width: 22px;
          height: 22px;
        }

        svg {
          width: 36px;
          height: 36px;
          font-size: 18px;

          @media (max-width: 799px) {
            font-size: 14px;
            width: 24px;
            height: 24px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
            width: 22px;
            height: 22px;
          }
        }
      }

      .Mui-selected {
        background-color: #d4d6d8;
        width: 45px;
        height: 45px;

        @media (max-width: 799px) {
          font-size: 14px;
          width: 24px;
          height: 24px;
        }

        @media (max-width: 379px) {
          font-size: 11px;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
