@import "../../../../assets/scss/variable.scss";

.enter-Filteritemlist-datepicker {
  display: contents;

  .details-search-picker {
    @media (max-width: 560px) {
      min-width: 365px;
    }
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
    max-width: 270px;
  }
  .MuiFormControl-marginNormal {
    margin: 0px;

    @media (max-width: 560px) {
      margin-top: 9px;
    }
  }

  .MuiOutlinedInput-input {
    padding: 10.5px 14px;

    @media (max-width: 560px) {
      padding: 13px 14px;
    }
  }
}

.sport-indicator-btn {
  font-size: 16px;
  line-height: 15px;
  padding: 6px;
  font-family: $primaryFont;
  font-weight: 400;
  color: $color-White;
  background-color: $color-Accent-1;
  border-radius: 23px;
  min-width: 30px;
  text-align: center;

  // @media (max-width:379px) {
  //   font-size: 10px;
  // }

  @media (max-width: 560px) {
    font-size: 11.42px;
  }
}
.tips-marketplace-tips-wrap {
  .marketplace-header {
    padding: 46px 18px 3px;
    margin-top: -5px;
    background-color: #fbfbfb;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .team-sport-table-wrap {
    margin-top: 18px;
    padding: 30px 33px 0px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;
    // width: max-content;

    @media (max-width: 1365px) {
      padding: 30px 12px 0px;
    }

    @media only screen and (max-width: 599px) {
      padding: 19px 12px 2px;
    }

    .table-header-wrap {
      border-bottom: 1px solid $color-Accent-1;
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 22.4px;
        line-height: 31.36px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Black;

        @media only screen and (max-width: 599px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .team-sport-table-indicator {
        display: flex;
        align-items: center;
        row-gap: 5px;
        column-gap: 21px;
        margin-bottom: 6px;

        @media (max-width: 1365px) {
          flex-direction: row-reverse;
          align-items: center;
          column-gap: 9px;
          margin-bottom: 4px;
        }
        @media (max-width: 560px) {
          column-gap: 9px;
        }

        .team-time-market {
          // width: 188px !important;

          @media (max-width: 560px) {
            font-size: 11px;
          }
        }
      }

      .currentbest-seeall {
        display: flex;

        .teamsports-seeall {
          margin-left: 13px;

          cursor: pointer;

          h1 {
            color: $color-Accent-1;
            text-decoration: underline;
          }
        }
      }
    }

    .team-sport-table {
      // border-bottom: 0.6px solid #d4d6d8;
      // min-width: 940px;
      // overflow-x: auto;

      .custom-tooltip {
        .smartboook-icon-box {
          cursor: pointer;
          background-color: #e7e9ec;
          margin-left: 9.5px;
          height: 26px;
          width: 26px;
          border-radius: 50%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          .book-icon {
            object-position: center;
          }

          .smartboook-add {
            position: absolute;
            bottom: -3px;
            right: -2px;
          }
        }
      }

      .MuiTableCell-root {
        border: none;
      }

      .MuiTableCell-head {
        font-family: $regulerFont;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $color-Black;
        text-align: center;
        padding: 14px 0px;

        @media (max-width: 1365px) {
          padding: 7px 0px;
        }
      }

      .MuiTableCell-body {
        text-align: center;
        font-family: $regulerFont;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: $color-Black;
        padding: 11.5px 0px 44px;
      }

      .MuiTableRow-head :first-child {
        text-align: left;
        width: 320px;

        @media (max-width: 1365px) {
          width: 240px;
        }
      }

      .teamsports-tablerow {
        position: relative;
      }

      .team-wrap {
        display: flex;
        align-items: center;
        margin-top: 14.5px;
        width: 300px;

        @media (max-width: 1365px) {
          width: 240px;
        }

        .team-img-wrap {
          width: 45px;
          height: 45px;

          .team-img {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            object-fit: contain;
          }
        }

        .team-name {
          margin-left: 15px;
          font-family: $primaryFont;
          font-size: 16px;
          line-height: 23px;
          font-weight: 400;
          color: $color-Black;

          @media only screen and (max-width: 599px) {
            text-align: center;
          }
        }
      }

      .hometeam-row {
        margin: 5.5px 0px 0px;
      }

      .awayteam-row {
        .team-wrap {
          margin-top: 20px;
        }

        .MuiTableCell-body {
          padding: 11.5px 0px 19px;
        }
      }

      .teamsports-tablerow:after {
        content: "";
        background-color: $color-grey;
        height: 1px;
        width: 90%;
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0 auto;
      }

      .bg-white {
        background-color: $color-White;
      }

      .hight-chart-cell {
        padding: 5px 0px !important;
        width: 300px;

        .nodata {
          width: 300px;
          margin: 0px auto;
          font-family: $regulerFont;
        }
      }
    }

    .mobileview-teamsport-table-wrap {
      padding: 18px 0px 2px;

      .time-market-team-wrap {
        .mobile-temsport-indicator {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 40px;
          position: relative;

          .live-indicator {
            padding: 3px 11px;
            background-color: $color-Negative;
            color: $color-White;
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;
            border-radius: 3px;
            margin-right: 13px;
            position: absolute;
            left: 0px;
          }
        }

        .teams-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          line-height: 20px;
          font-family: $primaryFont;
          color: $color-Black;
          padding: 19px 40px 12px;

          .team-wrap {
            display: flex;
            align-items: center;
            flex-direction: column;
            align-self: flex-start;
            width: 45%;

            .team-img-wrap {
              .team-img {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                object-fit: contain;
              }
            }

            span {
              font-size: 16px;
              line-height: 20px;
              font-family: $primaryFont;
              color: $color-Black;
            }

            .team-name {
              padding-top: 16px;
              text-align: center;
            }

            .smartbook-title {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .custom-tooltip {
              margin-top: 12px;
              .smartboook-icon-box {
                cursor: pointer;
                background-color: #e7e9ec;
                margin-left: 9.5px;
                height: 26px;
                width: 26px;
                border-radius: 50%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                .book-icon {
                  object-position: center;
                }

                .smartboook-add {
                  position: absolute;
                  bottom: -3px;
                  right: -2px;
                }
              }

              // .tooltip {
              .smart-details {
                .tooltip-txt {
                  font-size: 11.42px !important;
                  line-height: 15px !important;
                  font-family: $regulerFont !important;
                  color: #191919 !important;
                }
              }
              // }
            }
          }
        }
      }

      .odds-detail {
        padding: 5px 40px 23px;
        border-bottom: 1px solid $color-grey !important;
        position: relative;

        .odds-body {
          display: flex;
          // justify-content: space-around;
          justify-content: space-between;
          align-items: center;

          &::after {
            content: "";
            background-color: $color-grey;
            height: 100%;
            width: 0.6px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
          }

          @media (max-width: 430px) {
            // justify-content: space-between;
            justify-content: space-around;
          }
        }
      }

      .odds-details-best {
        background-color: #eeefff;
      }

      .blue-btn-flex {
        padding: 5px 126px 23px;

        @media (max-width: 1000px) {
          padding: 5px 100px 23px;
        }
        @media (max-width: 800px) {
          padding: 5px 134px 23px;
        }
        @media (max-width: 730px) {
          padding: 5px 100px 23px;
        }
        @media (max-width: 600px) {
          padding: 5px 80px 23px;
        }
        @media (max-width: 480px) {
          padding: 5px 60px 23px;
        }
        @media (max-width: 400px) {
          padding: 5px 10px 23px;
        }
      }

      .odds-after {
        border-bottom: 1px solid #d4d6d8 !important;

        &::after {
          content: "";
          background-color: $color-grey;
          height: 0.6px;
          width: 100%;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 auto;
          top: 50%;
        }
      }

      .odds-detail:last-child {
        border-bottom: none;
      }

      .mobile-chart-deatils {
        position: relative;

        .mobile-hight-chart {
          display: flex;
          align-items: center;
          justify-content: space-around;
          border: 1px solid #d4d6d8;

          &::after {
            content: "";
            background-color: $color-grey;
            height: 100%;
            width: 0.6px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
          }

          .ptb-10 {
            padding: 10px 0px;
          }

          .nodata {
            font-family: $regulerFont;
          }
        }
      }
    }

    .des-label {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      font-family: $regulerFont;
      color: $color-Black;
      margin-left: 9px;
      text-transform: uppercase;

      @media only screen and (max-width: 599px) {
        font-size: 11.42px;
        line-height: 14px;
      }
    }

    .des-head {
      background-color: #e7e9ec;
      padding: 9px 0px;
      margin-left: 0px !important;
    }

    .des-current-best {
      background-color: #4455c7;
      color: #ffffff;
    }

    .w160 {
      width: 167px;
    }

    .padding28 {
      padding: 28px 0px 28px;
    }

    .des-head-to-head {
      background-color: #003764;
      color: #ffffff;
    }

    .odds-label {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      font-family: $regulerFont;
      color: $color-Black;
      text-align: center;
      max-width: 160px;
      margin: 0px auto;

      @media only screen and (max-width: 599px) {
        font-size: 11.42px;
        line-height: 14px;
        max-width: 130px;
      }
    }

    .flex-row-rev {
      flex-direction: row-reverse !important;
      column-gap: 9px !important;
    }

    .flex-row {
      flex-direction: row !important;
      column-gap: 9px !important;
    }

    .odds-wrap {
      display: flex;
      justify-content: center;
      margin-top: 5px;
      align-items: center;
      flex-direction: column;
      row-gap: 9px;

      .odds {
        display: block;
        // padding: 8px 0px;
        // background: #d6d9f3;
        border-radius: 6px;
        font-size: 16px;
        line-height: 20px;
        color: $color-Black;
        min-width: 80px;
        text-align: center;
        filter: drop-shadow(0px 2px 2px #00000029);

        @media (max-width: 1365px) {
          min-width: 65px;
        }

        // @media (max-width:1120px) {
        //   min-width: 70px;
        // }

        @media only screen and (max-width: 599px) {
          font-size: 11.42px;
          line-height: 14px;
          min-width: 45px;
        }

        .no-odds {
          cursor: pointer;
        }

        .odds-btn {
          // padding: 8px 0px;
          display: block;
          background: #d6d9f3;
          border-radius: 6px;
        }
      }

      .odds-left {
        padding: 8px 0px;
        display: block;
        background: #d6d9f3;
        border-radius: 6px;

        margin-left: 5px;
        margin-right: 5px;

        @media (max-width: 1120px) {
          margin-right: 7px;
        }

        @media (max-width: 1025px) {
          margin-left: 0px;
          margin-right: 0px;
        }

        // @media only screen and (max-width: 599px) {
        //   margin-right: 7px;
        // }
      }

      .bookmaker-long {
        text-align: center;
        min-width: 80px;
        border-radius: 6px;
        max-width: 80px;
        min-height: 36px;
        max-height: 36px;
        height: 100%;

        @media only screen and (max-width: 599px) {
          max-width: 45px;
          min-width: 45px;
          max-height: 30px;
          min-height: 30px;
        }
      }

      .add-tip-btn {
        padding: 11px 26px 12px 25px;
        background-color: #4455c7;
        color: $color-White;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;

        @media (max-width: 579px) {
          font-size: 11.42px;
        }
      }
      .tipped-btn {
        padding: 11px 26px 12px 24px;
        background-color: #4455c7;
        color: $color-White;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        opacity: 1;
        cursor: not-allowed;
        @media (max-width: 579px) {
          font-size: 11.42px;
        }
      }
      .tipped-disable {
        padding: 11px 26px 12px 24px;
        background-color: #e7e9ec;
        color: #999999;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        cursor: not-allowed;
        border: 1px solid $color-grey;
        @media (max-width: 579px) {
          font-size: 11.42px;
        }
      }
    }

    .odds-bottom {
      margin-bottom: 21px;
    }
  }

  .mr-7 {
    margin-right: 7px;
  }

  .ml-7 {
    margin-left: 7px;
  }

  .win-odds-live {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .teamsports-seeall-tablewrap {
    padding: 0px 33px;
    background-color: $color-White;
    position: relative;

    .table-seeall-container {
      .overlay::after {
        content: "";
        position: absolute;
        width: 65px;
        height: calc(100%);
        z-index: 9;
        right: 0;
        background: transparent
          linear-gradient(90deg, #ffffff00 0%, #ffffff 100%) 0% 0% no-repeat
          padding-box;
      }

      .see-best {
        position: absolute;
        color: $color-Accent-1;
        z-index: 99;
        right: 8px;
        top: -72px;
        text-decoration: underline;
        font-family: $primaryFont;
        font-size: 22.4px;
        line-height: 14px;
        font-weight: 400;
        cursor: pointer;

        @media only screen and (max-width: 1023px) {
          top: -10px;
        }
      }

      .individual-table {
        .MuiTableCell-root {
          border-bottom: none;
        }

        .individual-team-head {
          .oddstype {
            .select__single-value {
              color: $color-Black;
            }

            // .select__menu{
            //   z-index: 99;
            // }
          }

          .MuiTableCell-head {
            padding: 20px 0px 6px;
            line-height: 0px;
            border-bottom: 1px solid $color-Accent-1;
            text-align: center;
            color: $color-Black;
          }

          .MuiTableCell-head:first-child {
            text-align: left;
          }
        }

        .individual-team-body {
          .MuiTableCell-root {
            padding: 39.5px 0px;
          }

          .multiple-team-row {
            .MuiTableCell-root {
              padding: 18px 3.5px;
            }
          }

          .time-market {
            font-family: $regulerFont;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            padding: 14px 0px 2px;
            color: $color-Black;

            @media only screen and (max-width: 599px) {
              font-size: 11px;
              line-height: 14px;
            }
          }

          .team-wrap {
            display: flex;
            align-items: center;

            .team-img-wrap {
              width: 45px;
              height: 45px;

              .team-img {
                border-radius: 50%;
                width: 45px;
                height: 45px;
                object-fit: contain;
              }
            }

            .team-name {
              margin-left: 15px;
              font-family: $primaryFont;
              font-size: 16px;
              line-height: 23px;
              font-weight: 400;
              color: $color-Black;
            }
          }

          .individual-tablecell {
            padding: 0px 3.5px;

            .odds-point {
              text-align: center;
              font-size: 16px;
              line-height: 20px;
              color: $color-Black;
              padding-bottom: 5px;

              @media only screen and (max-width: 599px) {
                font-size: 11.42px;
                line-height: 14px;
              }
            }
          }

          .odds {
            // padding: 8px 0px;
            // background: #d6d9f3;
            border-radius: 6px;
            margin: 0px 3.5px;
            font-size: 16px;
            line-height: 20px;
            color: $color-Black;
            // min-width: 80px;
            width: 80px;
            margin: 0 auto;
            display: block;
            text-align: center;
            filter: drop-shadow(0px 2px 2px #00000029);
            // margin-right: 7px;

            @media (max-width: 1090px) {
              width: 55px;
              max-height: 30px;
              font-size: 11.42px;
              line-height: 14px;
              padding: 8px 0px;
            }

            @media only screen and (max-width: 599px) {
              // padding: 8px 0px;
              // min-width: 45px;
              width: 45px;
              // max-height: 30px;
              // font-size: 11.42px;
              // line-height: 14px;
            }

            .no-odds {
              cursor: pointer;
            }

            .odds-btn {
              padding: 8px 0px;
              display: block;
              background: #d6d9f3;
              border-radius: 6px;
            }
          }

          .individual-team-row {
            position: relative;
          }

          .individual-team-row:not(:last-child)::after {
            content: "";
            background-color: $color-grey;
            height: 1px;
            width: 80%;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
            z-index: 91;
          }

          .multiple-team-row:not(:last-child)::after {
            width: 100%;
          }
        }
      }
    }
  }

  .Marketplace-select-wrap {
    padding: 18px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 799px) {
      padding: 12px;
    }

    .filter-type-select {
      max-width: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 12px;

      @media only screen and (max-width: 1023px) {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 12px;
        row-gap: 12px;
      }

      // @media only screen and (max-width: 579px) {
      //   display: unset;
      // }
      @media only screen and (max-width: 579px) {
        // display: block;
        grid-template-columns: auto auto auto;
        column-gap: 0px;
      }

      .horse-select {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 579px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .period-select {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 579px) {
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }

      .period-select-1 {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1023px) {
          display: none;
        }
      }

      .bet-type-select {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 579px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .rank-by-select {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }

      .show-select {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 3;
          grid-column-end: 4;
        }
      }
    }

    .tip-text {
      margin-top: 19px;

      .text {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        color: #989898;
        letter-spacing: 0px;
      }
    }

    .select__control {
      background-color: $color-Light-grey;
      border-radius: 8px;
      max-height: 36px;

      .select__single-value {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__placeholder {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__input-container {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__indicator-separator {
        width: 0px;
      }

      .select__dropdown-indicator {
        padding: 10px;
      }
    }

    .select__menu-portal {
      z-index: 99;
    }

    .select__menu,
    .react-select__menu {
      .select__menu-list,
      .react-select__menu-list {
        .select__option,
        .react-select__option {
          cursor: pointer;
          font-size: 16px;
          color: $color-Black;
          font-family: $regulerFont;
          line-height: 19px;
          padding: 11px;

          &.select__option--is-focused {
            background-color: #d4d6d8;
            color: #000;
          }

          &.select__option--is-selected {
            background-color: #d4d6d8;
            color: $color-Black;
          }
        }
      }

      .select__menu-list,
      .react-select__menu-list {
        .select__group {
          .select__group-heading {
            margin-bottom: 0.5rem;
            color: green;
            font-weight: bolder;
            font-size: inherit;
          }
        }
      }
    }
  }
}
