@import "../../../../assets/scss/variable.scss";

.activity-box {
  // margin: 0px 50px 0px 50px;

  @media (max-width: 630px) {
    margin: 33px 12px 0px 12px;
  }
  .activity-sec {
    margin-top: 18px;
    display: flex;
    justify-content: start;
    column-gap: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #e7e9ec;

    .user-img {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      // margin-top: -60px;

      @media (max-width: 560px) {
        height: 50px;
        width: 50px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .activity-detail {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .tip-txt {
        color: #191919;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        font-family: $regulerFont;
        span {
          font-weight: 600;
        }
      }

      .time-txt {
        color: #989898;
        font-size: 11.42px;
        line-height: 15px;
        font-weight: 400;
        font-family: $regulerFont;
      }
    }
  }
}
