@import "../../../assets/scss/variable.scss";

.bet-slip-data {
  padding: 10px;
  background-color: #e7e9ec;
  //   max-height: 806px;
  //     overflow: scroll;
  // height: calc(824px - 205px);
  // overflow-y: scroll;

  // @media (max-width: 799px) {
  //     height: calc(100vh - 205px);
  // }

  .bet-slip-data-box {
    max-width: 430px;
    margin: 0 auto;
    max-height: 806px;
    overflow-y: scroll;

    .bet-slip-single-data {
      background-color: $color-White;
      border-radius: 8px;
      // padding: 9px 6px;
      padding: 18px;
      box-shadow: 0px 1px 9px 0px #0000000d;
      max-width: 330px;
      width: 100%;
      margin: 0px auto;

      @media (max-width: 460px) {
        max-width: 300px;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .bet-slip-header-details {
        display: flex;
        margin-bottom: 9px;
        justify-content: space-between;

        .bet-slip-details {
          display: flex;

          .sport-img {
            width: 25px;
            height: 25px;
            object-fit: contain;
            margin-right: 5px;

            // img {
            //   height: 100%;
            //   width: 100%;
            // }
            svg {
              width: 100%;
              height: 100%;
            }
          }

          .text-details {
            max-width: 134px;
            margin-left: 3px;

            .bet-slip-text {
              font-size: 11.42px;
              line-height: 14px;
              font-family: $regulerFont;
              font-weight: 400;
              color: #5d5d5d;
            }

            .dark-text {
              color: $color-Black;
              font-weight: 600;
              line-height: 22.4px;
              font-size: 16px;
            }

            .mb-4 {
              margin-bottom: 4px;
            }
          }
        }

        .delete-box {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          background-color: #ccd7e0;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .stake-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;

        .fixed-select {
          max-width: 97px;
          width: 100%;
        }

        .select__control {
          background-color: $color-Light-grey;
          border-radius: 8px;
          max-height: 36px;
          max-width: 400px;

          @media (max-width: 479px) {
            max-width: none;
          }

          .select__value-container {
            padding: 0px;
          }

          .select__single-value {
            font-family: $regulerFont !important;
            font-size: 11.42px;
            line-height: 22.4px;
            font-weight: 600;
            color: $color-Black;
            padding: 0px 8px;
          }

          .select__placeholder {
            font-family: $regulerFont !important;
            font-size: 11.42px;
            line-height: 22.4px;
            font-weight: 600;
            color: $color-Black;
            padding: 0px 6px;
          }

          .select__input-container {
            font-family: $regulerFont !important;
            font-size: 11.42px;
            line-height: 22.4px;
            font-weight: 600;
            color: $color-Black;
            padding: 0px 8px;
          }

          .select__indicator-separator {
            width: 0px;
          }

          .select__dropdown-indicator {
            padding: 6px;
          }
        }

        .select__menu {
          margin: 0px;
          border-radius: 0px;
          padding: 0px;
          z-index: 999;
        }

        .select__menu-list {
          padding: 0px;
        }

        // Select Menu
        .select__menu,
        .react-select__menu {

          .select__menu-list,
          .react-select__menu-list {

            .select__option,
            .react-select__option {
              cursor: pointer;
              font-size: 11.42px;
              color: $color-Black;
              font-family: $regulerFont;
              line-height: 14px;
              padding: 11px;

              &.select__option--is-focused {
                background-color: #d4d6d8;
                color: #000;
              }

              &.select__option--is-selected {
                background-color: #d4d6d8;
                color: $color-Black;
              }
            }
          }

          .select__menu-list,
          .react-select__menu-list {
            .select__group {
              .select__group-heading {
                margin-bottom: 0.5rem;
                color: green;
                font-weight: bolder;
                font-size: inherit;
              }
            }
          }
        }

        .select__menu-portal {
          z-index: 99;
        }

        .stake-field {
          display: flex;
          align-items: center;

          .stake {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 500;
            font-family: $regulerFont;
            color: $color-Black;
          }

          .stake-input-field {
            width: 60px;

            .MuiInputBase-input {
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 500;
              font-family: $regulerFont;
              color: $color-Black;
              padding: 5px 0px;
            }

            .MuiInputAdornment-root {
              p {
                font-size: 11.42px;
                line-height: 14px;
                font-weight: 500;
                font-family: $regulerFont;
                color: $color-Black;
              }
            }

            .MuiInput-underline:before {
              border-bottom: 1px solid #e8eaec;
            }

            .MuiInput-underline:after {
              border-bottom: 1px solid #e8eaec;
            }
          }
        }

        .teamsport-stake-field {
          width: 100%;

          .stake-input-field {
            width: 100%;
          }
        }
      }

      .teamsports-stake-details {
        justify-content: center;
      }

      .odds-returns-wrap {
        margin-bottom: 9px;

        .odds-returns-top {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .odds-returns-text {
            font-size: 14px;
            line-height: 19px;
            font-weight: 500;
            font-family: $regulerFont;
            color: $color-Primary;
          }

          .mr-70 {
            margin-right: 27px;
          }

          .odds-wrap {
            width: 148px;
            text-align: center;
          }

          .returns-wrap {
            width: 118px;
          }
        }

        .odds-returns-bottom {
          border-radius: 8px;
          border: 1px solid #d6d9f3;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: 11.42px;
            line-height: 16px;
            font-weight: 600;
            font-family: $regulerFont;
            color: #000000;
            word-wrap: break-word;
          }

          .betslip-odds {
            margin-left: 4px;
          }

          .left-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            //   width: 90px;
            width: 50%;
            padding: 9px;
            border-right: 1px solid #d6d9f3;
          }

          .right-wrap {
            width: 90px;
            text-align: center;
            padding: 9px;
          }
        }
      }
    }

    .share-now-btn {
      text-align: end;

      .button-share-now {
        padding: 10px 24px 9px;
        background-color: $color-Accent-1;
        color: $color-White;
        font-size: 11.42px;
        line-height: 14px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;

        .MuiTouchRipple-root {
          display: none;
        }
      }
    }
  }
}

.bet-slip-empty {
  padding: 50px 18px 0px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bet-slip-empty-header {
    font-size: 11.42px;
    line-height: 14px;
    font-weight: 600;
    font-family: $regulerFont;
    color: $color-Black;
  }

  .empty-text {
    color: #5d5d5d;
  }
}

.bet-share-option {
  text-align: center;
  padding: 41px 20px 21.5px 20px;

  .share-header-box {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .share-header {
      font-size: 31.36px;
      line-height: 41.9px;
      font-weight: 500;
      font-family: $primaryFont;
      color: #191919;
    }

    .share-icon {
      margin-left: 7px;
    }
  }

  .share-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 551px;
    width: 100%;
    margin: 0 auto;
    column-gap: 20px;
    margin-bottom: 15px;

    @media (max-width: 1048px) {
      justify-content: center;
      max-width: 296px;
      row-gap: 11px;
      margin-top: 12px;
    }

    .copy-link {
      cursor: pointer;
    }

    .option-name {
      font-family: $regulerFont;
      font-size: 11.42px;
      line-height: 15px;
      color: #5d5d5d;
    }

    .react-share__ShareButton {
      width: 32px;
      height: 32px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .social-icon {
      cursor: pointer;
    }
  }
}