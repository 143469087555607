@import "../../../../assets/scss/variable.scss";

.custom-tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.mt-18 {
  margin-top: 18px;
}

.tipper-performance-sec {
  // margin: 12px 50px 0px 50px;
  box-shadow: 0px 1px 9px 4px #0000000d;
  padding: 33px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1110px) {
    display: block;
  }
  @media (max-width: 630px) {
    margin: 12px 12px 0px 12px;
    padding: 12px;
  }

  .left-sec {
    .graph {
      // padding: 84px 0px 34px;
      display: flex;
      justify-content: center;

      @media (max-width: 799px) {
        padding: 21px 0px 18px;
        // justify-content: start;
      }

      .recharts-wrapper {
        .recharts-surface {
          @media (max-width: 440px) {
            width: 320px;
          }
        }
      }
    }

    .profit-wrap {
      padding: 0px 33px 21px;
      display: flex;
      justify-content: start;
      column-gap: 89px;
      align-items: center;

      @media (max-width: 1250px) {
        column-gap: 46px;
      }
      @media (max-width: 1110px) {
        justify-content: center;
      }

      @media (max-width: 799px) {
        padding: 14px 0px;
        column-gap: 58px;
      }
      @media (max-width: 530px) {
        justify-content: start;
        column-gap: 52px;
      }

      .profit-section {
        width: max-content;

        .profit-text {
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-grey-Accent-1;

          @media (max-width: 460px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }

        .profit-text-bold {
          font-weight: 700;
          color: $color-Black;
        }
      }
    }

    .devider {
      height: 1px;
      width: 100%;
      background-color: $color-grey;
      margin-top: 21px;
      margin-bottom: 22px;
    }
  }
  .right-sec {
    align-self: start;
    max-width: 364px;
    width: 100%;

    @media (max-width: 1110px) {
      max-width: 100%;
      margin-top: 20px;
    }
    .right-content {
      margin-top: 8px;
      padding: 18px;
      background-color: $color-highlight;
      border-radius: 8px;

      &:first-child {
        margin-top: 0px;
      }

      .tips-detail {
        color: $color-Primary;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        font-family: $regulerFont;
      }
      .comment {
        margin-top: 9px;
        color: $color-grey-Accent-1;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;

        @media (max-width: 460px) {
          font-size: 11.42px;
          line-height: 16px;
        }
      }
      .buy-btn {
        margin-top: 7px;
        padding: 11px 26px 13px 26px;
        background-color: $color-Accent-1;
        color: $color-White;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        cursor: pointer;
        @media (max-width: 579px) {
          font-size: 11.42px;
        }
      }
    }
  }
}

.performance-table-sec {
  // margin: 12px 50px 0px 50px;
  box-shadow: 0px 1px 9px 4px #0000000d;
  // padding: 33px;
  border-radius: 8px;

  @media (max-width: 630px) {
    margin: 12px 12px 0px 12px;
  }
  .performance-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 100%;
    padding: 22px 18px 10px 18px;

    @media (max-width: 630px) {
      display: block;
    }

    .title {
      color: $color-Black;
      font-size: 22.4px;
      line-height: 31.36px;
      font-weight: 400;
      font-family: $primaryFont;
    }
    .Filteritemlist-racing {
      display: flex;
      list-style-type: none;
      padding: 0px;
      margin: 0px;

      @media (max-width: 630px) {
        margin-top: 7px !important;
      }

      li {
        margin-right: 14px;

        label {
          display: flex;
          column-gap: 5.2px;
          font-size: 12px;
          line-height: 15px;
          color: $color-Primary;
        }

        .MuiButtonBase-root {
          padding: 0px;
        }
      }
    }
  }
  .devider {
    height: 1px;
    width: 100%;
    background-color: $color-grey;
  }

  .table-content-sec {
    padding: 12px;
    .table-content {
      border: 1px solid $color-grey;

      .table-title {
        border-right: 1px solid $color-grey;
        color: $color-Primary;
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
      }
      .table-detail {
        border-right: 1px solid $color-grey;
        color: $color-Black;
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        font-family: $regulerFont;
      }
    }

    .inner-table-content {
      border-radius: 8px;
      padding: 2px;
      border: 1px solid $color-grey;
      // box-shadow: 10px 10px 5px 0px #0000000f;
      // -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
      // -moz-box-shadow: 10px 10px 5px 0px #0000000f;
      // border-collapse: separate;

      .border-right-none {
        border-right: 0px !important;
      }

      .table-title {
        border-right: 1px solid $color-grey;
        color: $color-Primary;
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
      }

      .text-center {
        text-align: center !important;
      }
      .table-detail {
        border-right: 1px solid $color-grey;
        color: $color-Black;
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;

        .chip-sec {
          text-align: center;
          max-width: fit-content;
          // margin: 0 auto;
          background-color: $color-Accent-1;
          border-radius: 23px;
          padding: 3px 6px 3px 6px;
          //   margin-left: 23px;
          .chip-txt {
            text-align: center;
            font-weight: 400;
            font-family: "VeneerClean-Soft" !important;
            color: $color-White !important;
            font-size: 16px;
            line-height: 31.36px;
            @media (max-width: 580px) {
              font-size: 11.42px;
            }
          }
        }

        span {
          color: $color-Black !important;
        }

        .time-count-txt {
          display: flex;
          justify-content: space-between;
          align-items: center;

          color: $color-Black;
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;
          font-family: $regulerFont;
        }

        .learn-more-box {
          margin-top: 6px;
          // padding: 11px 33px 0px 5px;
          .learn-more {
            text-align: start;
            text-transform: capitalize;
            color: $color-Accent-1;
            border: none;
            padding: 0;
            cursor: pointer;
            outline: none;
            text-decoration: underline;
            font-size: 14px;

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
      .continue-btn-sec {
        margin-top: 20px;
        text-align: center;
        width: 100%;

        .continue-btn {
          padding: 11px 24px 13px;
          border-radius: 8px;
          color: $color-White;
          background-color: $color-Accent-1;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          text-transform: capitalize;

          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
            padding: 12px 17px 12px;
          }
        }
      }
    }

    .event-details {
      width: 100%;
      margin-top: 26px;
      padding-bottom: 9px;
      border-bottom: 1px solid $color-grey;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-txt {
          width: 70%;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          font-family: $regulerFont;
          color: $color-Black;

          @media (max-width: 600px) {
            font-size: 14px;
            line-height: 20px;
          }

          span {
            color: $color-Accent-1;
          }
        }

        .date-txt {
          font-size: 13.42px;
          line-height: 26px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          @media (max-width: 560px) {
            font-size: 11.42px;
            line-height: 24px;
          }
        }
      }

      .profit-wrap {
        padding: 10px 0px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .align-self {
          align-self: self-start;
        }
        .profit-section {
          width: max-content;

          .profit-text {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-grey-Accent-1;

            @media (max-width: 560px) {
              font-size: 11.42px;
              line-height: 24px;
            }
          }

          .profit-text-bold {
            font-weight: 700;
            color: $color-Black;
          }

          .date-txt {
            font-size: 13.42px;
            line-height: 26px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
            @media (max-width: 560px) {
              font-size: 11.42px;
              line-height: 24px;
            }
          }
        }
      }

      .continue-btn-sec {
        text-align: center;

        .continue-btn {
          padding: 11px 24px 13px;
          border-radius: 8px;
          color: $color-White;
          background-color: $color-Accent-1;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          text-transform: capitalize;

          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
            padding: 12px 17px 12px;
          }
        }
      }
    }

    .accordion-details {
      // width: 100%;
      // padding: 8px 15px;
      padding-bottom: 9px;
      border-bottom: 1px solid $color-grey;
      // box-shadow: 1px 1px 5px 5px #0000000d;
      display: flex;
      justify-content: space-between;
      align-items: start;
      // border: 1px solid $color-Accent-1;
      // border-radius: 8px;

      .first-sec {
        .gray-txt {
          color: $color-grey-Accent-1 !important;
          font-size: 11.42px;
          line-height: 24px;
          @media (max-width: 560px) {
            font-size: 11.42px !important;
          }
        }
        .detail {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;

          // @media (max-width: 560px) {
          //   font-size: 11.42px;
          // }
        }

        .basic-flex {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 4px;
        }

        .mw-150 {
          max-width: 150px !important;
        }

        .chip-sec {
          text-align: center;
          margin-top: 8px;
          max-width: fit-content;
          // margin: 0 auto;
          background-color: $color-Accent-1;
          border-radius: 23px;
          padding: 3px 6px 3px 6px;
          //   margin-left: 23px;
          .chip-txt {
            text-align: center;
            font-weight: 400;
            font-family: "VeneerClean-Soft" !important;
            color: $color-White !important;
            font-size: 16px;
            line-height: 21px;
            @media (max-width: 580px) {
              font-size: 11.42px;
            }
          }
        }

        .txt-white {
          color: $color-White !important;
        }

        .fw-500 {
          font-weight: 500;
        }
        .fw-400 {
          font-weight: 400;
        }

        .result-chip-sec {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 6px;
          margin-top: 9px;
          padding-top: 5px;
          border-top: 1px dashed $color-Light-grey;

          .chip {
            width: 34px;
            height: 22px;
            border-radius: 10px;
            background-color: $color-Primary;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 560px) {
              width: 30px;
              height: 17px;
              border-radius: 7px;
            }
          }
        }

        .team-txt {
          color: $color-Black !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 20px !important;
        }

        .learn-more-box {
          margin-top: 6px;
          // padding: 11px 33px 0px 5px;
          .learn-more {
            text-align: start;
            text-transform: capitalize;
            color: $color-Accent-1;
            border: none;
            padding: 0;
            cursor: pointer;
            outline: none;
            text-decoration: underline;
            font-size: 14px;

            &:hover {
              background-color: transparent;
            }

            @media (max-width: 560px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    // .event-details {
    //   margin-top: 26px;
    //   padding-bottom: 9px;
    //   border-bottom: 1px solid $color-grey;
    //   .title {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;

    //     .title-txt {
    //       font-size: 16px;
    //       line-height: 24px;
    //       font-weight: 600;
    //       font-family: $regulerFont;
    //       color: $color-Black;

    //       @media (max-width: 600px) {
    //         font-size: 14px;
    //         line-height: 20px;
    //       }
    //     }

    //     .date-txt {
    //       font-size: 13.42px;
    //       line-height: 26px;
    //       font-weight: 400;
    //       font-family: $regulerFont;
    //       color: $color-Black;
    //       @media (max-width: 600px) {
    //         font-size: 11.42px;
    //         line-height: 24px;
    //       }
    //     }
    //   }

    //   .profit-wrap {
    //     padding: 10px 0px 0px 0px;
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;

    //     .profit-section {
    //       width: max-content;

    //       .profit-text {
    //         font-size: 14px;
    //         line-height: 20px;
    //         font-weight: 400;
    //         font-family: $regulerFont;
    //         color: $color-grey-Accent-1;

    //         @media (max-width: 600px) {
    //           font-size: 11.42px;
    //           line-height: 24px;
    //         }
    //       }

    //       .profit-text-bold {
    //         font-weight: 700;
    //         color: $color-Black;
    //       }
    //     }
    //   }

    //   // .pie-chart {
    //   //   display: flex;
    //   //   align-items: center;
    //   //   .success-rate-chart {
    //   //     display: flex;
    //   //     // justify-content: center;
    //   //     align-items: center;
    //   //     flex-direction: column;
    //   //     // height: 100vh; // Adjust height as needed

    //   //     .opactity {
    //   //       opacity: 0.5;
    //   //     }
    //   //     .prof-details {
    //   //       text-align: center;
    //   //       font-size: 16px;
    //   //       line-height: 22.4px;
    //   //       font-weight: 400;
    //   //       font-family: $regulerFont;
    //   //       color: $color-Accent-1;
    //   //     }
    //   //   }
    //   // }
    // }
  }
}
.dual-pie-charts {
  display: flex;
  align-items: center;
  justify-content: space-around;
  // column-gap: 20px;
  .pie-chart-container {
    .recharts-wrapper {
      transform: rotate(270deg);
    }
  }

  .opactity {
    opacity: 0.5;
  }
  .prof-details {
    text-align: center;
    font-size: 16px;
    line-height: 22.4px;
    font-weight: 400;
    font-family: $regulerFont;
    color: $color-Accent-1;

    @media (max-width: 630px) {
      font-size: 11.42px;
    }
  }
}
