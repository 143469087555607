@import "../../../assets/scss/variable.scss";
@import "../../../assets/scss/global.scss";

.maintenance-banner-box {
  background: linear-gradient(
    5deg,
    rgba(4, 17, 29, 1) 0%,
    rgba(2, 29, 51, 1) 59%
  );
  background-repeat: repeat;
  background-position: top center;
  background-size: auto;
  overflow: hidden;
  height: 100vh;

  .go-to-home-btn {
    margin-top: 18px;
    border: 1px solid $color-White;
    border-radius: 8px;
    color: $color-White;
    font-size: 14px;
    line-height: 16px;
    font-family: $regulerFont;
    font-weight: 500;
    padding: 10px 20px;

    @media (max-width: 1120px) {
      margin-top: 10px;
    }
    .MuiTouchRipple-root {
      display: none;
    }
  }

  .image-container {
    background-image: url("../../../assets/images/banner/maintenance-bg2.png");
    // background-image: url("../../../assets/images/banner/maintenance-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    .content-container {
      // max-width: 1454px;
      // width: 100%;
      // margin: 0 auto;
      // display: flex;
      // justify-content: center;
      // align-items: center;

      .players-box {
        // position: relative;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        @media (max-width: 1120px) {
          // flex-direction: column;
          display: none;
        }
        // @media (max-width: 899px) {
        //   flex-direction: column;
        // }

        @media (max-width: 899px) {
          max-width: unset;
        }

        .txt-box {
          // width: 30%;
          position: absolute;
          left: 12%;
          top: 40%;

          // @media (max-width: 1820px) {
          //   left: -30%;
          // }

          // @media (max-width: 1600px) {
          //   left: -16%;
          // }

          // @media (max-width: 1360px) {
          //   left: -10%;
          // }
          @media (max-width: 1280px) {
            left: 4%;
          }
          @media (max-width: 1120px) {
            margin-top: 140px;
            // margin-top: 25px;
            text-align: center;
            width: 100%;
          }

          .title {
            font-family: $primaryFont;
            font-size: 61.47px;
            color: $color-White;

            // line-height: 86.05px;
            @media (max-width: 1820px) {
              font-size: 55px;
            }

            @media (max-width: 1600px) {
              font-size: 45px;
            }

            @media (max-width: 1360px) {
              font-size: 34px;
            }
            @media (max-width: 1120px) {
              font-size: 31.36px;
            }
          }

          .des {
            margin-top: 23px;
            font-family: $regulerFont;
            font-size: 22.4px;
            color: $color-White;

            // line-height: 31.36px;
            @media (max-width: 1820px) {
              margin-top: 10px;
              font-size: 20px;
            }

            @media (max-width: 1600px) {
              font-size: 18px;
            }

            @media (max-width: 1360px) {
              font-size: 16px;
            }
            @media (max-width: 1120px) {
              font-size: 16px;
            }
          }
        }

        // .image-container-box {
        // width: 100%;
        .players-img {
          height: 100vh;
          // max-width: 100%;
          // max-height: 100%;

          position: absolute;
          right: 10%;

          @media (max-width: 1820px) {
            // margin-left: 100px;
            // max-width: 70%;
          }

          @media (max-width: 1580px) {
            margin-left: -10%;
          }
          @media (max-width: 1440px) {
            // margin-left: -10%;
          }
          @media (max-width: 1280px) {
            right: 8%;
          }
          @media (max-width: 1120px) {
            // object-fit: cover;
            // width: 480px;
            // height: 700px;
            // height: auto;
            margin-top: -11%;
            // position: absolute;
            bottom: 0;
          }
          @media (max-width: 768px) {
            // object-fit: cover;
            // width: 480px;
            // height: 700px;
            position: absolute;
            bottom: 0;
            height: auto;
            margin-top: -17%;
          }
          @media (max-width: 560px) {
            // object-fit: cover;
            width: 480px;
            // height: 700px;
            position: absolute;
            bottom: 0;
            height: auto;
            margin-top: -14%;
          }
          // @media (max-width: 899px) {
          //   // margin-left: 0px;
          //   width: 50%;
          //   margin-bottom: 3%;
          //   max-width: unset;
          //   // height: auto;
          //   // object-fit: cover;
          //   // overflow: hidden;
          // }
        }
        // }
      }
    }

    .responsive-content-container {
      // max-width: 1454px;
      // width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      // flex-direction: column;
      // position: relative;

      // @media (max-width: 1500px) {
      //   padding-top: 140px;
      // }

      // @media (max-width: 910px) {
      //   padding-top: 50px;
      // }
      // @media (max-width: 390px) {
      //   padding-top: 100px;
      // }

      .flex-box {
        // display: flex;
        // justify-content: center;
        // align-items: center;

        @media (max-width: 910px) {
          display: block;
          height: 100vh;
          width: 100%;
        }

        .txt-box {
          //   position: absolute;
          //   left: -40%;
          //   top: 40%;
          //   @media (max-width: 1520px) {
          //     left: -34%;
          //   }
          display: none;

          @media (max-width: 1120px) {
            display: block;
            margin-top: 50px;
            // margin-top: 163px;
            text-align: center;
          }
          @media (max-width: 490px) {
            // display: block;
            margin-top: 50px;
            // text-align: center;
          }

          .title {
            font-family: $primaryFont;
            font-size: 31.36px;
            color: $color-White;
            line-height: 20px;

            // @media (max-width: 1520px) {
            //   font-size: 55px;
            // }
            @media (max-width: 1010px) {
              font-size: 22px;
            }

            @media (max-width: 910px) {
              font-size: 21px;
            }

            @media (max-width: 860px) {
              font-size: 24px;
            }

            @media (max-width: 389px) {
              font-size: 26px;
            }
          }

          .des {
            // margin-left: 54px;
            margin-top: 13px;
            font-family: $regulerFont;
            font-size: 22.4px;
            color: $color-White;
            line-height: 40px;

            // @media (max-width: 1520px) {
            //   font-size: 20px;
            // }
            @media (max-width: 1010px) {
              font-size: 19px;
            }

            @media (max-width: 910px) {
              font-size: 18px;
            }

            @media (max-width: 860px) {
              font-size: 21px;
            }

            @media (max-width: 479px) {
              font-size: 16px;
            }
          }
        }

        .players-img-box {
          display: none;
          text-align: center;

          @media (max-width: 1120px) {
            display: block;
          }

          // @media (max-width: 860px) {
          //   display: block;
          //   position: absolute;
          //   bottom: 0px;
          // }

          .players-img {
            // height: 100vh;
            // height: 100%;
            // width: 90%;

            /* position: absolute; */
            /* bottom: 0; */
            /* top: 0; */
            /* right: 0; */
            position: absolute;
            bottom: 0;
            left: 20%;
            // justify-content: center;
            // align-items: center;
            /* left: 0; */

            // @media (max-width: 1140px) {
            //   // height: 77vh;
            //   height: 81vh;
            // }
            // @media (max-width: 860px) {
            //   width: 350px;
            //   // height: 44vh;
            //   height: 335px;
            // }
            // @media (max-width: 590px) {
            //   width: 300px;
            //   // height: 38vh;
            //   height: 285px;
            // }
            // @media (max-width: 450px) {
            //   width: 350px;
            // }
            // @media (max-width: 389px) {
            //   width: 300px;
            //   height: 40vh;
            // }
            @media (max-width: 799px) {
              left: 12% !important;
            }
            @media (max-width: 710px) {
              left: 6% !important;
            }
            @media (max-width: 520px) {
              left: 0% !important;
            }
            @media (max-width: 490px) {
              left: -10% !important;
            }
            @media (max-width: 440px) {
              left: -20% !important;
            }
            @media (max-width: 400px) {
              left: -25% !important;
            }
            @media (max-width: 380px) {
              left: -30% !important;
            }
            @media (max-width: 370px) {
              left: -35% !important;
            }
            // @media (max-width: 490px) {
            //   position: absolute;
            //   bottom: 0;
            //   right: 0%;
            // }
          }
        }
      }
    }
  }
}
