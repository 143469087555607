@import "../../../assets/scss/variable.scss";

.ourpartner-container {
  margin-top: 50px;
  padding: 30px 22px;
  background-color: $color-Light-grey;
  @media only screen and (max-width: 799px) {
    padding: 12px;
    margin: 30px -12px 0px;
  }
  .top-bookmaker {
    position: relative;
    padding-bottom: 50px;
    @media only screen and (max-width: 799px) {
      padding-bottom: 34px;
    }
    &::after {
      content: "";
      position: absolute;
      height: 3px;
      width: 100%;
      bottom: 0px;
      border-radius: inherit;
      background: linear-gradient(
        90deg,
        rgba(212, 214, 216, 0) 0%,
        rgba(212, 214, 216, 1) 50%,
        rgba(212, 214, 216, 0) 100%
      );
    }
  }
  .featured-bookmaker {
    margin-top: 30px;
    @media only screen and (max-width: 799px) {
      margin-top: 12px;
      padding-bottom: 34px;
    }
  }
  .our-partner {
    font-size: 31.36px;
    font-family: $primaryFont;
    line-height: 40px;
    padding: 0px 0px 23px;
    font-weight: normal;
    color: $color-Primary;
    margin: 0px;
    @media only screen and (max-width: 799px) {
      font-size: 22.4px;
      line-height: 28px;
      padding: 0px 0px 6px;
    }
  }
  .our-partner-logo {
    // max-width: 822px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    column-gap: 18px;
    row-gap: 35px;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 1024px) {
      column-gap: 5px;
    }

    .partner-logo {
      max-width: 192px;
      // width: 100%;
      margin: 0 auto;

      @media (max-width: 1365px) {
        max-width: 160px;
      }

      @media (max-width: 1024px) {
        max-width: 120px;
      }

      img {
        width: 100%;
        // mix-blend-mode: color-burn;
      }
    }
  }
}

h5 {
  font-size: 24px;
  text-align: center;
  margin: 15px 0px;
}

@media only screen and (max-width: 479px) {
  .ourpartner-container {
    h3 {
      font-size: 31.36px;
      line-height: 43.9px;
      margin: 27px 0px 6px 0px;
    }
  }
}

.our-partner-slider {
  .partner-logo {
    // @media (max-width:635px) {
    //   width: 120px !important;
    // }

    img {
      width: 100%;
      // mix-blend-mode: color-burn;
    }
  }

  .slick-dots {
    bottom: -25px;

    li {
      width: 8px;
      height: 8px;
      @media (max-width: 799px) {
        width: 6px;
        height: 6px;
      }

      button {
        width: 8px;
        height: 8px;
        @media (max-width: 799px) {
          width: 6px;
          height: 6px;
        }

        &::before {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          color: transparent;
          background-color: $color-Accent-1;
          opacity: 0.5;
          @media (max-width: 799px) {
            width: 6px;
            height: 6px;
          }
        }
      }
    }

    .slick-active {
      button {
        &::before {
          transform: scale(1.5);
          opacity: 1;
          background-color: #4455c7;
        }
      }
    }
  }

  .slick-active button:before {
    background-color: #4455c7;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
  .slider-img {
    width: 93% !important;
    margin: 0px auto;
  }
}
