@import "../../../../../../../assets/scss/variable.scss";

.team-deatils-wrap {
    .coach-personal-details {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;

        @media (max-width:799px) {
            font-size: 12px;
            line-height: 15px;
        }

        .bold {
            font-weight: 600;
        }
    }

    .mb-18 {
        margin-bottom: 18px;

        @media (max-width:799px) {
            margin-bottom: 15px;
        }
    }
}

.w45 {
    width: 45%;

    @media (max-width:799px) {
        width: 30%;
    }
}

.w30 {
    width: 30%;

    @media (max-width:799px) {
        width: 20%;
    }
}

.w15 {
    width: 15%;

    @media (max-width:799px) {
        width: 10%;
    }
}