@import "../../../assets/scss/variable.scss";

.profileV2-contanier {
  .bredcrumn-wrap {
    padding: 21px 0px 27px;
    @media (max-width: 799px) {
      padding: 12px 0px 5px;
    }

    li.MuiBreadcrumbs-separator {
      color: $color-Black;
    }

    li {
      font-size: 11.42px;
      line-height: 14px;

      p {
        font-size: 11.42px;
        line-height: 14px;
        color: $color-Accent-1;
        text-transform: uppercase;
      }

      a {
        color: $color-Black;
        font-size: 11.42px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }
  }
  .profile-details {
    .profile-header-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $color-Primary;
      color: $color-White;
      padding: 12px 23px;
      border-radius: 8px 8px 0px 0px;
      h3 {
        margin: 0px;
        font-size: 31.36px;
        line-height: 40px;
        font-weight: 400;
      }
      .profile-header-icon-wrap {
        position: relative;
        .profile-icon {
          background: $color-Accent-1 !important;
          // margin-left: 9px;
          border: 1px solid #fc4714;
          padding: 5px;
        }
        .profile-image {
          width: 46px;
          height: 46px;
          border: 1px solid #fc4714;
          border-radius: 50%;
        }
        .add-button {
          position: absolute;
          bottom: 0px;
          left: 29px;
          rect {
            fill: $color-Light-grey;
          }
          g {
            path {
              stroke: $color-Accent-1;
            }
          }
        }
      }
    }
    h3 {
      @media (max-width: 799px) {
        margin: 0px;
        font-size: 31.36px;
        line-height: 40px;
        font-weight: 400;
      }
    }
    .profile-details-wrap {
      display: flex;
      background-color: $color-White;
      border-radius: 0px 0px 8px 8px;
      @media (max-width: 799px) {
        display: block;
        background-color: transparent;
      }
      .profile-left {
        width: 100%;
        max-width: 248px;
        min-width: 248px;
        padding: 0px 12px 12px;
        box-shadow: 0px 1px 6px #00000026;
        border-radius: 0px 0px 0px 8px;
      }
      .profile-menu {
        .menu-wrap {
          .profile-menu-name-wrap {
            padding: 12px 0px 12px 12px;
            .edit-icon {
              margin-right: 5px;
            }
            //   .profile-menu-name {
            //   }
          }
          .submenu-wrap {
            padding: 9px 12px 9px 32px;
            border-radius: 8px;
            cursor: pointer;
            //   .submenu-name {
            //   }
            .width-max-content {
              display: block;
              width: max-content;
            }
          }
          .active-menu {
            background-color: $color-Light-grey-4;
          }
        }
        .logout-delete-wrap {
          padding-top: 33px;

          .icon-label-wrap {
            cursor: pointer;
            display: flex;
            align-items: center;
            column-gap: 5px;
            padding: 12px 0px 12px 12px;
            &:last-child {
              padding-bottom: 0px;
            }
            .icon {
              height: 16px;
              width: 16px;
              display: inline-block;
              svg {
                height: 16px;
                width: 16px;
              }
            }
            .label {
              color: $color-Accent-1;
            }
          }
          .profile-button {
            display: flex;
            margin-top: 26px;
            @media (max-width: 799px) {
              width: 100%;
            }
            .profile-btn {
              display: flex;
              width: 100%;
              column-gap: 18px;

              @media (max-width: 600px) {
                display: block;
              }

              .btn-logout {
                min-width: auto;
                width: 100%;
                border-radius: 8px;
                padding: 12px 0px;
                max-height: 45px;
                text-transform: capitalize;
                font-size: 18px;
                background-color: $color-Accent-1;
                color: $color-White;

                @media (max-width: 600px) {
                  margin-bottom: 15px;
                  font-size: 16px;
                }
              }

              .btn-delete {
                min-width: auto;
                width: 100%;

                border-radius: 8px;
                padding: 12px 0px;
                max-height: 45px;
                text-transform: capitalize;
                font-size: 18px;
                background-color: $color-White;
                border: 1px solid $color-Accent-1;
                color: $color-Accent-1;
                @media (max-width: 600px) {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
      .profile-main-section {
        width: calc(100% - 272px);
        padding: 22px 45px;
        overflow-x: auto;
        overflow-y: hidden;
        @media (max-width: 1023px) {
          padding: 18px;
        }
        @media (max-width: 799px) {
          width: auto;
          padding: 12px;
          margin-top: 18px;
          margin-bottom: 30px;
          background-color: $color-White;
          box-shadow: 0px 1px 6px #00000026;
          border-radius: 8px;
        }
        .details {
          width: 100%;
        }
        .profile-image-name-wrap {
          display: flex;
          align-items: flex-end;
          column-gap: 15px;
          padding-bottom: 27px;
          .profile-image-wrap {
            display: inline-block;
            position: relative;
            .profile-image {
              height: 98px;
              width: 98px;
              border-radius: 50%;
              border: 1px solid $color-Accent-1;
            }
            .add-button {
              position: absolute;
              bottom: 5px;
              right: 3px;
            }
          }
          .profile-link {
            color: $color-Accent-1;
            text-decoration: underline;
            cursor: pointer;
            font-weight: 600;
            padding-bottom: 3px;
          }
        }
      }
      .selected-title-wrap {
        padding: 9px 12px;
        margin: 0px -12px;
        background-color: $color-Light-grey-4;
        display: flex;
        align-items: center;
        column-gap: 5px;
        .collepse-arrow {
          path {
            stroke: $color-Primary;
          }
        }
        .open-arrow {
          transform: rotate(180deg);
          transition: all 0.3s;
        }
        .selected-title {
          color: $color-Primary;
        }
      }
    }
  }
  .regular-font {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    font-family: $regulerFont;
  }
  .regular-small-font {
    font-size: 11.42px;
    line-height: 20px;
    font-weight: 400;
    font-family: $regulerFont;
  }
  .semibold-font {
    font-weight: 600;
  }
}
