@import "../../../assets/scss/variable.scss";

.prize-content {
  @media (max-width: 799px) {
    margin: 0px -12px;
  }
}

.tipping-prize-wrap {
  .tipping-prize-data-header {
    padding: 44px 18px 0px;

    @media (max-width: 799px) {
      padding: 22px 12px 6px;
    }

    .bredcrumn-details {
      overflow-x: scroll;

      .bredcrumn-wrap {
        margin-bottom: 3px;
        min-width: max-content;

        li.MuiBreadcrumbs-separator {
          color: $color-Black;
        }

        li {
          font-size: 11.42px;
          line-height: 14px;

          p {
            font-size: 11.42px;
            line-height: 14px;
            color: $color-Accent-1;
            text-transform: uppercase;
          }

          a {
            color: $color-Black;
            font-size: 11.42px;
            line-height: 14px;
            text-transform: uppercase;
          }
        }
      }
    }

    .bredcrumn-details::-webkit-scrollbar {
      height: 0px;
    }

    .bredcrumn-details::-webkit-scrollbar-thumb {
      display: none;
    }

    h1 {
      font-size: 43.9px;
      line-height: 56px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-Black;

      @media (max-width: 799px) {
        font-size: 31.36px;
        line-height: 40px;
      }
    }
  }

  .team-sports-tab {
    padding: 19px 18px 13px;
    margin-bottom: 18px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 799px) {
      padding: 9px 12px 12px;
      margin-bottom: 12px;
    }

    .teamsport-tab-detail {
      border-bottom: 2px solid #4455c7;

      .MuiTabs-scroller {
        .MuiTabs-indicator {
          display: none;
        }

        .active {
          border-bottom: 3px solid $color-Primary;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .tab-label {
        display: flex;
        align-items: center;

        .label-name {
          font-size: 16px;
          font-family: VeneerClean-Soft !important;
          color: #191919;
          line-height: 21px;
          font-weight: 400;
        }

        .label-icon {
          margin-left: 3px;
          display: flex;
          align-items: center;

          svg {
            line {
              stroke: $color-Black;
            }

            circle {
              stroke: $color-Black;
            }

            #Rectangle_18598 {
              stroke: $color-Black;
            }
          }
        }
      }

      .active {
        .label-name {
          color: $color-Accent-1;
        }

        .label-icon {
          svg {
            #Group_40284 {
              path {
                stroke: $color-Accent-1;
                fill: transparent;
              }
            }

            #Group_40777 {
              #Path_32557 {
                stroke: $color-Accent-1;
              }

              #Path_32560 {
                fill: $color-Accent-1;
              }

              #Path_32561 {
                stroke: $color-Accent-1;
              }

              #Path_32562 {
                fill: $color-Accent-1;
              }

              #Path_32564 {
                fill: $color-Accent-1;
              }

              #Path_32566 {
                fill: $color-Accent-1;
              }
            }

            #Group_34085 {
              path {
                fill: $color-Accent-1;
              }
            }

            line {
              stroke: $color-Accent-1;
            }

            circle {
              stroke: $color-Accent-1;
            }

            #Rectangle_18598 {
              stroke: $color-Accent-1;
            }
          }
        }
      }

      .MuiBox-root {
        min-width: max-content;
      }

      .MuiTab-root {
        min-width: max-content;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: #003764;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .prize-card-wrap {
    background-color: $color-White;
    padding: 15px 18px;
    box-shadow: 0px 3px 9px #00000017;

    @media (max-width: 799px) {
      padding: 12px;
    }

    &:not(:last-child) {
      margin-bottom: 18px;

      @media (max-width: 799px) {
        margin-bottom: 12px;
      }
    }

    .competition-name {
      font-size: 31.36px;
      line-height: 40px;
      font-family: $primaryFont;
      letter-spacing: 0px;
      font-weight: 400;
      color: $color-Black;
      margin-bottom: 14px;

      @media (max-width: 799px) {
        font-size: 22.4px;
        line-height: 32px;
        margin-bottom: 6px;
      }
    }

    .about-competition {
      font-size: 16px;
      line-height: 22.4px;
      font-family: $regulerFont;
      letter-spacing: 0px;
      font-weight: 400;
      color: $color-Black;
      margin-bottom: 22px;

      @media (max-width: 799px) {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 23px;
      }
    }

    .powered-text {
      font-size: 16px;
      line-height: 19px;
      font-family: $regulerFont;
      letter-spacing: 0px;
      font-weight: 600;
      color: $color-Black;
      margin-bottom: 22px;

      @media (max-width: 799px) {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 23px;
      }
    }

    .prize-table {
      border: 1px solid #d4d6d8;
      border-collapse: collapse;

      .MuiTableBody-root {
        .MuiTableCell-body {
          font-size: 16px;
          line-height: 19px;
          font-family: $regulerFont;
          letter-spacing: 0px;
          color: $color-Black;
          border: 1px solid #d4d6d8;
          font-weight: 600;

          @media (max-width: 799px) {
            font-size: 14px;
            line-height: 18px;
          }

          &:first-child {
            background-color: #e7e9ec;
            width: 25%;
            padding: 8px 14px;

            @media (max-width: 799px) {
              width: 35%;
            }
          }

          &:not(:first-child) {
            padding: 8px 27px;
          }

          .bold-text {
            font-weight: 600;
          }

          .light-text {
            font-weight: 400;
          }
        }
      }
    }
  }

  .pagination-section {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 18px;

    @media (max-width: 799px) {
      margin-top: 30px;
      padding-bottom: 12px;
    }

    .MuiPagination-root {
      .MuiPagination-ul {
        .MuiButtonBase-root {
          width: 36px;
          height: 36px;

          @media (max-width: 799px) {
            font-size: 14px;
            width: 24px;
            height: 24px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
            width: 22px;
            height: 22px;
          }

          svg {
            width: 24px;
            height: 24px;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }

        .MuiPaginationItem-root {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          border-radius: 50%;

          @media (max-width: 799px) {
            font-size: 14px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
          }
        }
      }
    }
  }
}