@import "../../../assets/scss/variable.scss";

.odds-main-wrap {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-color: $color-off-White;

    .main-contain-wrap {
        // grid-template-columns: 285px auto;
        display: flex;
        position: relative;

        // @media (max-width: 799px) {
        //     grid-template-columns: auto;
        // }

        .left-race-bar-hideon {
            display: flex;
            align-items: center;
            column-gap: 9px;
            padding-top: 9px;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 999;

            .left-side-arrow-wrap {
                width: 45px;
                height: 36px;
                background-color: $color-Primary;
                border-radius: 0px 8px 8px 0px;
                text-align: center;
                cursor: pointer;

                svg {
                    margin-top: 7px;
                    transform: rotate(180deg);
                }

                .right-arrow {
                    transform: rotate(0deg);
                }
            }

            .hide-text {
                font-size: 16px;
                line-height: 19px;
                font-style: $regulerFont;
                font-weight: 400;
                letter-spacing: 0px;
                color: $color-gray-dark;
            }
        }

        .left-contain-wrap {
            max-width: 320px;
            min-width: 320px;
            padding-top: 45px;
            transition: transform 0.5s ease, opacity 0.5s ease;
            height: calc(100vh - 110px);

            @media (max-width: 799px) {
                display: none;
            }
        }

        .left-hide {
            max-width: 0;
            min-width: 0;
            transform: translateX(-100%);
            opacity: 0;
        }

        .right-contain-wrap {
            padding: 12px 18px 0px;
            width: calc(100% - 356px);
            height: calc(100vh - 65px);
            overflow: auto;

            @media (max-width: 799px) {
                width: calc(100% - 0px);
                padding: 12px 0px 8px 0px;
            }
        }

        .right-hide {
            width: 100%;

            @media (max-width: 799px) {
                width: 100%;
            }
        }
    }

    .left-side-mobile {
        display: none;

        @media (max-width: 799px) {
            display: block;
            width: 43px;
            height: 36px;
            background-color: $color-Accent-1;
            border-radius: 0px 8px 8px 0px;
            position: fixed;
            top: 85%;
            left: 0px;
            text-align: center;
            cursor: pointer;
            z-index: 99999;

            svg {
                margin-top: 7px;
            }
        }
    }


}

.left-side-collapse {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #e6eefa;
    z-index: 99;
    background-color: #00000063;
    width: 100%;

    .MuiCollapse-wrapper {
        width: 310px;
    }

    .menu-close-icon {
        text-align: right;
        cursor: pointer;

        svg {
            path {
                fill: $color-White;
            }
        }
    }

    .left-side-mobile {
        width: 43px;
        height: 36px;
        background-color: $color-Accent-1;
        border-radius: 0px 8px 8px 0px;
        text-align: center;
        margin-top: 5px;
        cursor: pointer;

        svg {
            margin-top: 7px;
        }

        .left-side-mobile {
            width: 43px;
            height: 36px;
            background-color: $color-Accent-1;
            border-radius: 0px 8px 8px 0px;
            text-align: center;
            margin-top: 5px;
            cursor: pointer;

            svg {
                margin-top: 7px;
            }
        }

    }


}

// Modal Design 

.odds-Comparison-modal {

    .MuiDialog-paper {
        max-width: 1122px;
        width: 100%;
        margin: 12px;
        border-radius: 8px;

        .modal-title {
            background-color: #E8EAEC;
            padding: 21px 18px 9px;
            border-radius: 8px 8px 0px 0px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width:799px) {
                padding: 12px 12px 12px;
            }

            .title {
                font-size: 31.36px;
                line-height: 31.36px;
                font-weight: 400;
                font-family: $primaryFont;
                color: $color-Black;

                @media (max-width:799px) {
                    font-size: 22.4px;
                    line-height: 28px;
                }
            }

            .MuiButtonBase-root {
                padding: 0px;

                svg {
                    path {
                        fill: #989898;
                    }
                }
            }

            .close-icon {
                position: absolute;
                top: 18px;
                right: 18px;

                @media (max-width:799px) {
                    top: 12px;
                    right: 12px;
                }
            }
        }

        .odds-Comparison-modal-details {
            padding: 26px 18px 27px;

            @media (max-width:799px) {
                padding: 10px 12px 15px;
            }

            .select__control {
                background-color: $color-Light-grey;
                border-radius: 8px;
                max-height: 45px;
                max-width: 400px;

                .select__single-value {
                    font-family: $regulerFont !important;
                    font-size: 16px;
                    line-height: 22.4px;
                    font-weight: 400;
                    color: $color-Black;
                    padding: 0px 8px;
                }

                .select__placeholder {
                    font-family: $regulerFont !important;
                    font-size: 16px;
                    line-height: 22.4px;
                    font-weight: 400;
                    color: $color-Black;
                    padding: 0px 8px;
                }

                .select__input-container {
                    font-family: $regulerFont !important;
                    font-size: 16px;
                    line-height: 22.4px;
                    font-weight: 400;
                    color: $color-Black;
                    padding: 0px 8px;
                }

                .select__indicator-separator {
                    width: 0px;
                }

                .select__dropdown-indicator {
                    padding: 10px;
                }
            }

            .select__menu-portal {
                z-index: 99;
            }

            .select__control,
            .react-select__control {
                &.select__control--is-disabled {

                    // border-color: $border-color;
                    .select__indicator-separator {
                        //   background-color: $border-color;
                    }
                }

                // &.select__control--is-focused,
                // &.react-select__control--is-focused {

                // }

                &.select__control--menu-is-open {
                    box-shadow: none;
                    // border: none;
                }

                .select__indicator svg {
                    cursor: pointer;
                }

                .select__indicator-separator {
                    display: none;
                }

                .select__single-value {
                    // border: none;
                    font-size: 16px;
                    line-height: 20px;
                    padding-left: 10px;
                    outline: none;
                    cursor: pointer;
                    margin: 0px;
                    font-family: "Inter", sans-serif;
                }

                .select__value-container {
                    // border: none;
                    padding: 0px;
                    margin: 0px;

                    .select__input-container {
                        font-size: 16px;
                        font-family: "Inter", sans-serif;
                        // margin-left: 10px;
                    }
                }

                .select__placeholder {
                    font-size: 16px;
                    line-height: 20px;
                    padding-left: 10px;
                    cursor: pointer;
                    font-family: $regulerFont;
                }
            }

            .select__menu {
                margin: 0px;
                border-radius: 0px;
                padding: 0px;
                z-index: 999;
            }

            .select__menu-list {
                padding: 0px;
            }

            // Select Menu
            .select__menu,
            .react-select__menu {

                .select__menu-list,
                .react-select__menu-list {

                    .select__option,
                    .react-select__option {
                        cursor: pointer;
                        font-size: 16px;
                        color: $color-Black;
                        font-family: $regulerFont;
                        line-height: 19px;
                        padding: 11px;

                        &.select__option--is-focused {
                            background-color: $color-grey;
                            color: #000;
                        }

                        &.select__option--is-selected {
                            background-color: $color-grey;
                            color: $color-Black;
                        }
                    }
                }

                .select__menu-list,
                .react-select__menu-list {
                    .select__group {
                        .select__group-heading {
                            margin-bottom: 0.5rem;
                            color: green;
                            font-weight: bolder;
                            font-size: inherit;
                        }
                    }
                }
            }
        }
    }
}

// custom dropdown 

.custom-dropdown {
    position: relative;

    .dropdown-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 12px 7px;
        background-color: #DFE0EA;
        cursor: pointer;

        .header-details {
            display: flex;
            align-items: center;
            column-gap: 9px;

            .country-name {
                font-size: 14px;
                line-height: 16px;
                font-weight: 600;
                font-family: $regulerFont;
                color: $color-Black;
                letter-spacing: 0px;
                text-transform: capitalize;
            }
        }

        .MuiFormControlLabel-root {
            margin: 0px;

            .MuiButtonBase-root {
                padding: 0px;
            }
        }
    }

    .dropdown-options {
        position: absolute;
        z-index: 9;
        background: #E7E9EC;
        width: calc(100% - 60px);
        top: 36px;
        right: 0px;
        padding: 10px 12px;
        border-radius: 8px;
        height: 216px;
        overflow-y: scroll;

        .options-list {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:not(:last-child) {
                margin-bottom: 9px;
            }

            .MuiFormControlLabel-root {
                margin: 0px;

                .MuiButtonBase-root {
                    padding: 0px;
                }
            }

            .state-name {
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                color: $color-Black;
                font-family: $regulerFont;
                letter-spacing: 0px;
                text-transform: capitalize
            }
        }
    }

    .dropdown-options-state {
        background: $color-White;
        padding: 0px;
        width: calc(100% - 20px);
        box-shadow: 0px 0px 2px 0px #00000033;

        .state-inner-dropdown {
            padding: 7px 8px 6px;
            box-shadow: 0px 0px 2px 0px #00000033;
            cursor: pointer;

            .state-header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .state-header-details {
                    display: flex;
                    align-items: center;
                    column-gap: 5px;

                    .state-name {
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 600;
                        font-family: $regulerFont;
                        color: $color-Black;
                        letter-spacing: 0px;
                        text-transform: capitalize;
                    }
                }

                .MuiFormControlLabel-root {
                    margin: 0px;

                    .MuiButtonBase-root {
                        padding: 0px;
                    }
                }
            }

            .tracks-dropdown {

                .tracks-list {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 7px 0px;

                    .track-name {
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 600;
                        font-family: $regulerFont;
                        color: $color-Black;
                        letter-spacing: 0px;
                        text-transform: capitalize;
                    }

                    .MuiFormControlLabel-root {
                        margin: 0px;

                        .MuiButtonBase-root {
                            padding: 0px;
                        }
                    }
                }
            }
        }

        .open {
            background: #E7E9EC;
        }
    }
}