@import "../../../assets/scss/variable.scss";

.comman-news-comp.individual-news-cont {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 18px;
  @media (max-width: 799px) {
    grid-template-columns: auto;
  }
  .news-tag-relative {
    position: relative;
    .news-img-container {
      position: relative;
      overflow: hidden;
    }
    .news-tag {
      padding: 3px 6px;
      background-color: #fc4714;
      color: #ffffff;
      font-size: 11.42px;
      line-height: 14px;
      font-weight: 400;
      font-family: "Inter", sans-serif;
      max-width: -moz-fit-content;
      max-width: fit-content;
      position: absolute;
      left: 9px;
      bottom: 9px;
      top: unset;
      text-transform: capitalize;

      @media (max-width: 1024px) {
        bottom: 15px;
        top: unset;
      }

      @media (max-width: 560px) {
        left: 6px;
        bottom: 6px;
      }
    }
  }
}
.comman-news-comp.single-news-comp {
  grid-template-columns: auto;
}
