@import "../../../../assets/scss/variable.scss";

.marketplace-tips-wrap {
  .marketplace-header {
    padding: 46px 18px 3px;
    margin-top: -5px;
    background-color: #fbfbfb;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .Marketplace-select-wrap {
    padding: 18px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 799px) {
      padding: 12px;
    }

    .filter-type-select {
      max-width: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 12px;

      @media only screen and (max-width: 1040px) {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 12px;
        row-gap: 12px;
      }

      @media only screen and (max-width: 479px) {
        grid-template-columns: auto auto;
        column-gap: 0px;
      }

      .horse-select {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1040px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .period-select {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1040px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }

      .period-select-1 {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1040px) {
          display: none;
        }
      }

      .period-select-2 {
        @media only screen and (max-width: 1320px) {
          display: none;
        }
      }

      .bet-type-select {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1040px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 3;
          grid-column-end: 4;
        }
      }

      .rank-by-select {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1040px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }

      .show-select {
        max-width: 257px;
        margin-right: 4px;
        width: 100%;

        @media only screen and (max-width: 1040px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 3;
          grid-column-end: 4;
        }
      }
    }

    .selling-checkbox {
      margin: 0px;

      @media (max-width: 1023px) {
        margin-top: 12px;
      }

      .MuiButtonBase-root {
        padding: 0px 0px 0px 8px;
        margin-right: 9px;

        @media (max-width: 1023px) {
          padding: 0px;
        }
      }

      .MuiTypography-root {
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: #003764;
      }
    }

    .tip-text {
      margin-top: 19px;

      @media (max-width: 799px) {
        margin-top: 15px;
      }

      .text {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        color: #989898;
        letter-spacing: 0px;

        @media (max-width: 799px) {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }

    .select__control {
      background-color: $color-Light-grey;
      border-radius: 8px;
      max-height: 36px;

      .select__single-value {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__placeholder {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__input-container {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__indicator-separator {
        width: 0px;
      }

      .select__dropdown-indicator {
        padding: 10px;
      }
    }

    .select__menu-portal {
      z-index: 99;
    }

    .select__menu,
    .react-select__menu {
      .select__menu-list,
      .react-select__menu-list {
        .select__option,
        .react-select__option {
          cursor: pointer;
          font-size: 16px;
          color: $color-Black;
          font-family: $regulerFont;
          line-height: 19px;
          padding: 11px;

          &.select__option--is-focused {
            background-color: #d4d6d8;
            color: #000;
          }

          &.select__option--is-selected {
            background-color: #d4d6d8;
            color: $color-Black;
          }
        }
      }

      .select__menu-list,
      .react-select__menu-list {
        .select__group {
          .select__group-heading {
            margin-bottom: 0.5rem;
            color: green;
            font-weight: bolder;
            font-size: inherit;
          }
        }
      }
    }
  }

  .tips-wrap {
    padding: 18px;

    .tips-wrap-grid {
      display: grid;
      grid-template-columns: 49% 49%;
      column-gap: 18px;
      row-gap: 18px;

      @media (max-width: 1023px) {
        grid-template-columns: auto;
      }
    }

    .tips-Pagination {
      display: flex;
      justify-content: center;
      padding: 50px 0px 0px;

      @media (max-width: 799px) {
        padding: 33px 0px 0px;
      }

      ul {
        li {
          margin-right: 4px;

          @media (max-width: 379px) {
            margin-right: 0px;
          }

          .MuiPaginationItem-root {
            font-size: 18px;
            line-height: 21px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
            width: 36px;
            height: 36px;
            border-radius: 50%;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }

            svg {
              width: 36px;
              height: 36px;
              font-size: 18px;

              @media (max-width: 799px) {
                font-size: 14px;
                width: 24px;
                height: 24px;
              }

              @media (max-width: 379px) {
                font-size: 11px;
                width: 22px;
                height: 22px;
              }
            }
          }

          .Mui-selected {
            background-color: #d4d6d8;
            width: 45px;
            height: 45px;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }
}
