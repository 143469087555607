@import "../../../../assets/scss/variable.scss";

.purchase-history-wrapper {
  .filter-section {
    max-width: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 12px;

    @media only screen and (max-width: 1023px) {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 12px;
      row-gap: 12px;
    }

    @media only screen and (max-width: 479px) {
      grid-template-columns: auto auto;
    }

    .sort-select {
      max-width: 282px;
      margin-right: 4px;
      min-width: 180px;

      @media only screen and (max-width: 1023px) {
        margin-right: 0px;
        max-width: none;
      }

      @media (max-width: 799px) {
        max-width: none;
      }

      @media only screen and (max-width: 479px) {
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }

    .mw-none {
      width: 100%;
    }

    .select__control {
      background-color: $color-Light-grey;
      border-radius: 8px;
      max-height: 36px;

      .select__single-value {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__placeholder {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__input-container {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__indicator-separator {
        width: 0px;
      }

      .select__dropdown-indicator {
        padding: 10px;
      }
    }

    .select__menu-portal {
      z-index: 99;
    }

    .select__menu,
    .react-select__menu {
      .select__menu-list,
      .react-select__menu-list {
        .select__option,
        .react-select__option {
          cursor: pointer;
          font-size: 16px;
          color: $color-Black;
          font-family: $regulerFont;
          line-height: 19px;
          padding: 11px;

          &.select__option--is-focused {
            background-color: #d4d6d8;
            color: #000;
          }

          &.select__option--is-selected {
            background-color: #d4d6d8;
            color: $color-Black;
          }
        }
      }

      .select__menu-list,
      .react-select__menu-list {
        .select__group {
          .select__group-heading {
            margin-bottom: 0.5rem;
            color: green;
            font-weight: bolder;
            font-size: inherit;
          }
        }
      }
    }
  }
  .payment-section-wrapper {
    margin-top: 20px;

    .payment-table-container {
      border-radius: 8px;
      box-shadow: 0px 3px 6px 0px #00000033;
      background-color: $color-White;
      @media (max-width: 799px) {
        padding: 12px;
        margin-bottom: 12px;
      }
      .MuiTableCell-root {
        padding: 13px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: $color-Black;
        font-family: $regulerFont;
      }
      .MuiTableCell-head {
        color: $color-Primary;
        font-weight: 600;
        background-color: $color-Light-grey-1;
        border-bottom: none;
      }
      .MuiTableCell-body {
        // border-bottom: 1px solid $color-Light-grey;
        border-bottom: none;
      }
      .MuiTableBody-root {
        .MuiTableRow-root {
          border-bottom: 1px solid $color-Light-grey;
        }
        .no-border {
          border-bottom: none;
        }
      }
      .details-btn {
        color: $color-Accent-1;
        @media (max-width: 799px) {
          font-size: 14px;
          line-height: 17px;
        }
        span {
          cursor: pointer;
          display: block;
          min-width: max-content;
        }
        button {
          height: 16px;
          width: 16px;
          margin-left: 5px;
          svg {
            fill: $color-Accent-1;
          }
        }
      }
      .history-extra-info-wrap {
        padding: 14px 0px;
        @media (max-width: 799px) {
          padding: 14px 0px 0px;
        }
        .history-extra-info {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: $color-Black;
          font-family: $regulerFont;
          margin-bottom: 11px;
          &:last-child {
            margin-bottom: 0px;
          }
          @media (max-width: 799px) {
            font-size: 14px;
            line-height: 17px;
          }
          .subs-badge {
            height: 30px;
            width: 30px;
          }
        }
        .info-label {
          font-weight: 600;
        }
        .badge-icon-wrap {
          display: flex;
          align-items: center;
          .icon-wrap {
            display: flex;
            align-items: center;
            column-gap: 4px;
          }
        }
      }

      // responsive css

      .res-history-header-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .history-header {
          font-size: 14px;
          line-height: 17px;
          font-weight: 600;
          font-family: $regulerFont;
        }
      }

      .res-history-data-wrap {
        display: flex;
        justify-content: space-between;
        padding: 9px 0px 0px;
        .info-wrap {
          font-family: $regulerFont;
          .info-label {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            color: $color-grey-Accent-1;
            margin-bottom: 5px;
          }
          .info-des {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 500;
            color: $color-Black;
          }
        }
      }
    }
  }
  .pagination-section {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .MuiPagination-root {
      .MuiPagination-ul {
        .MuiButtonBase-root {
          width: 36px;
          height: 36px;

          svg {
            width: 24px;
            height: 24px;
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }

        .MuiPaginationItem-root {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          border-radius: 50%;
        }
      }
    }
  }
  .text-capitalize {
    text-transform: capitalize;
  }
  .success-msg {
    color: $color-Affirmative !important;
  }
  .failed-msg {
    color: $color-Negative !important;
  }
}
