@import "../../../assets/scss/variable.scss";

.comp-tips-wrap {
  background-color: $color-off-White;

  .comp-tips-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;

      width: 80%;

      @media (max-width: 560px) {
        font-size: 31.36px;
        line-height: 30px;
        width: 63%;
      }
    }

    .bredcrumn-deatils {
      overflow-x: scroll;

      .bredcrumn-wrap {
        margin-bottom: 3px;
        min-width: max-content;

        li.MuiBreadcrumbs-separator {
          color: $color-Black;
        }

        li {
          font-size: 11.42px;
          line-height: 14px;

          p {
            font-size: 11.42px;
            line-height: 14px;
            color: $color-Accent-1;
            text-transform: uppercase;
          }

          a {
            color: $color-Black;
            font-size: 11.42px;
            line-height: 14px;
            text-transform: uppercase;
          }
        }
      }
    }

    .bredcrumn-deatils::-webkit-scrollbar {
      height: 0px;
    }

    .bredcrumn-deatils::-webkit-scrollbar-thumb {
      display: none;
    }
    .comp-details-wrap {
      position: relative;
      .comp-img-share-wrap {
        position: absolute;
        top: -35px;
        right: 0px;
        z-index: 11;
        @media (max-width: 1279px) {
          top: 18px;
        }
        @media (max-width: 1023px) {
          right: -10px;
        }
        @media (max-width: 980px) {
          top: 55px;
          right: -14px;
        }
      }
    }
    .about-wrap {
      margin-top: 3px;
      width: 80%;
      word-wrap: break-word;
      padding-bottom: 12px;
      @media (max-width: 799px) {
        width: 100%;
      }
      .about-label {
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        color: $color-Black;
        margin-right: 6px;
      }
      .about-des {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        color: $color-Black;
        .about-line {
          margin: 0px;
          display: inline;
        }
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 3;
      }
    }
    .comp-share-btn-wrap {
      text-align: center;
      margin-top: 13px;
      @media (max-width: 799px) {
        text-align: start;
        margin-top: 27px;
        margin-bottom: 18px;
      }
      @media (max-width: 479px) {
        margin-top: 40px;
      }

      .share-btn {
        border: 1px solid $color-Accent-1;
        max-width: 113px;
        width: 100%;
        border-radius: 8px;
        text-transform: capitalize;
        @media (max-width: 799px) {
          max-width: 160px;
        }

        .MuiButton-label {
          font-size: 16px;
          color: $color-Accent-1;
        }
      }
      .share-tooltip-icon {
        @media (max-width: 479px) {
          width: 50%;
        }
      }
      .comp-custom-tooltip {
        position: relative;
        .share-tooltip {
          top: 55px;
          left: -15px;
          width: max-content;
          padding: 12px !important;
          @media (max-width: 1023px) {
            left: -48px;
          }
          @media (max-width: 799px) {
            left: 0px;
            right: auto;
            top: 65px;
            transform: translateX(0px);
            padding: 8px !important;
          }
          .share-tooltip-arrow {
            top: -20px;
            right: 30px;
            left: auto;
            transform: rotate(180deg);
            @media (max-width: 799px) {
              right: auto;
              left: 35px;
              top: -16px;
            }
          }

          .share-option {
            //   display: flex;
            //   justify-content: space-between;
            //   align-items: center;
            //   flex-wrap: wrap;
            position: relative;
            max-width: 551px;
            width: 100%;
            margin: 0 auto;
            column-gap: 20px;

            @media (max-width: 1048px) {
              justify-content: center;
              max-width: 296px;
              row-gap: 11px;
              margin-top: 12px;
            }

            .copy-link {
              cursor: pointer;
            }

            .jf-start {
              justify-content: start !important;
            }

            .social-box {
              display: flex;
              align-items: center;
              column-gap: 10px;

              justify-content: space-between;
            }

            .option-name {
              font-family: $regulerFont;
              font-size: 11.42px;
              line-height: 15px;
              color: #5d5d5d;

              @media (max-width: 560px) {
                font-size: 8px;
              }
            }

            .react-share__ShareButton {
              width: 32px;
              height: 32px;

              img {
                height: 100%;
                width: 100%;
              }
            }

            .social-icon {
              cursor: pointer;
            }
          }

          .social-option-box {
            display: grid;
            grid-template-columns: auto auto auto auto;
            column-gap: 10px;
            row-gap: 10px;

            .option {
              text-align: center;
            }

            .option-name {
              font-family: $regulerFont;
              font-size: 11.42px;
              line-height: 15px;
              color: #5d5d5d;

              @media (max-width: 560px) {
                font-size: 11.42px;
              }
            }
          }
        }
      }
      .right-sec {
        .share-btn {
          margin-top: 11px;
          padding: 11px 24px 12px 24px;
          background-color: $color-White;
          color: $color-Accent-1;
          border: 1px solid $color-Accent-1;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: "Inter", sans-serif;
          border-radius: 8px;
          text-transform: capitalize;
          cursor: pointer;

          @media (max-width: 900px) {
            padding: 11px 14px 12px 14px;
          }

          @media (max-width: 799px) {
            padding: 11px 24px 12px 24px;
          }

          @media (max-width: 650px) {
            font-size: 11.45px;
            padding: 12px 12px 12px 12px;
          }

          // @media (max-width: 479px) {
          //   width: 100%;
          // }
        }
      }
    }
    .comp-img-wrap {
      z-index: 11;
      width: 206px;
      height: 206px;
      // position: absolute;
      // top: 0px;
      // right: 0px;
      @media (max-width: 1279px) {
        width: 160px;
        height: 160px;
      }
      @media (max-width: 980px) {
        width: 125px;
        height: 125px;
      }
      @media (max-width: 799px) {
        position: absolute;
        z-index: 11;
        top: 10px;
        right: 0px;
      }
      @media (max-width: 479px) {
        top: 6px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        background-color: $color-White;
        border: 1px solid $color-Accent-1;
      }
    }
  }

  .tab-search-section {
    padding: 18px 18px 19px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;
    // margin-bottom: 18px;

    .filter-section {
      max-width: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 12px;
      padding-bottom: 18px;
      border-bottom: 1px solid $color-Light-grey;

      @media only screen and (max-width: 1023px) {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 12px;
        row-gap: 12px;
      }

      @media only screen and (max-width: 479px) {
        grid-template-columns: auto auto;
      }

      .sort-select {
        max-width: 366px;
        width: 100%;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .mw-none {
        width: 100%;
      }

      .select__control {
        background-color: $color-Light-grey;
        border-radius: 8px;
        max-height: 36px;

        .select__single-value {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__placeholder {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__input-container {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__indicator-separator {
          width: 0px;
        }

        .select__dropdown-indicator {
          padding: 10px;
        }
      }

      .select__menu-portal {
        z-index: 99;
      }

      .select__menu,
      .react-select__menu {
        .select__menu-list,
        .react-select__menu-list {
          .select__option,
          .react-select__option {
            cursor: pointer;
            font-size: 16px;
            color: $color-Black;
            font-family: $regulerFont;
            line-height: 19px;
            padding: 11px;

            &.select__option--is-focused {
              background-color: $color-grey;
              color: #000;
            }

            &.select__option--is-selected {
              background-color: $color-grey;
              color: $color-Black;
            }
          }
        }

        .select__menu-list,
        .react-select__menu-list {
          .select__group {
            .select__group-heading {
              margin-bottom: 0.5rem;
              color: green;
              font-weight: bolder;
              font-size: inherit;
            }
          }
        }
      }
    }

    .teamsport-tab-detail {
      margin-top: 18px;
      //   margin-top: 28px;
      border-bottom: 2px solid $color-Accent-1;

      .MuiTabs-scrollable {
        .MuiTabs-indicator {
          display: none;
        }

        .active {
          border-bottom: 3px solid $color-Primary;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .active {
        .MuiTab-wrapper {
          color: $color-Accent-1;
        }
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .MuiTab-wrapper {
        font-size: 16px;
        font-family: VeneerClean-Soft !important;
        color: $color-Black;
        line-height: 21px;
      }

      .MuiTab-root {
        min-width: 120px;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: $color-Primary;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }

    .stepper-score-sec {
      padding-top: 15px;
      padding-bottom: 18px;

      .stepper-input {
        // margin-top: 15px;
        width: 80%;
        display: flex;
        //   border: 1px solid #c9c9c9;
        border-radius: 8px;
        max-height: 44px;
        column-gap: 3px;
        @media only screen and (max-width: 799px) {
          width: 100%;
        }

        .stepper-input__button {
          padding: 10px 30px;
          min-width: 35px;
          cursor: pointer;
          color: $color-Black;
          border-left: 0px;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          text-transform: capitalize;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 400;
          background-color: #e8eaec;
          //   background-color: #00000042;

          @media (max-width: 540px) {
            font-size: 12px;
          }
        }

        .Next-btn {
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
        }

        .stepper-input__content {
          border: 1px solid #c9c9c9;
          font-family: $regulerFont;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          padding: 8px 26px;

          color: $color-Black;
          width: 100%;
          text-align: center;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .score-share-sec {
        display: flex;
        justify-content: space-between;
        align-items: end;
        width: 100%;
        margin-top: 16px;

        @media (max-width: 620px) {
          display: block;
        }

        .score-sec {
          width: 80%;

          @media (max-width: 620px) {
            width: 100%;
          }

          .round-score-sec {
            max-width: 460px;
            width: 100%;
            display: flex;
            align-items: center;
            column-gap: 18px;

            @media (max-width: 620px) {
              justify-content: space-between;
              max-width: unset;
            }

            .round-score {
              column-gap: 25px;
              max-width: 193px;
              width: 100%;
              padding: 11px 30px 12px 18px;
              border: 1px solid #e7e9ec;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;

              @media (max-width: 620px) {
                padding: 11px 22px 12px 9px;
                column-gap: 18px;
              }

              .score {
                font-weight: 400;
                font-size: 16px;
                color: #191919;
                line-height: 21px;
                font-family: $regulerFont;
                text-align: center;
              }
            }
          }

          .bottom-line-sec {
            display: flex;
            align-items: center;
            column-gap: 10px;

            @media (max-width: 930px) {
              display: block;
            }

            .final-txt {
              margin-top: 18px;
              display: flex;
              align-items: center;
              column-gap: 5px;

              font-weight: 400;
              font-size: 16px;
              color: #4455c7;
              line-height: 21px;
              font-family: $regulerFont;

              @media (max-width: 580px) {
                display: block;
              }

              @media (max-width: 900px) {
                font-size: 14px;
              }

              @media (max-width: 799px) {
                font-size: 16px;
              }

              @media (max-width: 650px) {
                font-size: 15px;
              }

              @media (max-width: 460px) {
                font-size: 12px;
              }

              // .comp-header-details {
              //   .day-time-details {
              //     //   font-weight: 400;
              //     //   font-family: $regulerFont;
              //     //   color: #191919;
              //     //   font-size: 16px;
              //     //   line-height: 21px;

              //     //   @media (max-width: 900px) {
              //     //     font-size: 14px;
              //     //   }
              //     //   @media (max-width: 799px) {
              //     //     font-size: 16px;
              //     //   }

              //     //   @media (max-width: 650px) {
              //     //     font-size: 15px;
              //     //   }
              //   }
              // }
            }

            .joker-switch-box {
              margin-top: 8px;

              @media (max-width: 930px) {
                margin-top: 10px;
              }

              // margin-right: 30px;

              .MuiFormControlLabel-root {
                @media (max-width: 930px) {
                  margin-left: 0px;
                }

                max-width: 132px;

                .MuiSwitch-root {
                  padding: 0px;
                  width: 39px;
                  height: 20px;
                  border-radius: 10px;
                  margin-left: 9px;

                  .MuiButtonBase-root {
                    padding: 0px;
                  }

                  .Mui-checked {
                    color: #4455c7;
                  }

                  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
                    background-color: #d6d9f3;
                  }
                }

                .MuiTypography-root {
                  font-size: 16px;
                  line-height: 19px;
                  font-weight: 600;
                  font-family: $regulerFont;
                }
              }
            }
          }
        }
      }
    }
    // .comp-img-share-wrap {
    //   position: absolute;
    //   top: -63px;
    //   right: 15px;
    //   @media (max-width: 1279px) {
    //     top: -34px;
    //   }
    //   @media (max-width: 980px) {
    //     top: -13px;
    //   }
    //   // @media (max-width: 799px) {
    //   //   display: none;
    //   // }

    //   .comp-share-btn-wrap {
    //     .share-btn {
    //       border: 1px solid $color-Accent-1;
    //       max-width: 113px;
    //       width: 100%;
    //       border-radius: 8px;
    //       text-transform: capitalize;

    //       .MuiButton-label {
    //         font-size: 16px;
    //         color: $color-Accent-1;
    //       }
    //     }

    //     .share-tooltip-icon {
    //       @media (max-width: 479px) {
    //         width: 50%;
    //       }
    //     }
    //     .comp-custom-tooltip {
    //       position: relative;
    //       .tooltip {
    //         top: 55px;
    //         left: -15px;
    //         width: max-content;
    //         padding: 12px !important;
    //         @media (max-width: 1023px) {
    //           left: -48px;
    //         }
    //         // @media (max-width: 799px) {
    //         //   left: 34px;

    //         //   padding: 8px !important;
    //         // }

    //         // @media (max-width: 560px) {
    //         //   left: 42px;
    //         // }

    //         .tooltip-arrow {
    //           top: -20px;
    //           right: 30px;
    //           left: auto;
    //           transform: rotate(180deg);

    //           @media (max-width: 799px) {
    //             right: -19px;
    //           }
    //         }

    //         .share-option {
    //           position: relative;
    //           max-width: 551px;
    //           width: 100%;
    //           margin: 0 auto;
    //           column-gap: 20px;

    //           @media (max-width: 1048px) {
    //             justify-content: center;
    //             max-width: 296px;
    //             row-gap: 11px;
    //             margin-top: 12px;
    //           }

    //           .copy-link {
    //             cursor: pointer;
    //           }

    //           .jf-start {
    //             justify-content: start !important;
    //           }

    //           .social-box {
    //             display: flex;
    //             align-items: center;
    //             column-gap: 10px;

    //             justify-content: space-between;
    //           }

    //           .option-name {
    //             font-family: $regulerFont;
    //             font-size: 11.42px;
    //             line-height: 15px;
    //             color: #5d5d5d;

    //             @media (max-width: 560px) {
    //               font-size: 8px;
    //             }
    //           }

    //           .react-share__ShareButton {
    //             width: 32px;
    //             height: 32px;

    //             img {
    //               height: 100%;
    //               width: 100%;
    //             }
    //           }

    //           .social-icon {
    //             cursor: pointer;
    //           }
    //         }

    //         .social-option-box {
    //           display: grid;
    //           grid-template-columns: auto auto auto auto;
    //           column-gap: 10px;
    //           row-gap: 10px;

    //           .option {
    //             text-align: center;
    //           }

    //           .option-name {
    //             font-family: $regulerFont;
    //             font-size: 11.42px;
    //             line-height: 15px;
    //             color: #5d5d5d;

    //             @media (max-width: 560px) {
    //               font-size: 11.42px;
    //             }
    //           }
    //         }
    //       }
    //     }
    //     .right-sec {
    //       .share-btn {
    //         padding: 11px 24px 12px 24px;
    //         background-color: $color-White;
    //         color: $color-Accent-1;
    //         border: 1px solid $color-Accent-1;
    //         font-size: 16px;
    //         line-height: 21px;
    //         font-weight: 400;
    //         font-family: "Inter", sans-serif;
    //         border-radius: 8px;
    //         text-transform: capitalize;
    //         cursor: pointer;

    //         @media (max-width: 900px) {
    //           padding: 11px 14px 12px 14px;
    //         }

    //         @media (max-width: 799px) {
    //           padding: 11px 24px 12px 24px;
    //         }

    //         @media (max-width: 650px) {
    //           font-size: 11.45px;
    //           padding: 12px 12px 12px 12px;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  .padding-container {
    padding: 18px 33px !important;
    background-color: transparent !important;
    box-shadow: unset !important;

    @media (max-width: 1365px) {
      padding: 12px 18px !important;
    }
  }

  .page-deatils-wrap {
    margin-top: 18px;
    // padding: 18px 33px 84px 33px;
    padding-bottom: 9px;
    // background-color: $color-White;
    // box-shadow: 0px 3px 9px 0px #0000000d;

    // @media (max-width: 940px) {
    //   overflow: scroll;
    //   // padding: 16px 13px 16px 13px;
    // }

    .tips-sec {
      margin-top: 15px;
      padding: 18px 33px;
      background-color: $color-White;
      border-radius: 8px;
      box-shadow: 0px 3px 9px 0px #0000000d;

      @media (max-width: 1365px) {
        padding: 17px 15px 18px 15px;
      }

      &:first-child {
        margin-top: 0px;
      }

      .title-date-sec {
        text-align: center;

        .title-date {
          color: #191919;
          font-size: 16px;
          line-height: 21px;
          font-weight: 600;
          font-family: $regulerFont;
        }
      }

      .comp-tip-data-sec {
        border-top: 1px solid #4455c7;
        padding-top: 3px;

        .time-venue-txt {
          text-align: center;
        }

        .comp-odds-tips-sec {
          padding: 18px 26px 21px 26px;
          // width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 1365px) {
            column-gap: 2px;
            padding: 18px 0px 2px 0px;
          }

          // &:last-child {
          //   padding-bottom: 0px !important;
          // }
          .flex-rr {
            flex-direction: row-reverse !important;
          }

          .left-sec {
            display: flex;
            align-items: center;
            column-gap: 30px;

            @media (max-width: 1365px) {
              display: block;
              width: 90%;
            }

            .team-wrap {
              display: flex;
              flex-direction: column;
              align-items: center;
              row-gap: 5px;
              width: 120px;

              @media (max-width: 1365px) {
                width: initial;
                padding-bottom: 5px;
              }
              .team-img-wrap {
                width: 65px;
                height: 65px;

                border-radius: 50%;

                .team-img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: contain;
                }
              }
            }

            .odd-rr {
              @media (max-width: 1365px) {
                flex-direction: row-reverse !important;
              }
            }

            .odds-wrap {
              display: flex;
              flex-direction: column;
              align-items: center;
              row-gap: 5px;

              @media (max-width: 1365px) {
                flex-direction: row;
                justify-content: center;
                column-gap: 8px;
                margin-top: 10px;
              }

              .odds-btn {
                display: block;
                background: #d6d9f3;
                border-radius: 6px;
                width: 80px;
                max-width: 100%;
                text-align: center;
                padding: 8px;

                @media (max-width: 560px) {
                  width: 45px;
                }
              }

              .odd-img {
                max-width: 33px;
                border-radius: 6px;
                cursor: pointer;
                height: 33px;
                object-fit: contain;
              }
            }

            .blue-score {
              background-color: #003764;
              width: 63px;
              height: 58px;
              display: flex;
              align-items: center;
              justify-content: center;

              @media (max-width: 1365px) {
                width: 100%;
                margin-top: 12.1px;
                height: 36px;
              }

              .score {
                font-family: $regulerFont;
                font-size: 16px;
                line-height: 21px;
                font-weight: 400;
                color: $color-White;
                text-align: center;
              }
            }

            .check-box-sec {
              text-align: center;

              .MuiCheckbox-root {
                height: 58px;
                width: 58px;

                @media (max-width: 1365px) {
                  height: 34px;
                  width: 34px;
                }
              }
            }

            .odds-wrap {
              display: flex;
              flex-direction: column;
              align-items: center;
              row-gap: 5px;

              @media (max-width: 1365px) {
                flex-direction: row;
                justify-content: center;
                column-gap: 8px;
                margin-top: 10px;
              }

              .odds-btn {
                display: block;
                background: #d6d9f3;
                border-radius: 6px;
                width: 80px;
                max-width: 100%;
                text-align: center;
                padding: 8px;
              }

              .odd-img {
                max-width: 33px;
                border-radius: 6px;
                cursor: pointer;
                height: 33px;
                object-fit: contain;
              }
            }

            .comp-name-tip-wrap {
              display: flex;
              align-items: center;
              height: 40px;
              .team-name {
                width: 120px;
                text-align: center;
                font-family: $primaryFont;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                color: $color-Black;

                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                // @media (max-width: 1365px) {
                //   width: max-content;
                // }

                @media only screen and (max-width: 599px) {
                  min-width: 80px;
                  width: fit-content;
                }
              }
              .comp-smartbook-tooltip {
                width: 185px;
                transform: translateX(-118%);
                left: 122px;
                top: 36px;
                .comp-smartbook-tooltip-arrow {
                  left: initial;
                  right: 50px;
                  transform: rotate(540deg);
                  top: -9px;
                }
              }
            }
          }
          .vs {
            text-align: center;
            font-family: $primaryFont;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: $color-Black;
            @media (max-width: 1365px) {
              display: none;
            }
          }
          // .right-sec {}
        }

        .no-result {
          justify-content: space-around;
        }
        .indefinite-result {
          background-color: $color-Light-grey;
          border-radius: 8px;
        }

        .margin-sec {
          text-align: center;

          .margin-txt {
            color: #191919;
            font-size: 16px;
            line-height: 21px;
            font-weight: 500;
            font-family: $regulerFont;
          }
        }

        .counter-score-sec {
          padding-top: 15px;
          padding-bottom: 18px;
          max-width: 183px;
          width: 100%;
          margin: 0 auto;

          .counter-input {
            // margin-top: 15px;
            display: flex;
            //   border: 1px solid #c9c9c9;
            border-radius: 8px;
            max-height: 36px;

            // column-gap: 3px;
            border: 1px solid #c9c9c9;
            overflow: hidden;

            .counter-input__button {
              padding: 10px 10px;
              cursor: pointer;
              min-width: 36px;
              color: #191919;
              border-left: 0px;
              border-radius: 0px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              text-transform: capitalize;
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 400;
              // background-color: #e8eaec;
              //   background-color: #00000042;

              .MuiButton-startIcon {
                margin-left: 0px !important;
                margin-right: 0px !important;
              }

              .MuiButton-root {
                min-width: 36px !important;
              }

              @media (max-width: 540px) {
                font-size: 12px;
              }
            }

            .plus-btn {
              border-top-right-radius: 8px !important;
              border-bottom-right-radius: 8px !important;
              border-top-left-radius: 0px !important;
              border-bottom-left-radius: 0px !important;
            }

            .counter-input__content {
              border-left: 1px solid #c9c9c9;
              border-right: 1px solid #c9c9c9;
              font-family: $regulerFont;
              font-size: 16px;
              line-height: 20px;
              font-weight: 600;
              padding: 8px 26px;

              color: $color-Black;
              width: 100%;
              text-align: center;
            }

            .margin-textfield {
              .MuiInputBase-root {
                height: 36px;

                input {
                  padding: 9px 10px;
                  text-align: center;
                  border-left: 1px solid #c9c9c9;
                  border-right: 1px solid#c9c9c9;
                }

                fieldset {
                  border: none;
                }
              }
            }

            @media only screen and (max-width: 479px) {
              grid-column-start: 1;
              grid-column-end: 4;
            }
          }
        }
      }
    }

    .btn-flex {
      margin-top: 20px;
      display: flex;
      align-items: center;
      column-gap: 33px;
      justify-content: center;

      @media (max-width: 799px) {
        margin-top: 20px;
        column-gap: 12px;
      }
    }

    .share-btn {
      border: 1px solid $color-Accent-1;
      width: 145px;
      border-radius: 8px;
      padding: 11px 13px;
      font-size: 16px;
      color: $color-White;
      line-height: 20px;
      font-family: $regulerFont;
      @media (max-width: 799px) {
        max-width: none !important;
      }

      .MuiButton-label {
        text-transform: initial;
      }

      @media (max-width: 799px) {
        // max-width: unset;
        width: 100%;
      }
    }

    .submit-tips-btn-sec {
      // margin-top: 31px;
      // width: 100%;
      text-align: center;

      @media (max-width: 799px) {
        width: 100%;
      }
      .submit-tips-btn {
        // max-width: 164px;
        // width: 100%;
        width: 145px;
        padding: 12px 13px;
        border-radius: 8px;
        font-size: 16px;
        color: $color-White;
        line-height: 20px;
        font-family: $regulerFont;
        background-color: $color-Accent-1;

        .MuiButton-label {
          text-transform: initial;
        }

        @media (max-width: 799px) {
          max-width: unset;
          width: 100%;
        }
      }
    }

    .joker-box {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      box-shadow: 10px 10px 5px 0px #0000000f;
      -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
      -moz-box-shadow: 10px 10px 5px 0px #0000000f;
      .top-sec {
        text-align: center;
        background-color: #e8eaec;
        padding: 20px 0px 5px 0px;
        .joker-txt {
          color: $color-Black;
          font-size: 16px;
          line-height: 18px;
          font-weight: 600;
          font-family: $arialFont;
        }
      }
      .bottom-sec {
        display: flex;
        align-items: center;
        padding: 21px 0px 24px 0px;
        max-width: 520px;
        width: 100%;
        margin: 0 auto;
        column-gap: 13px;
        .select-txt {
          color: $color-Primary;
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          font-family: $arialFont;
        }
        .detail-txt {
          margin-top: 9px;
          color: $color-gray-dark;
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          font-family: $arialFont;
        }
      }
    }

    .tip-share-btn-wrap {
      text-align: center;
      margin-top: 18px;
      margin-bottom: 18px;
      .share-btn {
        border: 1px solid $color-Accent-1;
        max-width: 160px;
        width: 100%;
        border-radius: 8px;
        text-transform: capitalize;

        .MuiButton-label {
          font-size: 16px;
          color: $color-Accent-1;
        }
      }
      .share-tooltip-icon {
        // @media (max-width: 479px) {
        //   width: 50%;
        // }
      }
      .comp-custom-tooltip {
        position: relative;
        .tooltip {
          left: 40%;
          right: auto;
          top: 65px;
          transform: translateX(0px);
          width: max-content;
          padding: 8px !important;

          @media (max-width: 700px) {
            left: -40%;
          }
          .tooltip-arrow {
            right: auto;
            left: 35px;
            top: -16px;
            transform: rotate(180deg);
          }

          .share-option {
            //   display: flex;
            //   justify-content: space-between;
            //   align-items: center;
            //   flex-wrap: wrap;
            position: relative;
            max-width: 551px;
            width: 100%;
            margin: 0 auto;
            column-gap: 20px;

            @media (max-width: 1048px) {
              justify-content: center;
              max-width: 296px;
              row-gap: 11px;
              margin-top: 12px;
            }

            .copy-link {
              cursor: pointer;
            }

            .jf-start {
              justify-content: start !important;
            }

            .social-box {
              display: flex;
              align-items: center;
              column-gap: 10px;

              justify-content: space-between;
            }

            .option-name {
              font-family: $regulerFont;
              font-size: 11.42px;
              line-height: 15px;
              color: #5d5d5d;

              @media (max-width: 560px) {
                font-size: 8px;
              }
            }

            .react-share__ShareButton {
              width: 32px;
              height: 32px;

              img {
                height: 100%;
                width: 100%;
              }
            }

            .social-icon {
              cursor: pointer;
            }
          }

          .social-option-box {
            display: grid;
            grid-template-columns: auto auto auto auto;
            column-gap: 10px;
            row-gap: 10px;

            .option {
              text-align: center;
            }

            .option-name {
              font-family: $regulerFont;
              font-size: 11.42px;
              line-height: 15px;
              color: #5d5d5d;

              @media (max-width: 560px) {
                font-size: 11.42px;
              }
            }
          }
        }
      }

      .right-sec {
        .mt-0 {
          margin-top: 0px !important;
        }
        .share-btn {
          margin-top: 11px;
          padding: 11px 24px 12px 24px;
          background-color: $color-White;
          color: $color-Accent-1;
          border: 1px solid $color-Accent-1;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: "Inter", sans-serif;
          border-radius: 8px;
          text-transform: capitalize;
          cursor: pointer;

          @media (max-width: 900px) {
            padding: 11px 14px 12px 14px;
          }

          @media (max-width: 799px) {
            padding: 11px 24px 12px 24px;
          }

          // @media (max-width: 650px) {
          //   font-size: 11.45px;
          //   padding: 12px 12px 12px 12px;
          // }

          // @media (max-width: 479px) {
          //   width: 100%;
          // }
        }
      }
    }

    .margin-error {
      margin-top: 5px;
      text-align: center;

      .error {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        font-family: $regulerFont;
        color: $color-Negative;
      }
    }

    .share-btn {
      border: 1px solid $color-Accent-1;
      max-width: 160px;
      width: 100%;
      border-radius: 8px;
      text-transform: capitalize;

      .MuiButton-label {
        font-size: 16px;
        color: $color-Accent-1;
      }
    }

    .clear-all-box {
      // padding: 11px 33px 0px 5px;
      margin-top: 9px;

      .clear-all {
        text-align: center;
        text-transform: capitalize;
        color: #4455c7;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        text-decoration: underline;
        font-size: 14px;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
