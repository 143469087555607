@import "../../../assets/scss/variable.scss";

.FAQs-content {
    @media (max-width:799px) {
        margin: 0px -12px;
    }
}

.tipping-FAQs-wrap {

    .tipping-FAQs-data-header {
        padding: 44px 18px 0px;

        @media (max-width: 799px) {
            padding: 22px 12px 6px;
        }

        .bredcrumn-details {
            overflow-x: scroll;

            .bredcrumn-wrap {
                margin-bottom: 3px;
                min-width: max-content;

                li.MuiBreadcrumbs-separator {
                    color: $color-Black;
                }

                li {
                    font-size: 11.42px;
                    line-height: 14px;

                    p {
                        font-size: 11.42px;
                        line-height: 14px;
                        color: $color-Accent-1;
                        text-transform: uppercase;
                    }

                    a {
                        color: $color-Black;
                        font-size: 11.42px;
                        line-height: 14px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .bredcrumn-details::-webkit-scrollbar {
            height: 0px;
        }

        .bredcrumn-details::-webkit-scrollbar-thumb {
            display: none;
        }

        h1 {
            font-size: 43.9px;
            line-height: 56px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-Black;

            @media (max-width: 799px) {
                font-size: 31.36px;
                line-height: 40px;
            }
        }
    }

    .tipping-FAQs-details-wrap {
        padding: 21px 18px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #00000012;

        @media (max-width:799px) {
            padding: 12px;
        }

        .FAQs-que-qns-wrap {
            background-color: #E7E8E9;
            padding: 12px 18px;

            @media (max-width:799px) {
                padding: 6px 9px;
            }

            &:not(:last-child) {
                margin-bottom: 9px;

                @media (max-width:799px) {
                    margin-bottom: 6px;
                }
            }
        }

        .question-wrap {
            display: flex;
            align-items: flex-start;
            column-gap: 6px;
            cursor: pointer;

            @media (max-width:799px) {
                column-gap: 4px;
            }

            .icon-wrap {
                width: 20px;
                height: 20px;

                @media (max-width:799px) {
                    width: 17px;
                    height: 17px;
                    min-width: 17px;
                }

                svg {
                    width: 100%;
                    height: 100%;
                }

                .plus-icon {
                    path {
                        stroke: $color-Black;
                    }
                }
            }

            .question-test {
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                font-family: $regulerFont;
                letter-spacing: 0px;

                @media (max-width:799px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            .light-color {
                color: $color-Black;
            }

            .dark-color {
                color: $color-Accent-1;
            }
        }

        .answer-wrap {
            padding-left: 26px;
            margin-top: 13px;

            @media (max-width:799px) {
                padding-left: 21px;
                margin-top: 7px;
            }

            .answer-text {
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 400;
                font-family: $regulerFont;
                letter-spacing: 0px;
                color: $color-Black;

                @media (max-width:799px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .border-left-light {
            border-left: 4px solid #0037646b;
        }

        .border-left-dark {
            border-left: 4px solid $color-Primary;
        }
    }
}