@import "../../../../assets/scss/variable.scss";

.left-saved-article-section {
  .left-saved-container {
    padding-right: 75px;
    @media (max-width: 1023px) {
      padding-right: 0px;
    }
    .grid-container {
      // margin-top: 21px;
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 38px;
      row-gap: 18px;
      @media (max-width: 799px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }
    }

    .grid-item {
      position: relative;
      max-width: 418px;
      height: 290px;
      background: $color-White;
      border-radius: 8px;
      box-shadow: 0 1px 9px RGB(0 0 0 / 16%);
      z-index: 1;
      overflow: hidden;
      @media (max-width: 1400px) {
        width: 100%;
      }

      @media (max-width: 1080px) {
        width: 100%;
        height: 100%;
      }
      .news-img {
        height: 158px;
        width: 100%;
        @media (max-width: 425px) {
          // width: 180px;
          height: 97px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top;
          border-radius: 8px;
          @media (max-width: 799px) {
            object-position: center;
          }
        }
      }
      .details-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        background: $color-White;
        padding: 12px;
        min-height: 108px;
        width: calc(100% - 24px);
        border-radius: 0px 0px 8px 8px;
        @media (max-width: 799px) {
          position: inherit;
          min-height: 100%;
          width: 100%;
          border-radius: 0px 0px 8px 0px;
        }
        .news-deatils {
          @media (max-width: 799px) {
            position: relative;
            height: 100%;
          }
          .title-news {
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            font-family: $regulerFont;
            color: $color-Black;
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            @media (max-width: 799px) {
              font-size: 11.42px;
              line-height: 14px;
              -webkit-line-clamp: 6;
            }
            @media (max-width: 425px) {
              -webkit-line-clamp: 3;
            }
          }
          .desc-news {
            font-size: 14px;
            line-height: 19px;
            font-weight: 600;
            font-family: $regulerFont;
            color: $color-White;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .sub-details-wrap {
            .sub-details {
              font-size: 14px;
              line-height: 19px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-gray-dark;
              // position: relative;
              @media (max-width: 1080px) {
                font-size: 11.42px;
                line-height: 14px;
              }
              .read-more {
                color: $color-Accent-1;
                // position: absolute;
                // right: 23px;
                // bottom: 0px;
              }
            }
          }
          p {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            font-family: $regulerFont;
            color: #5d5d5d;
          }
          .news-time-comment-wrapper {
            position: absolute;
            bottom: 12px;
            left: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 24px);
            @media (max-width: 799px) {
              bottom: 0;
              left: 0;
              width: 100%;
            }
            .bookmark-chat-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              .chat-details {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 6px;
                svg {
                  margin-right: 3px;
                }
              }
              .delete-icon {
                height: 12px;
                width: 12px;
                path {
                  stroke: $color-gray-dark;
                }
              }
            }
          }
        }
      }
      .news-tag {
        padding: 3px 6px;
        background-color: #fc4714;
        color: #ffffff;
        font-size: 11.42px;
        line-height: 14px;
        font-weight: 400;
        font-family: "Inter", sans-serif;
        max-width: -moz-fit-content;
        max-width: fit-content;
        position: absolute;
        left: 12px;
        top: -30px;
        text-transform: capitalize;
        max-height: fit-content;
        border-radius: 3px;
        @media (max-width: 799px) {
          top: auto;
          bottom: 12px;
        }

        @media (max-width: 560px) {
          bottom: 10px;
          left: 6px;
        }

        @media (max-width: 440px) {
          left: 10px;
        }
      }

      .responsive-news-view {
        cursor: pointer;
        @media (max-width: 799px) {
          display: flex;
        }
      }
      .responsive-main-news-view {
        cursor: pointer;
        height: 100%;
        // @media (max-width: 559px) {
        // }
      }
    }

    .news-tooltip {
      background: #003764 !important;
      .MuiTooltip-arrow {
        color: #003764;
      }
    }

    .no-data-wrapper {
      background-color: $color-White;
      padding: 18px 12px;
      border-radius: 8px;
      margin: 0px 7.5px;
      text-align: center;
      border: 1px solid $color-Light-grey;

      .no-data-text {
        font-size: 16px;
        line-height: 20px;
        font-family: $regulerFont;
        color: $color-Black;
      }
      @media (max-width: 799px) {
        padding: 12px;
      }
    }

    .news-Pagination {
      display: flex;
      justify-content: center;
      margin: 45px auto 60px;
      @media (max-width: 799px) {
        margin: 27px auto 50px;
      }
      ul {
        li {
          margin-right: 4px;

          @media (max-width: 379px) {
            margin-right: 0px;
          }

          .MuiPaginationItem-root {
            font-size: 18px;
            line-height: 21px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
            width: 36px;
            height: 36px;
            border-radius: 50%;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }

            svg {
              width: 36px;
              height: 36px;
              font-size: 18px;

              @media (max-width: 799px) {
                font-size: 14px;
                width: 24px;
                height: 24px;
              }

              @media (max-width: 379px) {
                font-size: 11px;
                width: 22px;
                height: 22px;
              }
            }
          }

          .Mui-selected {
            background-color: #d4d6d8;
            width: 45px;
            height: 45px;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }

    // responsive design

    .individual-single-news {
      margin-bottom: 21px;
      padding: 15px 12px;
      background-color: #ffffff;
      box-shadow: 0px 1px 9px 0px #0000001f;
      border-radius: 8px;
      overflow: hidden;

      @media (max-width: 799px) {
        padding: 12px;
        margin-bottom: 12px;
      }

      // &:not(:last-child) {
      //   border-bottom: 1px solid #c9c9c9;
      // }

      .news-box {
        display: flex;
        column-gap: 12px;

        .news-img {
          position: relative;
          min-width: 312px;
          // min-height: 164px;
          max-width: 312px;
          height: 164px;
          // margin-bottom: 18px;
          cursor: pointer;

          @media (max-width: 1280px) {
            max-width: 200px;
            min-width: 200px;
            // margin-right: 12px;
            height: 140px;
          }

          @media (max-width: 1120px) {
            max-width: 180px;
            min-width: 180px;
            height: 120px;
          }

          @media (max-width: 439px) {
            max-width: 140px;
            min-width: 140px;
            height: 105px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;

            @media (max-width: 1280px) {
              width: 200px;
              height: 140px;
            }

            @media (max-width: 1120px) {
              width: 180px;
              height: 120px;
            }

            @media (max-width: 439px) {
              width: 140px;
              height: 105px;
            }
          }
        }

        .individual-news-details {
          width: 100%;
          position: relative;

          .exclusive {
            padding: 3px 11px;
            background-color: #d84727;
            color: #ffffff;
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            font-family: $regulerFont;
            max-width: fit-content;
            border-radius: 3px;
            margin-bottom: 9px;
          }

          .details {
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            font-family: $regulerFont;
            color: $color-Black;
            // margin-bottom: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            cursor: pointer;

            @media (max-width: 1440px) {
              -webkit-line-clamp: 2;
            }

            @media (max-width: 1024px) {
              margin-bottom: 4px;
              font-size: 14px;
              line-height: 18px;
            }

            // @media (max-width: 439px) {
            //   font-size: 14px;
            //   line-height: 18px;
            // }
          }

          .sub-details-wrap {
            .sub-details {
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-gray-dark;
              margin-top: 4px;

              @media (max-width: 1024px) {
                font-size: 11.42px;
                line-height: 14px;
              }

              .read-more {
                color: $color-Accent-1;
              }
            }
          }

          .tag-time-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 13px;

            @media (max-width: 599px) {
              flex-direction: column;
              row-gap: 4px;
              margin-top: 6px;
            }

            .news-tags {
              display: flex;
              flex-wrap: wrap;
              row-gap: 5px;
              width: 70%;

              @media (max-width: 599px) {
                width: 100%;
              }
            }

            .individual-news-tag {
              padding: 3px 6px;
              background-color: #989898;
              color: #ffffff;
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 400;
              font-family: $regulerFont;
              max-width: fit-content;
              border-radius: 23px;

              &:not(:last-child) {
                margin-right: 12px;

                @media (max-width: 1140px) {
                  margin-right: 6px;
                }
              }
            }

            .stories-time {
              font-size: 11.42px;
              line-height: 14px;
              font-family: $regulerFont;
              font-weight: 400;
              color: #5d5d5d;
              // width: 30%;
              // text-align: end;

              @media (max-width: 599px) {
                width: 100%;
              }

              .watch {
                margin-right: 4px;
                vertical-align: middle;
              }
            }
          }

          .news-time-comment-wrapper {
            position: absolute;
            left: 0px;
            bottom: 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 24px);

            @media (max-width: 799px) {
              bottom: 0;
              left: 0;
              width: 100%;
            }

            .news-time {
              font-size: 11.42px;
              line-height: 14px;
              font-family: $regulerFont;
              font-weight: 400;
              color: #5d5d5d;
            }

            .bookmark-chat-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;

              .chat-details {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 6px;

                svg {
                  margin-right: 3px;
                }
              }

              .delete-icon {
                height: 12px;
                width: 12px;
                path {
                  stroke: $color-gray-dark;
                }
              }
            }
          }
        }
      }
    }
    .comman-news-comp.individual-news-cont {
      display: grid;
      grid-template-columns: auto;
      column-gap: 18px;
      @media (max-width: 799px) {
        grid-template-columns: auto;
      }
      .news-tag-relative {
        position: relative;
        .news-img-container {
          position: relative;
          overflow: hidden;
        }
        .news-tag {
          padding: 3px 6px;
          background-color: #fc4714;
          color: #ffffff;
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          font-family: "Inter", sans-serif;
          max-width: -moz-fit-content;
          max-width: fit-content;
          position: absolute;
          left: 9px;
          bottom: 9px;
          top: unset;
          text-transform: capitalize;

          @media (max-width: 1024px) {
            bottom: 15px;
            top: unset;
          }

          @media (max-width: 560px) {
            left: 6px;
            bottom: 6px;
          }
        }
      }
    }
    .comman-news-comp.single-news-comp {
      grid-template-columns: auto;
    }
  }
}
