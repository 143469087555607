@import "../../../../../assets/scss/variable.scss";

.team-accordion-details {
    padding: 0px !important;
    box-shadow: 0px 3px 9px 0px #0000000d;
}

.teamsport-data-tab {
    padding-bottom: 18px;
}

.team-tab-content {
    margin: 18px 0px 36px;
    display: flex;
    align-items: center;

    @media (max-width:799px) {
        margin: 0 0px 24px;
    }

    .team-name {
        font-size: 22.4px;
        line-height: 31.36px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Black;
        margin-right: 25px;

        @media (max-width:799px) {
            margin-right: 14px;
        }
    }

    .active {
        padding: 9px 30px;
        border-radius: 8px;
        background-color: $color-Primary;
        color: $color-White !important;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0px;

        @media (max-width:799px) {
            padding: 6px 12px;
        }

        &:hover {
            background-color: $color-Primary;
            box-shadow: none;
        }

        .MuiButton-label {
            font-family: $primaryFont;
        }

        .MuiTouchRipple-root {
            display: none;
        }
    }


    .details-btn {
        padding: 9px 30px;
        border-radius: 8px;
        border: 2px solid $color-Primary;
        color: $color-Primary;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0px;
        margin-left: 12px;
        border: 1px solid transparent;

        @media (max-width:799px) {
            padding: 6px 12px;
            margin-left: 9px;
        }

        &:hover {
            border: 1px solid $color-Primary;
            box-shadow: none;
        }

        .MuiButton-label {
            font-family: $primaryFont;
        }

        .MuiTouchRipple-root {
            display: none;
        }
    }
}

.team-deatils-wrap {

    .team-deatils {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        font-family: $regulerFont;
        color: #8D8D8D;
    }
}

.note {
    padding: 0px 64px 0px 28px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: $regulerFont;
    color: #8D8D8D;

    @media (max-width:799px) {
        padding: 0px 12px;
        font-size: 12px;
        line-height: 15px;
    }

    span {
        color: #4455C7;
    }
}

.team-sport-tables {
    margin-top: 10px;

    .table-sub-header {
        .table-sub-head-row {
            .MuiTableCell-head {
                text-align: center !important;
                border-top: none;
            }
        }
    }

    .MuiTableCell-head {
        border-top: 1px solid #D4D6D8;
        text-align: left !important;
    }

    .MuiTableCell-body {
        text-align: left !important;
    }

    .first-cell {
        padding-left: 33px !important;

        @media (max-width:799px) {
            padding-left: 12px !important;
        }
    }



    .view-btn {
        color: $color-Accent-1;
        text-decoration: underline;
    }

    .head-to-head {
        font-size: 11.42px;
        line-height: 14px;
        font-family: $regulerFont;
        font-weight: 400;
        color: #D84727;
    }

    .flag-img-wrap {
        display: flex;
        align-items: center;
        column-gap: 10px;

        .flag-img {
            width: 29.14px;
            height: 17px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .table-result-row {
        .MuiTableCell-body {
            border-bottom: none !important;
        }
    }

    .no-data-td {
        text-align: center !important;
    }
}

.mb-18 {
    margin-bottom: 18px;
}

.light-blue {
    color: #1A73E8 !important;
}