@import "../../../assets/scss/variable.scss";
.restricted-main-wrap {
  .detail-header-wrap {
    margin: 200px auto;
    max-width: 750px;
    h1 {
      font-size: 45px;
      font-weight: 600;
    }
    p {
      font-size: 28px;
    }
  }
}
