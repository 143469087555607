@import "../../../assets/scss/variable.scss";

.ad-screen-container {
  // max-width: 1920px;
  // margin: 0 auto;

  .ad-screen-wrap {
    .top-bar {
      max-width: 100%;
    }

    .ad-screen-banner {
      background-image: url("../../../assets/images/banner/advertisingScreenBanner.png");
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      padding: 16px 0px;

      .slick-track {
        justify-content: center;
      }

      .slick-slide {
        width: 260px !important;
        margin: 0px 15px;
      }

      .our-partner-slider {
        .slider-img {
          width: 70% !important;
          object-fit: contain;
          height: 51px;
        }
      }
    }

    .banner-Contact {
      h1 {
        margin: 0px;
        font-size: 22px;
        font-family: $primaryFont;
        line-height: 32px;
      }
    }
  }

  .ad-screen-details {
    overflow: hidden;
    padding: 22px 0px 36px 19px;

    .race-details {
      display: flex;
      position: relative;

      @media screen and (max-width: 1440px) {
        display: block;
      }

      .racename-weather-wrap {
        display: flex;

        .race-details-name {
          display: flex;
          flex-direction: column;
          margin-right: 34px;

          .race-name {
            font-size: 61.47px;
            font-family: $primaryFont;
            max-height: 78px;
          }

          .location {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: $color-Black;
            line-height: 19px;

            img {
              margin-right: 12.7px;
            }
          }
        }

        .race-track-weather {
          margin-top: 15px;

          .race-track {
            margin-bottom: 7px;

            span {
              color: $color-Black;
            }

            span:not(:last-child) {
              margin-right: 9px;
            }
          }

          .race-weather {
            display: flex;

            span {
              color: $color-Black;
            }

            span:not(:last-child) {
              margin-right: 9px;
            }

            .weather {
              display: flex;
              align-items: center;

              img {
                margin-right: 5px;
              }
            }
          }
        }
      }

      .trackdetails-qr-wrap {
        @media screen and (max-width: 1440px) {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .race-track-details {
          margin: 20px 0px 0px 82px;

          @media screen and (max-width: 1440px) {
            margin: 20px 0px 0px 0px;
          }

          .race-track {
            .MuiTabs-flexContainer {
              column-gap: 9px;
            }

            .race-track-tab {
              min-height: auto;

              .MuiTab-wrapper {
                font-weight: 600;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 1px solid;
                color: #003764;
                background: transparent;
                border-color: #003764;
                font-size: 16px;
              }

              .MuiTab-root {
                min-width: auto;
                padding: 0px;
                min-height: auto;
                opacity: 1;
              }

              .active {
                background-color: #4455c7;
                border: none;
                border-radius: 50%;

                span {
                  width: 34px;
                  height: 34px;
                  border: none;
                  color: #ffffff;
                }
              }

              .disable-track {
                span {
                  opacity: 0.5;
                }
              }

              .MuiTabs-indicator {
                display: none;
              }
            }
          }

          .racing-detail-head {
            grid-column-gap: 31px;
            column-gap: 31px;
            padding: 25px 0px 0px;

            @media screen and (max-width: 1599px) {
              display: grid;
              grid-template-columns: auto auto;
              grid-row-gap: 10px;
            }

            @media screen and (max-width: 1440px) {
              display: flex;
            }

            h6 {
              font-size: 16px;
              line-height: 19px;
              font-weight: 600;
            }

            span {
              font-size: 16px;
              line-height: 19px;
              color: $color-Black;
            }
          }
        }

        .QR-Code {
          @media screen and (max-width: 1440px) {
            position: relative;
          }

          .qr-code-wrap {
            position: absolute;
            top: 10px;
            right: 109px;
            z-index: 999;

            @media screen and (max-width: 1440px) {
              top: 11px;
            }
          }

          img {
            position: absolute;
            right: -60px;
            top: -35px;

            @media screen and (max-width: 1440px) {
              position: relative;
            }
          }
        }
      }
    }

    .race-table-details {
      padding: 36px 19px 0px 0px;

      // margin-top: 36px;
      .table-responsive {
        max-height: calc(100vh - 380px);

        .racing-data-details {
          thead {
            tr {
              border-top: 1px solid #003764;

              th {
                border-top: 1px solid #003764;
                border-bottom: 1px solid #003764;
                font-size: 22.4px;
                line-height: 31.6px;
                font-family: $primaryFont;
                color: #4455c7;
                text-transform: uppercase;
                padding: 9px 0px;
              }
            }
          }

          tbody {
            tr {
              border-bottom: 1px solid #e7e9ec;

              td {
                padding: 9px 0px;
              }

              .runner-details {
                h6 {
                  font-size: 16px;
                  line-height: 19px;
                  font-weight: 600;
                  color: $color-Black;
                  font-family: $regulerFont;
                }
              }

              .odds-details {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }

  .no-odds {
    padding: 2px 5px;
    background-color: $color-grey-Accent-1;
    color: $color-White;
    border-radius: 14px;
    font-size: 12px;
    cursor: pointer;
  }

  .ad-scrren {
    img {
      position: fixed;
      bottom: 0;
    }
  }
}

.current-best {
  background-color: $color-highlight !important;
}

.sponsored-head {
  background: linear-gradient(
    180deg,
    rgba(252, 71, 20, 1) 0%,
    rgba(243, 70, 23, 1) 15%,
    rgba(142, 64, 55, 1) 40%,
    rgba(100, 61, 68, 1) 60%,
    rgba(53, 58, 83, 1) 70%,
    rgba(106, 62, 66, 1) 85%,
    rgba(0, 55, 100, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sponsored-odds {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  text-align: center;

  td {
    border: none;
  }

  @media only screen and (max-width: 799px) {
    td {
      padding: 3px 0px 3px 0px !important;
    }
  }

  .sponsored-odds-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
