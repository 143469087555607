@import "../../../../../assets/scss/variable.scss";


.landing-home-left-wrap {

    .hander-text {
        font-size: 31.36px;
        line-height: 40px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Primary;
        letter-spacing: 0px;
        margin: 0px;

        @media (max-width: 1080px) {
            font-size: 22.4px;
            line-height: 28px;
        }
    }

    .landing-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;

        .view-all-text {
            font-size: 18px;
            line-height: 21px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Accent-1;
            letter-spacing: 0px;
            text-decoration: underline;
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            @media (max-width: 1080px) {
                font-size: 14px;
                line-height: 16px;
            }

            svg {
                path {
                    stroke: $color-Accent-1;
                }
            }
        }
    }
}