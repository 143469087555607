@import "../../../../assets/scss/variable.scss";

.experts-tips-slider-wrap {
  margin-bottom: 0px;

  .experts-tips-slider-container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;

    @media (max-width: 1360px) {
      grid-template-columns: 100%;
    }

    @media (max-width: 799px) {
      gap: 50px;
    }

    .experts-tips-slick-slider {
      padding: 0px 30px;

      @media (max-width: 599px) {
        padding: 0px 10px;
      }

      //   .slick-slide {
      //     @media (max-width: 425px) {
      //       padding: 0px 5px;
      //     }
      //   }
      //   transform: scale(1);
      //   transition: transform 0.3s ease;
      //   .slick-list {
      //     overflow: visible;
      //   }
      //   .slick-track {
      //     display: flex;
      //   }
      //   .slick-slide {
      //     flex: 0 0 66.666%; / Show full slide and half of the next /
      //     max-width: 66.666%;
      //   }
      //   .slick-current {
      //     transform: scale(1);
      //   }
      .slick-prev {
        left: 9px;
        color: $color-Primary;

        @media (max-width: 799px) {
          left: 0px;
          bottom: -32px;
          top: auto;
        }

        &::before {
          content: "";
        }
      }

      .slick-disabled {
        opacity: 0.5;
      }

      .slick-next {
        right: 15px;
        color: $color-Primary;

        @media (max-width: 799px) {
          right: 8px;
          bottom: -32px;
          top: auto;
        }

        &::before {
          content: "";
        }
      }

      .slick-dots {
        li {
          button {
            &::before {
              color: #4455c7;
            }
          }
        }

        li.slick-active {
          button {
            &::before {
              color: #4455c7;
              font-size: 15px;
            }
          }
        }
      }

      .slick-list {
        .slick-track {
          margin-left: initial;
          margin-right: initial;
        }
      }

      .experts-tips-slider-details {
        width: 291px;
        // width: 100%;
        height: 100%;
        // max-height: 259px;
        background: $color-White;
        border-radius: 8px;
        box-shadow: 0 1px 9px RGB(0 0 0 / 16%);
        // margin: 12px 7px;
        // @media (max-width: 1360px) {
        //   max-width: fit-content;
        // }
        margin: 12px auto;

        @media (max-width: 799px) {
          //   width: 250px;
          width: 90%;
        }

        .slider-img {
          height: 168px;

          //   height: 100%;
          @media (max-width: 799px) {
            height: 145px;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 8px 8px 0px 0px;
            object-fit: cover;
          }
        }

        .details-wrapper {
          padding: 9px;

          .details-title {
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            letter-spacing: 0;
            color: $color-Black;
            margin-bottom: 17px;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            @media (max-width: 799px) {
              font-size: 14px;
              margin-bottom: 6px;
            }
          }

          .tips-analyst-details {
            font-size: 11.42px;
            line-height: 14px;
            letter-spacing: 0;
            font-weight: 400;
            color: $color-grey-Accent-1;

            .tips-analyst-name {
              color: $color-Accent-1;
            }
          }
        }

        .sports-details-wrapper {
          .tips-sport-title {
            font-size: 13px;
            font-family: $primaryFont;
            color: $color-White;
            background: $color-Accent-1;
            border-radius: 23px;
            padding: 3px 6px 1px 6px;
            width: max-content;
            margin-bottom: 6px;
          }

          .sports-name {
            margin-bottom: 12px;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }
      }
    }
  }

  .single-expert-tips-slider-container {
    grid-template-columns: 100%;
  }
}
