@import "../../../../../../assets/scss/variable.scss";


.landing-featured-news-component-wrap {

    .grid-container {
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: 1080px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .slick-slide {
            @media (max-width:1080px) {
                width: calc(100vw - 24px);
            }
        }

        .grid-item1 {
            @media (max-width:1080px) {
                height: 440px
            }
        }
    }
}