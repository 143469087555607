@import "../../../../../../assets/scss/variable.scss";

.player-info-wrap {
  .bredcrumn-details {
    padding: 45px 18px 0px;
    overflow-x: scroll;

    @media (max-width: 799px) {
      padding: 22px 12px 0px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;
      min-width: 465px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .bredcrumn-details::-webkit-scrollbar {
    height: 0px;
  }

  .bredcrumn-details::-webkit-scrollbar-thumb {
    display: none;
  }

  .player-details {
    padding: 17px 18px 19px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 799px) {
      padding: 9px 12px 12px;
    }

    .player-ul {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-Light-grey;

      .player-name {
        display: flex;
        align-items: center;

        .player-label,
        .player-value {
          font-size: 22.4px;
          font-weight: 400;
          line-height: 31.36px;

          font-family: $primaryFont;
        }

        .player-label {
          color: $color-Primary;
        }

        .player-value {
          color: $color-Secondary;
        }

        @media (max-width: 799px) {
          font-size: 31.6px;
          line-height: 43.9px;
          margin-right: 0px;
        }
      }

      .player-scroll {
        overflow-x: scroll;
      }

      .player-scroll::-webkit-scrollbar {
        height: 0px;
      }

      .player-scroll::-webkit-scrollbar-thumb {
        display: none;
      }

      .player-scroll-horse {
        overflow-x: scroll;

        ul {
          min-width: 535px;
        }
      }

      .player-scroll-horse::-webkit-scrollbar {
        height: 0px;
      }

      .player-scroll-horse::-webkit-scrollbar-thumb {
        display: none;
      }

      ul {
        display: flex;
        list-style-type: none;
        align-items: center;
        padding: 0px;
        margin: 0px;
        min-width: 390px;

        @media (max-width: 799px) {
          margin-top: 6px;
        }

        li {
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          color: #191919;
          position: relative;
          display: flex;
          align-items: center;

          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
          }

          img {
            margin-right: 10px;
            width: 22.79px;
            height: 13.29px;
            object-fit: contain;

            @media (max-width: 799px) {
              margin-right: 6.2px;
            }
          }
        }

        li:not(:last-child):after {
          content: "/";
          font-size: 16px;
          color: $color-Black;
          font-family: $regulerFont;
          position: absolute;
          right: -14px;
        }

        li:not(:last-child) {
          margin-right: 24px;

          @media (max-width: 799px) {
            margin-right: 16px;
          }
        }
      }
    }

    .player-tab {
      .player-tab-detail {
        margin-top: 31px;
        border-bottom: 2px solid #4455c7;

        @media (max-width: 799px) {
          margin-top: 14px;
        }

        .MuiTab-textColorInherit {
          opacity: 1;
        }

        .active {
          .MuiTab-wrapper {
            color: $color-Accent-1;
          }
        }

        .MuiTab-wrapper {
          font-size: 16px;
          font-family: VeneerClean-Soft !important;
          color: #191919;
          line-height: 21px;
        }

        .MuiTabs-indicator {
          height: 3px;
          color: #003764;
          background-color: #003764;
        }
      }
    }

    .tabs-header {
      margin-top: 14px;

      @media (max-width: 799px) {
        margin-top: 9px;
      }

      .tabs-para {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #191919;

        @media (max-width: 799px) {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .para-1 {
        line-height: 17px;
        font-size: 14px;
        color: #8d8d8d;
        margin-top: 9px;

        @media (max-width: 799px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }

  .player-tab-details {
    margin-top: 18px;

    .profile-info-wrap {
      .profile-details-wrap-1 {
        padding: 20px 18px 31px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000001f;
        margin-bottom: 18px;

        @media (max-width: 799px) {
          padding: 0px 12px 9px;
        }
      }

      .profile-details-wrap-2 {
        padding: 20px 18px 31px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000001f;
        margin-bottom: 18px;

        @media (max-width: 799px) {
          padding: 0px 12px 9px;
        }
      }
    }

    .player-form-wrap {
      background-color: $color-White;
      padding: 0 18px;
      box-shadow: 0px 3px 9px 0px #0000000d;

      @media (max-width: 799px) {
        padding: 0px;
      }

      .form-table,
      .mobileview-forms-wrap {
        .MuiTableCell-body {
          font-size: 16px;
          line-height: 20px;
          color: $color-Black;
        }

        .MuiTableCell-root {
          padding: 18px 10px 18px 0px;
        }

        .place-wrap {
          min-width: 83px;

          @media (max-width: 799px) {
            min-width: auto;
            text-align: center;
            background: #d6d9f3;
            padding: 10px 13px;
            font-weight: 600;
            color: $color-Black;
          }

          .player-rank {
            padding: 21px 20px 21px 20px;
            background: #d6d9f3;
            clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
            border-radius: 8px;
            display: block;
            width: 30px;

            .place {
              font-weight: 600;
            }
          }

          .first-place {
            background: $color-Accent-1;
            color: $color-White;
          }
        }

        .first-place {
          @media (max-width: 799px) {
            background: $color-Accent-1;
            color: $color-White;
          }
        }

        .form-extra-details {
          max-width: 468px;
          color: $color-Black;

          @media (max-width: 799px) {
            max-width: none;
            padding: 9px 12px;
          }

          @media (max-width: 479px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .track-condition {
          padding: 0 5px;
          border-radius: 10px / 5px;
          color: $color-White;
        }

        .heavy {
          background: $color-Secondary;
        }

        .soft {
          background: $color-Accent-1;
        }

        .good {
          background: $color-Affirmative;
        }

        .race-position-wrap {
          list-style: none;
          padding-left: 0px;
          margin: 0px;

          li {
            display: flex;

            span {
              color: $color-Black;
            }

            span:first-child {
              margin-right: 5px;
              min-width: 30px;
            }
          }

          @media (max-width: 799px) {
            padding: 0px 0px 9px;
            margin: 0px 12px 0px;
            border-bottom: 1px solid $color-Light-grey;
          }

          @media (max-width: 479px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .form-detail {
          padding-bottom: 10px;
        }
      }

      .show-more {
        display: flex;
        justify-content: flex-end;
        color: $color-Secondary;
        text-decoration: underline;
        padding: 18px 0px 9px;
        font-size: 18px;
        line-height: 21px;
      }
    }

    .galleryinfo-wrap {
      padding: 30px 18px 20px;
      background-color: $color-White;

      @media (max-width: 799px) {
        padding: 0px 12px;
      }

      .gallery-wrap {
        display: grid;
        grid-template-columns: auto auto auto auto auto;

        @media (max-width: 1360px) {
          grid-template-columns: auto auto auto;
        }

        @media (max-width: 799px) {
          grid-template-columns: auto auto;
        }

        @media (max-width: 440px) {
          grid-template-columns: auto;
        }

        .gallery-detail-wrap {
          padding-bottom: 18px;

          .gallery-header {
            display: none;

            @media (max-width: 440px) {
              display: block;
              font-size: 15px;
              line-height: 16px;
              font-weight: 600;
              margin-bottom: 6px;
            }
          }

          .gallery-img-wrap {
            @media (max-width: 799px) {
              max-width: 95%;
            }

            @media (max-width: 440px) {
              max-width: 100%;
            }

            img {
              @media (max-width: 799px) {
                width: 100%;
              }
            }
          }

          .gallery-text-wrap {
            max-width: 200px;
            font-size: 16px;
            line-height: 20px;
            color: $color-Black;

            @media (max-width: 799px) {
              max-width: 95%;
            }

            @media (max-width: 440px) {
              max-width: 100%;
            }
          }
        }

        .gallery-detail-wrap:not(:last-child) {
          @media (max-width: 440px) {
            border-bottom: 1px solid #bdbdbf;
            padding-bottom: 6px;
            margin-bottom: 6px;
          }
        }
      }
    }

    .horses-info-wrap {
      padding: 19px 18px 32px;
      background-color: $color-White;

      @media (max-width: 799px) {
        padding: 0px 12px;
      }

      h1 {
        font-size: 22.4px;
        line-height: 31.36px;
        font-weight: 400;
        font-family: VeneerClean-Soft !important;
        border-bottom: 1px solid $color-Accent-1;
        color: $color-Black;
      }

      .horsesinfo-header {
        padding-top: 34px;

        @media (max-width: 799px) {
          padding-top: 18px;
        }
      }

      h4 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: $color-Accent-1;
        text-transform: uppercase;
        cursor: pointer;
      }

      .detail-label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;

        @media (max-width: 799px) {
          font-size: 12px;
          line-height: 20px;
        }
      }

      .detail-info {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;

        @media (max-width: 799px) {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .horse-detail-wrap {
        padding: 18px 0px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);

        @media (max-width: 799px) {
          padding: 9px 0px;
        }

        .horse-detail {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;

          @media (max-width: 540px) {
            display: grid;
            grid-template-columns: 65% 35%;
            margin-top: 5px;
          }

          .next-race-detail {
            @media (max-width: 540px) {
              border-right: 1px solid #bdbdbf;
            }

            .next-race-details-info {
              display: flex;

              @media (max-width: 540px) {
                flex-direction: column;
              }
            }
          }

          .horse-performance {
            display: flex;

            @media (max-width: 540px) {
              flex-direction: column;
              margin-left: auto;
            }

            .left-detail {
              margin-right: 50px;

              @media (max-width: 540px) {
                margin-right: 0px;
              }
            }
          }
        }
      }
    }
  }

  .details-table {
    .table-wrap {
      .table-head-row {
        border-bottom: 1px solid #989898;

        .head-row-span {
          font-size: 14px;
          color: #8d8d8d;
          font-weight: 400;
        }

        th {
          font-size: 16px;
          font-weight: 600;
          color: #191919;
          font-family: $regulerFont;
        }

        th:not(:first-child) {
          text-align: center;
        }

        @media (max-width: 799px) {
          .MuiTableCell-root {
            padding: 16px 0px;
          }
        }
      }

      .table-body-row {
        border-bottom: 1px solid #e7e9ec;

        .body-row-name {
          font-weight: 600;
        }

        .clickable {
          cursor: pointer;
        }

        td {
          font-size: 16px;
          font-weight: 400;
          color: #191919;
          font-family: $regulerFont;
        }

        td:not(:first-child) {
          text-align: center;
        }

        @media (max-width: 799px) {
          .MuiTableCell-root {
            padding: 9px 0px;
          }
        }
      }

      .MuiTableCell-root {
        padding: 24px 0px 17px;
      }

      .no-data-td {
        border-bottom: none;
        text-align: center;
        padding: 12px;
        font-size: 16px;
        font-weight: 600;
        color: #191919;
        font-family: $regulerFont;
      }
    }
  }
}