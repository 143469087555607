@import "../../../assets/scss/variable.scss";

.cart-payment-wrap {
  background-color: $color-off-White;

  .cart-payment-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .page-deatils-wrap {
    margin-top: 18px;
    padding: 17px 50px 18px 50px;
    // padding-bottom: 9px;
    // background-color: $color-White;
    // box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 940px) {
      padding: 16px 13px 16px 13px;
    }

    .back-btn-sec {
      .back-btn {
        padding: 0;
        color: $color-Accent-1;
        text-decoration: underline;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 22.4px;
        text-transform: inherit;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .cart-total-sec {
      margin-top: 4px;
      display: flex;
      align-items: start;
      column-gap: 18px;

      @media (max-width: 1120px) {
        flex-direction: column-reverse;
      }
      .cart-accordian-wrap {
        width: 56%;
        @media (max-width: 1120px) {
          width: 100%;
        }
      }
      .title-sec {
        cursor: pointer;
        padding: 21px 18px 10px 18px;
        border-bottom: 1px solid $color-grey;
        .title {
          color: $color-Black;
          font-family: $primaryFont;
          font-size: 22.4px;
          line-height: 31.36px;
          font-weight: 400px;
        }
      }

      .cart-sec {
        width: 100%;
        border-radius: 8px;
        // box-shadow: 0px 3px 9px 0px #0000000d;
        box-shadow: 10px 10px 5px 0px #0000000f;
        -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
        -moz-box-shadow: 10px 10px 5px 0px #0000000f;

        @media (max-width: 1120px) {
          margin-top: 18px;
          width: 100%;
        }

        .cart-items-sec {
          padding: 20px 18px;
          .cart-item {
            margin-top: 18px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            padding-bottom: 18px;
            border-bottom: 1px solid $color-grey;

            &:first-child {
              margin-top: 0px;
            }

            .activity-sec {
              display: flex;
              justify-content: start;
              column-gap: 12px;

              .user-img {
                height: 45px;
                width: 45px;
                border-radius: 50%;
                // margin-top: -60px;

                // @media (max-width: 560px) {
                //   height: 50px;
                //   width: 50px;
                // }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .activity-detail {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                max-width: calc(100% - 57px);
                .tip-txt {
                  color: $color-Black;
                  font-size: 16px;
                  line-height: 22.4px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  span {
                    font-weight: 600;
                    color: $color-Accent-1;
                  }
                }

                .time-txt {
                  color: $color-Black;
                  font-size: 16px;
                  line-height: 22.4px;
                  font-weight: 400;
                  font-family: $regulerFont;
                }
              }
            }
          }

          .continue-btn-sec {
            margin-top: 20px;
            text-align: center;
            width: 100%;

            .continue-btn {
              padding: 11px 24px 13px;
              border-radius: 8px;
              color: $color-White;
              background-color: $color-Accent-1;
              font-size: 16px;
              line-height: 21px;
              font-weight: 400;
              font-family: $regulerFont;
              text-transform: capitalize;

              @media (max-width: 799px) {
                // font-size: 11.42px;
                // line-height: 14px;
                padding: 12px 17px 12px;
              }
              @media (max-width: 460px) {
                width: 100%;
              }
            }
          }
        }
      }
      .total-sec {
        width: 44%;
        background-color: #e8eaec;
        border-radius: 8px;

        @media (max-width: 1120px) {
          width: 100%;
        }

        .border-bottom-blue {
          border-bottom: 1px solid $color-Accent-1 !important;
        }
        .total-ammount-sec {
          padding: 20px 18px 8px 18px;
          border-bottom: 1px solid $color-grey;
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 5px;

          .left-txt {
            color: $color-Black;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 400;
            font-family: $regulerFont;
          }

          .ammount {
            color: $color-Accent-1;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            font-family: $regulerFont;
          }
        }

        .coupon-sec {
          padding: 10px 20px 21px 18px;

          .gift-txt {
            color: $color-Black;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 400;
            font-family: $regulerFont;
          }

          .send-box {
            display: flex;
            align-items: center;
            column-gap: 8px;
            .text-field {
              width: 100%;

              .MuiInputBase-input {
                padding: 10px 10px;
                // max-width: 439px;
                // width: 100%;
                min-height: 25px;
                font-family: $regulerFont;
                font-size: 16px;
                line-height: 20px;
              }

              .MuiInputBase-root {
                background-color: $color-White !important;
                border-radius: 8px;
              }

              input[type="number"]::-webkit-inner-spin-button,
              input[type="number"]::-webkit-outer-spin-button {
                display: none;
              }

              .Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                  border-color: $color-Accent-1;
                  border-width: 1;
                }
              }

              .MuiOutlinedInput-adornedEnd {
                justify-content: space-between;
              }
            }

            .send-btn {
              max-width: 86px;
              width: 100%;
              padding: 11px 25px 12px 25px;
              background-color: $color-Accent-1;
              color: $color-White;
              font-size: 16px;
              line-height: 21px;
              font-weight: 400;
              font-family: $regulerFont;
              border-radius: 8px;
              text-transform: capitalize;
              cursor: pointer;
              // @media (max-width: 579px) {
              //   font-size: 11.42px;
              // }
            }
          }
        }
      }
    }

    .payment-sec {
      margin-top: 18px;
      box-shadow: 10px 10px 5px 0px #0000000f;
      -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
      -moz-box-shadow: 10px 10px 5px 0px #0000000f;
      border-radius: 8px;
      // max-width: 570px;
      width: 100%;
      .title-sec {
        padding: 21px 20px 10px 20px;
        border-bottom: 1px solid $color-grey;
        .title {
          color: $color-Black;
          font-family: $primaryFont;
          font-size: 22.4px;
          line-height: 31.36px;
          font-weight: 400px;
        }
      }

      .payment-info {
        padding: 20px 18px 21px 18px;

        .payment-select {
          .payment-radioGroup {
            .MuiFormControlLabel-root {
              &:not(:last-child) {
                margin-bottom: 19px;
              }
            }

            .MuiButtonBase-root {
              padding: 0px 9px 0px 12px;
            }
            .d-flex {
              display: flex !important;
            }

            .payment-label {
              align-items: center;

              .label {
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-Black;
                margin-right: 8px;
              }

              .card-section {
                display: flex;
                column-gap: 8px;
              }
            }

            .save-card {
              align-items: flex-start;

              .MuiTypography-root {
                width: 100%;
              }

              .save-payment-card-label {
                display: flex;
                justify-content: space-between;

                p {
                  font-size: 16px;
                  line-height: 19px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  color: $color-Black;
                }

                .bold {
                  font-weight: 600;
                }
              }
            }
          }
        }

        .card-details {
          margin-top: 28px;
        }

        .mb-19 {
          margin-bottom: 19px;
        }

        .date-cvv-wrap {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          column-gap: 18px;

          @media (max-width: 799px) {
            flex-direction: column;
            row-gap: 18px;

            .card-info {
              width: 100%;
            }
          }
        }

        .card-info {
          //   padding: 20px 18px 21px 18px;

          //   @media (max-width: 799px) {
          //     padding: 17px 12px 25px 12px;
          //   }

          .textfield-text {
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            font-family: $regulerFont;
            margin-bottom: 4px;
            color: $color-Black;

            @media (max-width: 799px) {
              font-size: 11.42px;
              line-height: 14px;
            }

            .star {
              color: #d84727;
            }
          }

          .cvv-text {
            display: flex;
            align-items: center;
            column-gap: 5px;
          }

          .details-textfield {
            width: 100%;

            .MuiInputBase-root {
              font-size: 16px;
              line-height: 19px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-Black;
              border-radius: 8px;

              .MuiInputBase-input {
                padding: 13px 14px;
              }

              .MuiOutlinedInput-notchedOutline {
                border-color: $color-grey;
              }

              input[type="number"]::-webkit-inner-spin-button,
              input[type="number"]::-webkit-outer-spin-button {
                display: none;
              }
            }

            .Mui-focused {
              .MuiOutlinedInput-notchedOutline {
                border-color: $color-Accent-1;
                border-width: 1px;
              }
            }
          }
        }

        .date-card-info {
          width: 50%;
        }

        .cvv-card-info {
          width: 50%;
        }

        .checkBox-wrap {
          border-bottom: 1px solid $color-Accent-1;
          padding-bottom: 18px;

          .documentsRead-check {
            .MuiButtonBase-root {
              margin-right: 12px;
              padding: 0px 0px 0px 10px;

              &:hover {
                background-color: transparent;
              }

              .MuiSvgIcon-root {
                path {
                  fill: $color-grey;
                }
              }

              .MuiTouchRipple-root {
                display: none;
              }
            }

            .Mui-checked {
              .MuiSvgIcon-root {
                path {
                  fill: $color-Primary;
                }
              }
            }

            .MuiTypography-root {
              font-size: 16px;
              line-height: 19px;
              font-weight: 400;
              font-family: $regulerFont;
              letter-spacing: 0px;
              color: #989898;
            }
          }
        }

        .total-amount {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 25px;
          border-bottom: 1px solid $color-grey;
          margin-top: 7px;

          p {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
          }

          .bold {
            font-weight: 600;
          }
        }

        // .w-100 {
        //   width: 100% !important;
        // }

        // .subscribe-wrap-btn {
        //   text-align: center;
        //   margin-top: 19px;
        //   margin-bottom: 8px;

        //   .subscribe-btn {
        //     padding: 13px 24px 12px;
        //     background-color: $color-Accent-1;
        //     color: $color-White;
        //     font-size: 16px;
        //     line-height: 19px;
        //     font-weight: 400;
        //     font-family: $regulerFont;
        //     border-radius: 8px;
        //     text-transform: capitalize;
        //     box-shadow: none;

        //     .MuiTouchRipple-root {
        //       display: none;
        //     }

        //     @media (max-width: 460px) {
        //       width: 100%;
        //     }
        //   }
        // }

        .subscribe-note {
          text-align: center;

          p {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-grey-Accent-1;

            @media (max-width: 799px) {
              font-size: 11.42px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
}

.w-100 {
  width: 100% !important;
}

.subscribe-wrap-btn {
  text-align: center;
  margin-top: 19px;
  margin-bottom: 8px;

  .subscribe-btn {
    padding: 13px 24px 12px;
    background-color: $color-Accent-1;
    color: $color-White;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: $regulerFont;
    border-radius: 8px;
    text-transform: capitalize;
    box-shadow: none;

    .MuiTouchRipple-root {
      display: none;
    }

    @media (max-width: 460px) {
      width: 100%;
    }
  }
}
