@import "../../../assets/scss/variable.scss";

.enquiries-wrap {
  padding: 33px 18px 36px;
  background-color: #fbfbfb;

  @media (max-width: 539px) {
    padding: 27px 12px;
  }

  .advertising-head {
    font-size: 43.9px;
    line-height: 56px;
    font-family: $primaryFont;
    margin-bottom: 19px;
    font-weight: 400;
    color: $color-Black;

    @media (max-width: 539px) {
      font-size: 31.6px;
      line-height: 40px;
      margin-bottom: 18px;
    }
  }

  .advertising-contact {
    font-size: 16px;
    line-height: 19px;
    font-family: $regulerFont;
    font-weight: 700;
    color: $color-Black;
    margin-bottom: 20px;

    @media (max-width: 1160px) {
      br {
        display: none;
      }
    }
  }

  .general-box {
    display: flex;

    .general-advertising {
      margin-bottom: 18px;
    }

    .contact-link {
      @media (max-width: 539px) {
        display: flex;
        flex-direction: column;
      }

      .email {
        margin-left: 11px;
        margin-right: 3px;
        font-weight: 400;

        @media (max-width: 539px) {
          margin-bottom: 5px;
        }

        a {
          color: #1a73e8;
        }
      }

      .phone {
        @media (max-width: 539px) {
          margin-left: 11px;
        }

        a {
          color: $color-Black;
          font-weight: 400;
        }
      }
    }
  }

  .advertising-link {
    list-style-type: none;
    margin: 0px;
    padding: 0px;

    li {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      font-family: $regulerFont;
      color: $color-Primary;

      .download-icon {
        margin-right: 12px;
        vertical-align: middle;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}

.advertising-with-us-wrap {
  padding: 34px 31px 50px;
  background-color: #ffffff;
  box-shadow: 0px 3px 9px 0px #0000000d;

  @media (max-width: 539px) {
    padding: 21px 12px;
  }

  .with-us-title {
    font-size: 22.4px;
    line-height: 28px;
    font-family: $primaryFont;
    font-weight: 400;
    color: $color-Primary;
    margin-bottom: 21px;
  }

  .details {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    @media (max-width: 799px) {
      display: block;
      margin-bottom: 16px;
    }

    .textfield-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      // font-family: $regulerFont;
      font-family: "Inter", sans-serif;
      width: 218px;

      @media (max-width: 539px) {
        font-size: 15px;
      }
    }

    .details-textfield {
      width: 74%;

      @media (max-width: 799px) {
        width: 100%;
        margin-top: 5px;
      }

      .MuiOutlinedInput-root {
        border-radius: 8px;
        font-family: "Inter", sans-serif;

        .MuiOutlinedInput-input {
          padding: 13px 11px;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          display: none;
        }
      }

      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: #4455c7;
          border-width: 1;
        }
      }

      svg {
        cursor: pointer;
      }
    }

    .select-state {
      width: 74%;

      @media (max-width: 799px) {
        width: 100%;
        margin-top: 5px;
      }

      .state-field {
        .select__control {
          min-height: 45px;
          border-radius: 8px;
          font-family: $regulerFont;

          // &:hover {
          //     border-color: $color-Primary;
          // }

          .select__placeholder {
            font-family: $regulerFont;
          }
        }

        .select__indicators {
          .select__indicator-separator {
            display: none;
          }

          .select__indicator {
            padding: 14.5px;
          }
        }

        .select__menu,
        .react-select__menu {

          .select__menu-list,
          .react-select__menu-list {

            .select__option,
            .react-select__option {
              cursor: pointer;
              font-size: 16px;
              color: $color-Black;
              font-family: $regulerFont;
              line-height: 19px;
              padding: 11px;

              &.select__option--is-focused {
                background-color: #d4d6d8;
                color: #000;
              }

              &.select__option--is-selected {
                background-color: #d4d6d8;
                color: $color-Black;
              }
            }
          }

          .select__menu-list,
          .react-select__menu-list {
            .select__group {
              .select__group-heading {
                margin-bottom: 0.5rem;
                color: green;
                font-weight: bolder;
                font-size: inherit;
              }
            }
          }
        }
      }
    }
  }

  .interested-wrap {
    display: flex;
    margin-bottom: 31px;

    @media (max-width: 539px) {
      flex-direction: column;
    }

    .interested-label {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      // font-family: $regulerFont;
      font-family: "Inter", sans-serif;
      width: 218px;
      color: #191919;

      @media (max-width: 539px) {
        font-size: 15px;
      }
    }

    .checkbox-list {
      display: flex;
      flex-direction: column;
      width: 74%;
    }

    .interested-check {
      .PrivateSwitchBase-root-5 {
        padding: 9.5px;
      }

      .Mui-checked {
        color: #003764;
      }

      .interested-checkbox {
        .MuiSvgIcon-root {
          width: 16px;
          height: 16px;
          border-radius: 4px;
        }
      }

      .MuiTouchRipple-root {
        display: none;
      }

      .MuiTypography-root {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;

        @media (max-width: 539px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }
    }
  }

  .details-textarea {
    align-items: flex-start;
    margin-bottom: 27px;

    .details-textarea-field {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      // font-family: $regulerFont;
      font-family: "Inter", sans-serif;
      padding: 13px 11px;
      border-radius: 8px;
      border-color: #d4d6d8;

      @media (max-width: 799px) {
        width: 96% !important;
        height: 174px !important;
        margin-top: 5px;
      }

      @media (max-width: 539px) {
        font-size: 15px;
      }

      @media (max-width: 439px) {
        width: 91% !important;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }

  .star-red {
    color: #d84727;
  }

  .advertise-btn {
    display: flex;
    justify-content: flex-end;

    .btn-save {
      background-color: #4455c7;
      color: #ffffff;
      border-radius: 8px;
      padding: 17px 34px 16px;
      max-height: 54px;
      text-transform: capitalize;
      font-size: 18px;

      @media (max-width: 600px) {
        font-size: 16px;
        width: 100%;
      }

      .MuiTouchRipple-root {
        display: none;
      }

      &:hover {
        box-shadow: none;
        background-color: #4455c7;
      }
    }
  }
}