@import "../../../../assets/scss/variable.scss";

.comp-component-wrap {
  border-radius: 8px;
  box-shadow: 0px 1px 9px 0px #0000003b;
  @media (max-width: 799px) {
    border-radius: 0px;
    margin: 0px -12px;
  }

  .comp-container {
    .comp-table {
      min-width: 700px;
      @media (max-width: 799px) {
        min-width: 475px;
      }
      .comp-table-header-wrap {
        background-color: $color-Light-grey;
        .comp-header {
          font-size: 16px;
          line-height: 20px;
          color: $color-Primary;
          font-weight: 600;
          font-family: $regulerFont;
          text-align: center;
          padding: 12px;
          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
            padding: 6px 12px;
          }
        }
        .comp-name-header {
          text-align: left;
          padding: 12px 56px;
          @media (max-width: 799px) {
            padding: 6px 12px;
          }
        }
        .align-left {
          text-align: left;
        }
      }
      .comp-table-body-wrap {
        .MuiTableCell-body {
          padding: 6px;
          text-align: center;
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          color: $color-Black;
          font-family: $regulerFont;
          border-bottom: 1px solid $color-Light-grey-1;
        }
        // .MuiTableRow-root:not(:last-child) {
        //   border-bottom: 1px solid $color-Light-grey-1;
        // }
        .comp-name-wrap {
          text-align: left;
          padding: 6px 56px;
          @media (max-width: 799px) {
            padding: 6px 12px;
          }
        }
        .comp-name {
          font-size: 16px;
          line-height: 20px;
          @media (max-width: 799px) {
            font-size: 14px;
            line-height: 17px;
          }
        }
        .owner-name {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          text-align: left;
          text-transform: capitalize;
          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }
        .name-img-wrap {
          display: flex;
          align-items: center;
          column-gap: 5px;
          .comp-img-wrap {
            max-width: 38px;
            min-width: 38px;
            height: 38px;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .comp-owner-wrap {
            @media (max-width: 799px) {
              display: flex;
              flex-direction: column;
              row-gap: 5px;
            }
          }
        }
        .comp-btn {
          padding: 6px 12px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          font-family: $regulerFont;
          border-radius: 8px;
          text-transform: capitalize;
        }
        .view-btn {
          background-color: $color-Accent-1;
          color: $color-White;
        }
        .join-btn {
          background-color: $color-White;
          color: $color-Accent-1;
          border: 1px solid $color-Accent-1;
        }
      }
    }
  }
  .view-more {
    padding: 9px 25px;
    text-align: right;

    .view-more-text {
      font-size: 12px;
      line-height: 16px;
      color: #4455c7;
      font-weight: 400;
      font-family: $regulerFont;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
