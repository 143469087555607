.tournament-team {
  display: flex;
  column-gap: 5px;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 799px) {
    flex-direction: column;
    .series-select {
      width: 100%;
    }
  }
}
