.comingsoon-wrap {
  .social-wrap {
    display: flex;
    column-gap: 12px;
    justify-content: center;
    padding: 5px 0px 20px 0px;
  }
  .title-orange {
    color: #fc4714;
    font-family: VeneerClean-Soft !important;
  }
  h1 {
    color: #191919;
    margin: 33px 0px 18px 0px;
    text-align: center;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    color: #191919;
    margin-bottom: 25px;
  }
  b {
    color: #003764;
    font-weight: 600;
  }
  .orange {
    color: #fc4714;
  }
}
.our-partner {
  h1 {
    text-align: center;
    margin: 15px 0px 18px 0px !important;
  }
  h5 {
    font-size: 24px;
    text-align: center;
    margin: 15px 0px 0px 0px;
    padding: 50px 0px;
  }
}
@media only screen and (max-width: 479px) {
  .comingsoon-wrap {
    .social-wrap {
      padding: 0px;
    }
    h1 {
      font-size: 31.36px;
      line-height: 43.9px;
      margin: 41px 0px 9px 0px;
    }
    p:last-of-type {
      margin-bottom: 9px;
    }
  }
}
