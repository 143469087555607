@import "../../../assets/scss/variable.scss";

.our-team-wrap {
  background-color: #fbfbfb;

  .team-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .team-details {
    // with search padding
    // padding: 18px 18px 0px;

    // with search padding
    padding: 0px 18px;
    background-color: #ffffff;

    // margin-bottom: 26px;

    @media (max-width: 799px) {
      padding: 9px 12px 0px;
    }

    .team-tab-details {
      padding: 18px 0px 9px 0px;
      min-height: 38px;
      border-bottom: 2px solid #4455c7;

      @media (max-width: 799px) {
        padding: 9px 0px;
        min-height: 32px;
      }

      // .MuiTabScrollButton-root {
      //   // border: 2px solid $color-Primary;
      // }

      .MuiTab-textColorInherit {
        color: $color-Black;
        border: 2px solid $color-Primary;
        margin: 0px 12px;
        border-radius: 8px;
        opacity: 1;
        min-height: 38px;
        padding: 9px 10px;

        @media (max-width: 799px) {
          margin: 0 9px;
          padding: 6px 12px;
          min-height: 32px;
        }
      }

      .active {
        color: $color-White;
        background: $color-Primary;
      }

      .MuiTabs-indicator {
        background: none;
      }

      .MuiTab-textColorInherit:first-child {
        margin-left: 0px;
      }

      .MuiTab-root {
        min-width: initial;
        width: max-content;
      }

      .MuiButtonBase-root {
        font-size: 16px;
        line-height: 20px;
        font-family: $primaryFont;
      }

      .Mui-disabled {
        opacity: 1;
      }

      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          .MuiBox-root:last-child {
            button {
              margin: 0px;
            }
          }
        }
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .page-deatils-wrap {
    // display: flex;
    // flex-wrap: wrap;
    // column-gap: 18px;
    // row-gap: 18px;
    background-color: $color-White;
    padding: 32px 22px;
    box-shadow: 0px 3px 9px 0px #0000000d;
    // @media (max-width: 1349px) {
    //   justify-content: center;
    // }
    // @media (max-width: 799px) {
    //   justify-self: start;
    //   padding: 21px 12px 27px 12px;
    // }
    // @media (max-width: 730px) {
    //   justify-content: center;
    // }

    .allsport-loader-center {
      text-align: center;
    }

    .team-scroll-div {
      overflow: unset !important;
      display: flex;
      flex-wrap: wrap;
      column-gap: 18px;
      row-gap: 18px;
      height: auto;
      // overflow: hidden !important;

      @media (max-width: 1349px) {
        justify-content: center;
      }

      @media (max-width: 799px) {
        justify-self: start;
        padding: 21px 12px 27px 12px;
      }

      @media (max-width: 730px) {
        justify-content: center;
      }

      .card-sec {
        max-height: fit-content;
        max-width: 340px;
        width: 100%;
        //   box-shadow: 0px 3px 9px 0px #0000000d;
        box-shadow: 0px 3px 9px 0px #0000000d;

        border-radius: 8px;

        .title {
          font-weight: 400;
          font-size: 14px;
          color: #4455c7;
          line-height: 18px;
          font-family: $regulerFont;
          text-transform: capitalize;
        }

        .top-sec {
          text-align: center;
          padding: 13px 13px 11px 13px;
          border-bottom: 1px solid #e7e9ec;

          .name {
            font-weight: 600;
            font-size: 22.4px;
            color: #191919;
            line-height: 31.36px;
            font-family: $regulerFont;
          }
        }

        .bottom-sec {
          // height: 65%;
          // height: calc(70% - 90px);
          height: calc(94% - 77px);
          row-gap: 10px;
          padding: 11px 15px 12px 15px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .des-sec {
            .des {
              margin-top: 11px;
              font-weight: 400;
              font-size: 14px;
              color: #191919;
              line-height: 19px;
              font-family: $regulerFont;
            }
          }

          .social-sec {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 15px;

            .social-btn {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.no-data-wrap-bookmakers {
  .no-data {
    padding: 100px 0px 9px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: $regulerFont;
  }
}