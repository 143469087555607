@import "../../../../../assets/scss/variable.scss";

.trackprofiledetailpage-wrap {
  .trackprofiledetailpage-header {
    padding: 45px 18px 0px;
    overflow-x: scroll;

    @media (max-width: 799px) {
      padding: 22px 9px 5px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;
      min-width: 465px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .trackprofiledetailpage-header::-webkit-scrollbar {
    height: 0px;
  }

  .trackprofiledetailpage-header::-webkit-scrollbar-thumb {
    display: none;
  }

  .trackprofile-detail {
    padding: 17px 18px 19px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 799px) {
      padding: 0px 12px 12px;
    }

    .select-wrap {
      max-width: 244px;

      @media (max-width: 799px) {
        max-width: 335px;
      }

      svg {
        fill: #003764;
      }

      .select__single-value {
        color: #191919;

        @media (max-width: 799px) {
          font-size: 31.36px;
          line-height: 43.9px;
        }
      }
    }

    .profile-tab {
      .profile-tab-detail {
        margin-top: 29px;
        border-bottom: 2px solid #4455c7;

        @media (max-width: 799px) {
          margin-top: 6px;
        }

        .MuiTab-textColorInherit {
          opacity: 1;
        }

        .active {
          .MuiTab-wrapper {
            color: $color-Accent-1;
          }
        }

        .MuiTab-wrapper {
          font-size: 16px;
          font-family: VeneerClean-Soft !important;
          color: #191919;
          line-height: 21px;
        }

        .MuiTab-root {
          min-width: 120px;
        }

        .MuiTabs-indicator {
          height: 3px;
          color: #003764;
          background-color: #003764;
        }
      }
    }

    .tabs-header {
      margin-top: 14px;

      @media (max-width: 799px) {
        margin-top: 9px;
      }

      .tabs-para {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #191919;

        @media (max-width: 799px) {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .para-1 {
        line-height: 17px;
        font-size: 14px;
        color: #8d8d8d;
        margin-top: 9px;

        @media (max-width: 799px) {
          font-size: 12px;
          line-height: 16px;
          margin-top: 5px;
        }
      }
    }
  }

  .profils-tab-details {
    margin-top: 18px;

    .news-details-wrap {
      padding: 19px 18px 136px;
      background-color: $color-White;
      box-shadow: 0px 3px 9px 0px #0000000d;

      @media (max-width: 479px) {
        padding: 6px 12px 6px;
      }

      .news-head {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 9px;

        @media (max-width: 479px) {
          font-size: 15px;
          line-height: 16px;
        }
      }

      .news-details {
        display: flex;
        margin-bottom: 19px;

        @media (max-width: 479px) {
          flex-direction: column;
          margin-bottom: 6px;
        }

        .news-img-wrap {
          margin-bottom: 19px;

          @media (max-width: 479px) {
            margin-bottom: 5px;
          }

          img {
            margin-right: 9px;

            @media (max-width: 479px) {
              width: 100%;
              margin-right: 0px;
            }
          }
        }

        .news-text-wrap {
          p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #000000;

            @media (max-width: 479px) {
              font-size: 15px;
              line-height: 16px;
            }
          }

          @media (max-width: 479px) {
            padding-bottom: 5px;
          }
        }
      }

      .news-details-border {
        border-bottom: 1px solid #989898;
      }
    }

    .results-details-wrap {
      .results-details-wrap-1 {
        padding: 19px 18px 18px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000001f;
        margin-bottom: 18px;

        @media (max-width: 799px) {
          padding: 0px;
          margin-bottom: 0px;
        }

        h1 {
          font-size: 22.4px;
          line-height: 31.36px;
          font-weight: 400;
          font-family: VeneerClean-Soft !important;
          border-bottom: 1px solid $color-Accent-1;
          color: $color-Black;

          @media (max-width: 799px) {
            margin: 0px 12px;
          }
        }

        .result-table {
          margin-top: 15px;
          margin-bottom: 18px;

          .MuiTableCell-head {
            font-weight: 600;
            line-height: 20px;
            font-size: 16px;
            color: $color-Black;
            text-align: center;
          }

          .MuiTableCell-head:first-child {
            text-align: left;
          }

          .table-head-des {
            font-weight: 400;
          }

          .race-des {
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            color: #8d8d8d;
          }

          .MuiTableCell-root {
            padding: 14px 13px;
            border: 1px solid #e7e9ec;
            border-collapse: collapse;
            font-size: 16px;
            line-height: 20px;
            color: $color-Black;
            font-family: $regulerFont;
            text-align: center;
          }

          tbody {

            .MuiTableCell-root:first-child,
            .MuiTableCell-root:nth-child(2) {
              text-align: left;
            }
          }

          .rank {
            width: 20px;
            padding: 0 5px;
            background: #e7e9ec;
            color: #999999;
            border-radius: 2px;
            margin-right: 6px;
          }

          .winner {
            width: 20px;
            padding: 0 5px;
            color: $color-White;
            background: $color-Accent-1;
            border-radius: 2px;
            margin-right: 6px;
          }

          .runner-number {
            margin-right: 6px;
          }

          .jockey {
            color: #8d8d8d;
          }

          .tope-tote {
            min-width: 85px;
          }
        }

        &:first-child {
          .mobileview-result-wrap {
            padding-top: 8px;
          }
        }

        .mobileview-result-wrap {
          .mobile-result-table-header {
            background-image: linear-gradient(to right,
                $color-Accent-1,
                #222b64);
            padding: 9px 12px 10px;

            div {
              padding-bottom: 5px;
            }

            .racetrack-number {
              font-size: 16px;
              line-height: 16px;
              font-weight: 600;
              color: $color-White;
            }

            .table-head-des {
              font-size: 16px;
              line-height: 16px;
              font-weight: 400;
              color: $color-White;
            }

            .race-des {
              font-size: 14px;
              line-height: 16px;
              font-weight: 400;
              color: $color-grey;
            }
          }

          .mobileview-race-details {
            padding: 0px 12px 18px;

            .mobileview-result-detail-wrap {
              .animal-jockey {
                display: flex;
                justify-content: space-between;
                padding: 9px 0px;

                .rank {
                  width: 20px;
                  padding: 0 5px;
                  background: #e7e9ec;
                  color: #999999;
                  border-radius: 2px;
                  margin-right: 6px;
                }

                .winner {
                  width: 20px;
                  padding: 0 5px;
                  color: $color-White;
                  background: $color-Accent-1;
                  border-radius: 2px;
                  margin-right: 6px;
                }

                .runnernumber-animal {
                  font-size: 16px;
                  line-height: 16px;
                  font-weight: 600;
                  color: $color-Black;

                  .runner-number {
                    margin-right: 6px;
                  }
                }

                .jockey {
                  font-size: 14px;
                  line-height: 16px;
                  color: #8d8d8d;
                  font-family: $regulerFont;
                }
              }

              .animal-performance {
                display: grid;
                grid-template-columns: 33.33% 33.33% 33.33%;
                padding-bottom: 12px;
                border-bottom: 1px solid #bdbdbf;

                span {
                  text-align: center;
                }

                .tope-tote,
                .sp {
                  border-right: 1px solid #bdbdbf;
                }

                span {
                  font-size: 12px;
                  line-height: 16px;
                }

                .des-label {
                  font-weight: 600;
                }

                .des-info {
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      .results-details-wrap-2 {
        padding: 19px 18px 18px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000001f;

        @media (max-width: 799px) {
          padding: 0px 12px;
        }

        h1 {
          font-size: 22.4px;
          line-height: 31.36px;
          font-weight: 400;
          font-family: VeneerClean-Soft !important;
          border-bottom: 1px solid $color-Accent-1;
          color: $color-Black;
        }

        .latestresult-wrap {
          .latest-result-table {
            margin-top: 15px;

            @media (max-width: 799px) {
              margin-top: 0px;
            }

            .MuiTableRow-root {
              border-left: 1px solid #e7e9ec;
              border-right: 1px solid #e7e9ec;
            }

            .MuiTableRow-root:first-child {
              border-top: 1px solid #e7e9ec;
            }

            .MuiTableCell-root {
              padding: 14px 13px;

              @media (max-width: 799px) {
                padding: 9px 0px 12px;
              }
            }

            .resultlist {
              font-weight: 600;
              line-height: 20px;
              font-size: 16px;
              color: $color-Black;

              @media (max-width: 1024px) {
                display: flex;
                flex-direction: column;
              }

              .resultlist-detail {
                color: #8d8d8d;
                font-weight: 400;
              }
            }

            .track-condition-wrap {
              text-align: center;

              .track-condition {
                font-size: 16px;
                line-height: 22.4px;
                padding: 0 5px;
                border-radius: 12px / 5px;
                color: $color-White;
              }

              .heavy {
                background: $color-Secondary;
              }

              .soft {
                background: $color-Accent-1;
              }

              .good {
                background: $color-Affirmative;
              }
            }
          }
        }
      }
    }

    .jockey-details-wrap {
      .jockey-details-wrap-1 {
        padding: 19px 18px 31px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000001f;
        margin-bottom: 18px;

        @media (max-width: 799px) {
          padding: 0px 12px;
        }
      }

      .jockey-details-wrap-2 {
        padding: 19px 18px 31px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000001f;

        @media (max-width: 799px) {
          padding: 0px 12px 9px;
        }
      }
    }

    .record-details-wrap {
      padding: 19px 18px 31px;
      background-color: $color-White;
      box-shadow: 0px 3px 9px 0px #0000000d;

      @media (max-width: 799px) {
        padding: 0px 12px 17px;
      }
    }

    .tariner-details-wrap {
      .tariner-details-wrap-1 {
        padding: 19px 18px 31px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000001f;
        margin-bottom: 18px;

        @media (max-width: 799px) {
          padding: 0px 12px 9px;
        }
      }

      .tariner-details-wrap-2 {
        padding: 19px 18px 31px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000001f;

        @media (max-width: 799px) {
          padding: 0px 12px 9px;
        }
      }
    }
  }

  .details-table {
    .table-wrap {
      .table-head-row {
        border-bottom: 1px solid #989898;

        .head-row-span {
          font-size: 14px;
          color: #8d8d8d;
          font-weight: 400;
        }

        th {
          font-size: 16px;
          font-weight: 600;
          color: #191919;
          font-family: $regulerFont;
        }

        th:not(:first-child) {
          text-align: center;
        }

        @media (max-width: 799px) {
          .MuiTableCell-root {
            padding: 16px 0px;
          }
        }
      }

      .table-body-row {
        border-bottom: 1px solid #e7e9ec;

        .body-row-name {
          font-weight: 600;
        }

        .clickable {
          cursor: pointer;
        }

        td {
          font-size: 16px;
          font-weight: 400;
          color: #191919;
          font-family: $regulerFont;
        }

        td:not(:first-child) {
          text-align: center;
        }

        @media (max-width: 799px) {
          .MuiTableCell-root {
            padding: 9px 0px;
          }
        }
      }

      .MuiTableCell-root {
        padding: 24px 0px 13px;
      }

      .no-data-td {
        border-bottom: none;
        text-align: center;
        padding: 12px;
        font-size: 16px;
        font-weight: 600;
        color: #191919;
        font-family: $regulerFont;
      }
    }
  }
}

.trackprofile-mobile-details {
  .trackprofile-heading {
    font-size: 16px;
    line-height: 16px;
    color: $color-Black;
    font-weight: 600;
    font-family: $regulerFont;
    padding: 17px 0px 16px;
    border-bottom: 1px solid #bdbdbf;

    .head-row-span {
      font-size: 12px;
      color: #8d8d8d;
      font-weight: 400;
    }
  }

  .trackprofile-deatils {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 9px;

    .details-name {
      font-size: 16px;
      line-height: 19px;
      color: $color-Black;
      font-weight: 600;
      font-family: $regulerFont;
    }

    .arrow-details {
      width: 22px;
      height: 22px;

      .MuiButtonBase-root {
        width: 100%;
        height: 100%;

        svg {
          path {
            fill: #e2662c;
          }
        }

        .MuiTouchRipple-root {
          display: none;
        }
      }
    }
  }

  .trackprofile-border-bottom {
    &:not(:last-child) {
      border-bottom: 1px solid #bdbdbf;
    }
  }

  .collapse-box {
    &:not(:last-child) {
      border-bottom: 1px solid #bdbdbf;
    }

    .collapse-deatils {
      box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.16);

      .collapse-table {
        .collapse-table-row {
          border-bottom: 1px solid rgba(189, 189, 191, 50%);

          .MuiTableCell-root {
            padding: 16px 31px 11px 27px;
            font-size: 16px;
            line-height: 16px;
            font-family: $regulerFont;
          }

          .td-left {
            color: #003764;
            font-weight: 600;
          }

          .td-right {
            color: #191919;
            font-weight: 500;
            text-align: end;
          }
        }
      }
    }

    .view-profile-box {
      text-align: end;

      .view-profile {
        font-size: 12px;
        line-height: 16px;
        color: #4455c7;
        font-weight: 400;
        font-family: $regulerFont;
        text-decoration: underline;
        padding: 9px 0px;
      }
    }
  }

  .collapse-box-bottom {
    padding-bottom: 9px;
  }

  .no-data-mobile {
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: $color-Black;
    font-weight: 600;
    font-family: $regulerFont;
    padding: 10px 0px 9px;
  }
}

.view-more {
  padding: 9px 0px;
  text-align: right;

  .view-more-text {
    font-size: 12px;
    line-height: 16px;
    color: #4455c7;
    font-weight: 400;
    font-family: $regulerFont;
    text-decoration: underline;
    cursor: pointer;
  }

  .red {
    color: #fc4714;
  }

  .view-lese-text {
    margin-left: 5px;
  }

  .red {
    color: #fc4714;
  }
}

.blackbook-icon-wrap {
  .profile-tab-detail {
    border-bottom: 0px !important;
  }
}