@import "../../../../../../assets/scss/variable.scss";

.tab-deatils {
    padding: 20px 0px 0px;

    .tab-details-grid {
        margin-bottom: 18px;

        @media (max-width:799px) {
            grid-template-columns: auto auto !important;
        }
    }

    .stadium-other-name {

        .stadium-name {
            font-size: 13px;
            line-height: 16px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;

            @media (max-width:799px) {
                font-size: 12px;
                line-height: 15px;
            }
        }

        .bold {
            font-weight: 600;
        }
    }
}