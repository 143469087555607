@import "../../../assets/scss/variable.scss";

.bredcrumn-wrap-home {
  padding: 34px 18px 10px;
  text-align: center;

  @media (max-width: 479px) {
    text-align: initial;
  }

  h1 {
    line-height: 45px;
    margin-top: 5px;
  }

  .homepage-subheading {
    font-size: 20px;
    font-family: $primaryFont;
    line-height: 30px;
  }

  @media (max-width: 479px) {
    padding: 22px 12px 10px;

    h1 {
      font-size: 31.36px;
      line-height: 40px;
    }
  }

  li.MuiBreadcrumbs-separator {
    color: $color-Black;
  }

  li {
    font-size: 11.42px;
    line-height: 14px;

    p {
      font-size: 11.42px;
      line-height: 14px;
      color: $color-Accent-1;
      text-transform: uppercase;
    }

    a {
      color: $color-Black;
      font-size: 11.42px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }
}

.smart-odds-comparison-wrap-text {
  padding: 8px 18px 0px;

  @media (max-width: 799px) {
    padding: 8px 12px 0px;
  }

  .text-odds-wrap {
    background-image: url("../../../assets/images/oddsComparison/smartb-odds-text-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    padding: 6px 6px 6px 15px;

    @media (max-width: 799px) {
      padding: 5px 5px 5px 9px;
    }

    .text-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text-odds {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-White;
        letter-spacing: 0px;

        @media (max-width: 799px) {
          font-size: 12px;
          line-height: 14px;
        }
      }

      .odds-sub-btn {
        padding: 10px 0px 9px;
        max-width: 196px;
        width: 100%;
        text-align: center;
        border-radius: 8px;
        background-color: $color-Secondary;
        color: $color-White;
        font-size: 16px;
        line-height: 19px;
        font-family: $primaryFont;
        letter-spacing: 0px;
        font-weight: 400;

        @media (max-width: 799px) {
          font-size: 12px;
          line-height: 14px;
          max-width: 125px;
        }

        .MuiTouchRipple-root {
          display: none;
        }
      }
    }
  }
}

.racing-wrap {
  .racing-content {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .racing-content:not(:last-child) {
    padding-bottom: 9px;
    border-bottom: 1px solid #e7e9ec;
  }

  .racing-content:not(:last-child) {
    padding-bottom: 9px;
    border-bottom: 1px solid #e7e9ec;
  }

  .racing-content:not(:first-child) {
    padding-top: 9px;
  }

  .event-name {
    padding-left: 9px;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
  }

  .country-name {
    margin-left: 5px;
    font-size: 11.42px;
    line-height: 14px;
    font-family: $regulerFont;
    padding-top: 5px;
  }

  .allsports_racing_timer {
    margin-left: auto;
    cursor: pointer;
    color: #fff;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    padding: 3px;
    height: auto;
    min-width: 48px;

    .MuiChip-label {
      padding: 0px;
    }
  }

  .bg-orange {
    background: #fc4714;
  }

  .sqare-chip {
    background: #4455c7;
  }
}

.smart-b-banner {
  width: 100%;
}

.ad-banner {
  width: 100%;
  margin: 18px 0px;
  cursor: pointer;
}

.news-ad-banner {
  width: 100%;
  margin: 18px 0px;
  // cursor: pointer;
}

.racing-list {
  .teamsport-content {
    cursor: pointer;
    padding-left: 18px;
    padding-right: 18px;

    @media (max-width: 779px) {
      padding-left: 12px;
      padding-right: 12px;
    }

    .event-details-wrap {
      text-align: center;
      padding: 0px 4px;
    }

    .golf-event-details-wrap {
      width: 50%;
    }

    .event-name {
      font-size: 11.42px;
      line-height: 14px;
      font-weight: 400;
      background-color: #d6d9f3;
      font-family: $primaryFont;
      padding: 2px 9px;
      color: #4455c7;
      border-radius: 10px;
      // margin-left: 20px;
      // @media (max-width: 479px) {
      //   margin-left: 45px;
      // }
    }

    .event-teams {
      font-size: 15px;
      line-height: 19px;
      font-family: $primaryFont;
      color: $color-Black;
      text-align: center;
      display: block;
      margin-top: 5px;
    }

    .event-wrap {
      // margin-top: 10px;
      // display: flex;
      // justify-content: space-around;

      // padding: 0px 11px;
      .teams-details {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .team-wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          max-width: 45%;

          .team-img-wrap {
            .team-img {
              // max-width: 36px;
              // max-height: 36px;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              object-fit: contain;
            }
          }

          .team-name {
            font-size: 11.42px;
          }
        }

        .vs {
          display: flex;
          align-items: center;
          font-size: 11.42px;
          line-height: 14px;
          font-family: $regulerFont;
          color: $color-Black;
          padding: 0px 7px;
        }
      }

      .tournament-details {
        justify-content: space-around;
      }

      .date-time-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;

        // padding-right: 16px;
        .event-date {
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
        }

        .event-time {
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          text-align: center;
          font-family: $regulerFont;
          color: $color-Black;
        }
      }
    }
  }

  .teamsport-content:not(:last-child) {
    padding-bottom: 9px;
    border-bottom: 1px solid #e7e9ec;
  }

  // .teamsport-content:not(:first-child) {
  //   padding-top: 9px;
  // }
  .teamsport-content {
    padding-top: 9px;
  }
}

.slider-tab-wrap {
  padding: 9px 18px 0px;

  @media (max-width: 479px) {
    padding: 9px 12px 0px;
  }

  .tab-container {
    margin-bottom: 27px;

    .home-tab-details {
      .MuiTabs-flexContainer {
        background: linear-gradient(to top, #090b0d 0%, #003764 100%);
        border-radius: 8px;
        padding: 3px;

        @media (max-width: 479px) {
          padding: 2px;
        }
      }

      .MuiTabs-indicator {
        height: 0px;
      }

      .home-tab {
        text-align: center;
        max-height: 45px;

        button {
          width: 100%;
          // background-color: $color-Primary;
          margin: 0px;
          padding: 6px 0px;
          max-height: 39px;
          max-width: initial;
          border-radius: 8px;
          min-height: initial;
          opacity: 1;
        }

        .active-hometab-button {
          background: $color-White;
        }

        .tabicon-label-wrap {
          display: flex;
          align-items: center;

          .hometab-icon {
            margin-right: 4px;

            span {
              display: flex;
            }
          }

          .hometab-label {
            font-size: 22.4px;
            line-height: 28px;
            color: $color-grey;
            font-family: $primaryFont;
          }

          .active-hometab-label {
            color: $color-Primary;
          }
        }
      }

      .w-33 {
        width: 33.33%;
      }

      .w-50 {
        width: 50%;
      }

      .w-100 {
        width: 100%;
      }
    }
  }
}

.home-newstab-details {
  padding: 0px 18px 18px;
  margin-top: 33px;

  @media (max-width: 479px) {
    padding: 0px 12px 18px;
    margin-top: 22px;
  }

  .individual-news-cont {
    margin-top: 33px;
    // box-shadow: 0px 3px 9px 0px #0000000d;
    // padding: 23.5px 27px;
    // border-radius: 8px;

    @media (max-width: 799px) {
      margin-top: 18px;
      padding: 12px;
    }

    .individual-single-news {
      margin-bottom: 27px;
      padding: 18px;
      background-color: #ffffff;
      box-shadow: 0px 1px 9px 0px #0000001f;
      border-radius: 8px;
      overflow: hidden;

      @media (max-width: 799px) {
        padding: 12px;
      }

      @media (max-width: 479px) {
        margin-bottom: 15px;
      }

      .news-box {
        display: flex;

        .news-tag-relative {
          position: relative;

          @media (max-width: 439px) {
            max-height: 85px;
          }
        }

        .news-img {
          min-width: 312px;
          min-height: 164px;
          max-width: 312px;
          margin-right: 27px;
          // margin-bottom: 18px;
          cursor: pointer;

          @media (max-width: 1280px) {
            max-width: 200px;
            min-width: 200px;
            margin-right: 12px;
            min-height: auto;
          }

          @media (max-width: 1120px) {
            max-width: 180px;
            min-width: 180px;
          }

          @media (max-width: 439px) {
            max-width: 140px;
            min-width: 140px;
            max-height: 85px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;

            @media (max-width: 1280px) {
              width: 200px;
              height: auto;
            }

            @media (max-width: 1120px) {
              width: 180px;
            }

            @media (max-width: 439px) {
              width: 140px;
              height: 85px;
            }
          }
        }

        .news-tag {
          padding: 3px 6px;
          background-color: #fc4714;
          color: #ffffff;
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          font-family: "Inter", sans-serif;
          max-width: -moz-fit-content;
          max-width: fit-content;
          position: absolute;
          left: 12px;
          top: 13px;
          text-transform: capitalize;

          @media (max-width: 1024px) {
            bottom: 15px;
            top: unset;
          }

          @media (max-width: 560px) {
            bottom: 10px;
            left: 6px;
          }

          @media (max-width: 440px) {
            left: 10px;
          }
        }

        .individual-news-details {
          width: 100%;

          .exclusive {
            padding: 3px 11px;
            background-color: #d84727;
            color: #ffffff;
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            font-family: $regulerFont;
            max-width: fit-content;
            border-radius: 3px;
            margin-bottom: 9px;
          }

          .details {
            font-size: 22.4px;
            line-height: 31.36px;
            font-weight: 600;
            font-family: $regulerFont;
            color: $color-Black;
            // margin-bottom: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            cursor: pointer;

            @media (max-width: 1440px) {
              -webkit-line-clamp: 2;
            }

            // @media (max-width: 799px) {
            //   -webkit-line-clamp: 2;
            // }
            @media (max-width: 1024px) {
              margin-bottom: 4px;
              font-size: 14px;
              line-height: 18px;
            }

            // @media (max-width: 599px) {
            //   margin-bottom: 4px;
            // }
          }

          .sub-details-wrap {
            .sub-details {
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-gray-dark;
              margin-top: 4px;

              @media (max-width: 1024px) {
                font-size: 11.42px;
                line-height: 14px;
              }

              .read-more {
                color: $color-Accent-1;
              }
            }
          }

          .tag-time-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 13px;

            @media (max-width: 599px) {
              flex-direction: column;
              row-gap: 4px;
              margin-top: 6px;
            }

            .news-tags {
              display: flex;
              flex-wrap: wrap;
              row-gap: 5px;
              width: 70%;

              @media (max-width: 599px) {
                width: 100%;
              }
            }

            .individual-news-tag {
              padding: 3px 6px;
              background-color: #989898;
              color: #ffffff;
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 400;
              font-family: $regulerFont;
              max-width: fit-content;
              border-radius: 23px;

              &:not(:last-child) {
                margin-right: 12px;

                @media (max-width: 1140px) {
                  margin-right: 6px;
                }
              }
            }

            .stories-time {
              font-size: 11.42px;
              line-height: 14px;
              font-family: $regulerFont;
              font-weight: 400;
              color: #5d5d5d;
              // width: 30%;
              // text-align: end;

              @media (max-width: 599px) {
                width: 100%;
              }

              .watch {
                margin-right: 4px;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }

    .View-more-wrap {
      display: flex;
      justify-content: end;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      font-family: $regulerFont;
      color: $color-Accent-1;
      text-decoration: underline;
      cursor: pointer;

      span {
        display: flex;
        margin-left: 7px;

        svg {
          path {
            stroke: $color-Accent-1;
          }
        }
      }
    }
  }
}

.sport-grid {
  margin-top: 33px;

  @media (max-width: 479px) {
    margin-top: 22px;
  }

  .sport-inner {
    .racing-list {
      padding: 0px 0px 13px;
      margin-top: 13px;

      @media (max-width: 479px) {
        margin-top: 0px;
      }

      .sport-title {
        padding: 9px 13px;
        background-color: #e7e9ec;
        border-radius: 8px 8px 0px 0px;

        .sport-title-name {
          display: flex;
          align-items: center;

          img {
            margin-right: 7px;
          }

          .sport-icon {
            display: flex;
            margin-right: 6px;
            width: 22px;
            height: 22px;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }

        .see-all {
          font-size: 14px;
          line-height: 18px;
        }
      }

      .racing-content {
        padding: 9px 13px;
      }
    }
  }

  .teamsport-inner {
    height: calc(100% - 28px);
  }
}

.sport-wrap {
  .teamsport-inner {
    .sport-shadow {
      height: 100%;

      .eventlist-wrap {
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .racing-list {
      margin-top: 0px;
    }
  }
}

.racing-slider-wrap {
  background-image: url("../../../assets/images/featured-sport-slider-bg.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  border-radius: 8px;
  padding: 18px 29px 57px;
  margin-bottom: 33px;
  margin-top: 15px;

  .slider-data-container {
    margin-top: 33px;
    min-height: 304px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (max-width: 799px) {
      margin-top: 26px;
      min-height: 274px;
    }
  }

  .home-slider-header {
    font-size: 31.36px;
    line-height: 40px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-White;

    @media (max-width: 799px) {
      font-size: 22.4px;
      line-height: 28px;
    }
  }

  .racing-slick-slider {
    width: 100%;
    margin: 0px auto;
    // height: 246px;

    .slick-arrow {
      width: 28px;
      height: 28px;

      svg {
        width: 28px;
        height: 28px;

        path {
          fill: #989898;
        }
      }

      &::before {
        content: "";
        display: none;
      }
    }

    .slick-prev {
      left: -34px;

      @media (max-width: 799px) {
        left: -27px;
        bottom: -32px;
        top: auto;
      }
    }

    .slick-next {
      right: -34px;

      @media (max-width: 799px) {
        right: -17px;
        bottom: -32px;
        top: auto;
      }
    }

    .slick-dots {
      bottom: -25px;

      li {
        width: 10px;
        height: 10px;

        @media (max-width: 799px) {
          width: 7px;
          height: 7px;
        }

        button {
          width: 10px;
          height: 10px;

          @media (max-width: 799px) {
            width: 7px;
            height: 7px;
          }

          &::before {
            width: 10px;
            height: 10px;
            border: 1px solid #4455c7;
            border-radius: 50%;
            color: transparent;
            opacity: 1;

            @media (max-width: 799px) {
              width: 7px;
              height: 7px;
            }
          }
        }
      }

      .slick-active {
        button {
          &::before {
            opacity: 1;
            background-color: #4455c7;
          }
        }
      }
    }
  }

  .racing-slider-details {
    background-color: $color-White;
    padding: 12px 21px;
    border-radius: 8px;
    margin: 0px 7.5px;
    height: 230px;

    @media (max-width: 799px) {
      padding: 9px;
      height: 238px;
    }

    .slider-header-wrap {
      padding-bottom: 7px;
      border-bottom: 1px solid $color-Accent-1;

      @media (max-width: 799px) {
        flex-direction: column;
      }

      .event-name-deatils {
        @media (max-width: 799px) {
          width: 100%;
          column-gap: 5px;
          justify-content: space-between;
        }

        .sport-icon {
          margin-right: 3px;
          width: 22px;
          height: 22px;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .race-img {
          margin-right: 3px;

          img {
            width: 27px;
            height: 27px;
          }
        }

        .event-name {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-Primary;
        }

        .country-name {
          font-size: 11.42px;
          line-height: 20px;
          font-family: $regulerFont !important;
        }

        .runner-name {
          text-align: center;
          margin-left: 12px;
          background-color: $color-Accent-1;
          border-radius: 3px;
          color: #ffffff;
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
          font-family: $regulerFont;
          padding: 3px 9px;
        }

        .tournament-name {
          text-align: center;
          margin-left: 12px;
          background-color: $color-Accent-1;
          border-radius: 23px;
          color: #ffffff;
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
          font-family: $regulerFont;
          padding: 3px 6px;

          @media (max-width: 799px) {
            margin-left: 0px;
          }
        }
      }

      .event-time-distance {
        font-size: 11.42px;
        line-height: 14px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-gray-dark;
        display: flex;
        align-items: center;
        column-gap: 5px;

        @media (max-width: 799px) {
          width: 100%;
          justify-content: space-between;
          margin-top: 3px;

          .seperator {
            display: none;
          }
        }
      }

      .divider {
        @media (max-width: 799px) {
          display: none;
        }
      }
    }

    .slider-event-race-deatils {
      padding-top: 12px;

      @media (max-width: 799px) {
        padding-top: 8px;
      }

      &:not(:last-child) {
        padding-bottom: 9px;
        border-bottom: 1px solid $color-grey;

        @media (max-width: 799px) {
          padding-bottom: 6px;
        }
      }

      .details-race {
        .race-name {
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
          font-family: $regulerFont;
          color: #000000;
          margin-bottom: 9px;
        }

        .race-deatils {
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          .text-semibold {
            font-weight: 600;
          }
        }

        .deatils-1 {
          column-gap: 26px;

          @media (max-width: 799px) {
            column-gap: 12px;
          }
        }
      }

      .racing-current-best-odds-wrap {
        min-height: 63px;

        @media (max-width: 799px) {
          min-height: 55px;
        }
      }

      .best-value {
        display: flex;
        flex-direction: column;
        padding: 6px 0px;
        width: 56px;
        text-align: center;
        background-color: #d6d9f3;
        border-radius: 6px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.16);

        .best {
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          text-transform: uppercase;
        }

        .best-odds {
          font-size: 16px;
          line-height: 19px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
        }
      }
    }

    .sport-deatils {
      justify-content: space-around;
      padding-top: 25px;
      column-gap: 5px;

      @media (max-width: 799px) {
        padding-top: 12px;
      }

      &:not(:last-child) {
        padding-bottom: 18px;
        border-bottom: 1px solid $color-grey;

        @media (max-width: 799px) {
          padding-bottom: 12px;
        }
      }

      .sport-team-wrap {
        column-gap: 21px;

        @media (max-width: 799px) {
          flex-direction: column;
          column-gap: 0px;
          row-gap: 9px;
        }
      }

      .sport-team-home {
        @media (max-width: 799px) {
          flex-direction: column-reverse;
        }
      }

      .flag-wrap {
        @media (max-width: 799px) {
          display: flex;
          align-items: center;
          column-gap: 6px;
        }

        .flag-img {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          object-fit: contain;
          margin: 0 auto;

          @media (max-width: 799px) {
            width: 28px;
            height: 28px;
          }

          img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }
        }
      }

      .away-flag-wrap {
        @media (max-width: 799px) {
          flex-direction: row-reverse;
        }
      }

      .team-vs {
        font-size: 22.4px;
        line-height: 30px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Black;
      }

      .team-name {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Black;

        @media (max-width: 799px) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      .awayteam-name {
        @media (max-width: 799px) {
          text-align: right;
        }
      }
    }
  }

  .racing-slider {
    height: auto;
  }

  .sport-slider-details {
    .slider-event-race-deatils {
      height: calc(210px - 54px);

      @media (max-width: 799px) {
        height: calc(238px - 54px);
      }
    }
  }

  .live-wrap {
    margin-top: 12px;
    display: block;

    .live-indicator {
      padding: 3px 11px;
      background-color: $color-Negative;
      color: $color-White;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      border-radius: 3px;
      margin-right: 13px;
    }
  }

  .featured-sport-score {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #003764;
    height: calc(100% - 150px);
    margin: 0px -21px;
    border-radius: 0px 0px 8px 8px;

    @media (max-width: 799px) {
      height: calc(100% - 175px);
      margin: 0px -9px;
    }

    .score-details {
      display: flex;
      align-items: center;
      flex-direction: column;

      .score {
        font-size: 22.4px;
        line-height: 28px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-White;
        display: flex;
        align-items: center;
        column-gap: 5px;

        @media (max-width: 799px) {
          font-size: 16px;
          line-height: 20px;
        }

        .score-icon {
          width: 25px;
          height: 25px;
          display: block;

          @media (max-width: 799px) {
            width: 18px;
            height: 18px;
          }

          svg {
            width: 100%;
            height: 100%;

            path {
              fill: $color-White;
            }
          }
        }
      }

      .desh {
        color: $color-White;
      }

      .score-team {
        font-size: 11.42px;
        line-height: 14px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-White;
      }
    }
  }

  .single-silder {
    position: relative;
    cursor: pointer;
    // margin: 0px 7.5px;
    height: 246px;
    overflow: hidden;

    .slider-img {
      max-width: 692px;
      width: 100%;
      height: 100%;
      max-height: 246px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: contain;
      }
    }
    .featured-news-img {
      max-width: 100%;
    }

    .news-tag {
      padding: 3px 6px;
      background-color: $color-Secondary;
      color: #ffffff;
      font-size: 11.42px;
      line-height: 14px;
      font-weight: 400;
      font-family: $regulerFont;
      max-width: fit-content;
      position: absolute;
      left: 12px;
      top: 13px;
      text-transform: capitalize;
    }

    .details-back {
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0%),
        #3e3e3e21,
        #b3b3b3b8
      );
      width: -webkit-fill-available;

      .slider-news-deatils {
        padding: 21px 12px 9px;

        .title-news {
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          font-family: $regulerFont;
          color: #ffffff;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }

        p {
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          font-family: $regulerFont;
          color: #c9c9c9;
        }
      }
    }
  }

  .nodata-feature {
    color: $color-White !important;
    padding: 18px 12px;
    border-radius: 8px;
    margin: 0px 7.5px;
    text-align: center;
    border: 1px solid $color-White;
    width: 100%;
    @media (max-width: 799px) {
      padding: 12px;
    }
  }
  .featured-loader {
    .MuiCircularProgress-root {
      color: $color-White !important;
    }
  }
  .MuiTabScrollButton-root {
    svg {
      fill: $color-White;
    }
  }
  .MuiTabs-scrollButtons:first-of-type {
    @media (max-width: 799px) {
      left: -2px !important;
    }
  }
}
.news-slider-wrap {
  padding: 27px 105px 57px;
  @media (max-width: 1023px) {
    padding: 10px 54px 44px;
  }
  @media (max-width: 799px) {
    margin-left: -12px;
    margin-right: -12px;
    border-radius: 0px;
    padding: 10px 36px 44px;
  }

  .home-slider-header {
    text-align: center;
    padding-bottom: 24px;
    @media (max-width: 799px) {
      padding-bottom: 9px;
    }
  }
  .racing-slick-slider {
    .slick-prev {
      left: -46px;
      @media (max-width: 799px) {
        bottom: -46px;
        left: -10px;
      }
    }
    .slick-next {
      @media (max-width: 799px) {
        right: -10px;
        bottom: -47px;
      }
    }
    .news-single-slider {
      width: 96% !important;
      @media (max-width: 799px) {
        width: 100% !important;
      }
      .details-back {
        background: linear-gradient(
          180deg,
          rgba(25, 25, 25, 0) 0%,
          rgba(25, 25, 25, 0.5) 50%,
          rgba(25, 25, 25, 1) 100%
        );
        .news-tag {
          top: -3px;
        }
        .sub-details-wrap {
          .sub-details {
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-White;
            margin-top: 4px;

            @media (max-width: 1024px) {
              font-size: 11.42px;
              line-height: 14px;
            }

            .read-more {
              color: $color-White;
            }
          }
        }
        .news-time-comment-wrapper {
          // position: absolute;
          // bottom: 12px;
          // left: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: calc(100% - 24px);
          @media (max-width: 799px) {
            bottom: 0;
            left: 0;
            width: 100%;
          }
          .news-time {
            color: $color-White;
          }
          .bookmark-chat-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            .chat-details {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 6px;
              svg {
                margin-right: 3px;
                path {
                  stroke: $color-White;
                }
              }
            }
            .saved-bookmark-icon {
              path {
                fill: $color-White;
                stroke: $color-White;
              }
            }
            .unsaved-bookmark-icon {
              path {
                stroke: $color-White;
              }
            }
          }
        }
      }
    }
  }
}

.featured-racing-slider {
  @media (max-width: 799px) {
    margin: 0px -12px 12px;
    padding: 9.5px 12px 44px;
    border-radius: 0px;
  }

  .racing-slick-slider {
    .slick-prev {
      @media (max-width: 799px) {
        left: 0px;
        bottom: -45px;
        top: auto;
      }
    }

    .slick-next {
      right: -34px;

      @media (max-width: 799px) {
        right: 8px;
        bottom: -45px;
        top: auto;
      }
    }
  }
}

.d-flex {
  display: flex;
}

.text-align {
  align-items: center;
}

.content-space-betwen {
  justify-content: space-between;
}

// .news-loader {
//   .MuiCircularProgress-svg {
//     color: $color-White;
//   }
// }

.cursor-pointer {
  cursor: pointer;
}

.no-data-avail {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 600;
  font-family: $regulerFont;
  color: #ffffff;
}
.no-data-wrapper {
  background-color: $color-White;
  padding: 18px 12px;
  border-radius: 8px;
  margin: 0px 7.5px;
  text-align: center;
  border: 1px solid $color-Light-grey;

  .no-data-text {
    font-size: 16px;
    line-height: 20px;
    font-family: $regulerFont;
    color: $color-Black;
  }
  @media (max-width: 799px) {
    padding: 12px;
  }
}
