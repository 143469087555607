@import "../../../../../assets/scss/variable.scss";

.flutuation-graph-details-wrap {
  .odds-table-head {
    margin-bottom: 15px;

    @media (max-width: 799px) {
      flex-direction: column-reverse;
      row-gap: 18px;
    }

    .select-wrap {
      @media (max-width: 799px) {
        margin-right: auto;
      }
    }
  }

  .select-odds-wrap {
    @media (max-width: 799px) {
      width: 100%;
    }

    .odds-select {
      min-width: 257px;
    }

    .select__control {
      background-color: $color-Light-grey;
      border-radius: 8px;
      max-height: 36px;
      max-width: 400px;

      .select__single-value {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__placeholder {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__input-container {
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
        padding: 0px 8px;
      }

      .select__indicator-separator {
        width: 0px;
      }

      .select__dropdown-indicator {
        padding: 10px;
      }
    }

    .select__menu {
      margin: 0px;
      border-radius: 0px;
      padding: 0px;
      z-index: 999;
    }

    .select__menu-list {
      padding: 0px;
    }

    // Select Menu
    .select__menu,
    .react-select__menu {
      .select__menu-list,
      .react-select__menu-list {
        .select__option,
        .react-select__option {
          cursor: pointer;
          font-size: 16px;
          color: $color-Black;
          font-family: $regulerFont;
          line-height: 19px;
          padding: 11px;

          &.select__option--is-focused {
            background-color: #d4d6d8;
            color: #000;
          }

          &.select__option--is-selected {
            background-color: #d4d6d8;
            color: $color-Black;
          }
        }
      }

      .select__menu-list,
      .react-select__menu-list {
        .select__group {
          .select__group-heading {
            margin-bottom: 0.5rem;
            color: green;
            font-weight: bolder;
            font-size: inherit;
          }
        }
      }
    }
    .select__menu-portal {
      z-index: 99;
    }
  }

  .flutuation-graph-table {
    .chart-cell {
      padding: 10px 0px !important;
    }
  }
  .featured-table {
    .middle-header {
      text-align: center;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      color: $color-Accent-1;
      background-color: $color-Light-grey-4;
      border-bottom: none;
    }
    .responsive-top-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
  .mobile-table-container {
    border: 1px solid #e7e9ec;
    border-radius: 8px;
    .mobile-graph-table {
      .MuiTableCell-root {
        border-right: none !important;
      }

      .mobile-graph-cell {
        background-color: transparent !important;
      }
      .mobile-table-data {
        border-bottom: none;
      }
      .mobile-data-head {
        .fluc-header {
          font-size: 11.42px;
          font-weight: 800;
          line-height: 14px;
          color: $color-Accent-1;
          background-color: $color-Light-grey-4;
        }
      }
    }
  }
}
