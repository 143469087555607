@import "../../../../../assets/scss/variable.scss";

.statistics-data-wrap {

    .search-filter-wrap {
        padding: 12px 18px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000000d;

        @media (max-width:799px) {
            padding: 12px;
        }

        .search_input {
            width: 100%;

            .MuiInputBase-root {
                max-height: 45px;

                svg {
                    height: 18px;
                    width: 18px;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border-color: #e7e9ec;
            }

            .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: $color-Accent-1;
                border-width: 1.5px;
            }

            @media (max-width: 799px) {
                margin-left: 0px !important;
                min-width: auto;
                width: 100%;
            }
        }

        .alphabet-filter {
            display: flex;
            align-items: center;
            column-gap: 40px;
            row-gap: 12px;
            flex-wrap: wrap;
            padding: 12px 14px 0px;

            @media (max-width: 799px) {
                column-gap: 9px;
                padding: 12px 0px 0px;
            }

            .letters-alphabet {
                padding: 6px 0px;
                text-align: center;
                width: 42px;
                border-radius: 8px;
                border: 1px solid #e7e9ec;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                font-family: $primaryFont;
                color: #989898;
                cursor: pointer;
                -webkit-tap-highlight-color: transparent;

                @media (max-width: 799px) {
                    width: 30px;
                }
            }

            .selected {
                background-color: $color-Primary;
                color: $color-White;
            }
        }
    }

    .trackprofilelist-wrap {
        list-style: none;
        padding: 0px;
        margin: 0px;

        .trackprofile-list {
            padding-bottom: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid #e7e9ec;
            justify-content: space-between;
            padding: 8px 0px;

            .sport-icon-img {
                padding-right: 5px;
                width: 30px;
                height: 30px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .statistics-name {
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                color: $color-Black;

                @media (max-width: 799px) {
                    font-size: 15px;
                    line-height: 18px;
                }
            }

            .name-hover {
                &:hover {
                    color: $color-Primary;
                    text-decoration: underline;
                }
            }

            .add-to-blackbook {
                color: #9ca5e1;
                display: flex;
                align-items: center;
                cursor: pointer;

                svg {
                    margin-right: 3px;
                }

                &:hover {
                    color: #9ca5e1;
                    text-decoration: underline;
                }
            }

            .view-Blackbook {
                color: $color-Accent-1;

                &:hover {
                    color: $color-Accent-1;
                    text-decoration: underline;
                }
            }
        }
    }

    .track-accordion {
        // background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000000d;
        margin: 18px 0px;

        .MuiAccordion-root {
            margin-bottom: 10px;
            box-shadow: none;

            &::before {
                display: none;
            }

            .MuiAccordionSummary-root {
                padding: 0px 33px 0px 63px;
                background: #e7e9ec;
                min-height: 45px;

                @media (max-width: 799px) {
                    padding: 0px 12px;
                }

                .MuiSvgIcon-root {
                    fill: $color-Black;
                }
            }

            .MuiAccordionSummary-content {
                margin: 0px;
                column-gap: 11px;

                p {
                    font-size: 22.4px;
                    font-family: $primaryFont;
                    color: $color-Black;
                    line-height: 31.36px;

                    @media (max-width: 799px) {
                        font-size: 16px;
                    }
                }
            }

            .MuiIconButton-root {
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }

        .MuiAccordionDetails-root {
            padding: 9px 33px;
            width: auto;
            display: block;
            box-shadow: 0px 3px 9px 0px #0000000d;

            @media (max-width: 799px) {
                padding: 9px 12px;
            }
        }

        .view-all {
            text-align: end;
            padding-top: 13px;

            @media (max-width: 799px) {
                padding-top: 9px;
            }

            .view-all-text {
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-Accent-1;
                border-bottom: 1px solid $color-Accent-1;
                cursor: pointer;

                @media (max-width: 799px) {
                    font-size: 11.42px;
                    line-height: 14px;
                }
            }
        }
    }
}