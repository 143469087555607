@import "../../../../../assets//scss/variable.scss";

.future-side-wrap {
  padding: 17px 18px 18px;
  background-color: $color-White;
  box-shadow: 0px 3px 9px 0px #0000000d;
  margin: 5px 0px 18px;

  @media (max-width: 799px) {
    padding: 0px 12px 9px;
    background-color: #fbfbfb;
    box-shadow: none;
  }

  .select-wrap {
    max-width: 225px;

    @media (max-width: 799px) {
      max-width: 270px;
    }

    .select__control {
      @media (max-width: 799px) {
        background: none;
      }
    }

    .select__single-value {
      color: #191919;

      @media (max-width: 799px) {
        font-size: 31.36px;
        line-height: 43.9px;
      }
    }
  }

  .future-event-name {
    border-top: 1px solid #4455c7;
    padding-top: 18px;

    span {
      font-size: 13px;
      line-height: 16px;
      color: #191919;
      font-family: $regulerFont;

      @media (max-width: 799px) {
        font-size: 12px;
      }
    }

    .event-name {
      font-weight: 600;
      margin-right: 45px;

      @media (max-width: 799px) {
        margin-right: 36px;
      }
    }

    .event-date {
      font-weight: 400;
    }
  }
}

.future-table {
  padding: 18px 0px 0px 18px;
  background-color: $color-White;
  box-shadow: 0px 3px 9px 0px #0000000d;
  position: relative;

  @media (max-width: 799px) {
    padding: 12px;
  }

  .table-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e7e9ec;

    @media (max-width: 799px) {
      border-bottom-color: #4455c7;
    }

    .table-see-best {
      margin-right: 21px;
      font-size: 11.42px;
      font-weight: 400;
      line-height: 14px;
      color: #4455c7;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .table-futures-container {
    .overlay::after {
      content: "";
      position: absolute;
      width: 90px;
      height: calc(100%);
      z-index: 9;
      right: 0;
      background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 100%)
        0% 0% no-repeat padding-box;
    }

    .arrow-wrap {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0px;
      top: 65px;
      z-index: 99;

      .arrow {
        background-color: #707070;
        width: 32px;
        height: 26px;

        .MuiSvgIcon-root {
          width: 32px;
          height: 26px;
          color: #ffffff;
        }
      }

      .disable {
        background-color: #d8d8d8;
        width: 32px;
        height: 26px;

        .MuiSvgIcon-root {
          width: 32px;
          height: 26px;
          color: #ffffff;
        }
      }
    }

    .table-futures {
      .futures-head {
        tr {
          th {
            padding: 18px 0px 15px;
            font-size: 16px;
            line-height: 19px;
            font-family: $regulerFont;
            border-bottom-color: #707070;
            color: $color-Black;

            @media (max-width: 799px) {
              padding: 16px 0px;
            }
          }

          th:not(:first-child) {
            padding: 19px 18px 18px;
          }
        }
      }

      .futures-body {
        tr {
          td {
            padding: 19px 0px 18px;
            font-size: 16px;
            line-height: 19px;
            font-family: $regulerFont;
            border-bottom-color: #e7e9ec;
            color: $color-Black;

            @media (max-width: 799px) {
              padding: 10px 0px 9px;
            }
          }

          td:not(:first-child) {
            padding: 19px 18px 18px;

            @media (max-width: 799px) {
              padding: 10px 18px 9px;
            }
          }

          .body-runner-name {
            font-weight: 600;
          }
        }
      }
    }
  }
}
.future-select-wrap {
  max-width: 358px;
}
