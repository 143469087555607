@import "../../../assets/scss/variable.scss";


.new-team-sports-component-wrap {


    .bredcrumn-wrap {
        margin-bottom: 3px;
        margin-top: 33px;

        @media only screen and (max-width: 599px) {
            margin-bottom: 5px;
        }

        li.MuiBreadcrumbs-separator {
            color: $color-Black;
        }

        li {
            font-size: 11.42px;
            line-height: 14px;

            p {
                font-size: 11.42px;
                line-height: 14px;
                color: $color-Accent-1;
                text-transform: uppercase;
            }

            a {
                color: $color-Black;
                font-size: 11.42px;
                line-height: 14px;
                text-transform: uppercase;
            }
        }
    }

    .title-btn-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 799px) {
            display: block;
        }

        h1 {
            line-height: 56px;
            color: $color-Black;

            @media only screen and (max-width: 599px) {
                font-size: 31.36px;
                line-height: 40px;
            }
        }


    }

    .w-full {
        width: 100%;
    }

    .exprt-tips-btn {
        max-width: 212px;
        width: 100%;
        padding: 8px 24px 12px 24px;
        background: $bg-new-home-button;
        color: $color-White;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        cursor: pointer;

        .exprt-btn-wrap {
            display: flex;
            column-gap: 6px;
        }

        @media (max-width:559px) {
            max-width: 100%;
        }
    }

    .select__control {
        background-color: $color-Light-grey;
        border-radius: 8px;
        max-height: 45px;
        max-width: 400px;

        .select__single-value {
            font-family: $regulerFont !important;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 400;
            color: $color-Black;
            padding: 0px 8px;
        }

        .select__placeholder {
            font-family: $regulerFont !important;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 400;
            color: $color-Black;
            padding: 0px 8px;
        }

        .select__input-container {
            font-family: $regulerFont !important;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 400;
            color: $color-Black;
            padding: 0px 8px;
        }

        .select__indicator-separator {
            width: 0px;
        }

        .select__dropdown-indicator {
            padding: 10px;
        }
    }

    .select__menu-portal {
        z-index: 99;
    }

    .select__control,
    .react-select__control {
        &.select__control--is-disabled {

            // border-color: $border-color;
            .select__indicator-separator {
                //   background-color: $border-color;
            }
        }

        // &.select__control--is-focused,
        // &.react-select__control--is-focused {

        // }

        &.select__control--menu-is-open {
            box-shadow: none;
            // border: none;
        }

        .select__indicator svg {
            cursor: pointer;
        }

        .select__indicator-separator {
            display: none;
        }

        .select__single-value {
            // border: none;
            font-size: 16px;
            line-height: 20px;
            padding-left: 10px;
            outline: none;
            cursor: pointer;
            margin: 0px;
            font-family: "Inter", sans-serif;
        }

        .select__value-container {
            // border: none;
            padding: 0px;
            margin: 0px;

            .select__input-container {
                font-size: 16px;
                font-family: "Inter", sans-serif;
                // margin-left: 10px;
            }
        }

        .select__placeholder {
            font-size: 16px;
            line-height: 20px;
            padding-left: 10px;
            cursor: pointer;
            font-family: $regulerFont;
        }
    }

    .select__menu {
        margin: 0px;
        border-radius: 0px;
        padding: 0px;
        z-index: 999;
    }

    .select__menu-list {
        padding: 0px;
    }

    // Select Menu
    .select__menu,
    .react-select__menu {

        .select__menu-list,
        .react-select__menu-list {

            .select__option,
            .react-select__option {
                cursor: pointer;
                font-size: 16px;
                color: $color-Black;
                font-family: $regulerFont;
                line-height: 19px;
                padding: 11px;

                &.select__option--is-focused {
                    background-color: $color-grey;
                    color: #000;
                }

                &.select__option--is-selected {
                    background-color: $color-grey;
                    color: $color-Black;
                }
            }
        }

        .select__menu-list,
        .react-select__menu-list {
            .select__group {
                .select__group-heading {
                    margin-bottom: 0.5rem;
                    color: green;
                    font-weight: bolder;
                    font-size: inherit;
                }
            }
        }
    }
}