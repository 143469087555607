@import "../../../assets/scss/variable.scss";

.racing-current-best-odds-wrap {
  //   padding: 0px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .racing-current-best-odds-value {
    background-color: $color-Lavender;
    padding: 9px 0px;
    width: 80px;
    margin: 0px auto;
    border-radius: 6px 6px 0px 0px;
    text-align: center;
    @media (max-width: 799px) {
      padding: 5px 0px;
      width: 70px;
    }
  }
  .active-odd-style {
    background-color: $color-Primary;
    span,
    .odds {
      color: $color-White;
    }
  }
  .racing-current-best-odds-icon {
    border-radius: 0px 0px 6px 6px;
    width: 80px;
    margin: 0px auto;
    @media (max-width: 799px) {
      width: 70px;
    }
    .currentbest-bookmaker-thumb {
      margin: 0px auto;
      border-radius: 0px 0px 6px 6px;
      cursor: pointer;
      height: 25px;
      object-fit: cover;
      width: 100%;
      max-width: none;
    }
  }
  .sp-odds {
    background-color: $color-Lavender;
    padding: 9px 0px;
    width: 80px;
    margin: 0px auto;
    border-radius: 6px;
    text-align: center;
    font-family: $regulerFont;
    cursor: auto;
    font-size: 16px;
    line-height: 20px;
    color: $color-Black;
    @media (max-width: 799px) {
      padding: 5px 0px;
      width: 70px;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
