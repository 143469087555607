@import "../../../assets/scss/variable.scss";

.left-sidebarcontent {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  .MuiListItemText-primary {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    padding: 8px;
  }

  .MuiCollapse-root {
    padding-left: 8px;

    .MuiListItemText-primary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: normal;
    }
  }

  .menu-wrap {
    padding: 30px 19px 120px 19px;

    .MuiListItem-root.Mui-selected {
      background-color: transparent;
    }

    .homemenu.Mui-selected {
      position: relative;
      background-color: #ffffff;
      border-radius: 6px;

      span {
        color: #fc4714;
        font-weight: 600;
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 14px;
        height: 14px;
        background-image: url("../../../assets/images/SidemenuArowActive.png");
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover {
        background-color: #ffffff;
        border-radius: 6px;
        color: #fc4714;
        font-weight: 600;

        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 14px;
          height: 14px;
          background-image: url("../../../assets/images/SidemenuArowActive.png");
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .homemenu {
      position: relative;

      &:hover {
        background-color: #29577d;
        color: #e7e9ec;
        border-radius: 6px;

        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 14px;
          height: 14px;
          background-image: url("../../../assets/images/SidemenuArow.png");
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    // .MuiListItem-button:hover {
    //   background: transparent;
    // }

    // .Mui-selected::before {
    //   content: "";
    //   width: 7px;
    //   height: 20px;
    //   background: #fc4714;
    //   position: absolute;
    //   left: -22px;
    //   top: 0px;
    // }

    .MuiButtonBase-root {
      padding: 0px;
    }

    .MuiListItemText-root {
      margin: 0px;
    }

    svg.MuiSvgIcon-root {
      fill: #fff;
    }

    .activesubmenu {
      position: relative;
      background-color: #ffffff;

      border-radius: 6px;

      span {
        color: #fc4714;
        font-weight: 600;
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 14px;
        height: 14px;
        background-image: url("../../../assets/images/SidemenuArowActive.png");
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }

      .MuiListItemText-primary {
        padding: 8px 8px 8px 4px;
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }

    .submenu-name {
      position: relative;
    }

    .submenu-name:hover {
      background-color: #29577d;
      color: #e7e9ec;
      border-radius: 6px;

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 14px;
        height: 14px;
        background-image: url("../../../assets/images/SidemenuArow.png");
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .second-submenu-name {
      .MuiListItemText-primary {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &:hover {
        &::after {
          display: none;
        }
      }
    }
  }
}
.menu-wrap {
  .social-media-wrap {
    padding-top: 8px;

    p {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;

      @media only screen and (max-width: 799px) {
        padding: 8px 16px;
      }
    }
  }

  .social-icon-wrap {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 10px;
    row-gap: 12px;
    justify-content: space-around;
    padding: 10px 0px;

    @media only screen and (max-width: 799px) {
      padding: 8px 16px;
    }

    svg {
      cursor: pointer;

      g {
        circle {
          fill: #ffffff;
        }

        path {
          fill: #003764;
        }
      }
    }

    .twitter {
      cursor: pointer;

      svg {
        g {
          g {
            path {
              width: 30px;
              fill: #ffffff;
            }
          }
        }
      }
    }

    .tiktok {
      width: 30px;
      height: 30px;
      background: #ffffff;
      border-radius: 50%;

      img {
        border-radius: 50%;
        width: 100%;
      }
    }

    .youtube {
      text-align: center;

      svg {
        width: 28px;
        height: 28px;
        margin-top: 0.7px;

        path {
          fill: #003764;
        }
      }
    }

    .snapChat {
      svg {
        border-radius: 50%;
      }
    }

    .linkedin {
      svg {
        width: 30px;
        height: 30px;
        margin-top: 0px;

        path {
          fill: #ffffff;
        }

        #linkdinId {
          fill: #003764;
        }
      }
    }

    .sidebar-icon {
      width: 30px;
      height: 30px;
    }
  }
}
