.terms-wrap {
  .title-orange {
    color: #191919;
    font-family: VeneerClean-Soft !important;
  }

  .created-date {
    margin-bottom: 9px;
  }

  .title-skyblue {
    color: #4455c7;
    font-size: 11.42px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }

  .modified-on {
    margin-bottom: 18px;
  }

  h1 {
    color: #191919;
    padding: 33px 0px 18px 0px;
    text-align: left;
  }

  h5 {
    font-size: 22.4px;
    font-weight: 600;
    line-height: 31.36px;
    color: #191919;
    margin-top: 46px;
    margin-bottom: 18px;
    text-align: left;
  }

  p {
    font-size: 16px;
    line-height: 22.4px;
    color: #191919;
    margin-bottom: 30px;
  }

  b {
    color: #191919;
    font-weight: 600;
  }

  a {
    color: #191919;
  }

  .gambling-link {
    color: #1a73e8;
  }

  .link-blue {
    color: #4455c7;
  }

  .orange {
    color: #fc4714;
  }

  li {
    margin-bottom: 10px;
  }

  ul {
    margin: 0px 0px 19px;

    ul {
      margin: 28px 0px;
    }
  }

  table {
    td {
      border-left: 1px solid #d4d6d8;
      border-top: 1px solid #d4d6d8;
      border-right: 1px solid #d4d6d8;
    }
  }

  .mt-15 {
    margin-top: 15px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .pb-46 {
    padding-bottom: 46px;
  }

  .terms-ul {
    li {
      margin-bottom: 28px;
    }
  }

  .radio-group {
    flex-direction: row;

    .MuiRadio-colorSecondary.Mui-checked {
      color: #0095ff;
    }

    label {
      margin-right: 46px;
    }
  }

  .problem-question {
    padding-left: 0px;
    list-style-type: none;
  }

  .policy-sub-header {
    padding: 0px 18px;

    @media (max-width:470px) {
      padding: 0px 12px;
    }
  }

  .underline {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 479px) {
  .terms-wrap {
    h1 {
      font-size: 31.36px;
      line-height: 43.9px;
    }

    .gambling-link {
      word-wrap: break-word;
    }
  }
}