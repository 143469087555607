@import "../../../../../assets/scss/variable.scss";

.summary-section-wrap {
  padding-bottom: 18px;
  @media (max-width: 799px) {
    padding-bottom: 0px;
  }
  .d-flex {
    display: flex;
  }

  .align-center {
    align-items: center;
  }

  .content-space-between {
    justify-content: space-between;
  }

  .col-gap-5 {
    column-gap: 5px;
  }

  .summary-wrap {
    border: 1px solid #e8eaec;
    border-radius: 8px;
    overflow: hidden;

    .summary-text {
      font-size: 22.4px;
      line-height: 28px;
      font-family: $primaryFont;
      font-weight: 400;
      letter-spacing: 0px;
      color: $color-Black;
      text-align: center;
      padding: 8px 0px;
      background-color: #e7e9ec;

      @media (max-width: 1023px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .summary-details {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 12px 33px;

      &:not(:last-child) {
        border-bottom: 1px solid #e7e9ec;
      }

      @media (max-width: 1023px) {
        justify-content: space-between;
        padding: 12px 15px;
      }

      .details-text {
        font-size: 16px;
        line-height: 19px;
        font-family: $regulerFont;
        color: $color-Black;
        letter-spacing: 0px;

        @media (max-width: 1023px) {
          font-size: 12px;
          line-height: 15px;
        }
      }

      .dark {
        font-weight: 600;
      }

      .light {
        font-weight: 400;
      }

      .w-20 {
        width: 20%;
      }

      .w-60 {
        width: 60%;
      }
    }

    .match-point-Score-board {
      box-shadow: 0px 3px 9px 0px #0000000d;

      .match-details-wrap {
        border-radius: 8px;
        padding: 12px;
        position: relative;

        .divider-indicator {
          height: 100%;
          width: 5px;
          background: $color-Primary;
          position: absolute;
          top: 0px;
          left: 0px;
          border-radius: 8px;
        }

        .match-title {
          font-size: 16px;
          line-height: 19px;
          font-family: $regulerFont;
          color: $color-Black;
          font-weight: 400;

          @media (max-width: 799px) {
            font-size: 14px;
            line-height: 16px;
          }
        }

        .match-status {
          font-size: 14px;
          line-height: 16px;
          font-family: $regulerFont;
          color: $color-Black;
          font-weight: 400;
          margin-top: 3px;

          @media (max-width: 799px) {
            font-size: 12px;
            line-height: 14px;
          }
        }

        .match-team-name {
          font-size: 16px;
          line-height: 19px;
          font-family: $regulerFont;
          color: $color-Black;
          font-weight: 600;
          margin-top: 3px;

          @media (max-width: 799px) {
            font-size: 14px;
            line-height: 16px;
          }
        }

        .team-score {
          font-size: 16px;
          line-height: 19px;
          font-family: $regulerFont;
          color: $color-Black;
          font-weight: 600;
          margin-top: 3px;

          @media (max-width: 799px) {
            font-size: 14px;
            line-height: 16px;
          }

          span {
            font-weight: 400;
          }
        }
      }
    }
  }
}
