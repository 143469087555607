@import "../../../assets/scss/variable.scss";

.international-racing-wrap {
  .fs-16 {
    font-size: 16px;
    line-height: 22.6px;
    font-family: $regulerFont;
    letter-spacing: 0px;
  }

  .fw-400 {
    font-weight: 400;
  }

  .fw-500 {
    font-weight: 500;
  }

  .fw-600 {
    font-weight: 600;
  }

  .fw-700 {
    font-weight: 700;
  }

  .color-black {
    color: $color-Black;
  }

  .bg-color-Light-grey {
    background-color: $color-Light-grey;
  }

  .bg-color-Light-blue {
    background-color: #d6d9f3;
  }

  .racing-header {
    padding: 46px 18px 3px;
    margin-top: -5px;
    background-color: #fbfbfb;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;

      @media (max-width: 1060px) {
        font-size: 38.6px;
      }

      @media (max-width: 1023px) {
        line-height: 31.36px;
        font-size: 31.36px;
      }
    }

    .page-bredcrumn-wrap {
      overflow-x: scroll;

      .bredcrumn-wrap {
        margin-bottom: 5px;
        width: max-content;

        li.MuiBreadcrumbs-separator {
          color: $color-Black;
        }

        li {
          font-size: 11.42px;
          line-height: 14px;

          p {
            font-size: 11.42px;
            line-height: 14px;
            color: $color-Accent-1;
            text-transform: uppercase;
          }

          a {
            color: $color-Black;
            font-size: 11.42px;
            line-height: 14px;
            text-transform: uppercase;
          }
        }
      }
    }

    .page-bredcrumn-wrap::-webkit-scrollbar {
      height: 0px;
    }

    .page-bredcrumn-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .international-racing-tab {
    padding: 19px 18px 13px;
    margin-bottom: 18px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 599px) {
      padding: 9px 12px 12px;
      margin-bottom: 18px;
    }

    .international-racing-tab-detail {
      border-bottom: 2px solid #4455c7;

      .MuiTabs-scrollable {
        .MuiTabs-indicator {
          display: none;
        }

        .active {
          border-bottom: 3px solid $color-Primary;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .MuiButtonBase-root {
        border-bottom: 3px solid transparent;

        .MuiTab-wrapper {
          font-size: 16px;
          font-family: $primaryFont;
          color: $color-Black;
          line-height: 21px;
          font-weight: 400;
        }
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .MuiTab-root {
        min-width: max-content;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: #003764;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .hw-100 {
    width: 100% !important;
    height: 100% !important;
  }

  .img-sec {
    width: 300px;
    height: 214px;
    border-radius: 8px;

    @media (max-width: 400px) {
      width: 100%;
      height: 100%;
    }

    .inner-img {
      @media (max-width: 799px) {
        width: 100%;
        height: 218px;
        object-fit: initial;
      }
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      //   border-radius: 8px;

      @media (max-width: 400px) {
        // width: 100%;
        // height: 100%;
        // object-fit: fill;
      }
    }
  }

  .international-racing-details-wrap {
    padding: 33px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 799px) {
      padding: 18px 12px;
    }

    .top-img-header-sec {
      // margin-top: 21px;
      display: flex;
      align-items: flex-start;
      column-gap: 27px;
      padding-bottom: 18px;
      border-bottom: 1px solid #d4d6d8;

      &:first-child {
        margin-top: 0px;
      }

      @media (max-width: 940px) {
        display: block;
      }

      //   .txt-sec {
      //     width: 70%;

      //     @media (max-width: 940px) {
      //       margin-top: 18px;
      //       width: 100%;
      //     }

      //     // h1 {
      //     //   font-weight: 600 !important;
      //     //   font-size: 16px !important;
      //     //   color: #4455c7 !important;
      //     //   line-height: 22.4px !important;
      //     //   font-family: $regulerFont !important;
      //     // }

      //     // p {
      //     //   span {
      //     //     font-weight: 400 !important;
      //     //     font-size: 16px !important;
      //     //     color: #191919 !important;
      //     //     line-height: 20px !important;
      //     //     font-family: $regulerFont !important;
      //     //   }
      //     // }

      //     // ul {
      //     //   list-style: square !important;
      //     //   li {
      //     //     span {
      //     //       font-weight: 400 !important;
      //     //       font-size: 16px !important;
      //     //       color: #191919 !important;
      //     //       line-height: 20px !important;
      //     //       font-family: $regulerFont !important;
      //     //     }
      //     //   }
      //     // }

      //     .title {
      //       cursor: pointer;
      //       font-weight: 600;
      //       font-size: 22.4px;
      //       color: #191919;
      //       line-height: 31.36px;
      //       font-family: $regulerFont;
      //     }

      //     .des-box {
      //       margin-top: 15px;

      //       .description {
      //         font-weight: 400;
      //         font-size: 16px;
      //         color: #191919;
      //         line-height: 20px;
      //         font-family: $regulerFont;

      //         .read-more-button {
      //           background-color: #007bff;
      //           color: #fff;
      //           border: none;
      //           padding: 5px 10px;
      //           cursor: pointer;
      //           position: absolute;
      //           bottom: 0;
      //           right: 0;
      //           margin: 10px;
      //           outline: none;
      //           font-size: 14px;

      //           &:hover {
      //             background-color: #0056b3;
      //           }

      //           h1 {
      //             font-weight: 600 !important;
      //             font-size: 16px !important;
      //             color: #4455c7 !important;
      //             line-height: 22.4px !important;
      //             font-family: $regulerFont !important;
      //           }

      //           p {
      //             span {
      //               font-weight: 400 !important;
      //               font-size: 16px !important;
      //               color: #191919 !important;
      //               line-height: 20px !important;
      //               font-family: $regulerFont !important;
      //             }
      //           }

      //           ul {
      //             // list-style: square !important;
      //             li {
      //               span {
      //                 font-weight: 400 !important;
      //                 font-size: 16px !important;
      //                 color: #191919 !important;
      //                 line-height: 20px !important;
      //                 font-family: $regulerFont !important;
      //               }
      //             }
      //           }
      //         }
      //       }

      //       .read-more-button {
      //         // background-color: #007bff;
      //         text-transform: capitalize;
      //         color: #4455c7;
      //         border: none;
      //         padding: 0;
      //         cursor: pointer;
      //         // position: absolute;
      //         // bottom: 0;
      //         // right: 0;
      //         // margin: 10px;
      //         outline: none;
      //         font-size: 14px;

      //         &:hover {
      //           background-color: transparent;
      //         }
      //       }

      //       .learn-more {
      //         text-align: end;
      //         text-transform: capitalize;
      //         color: #4455c7;
      //         border: none;
      //         padding: 0;
      //         cursor: pointer;
      //         outline: none;
      //         text-decoration: underline;
      //         font-size: 14px;

      //         &:hover {
      //           background-color: transparent;
      //         }
      //       }
      //     }
      //   }
    }
    .view-tips-btn-sec {
      margin-top: 27px;
      width: 100%;
      .skip-now-btn {
        padding: 15px 0px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        font-family: $regulerFont;
        color: $color-Accent-1;
        border: 1px solid $color-Accent-1;
        .MuiButton-label {
          text-transform: initial;
        }
        @media (max-width: 599px) {
          width: 100%;
        }
      }
      .view-tips-btn {
        padding: 16px 0px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        font-family: $regulerFont;
        background-color: $color-Accent-1;
        color: $color-White;
        text-transform: capitalize;
      }
    }
  }

  .mt-27 {
    margin-top: 27px;
    @media (max-width: 560px) {
      margin-top: 15px;
    }
  }

  .pb-bottom {
    padding-bottom: 18px;
    border-bottom: 1px solid #d4d6d8;
  }

  .w-100 {
    width: 100% !important;
  }

  .race-share-option {
    margin-top: 19.3px !important;
    max-width: 648px;
    width: 100%;
    // margin: 0 auto;
    column-gap: 32px;

    // @media (max-width: 1048px) {
    //   justify-content: center;
    //   max-width: 296px;
    //   row-gap: 11px;
    //   margin-top: 12px;
    // }

    .copy-link {
      cursor: pointer;
    }

    .social-box {
      display: flex;
      align-items: center;
      column-gap: 34px;
      justify-content: space-between;

      @media (max-width: 560px) {
        flex-wrap: wrap;
      }
    }

    .option-name {
      font-family: $regulerFont;
      font-size: 11.42px;
      line-height: 15px;
      color: #5d5d5d;

      @media (max-width: 560px) {
        font-size: 11.42px;
      }
    }

    .react-share__ShareButton {
      width: 32px;
      height: 32px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .social-icon {
      cursor: pointer;
    }
  }

  .txt-sec {
    width: 70%;

    @media (max-width: 940px) {
      margin-top: 18px;
      width: 100%;
    }

    // h1 {
    //   font-weight: 600 !important;
    //   font-size: 16px !important;
    //   color: #4455c7 !important;
    //   line-height: 22.4px !important;
    //   font-family: $regulerFont !important;
    // }

    // p {
    //   span {
    //     font-weight: 400 !important;
    //     font-size: 16px !important;
    //     color: #191919 !important;
    //     line-height: 20px !important;
    //     font-family: $regulerFont !important;
    //   }
    // }

    // ul {
    //   list-style: square !important;
    //   li {
    //     span {
    //       font-weight: 400 !important;
    //       font-size: 16px !important;
    //       color: #191919 !important;
    //       line-height: 20px !important;
    //       font-family: $regulerFont !important;
    //     }
    //   }
    // }

    .title {
      cursor: pointer;
      font-weight: 600;
      font-size: 22.4px;
      color: #191919;
      line-height: 31.36px;
      font-family: $regulerFont;
    }

    .des-box {
      margin-top: 15px;

      .description {
        font-weight: 400;
        font-size: 16px;
        color: #191919;
        line-height: 20px;
        font-family: $regulerFont;

        .read-more-button {
          background-color: #007bff;
          color: #fff;
          border: none;
          padding: 5px 10px;
          cursor: pointer;
          position: absolute;
          bottom: 0;
          right: 0;
          margin: 10px;
          outline: none;
          font-size: 14px;

          &:hover {
            background-color: #0056b3;
          }

          h1 {
            font-weight: 600 !important;
            font-size: 16px !important;
            color: #4455c7 !important;
            line-height: 22.4px !important;
            font-family: $regulerFont !important;
          }

          p {
            span {
              font-weight: 400 !important;
              font-size: 16px !important;
              color: #191919 !important;
              line-height: 20px !important;
              font-family: $regulerFont !important;
            }
          }

          ul {
            // list-style: square !important;
            li {
              span {
                font-weight: 400 !important;
                font-size: 16px !important;
                color: #191919 !important;
                line-height: 20px !important;
                font-family: $regulerFont !important;
              }
            }
          }
        }
      }

      .read-more-button {
        // background-color: #007bff;
        text-transform: capitalize;
        color: #4455c7;
        border: none;
        padding: 0;
        cursor: pointer;
        // position: absolute;
        // bottom: 0;
        // right: 0;
        // margin: 10px;
        outline: none;
        font-size: 14px;

        &:hover {
          background-color: transparent;
        }
      }

      .learn-more {
        text-align: end;
        text-transform: capitalize;
        color: #4455c7;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        text-decoration: underline;
        font-size: 14px;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .typography-header {
    padding-bottom: 10px;
    border-bottom: 1px solid $color-Accent-1;

    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .international-racing-warp {
    margin-top: 18px;
  }

  // Table

  .table-box-shadow {
    box-shadow: 0px 1px 9px 0px #0000002e;
  }

  .border-gray {
    border: 1px solid #e7e9ec;
  }

  .international-table-container {
    border-radius: 8px;

    .international-table {
      .MuiTableRow-head {
        .MuiTableCell-head {
          padding: 9px 21px;
        }

        .in-head {
          font-size: 22.4px;
          line-height: 28px;
          font-weight: 400;
          color: $color-Black;
          font-family: $primaryFont;
          letter-spacing: 0px;
        }

        .in-head-txt {
          text-transform: uppercase;
          font-size: 16px;
          line-height: 21px;
          font-weight: 600;
          color: #4455c7;
          font-family: $regulerFont;
          letter-spacing: 0px;
        }
      }

      .MuiTableCell-body {
        padding: 9px 21px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        color: $color-Black;
        font-family: $regulerFont;
        letter-spacing: 0px;
        border-bottom: 1px solid $color-Light-grey;

        // &:last-child {
        //   border-bottom: 0 !important;
        // }
      }

      .link-blue {
        color: $color-Accent-1;
      }
    }
  }
}
