@import "../../../../assets/scss/variable.scss";

.public-comp-wrap {
  background-color: #fbfbfb;

  .my-comp-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .tab-search-section {
    padding: 18px 18px 19px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;
    // margin-bottom: 18px;
    @media (max-width: 799px) {
      margin: 0px -12px;
    }

    .search-filter-wrap {
      margin-bottom: 12px;

      .search_input {
        width: 100%;

        .MuiInputBase-root {
          max-height: 45px;

          svg {
            height: 18px;
            width: 18px;
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: #e7e9ec;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: $color-Accent-1;
          border-width: 1.5px;
        }

        @media (max-width: 799px) {
          margin-left: 0px !important;
          min-width: auto;
          width: 100%;
        }
      }
    }

    .filter-section {
      max-width: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 12px;

      @media only screen and (max-width: 1023px) {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 12px;
        row-gap: 12px;
      }

      @media only screen and (max-width: 479px) {
        grid-template-columns: auto auto auto;
      }

      .sort-select {
        max-width: 265px;
        width: 100%;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .mw-none {
        width: 100%;
      }

      .select__control {
        background-color: $color-Light-grey;
        border-radius: 8px;
        max-height: 36px;

        .select__single-value {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__placeholder {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__input-container {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__indicator-separator {
          width: 0px;
        }

        .select__dropdown-indicator {
          padding: 10px;
        }
      }

      .select__menu-portal {
        z-index: 99;
      }

      .select__menu,
      .react-select__menu {
        .select__menu-list,
        .react-select__menu-list {
          .select__option,
          .react-select__option {
            cursor: pointer;
            font-size: 16px;
            color: $color-Black;
            font-family: $regulerFont;
            line-height: 19px;
            padding: 11px;

            &.select__option--is-focused {
              background-color: #d4d6d8;
              color: #000;
            }

            &.select__option--is-selected {
              background-color: #d4d6d8;
              color: $color-Black;
            }
          }
        }

        .select__menu-list,
        .react-select__menu-list {
          .select__group {
            .select__group-heading {
              margin-bottom: 0.5rem;
              color: green;
              font-weight: bolder;
              font-size: inherit;
            }
          }
        }
      }

      .disable-state {
        .select__placeholder {
          opacity: 0.4;
        }
      }
    }
    .tipping-type-checkbox {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      padding-top: 12px;
      display: flex;
      column-gap: 30px;
      flex-wrap: wrap;
    }
  }

  .page-deatils-wrap {
    padding: 18px;

    @media (max-width: 799px) {
      padding: 21px 12px 27px 12px;
    }

    .title {
      font-weight: 400;
      font-size: 22.4px;
      color: #003764;
      line-height: 31.36px;
      font-family: $primaryFont;
    }

    .useredit-details {
      display: flex;
      align-items: center;

      .back-arrow {
        transform: rotate(90deg);
        margin-right: 10px;
        cursor: pointer;
      }

      h4 {
        text-decoration: underline;
      }
    }

    .comp-field-container {
      margin-top: 21px;
      max-width: 835px;
      width: 100%;
    }

    .my-comp-sec {
      background-color: $color-White;
      box-shadow: 0px 3px 9px 0px #0000000d;
      border-radius: 8px;
      width: 100%;
      margin-top: 18px;

      &:first-child {
        margin-top: 0px;
      }

      .top-sec {
        background-image: url("../../../../assets/images/comp-header.png");
        background-repeat: round;
        padding: 9px 9px 9px 27px;
        display: flex;
        align-items: center;
        column-gap: 11px;

        @media (max-width: 799px) {
          padding: 9px 9px 9px 12px;
        }

        .tournament-txt {
          font-weight: 400;
          font-family: "VeneerClean-Soft" !important;
          color: #fff;
          font-size: 22.4px;
          line-height: 31.36px;

          @media (max-width: 580px) {
            font-size: 16px;
          }
        }

        .chip-sec {
          text-align: center;
          background-color: #4455c7;
          border-radius: 23px;
          padding: 3px 6px 1px 6px;

          //   margin-left: 23px;
          .chip-txt {
            font-weight: 400;
            font-family: "VeneerClean-Soft" !important;
            color: #fff;
            font-size: 16px;
            line-height: 31.36px;

            @media (max-width: 580px) {
              font-size: 11.42px;
            }
          }
        }
      }

      .bottom-sec {
        display: flex;
        justify-content: space-between;
        align-items: end;
        padding: 19px 27px 21px 27px;

        @media (max-width: 999px) {
          padding: 19px 11px 21px 11px;
          flex-direction: column;
          align-items: flex-start;
        }

        @media (max-width: 799px) {
          padding: 19px 27px 21px 27px;
        }

        @media (max-width: 650px) {
          align-items: start;
          padding: 12px 12px 12px 12px;
        }

        @media (max-width: 479px) {
          flex-direction: column;
        }

        .comp-custom-tooltip {
          position: relative;
          // padding-left: 5px;
          // padding-right: 10px;

          .tooltip {
            // transform: translateX(-22%);
            top: -28px;
            left: -202px;
            width: max-content;
            // top: -34px;
            // left: -222px;

            // transform: translateX(-26%);
            padding: 12px !important;

            @media (max-width: 799px) {
              left: 34px;
              // left: initial;
              // right: -12px;
              // transform: rotate(270deg);

              // right: 50px;
              // transform: rotate(540deg);
              // top: -9px;
              padding: 8px !important;
            }

            @media (max-width: 560px) {
              left: 42px;
            }

            @media (max-width: 479px) {
              left: auto;
              right: 0px;
              top: 65px;
              transform: translateX(0px);
            }

            .add-blackbook {
              border-bottom: none;
              padding: 6px 10px 6px 8px;
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 600;
              color: #003764;
              font-family: $regulerFont;
              display: flex;
              align-items: center;
            }

            .tooltip-arrow {
              right: -24px;
              left: unset;
              top: 47px;
              transform: rotate(270deg);

              @media (max-width: 799px) {
                // left: initial;
                // right: -12px;
                // transform: rotate(270deg);

                // right: 50px;
                // transform: rotate(540deg);
                // top: -9px;
                right: -19px;
              }

              @media (max-width: 479px) {
                top: -16px;
                transform: rotate(180deg);
                right: 5px;
              }
            }

            .share-option {
              //   display: flex;
              //   justify-content: space-between;
              //   align-items: center;
              //   flex-wrap: wrap;
              position: relative;
              max-width: 551px;
              width: 100%;
              margin: 0 auto;
              column-gap: 20px;

              @media (max-width: 1048px) {
                justify-content: center;
                max-width: 296px;
                row-gap: 11px;
                margin-top: 12px;
              }

              .react-share__ShareButton {
                width: 32px;
                height: 32px;

                img {
                  height: 100%;
                  width: 100%;
                }
              }
            }

            .social-option-box {
              display: grid;
              grid-template-columns: auto auto auto auto;
              column-gap: 10px;
              row-gap: 10px;

              .option {
                text-align: center;
              }

              .option-name {
                font-family: $regulerFont;
                font-size: 11.42px;
                line-height: 15px;
                color: #5d5d5d;

                @media (max-width: 560px) {
                  font-size: 11.42px;
                }
              }
            }
          }
        }

        .left-sec {
          .left-sec-image-des {
            display: flex;
            align-items: start;
            column-gap: 32px;
            @media (max-width: 799px) {
              column-gap: 12px;
            }
          }
          .profile-img-wrap {
            width: 100px;
            height: 100px;
            border-radius: 50%;

            @media (max-width: 799px) {
              width: 54px;
              height: 54px;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
              background-color: $color-White;
              border: 1px solid $color-Accent-1;
            }
          }

          .sport-icon-sec {
            height: 27px;
            width: 27px;

            @media (max-width: 999px) {
              width: 20px;
              height: 20px;
            }

            .sport-icon {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          .name-time {
            .my-comp-icon-name-wrap {
              display: flex;
              align-items: center;
              column-gap: 5px;

              @media (max-width: 999px) {
                align-items: flex-start;
              }
              @media (max-width: 799px) {
                margin-bottom: 6px;
              }
            }

            .sport-name {
              font-weight: 600;
              font-family: $regulerFont;
              color: #191919;
              font-size: 22.4px;
              line-height: 31.36px;

              @media (max-width: 999px) {
                font-size: 16px;
                line-height: 20px;
              }
            }

            .about-comp-dec {
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 400;
              font-family: $regulerFont;
              letter-spacing: 0px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              .about-line {
                margin: 0px;
                display: inline;
              }
              span {
                font-weight: 600;
              }
              @media (max-width: 999px) {
                font-size: 14px;
              }
              @media (max-width: 799px) {
                margin-bottom: 9px;
              }
              @media (max-width: 580px) {
                font-size: 12px;
                line-height: 15px;
              }
            }

            .final-txt {
              font-size: 16px;
              margin-top: 5px;
              display: flex;
              align-items: center;
              column-gap: 5px;

              @media (max-width: 999px) {
                font-size: 14px;
              }

              @media (max-width: 580px) {
                display: block;
                font-size: 12px;
                margin-top: 0px;
              }

              .comp-header-details {
                .day-time-details {
                  font-weight: 400;
                  font-family: $regulerFont;
                  color: $color-Black;
                  font-size: 16px;
                  line-height: 21px;

                  @media (max-width: 999px) {
                    font-size: 14px;
                    line-height: 16px;
                  }
                  @media (max-width: 580px) {
                    font-size: 12px;
                  }

                  span {
                    font-weight: 400;
                    font-family: $regulerFont;
                    color: #191919;
                    font-size: 16px;
                    line-height: 21px;

                    @media (max-width: 999px) {
                      font-size: 14px;
                      line-height: 16px;
                    }
                    @media (max-width: 580px) {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }

        .right-btn-wrap {
          display: flex;
          column-gap: 5px;

          @media (max-width: 999px) {
            width: 100%;
            justify-content: end;
          }
        }

        .join-btn {
          margin-top: 11px;
          padding: 11px 24px 12px 24px;
          background-color: #fff;
          color: #4455c7;
          border: 1px solid #4455c7;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: "Inter", sans-serif;
          border-radius: 8px;
          text-transform: capitalize;
          cursor: pointer;
          width: 120px;
          @media (max-width: 999px) {
            width: 50%;
          }
          @media (max-width: 900px) {
            padding: 11px 14px 12px 14px;
          }

          @media (max-width: 799px) {
            padding: 11px 24px 12px 24px;
          }

          @media (max-width: 650px) {
            font-size: 11.45px;
            padding: 12px 12px 12px 12px;
          }

          // @media (max-width: 479px) {
          //   width: 50%;
          // }
        }

        .disable-join-btn {
          width: 100%;
          background-color: #e7e9ec;
          color: #999999;
          border: 1px solid #d4d6d8;
          @media (max-width: 999px) {
            width: 50%;
          }
          // @media (max-width: 479px) {
          //   width: 50%;
          // }

          .MuiButton-startIcon {
            svg {
              g {
                path {
                  stroke: #999999;
                }
              }
            }
          }
        }

        .share-tooltip-icon {
          @media (max-width: 999px) {
            width: 50%;
          }
        }

        .right-sec {
          @media (max-width: 999px) {
            width: 100%;
          }
          .share-btn {
            margin-top: 11px;
            padding: 11px 24px 12px 24px;
            background-color: #fff;
            color: #4455c7;
            border: 1px solid #4455c7;
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            font-family: "Inter", sans-serif;
            border-radius: 8px;
            text-transform: capitalize;
            cursor: pointer;
            @media (max-width: 999px) {
              width: 100%;
            }
            @media (max-width: 900px) {
              padding: 11px 14px 12px 14px;
            }

            @media (max-width: 799px) {
              padding: 11px 24px 12px 24px;
            }

            @media (max-width: 650px) {
              font-size: 11.45px;
              padding: 12px 12px 12px 12px;
            }

            // @media (max-width: 479px) {
            //   width: 100%;
            // }
          }
        }
      }
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .cursor-text {
      cursor: auto;
    }
  }

  .pagination-section {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .MuiPagination-root {
      .MuiPagination-ul {
        .MuiButtonBase-root {
          width: 36px;
          height: 36px;

          svg {
            width: 24px;
            height: 24px;
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }

        .MuiPaginationItem-root {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          border-radius: 50%;
        }
      }
    }
  }

  .comp-component-wrap {
    .comp-container {
      margin-top: 18px;
    }
  }
}
