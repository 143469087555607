@import "../../../assets/scss/variable.scss";

body {
  &::-webkit-scrollbar {
    display: none;
  }
}

.event-calendar {
  .event-calendar-top {
    padding: 21px 24px 22px 18px;
    background-color: #ffffff;

    .search {
      width: 100%;
      padding-bottom: 16px;

      input {
        padding: 14px 5px;
        font-size: 16px;
        color: #191919;
        line-height: 19px;
        font-family: "Inter", sans-serif;
      }
    }

    .select-wrap {
      max-width: none;
      margin: 13px 0px 0px;
      display: flex;

      @media only screen and (max-width: 1023px) {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 12px;
        row-gap: 12px;
      }

      @media only screen and (max-width: 479px) {
        grid-template-columns: auto auto auto;
      }

      .series-select {
        min-width: 151.8px;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      // .year-select {
      //   min-width: 140px;
      //   margin-right: 4px;
      //   @media only screen and (max-width: 1023px) {
      //     margin-right: 0px;
      //   }
      //   @media only screen and (max-width: 479px) {
      //     grid-column-start: 1;
      //     grid-column-end: 3;
      //   }
      // }
      .details-search-picker {
        margin: 0;

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        .MuiOutlinedInput-root {
          background: #e7e9ec;
          border-radius: 8px;
          padding: 0;
          max-width: 180px;
          margin-right: 4px;

          @media only screen and (max-width: 1023px) {
            max-width: initial;
            width: 100%;
            margin-right: 0px;
          }

          input {
            padding: 9px 8px;
            font-family: $regulerFont !important;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            color: $color-Black;
          }

          fieldset {
            border: none;
          }
        }
      }

      #date-picker-inline::placeholder {
        color: #191919;
        opacity: 1;
      }

      .team-select {
        min-width: 140px;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 3;
          grid-column-end: 4;
        }
      }

      .market-select {
        min-width: 140px;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .stepper-input {
        display: flex;
        border: 1px solid #c9c9c9;
        border-radius: 8px;
        max-height: 36px;

        .stepper-input__button {
          padding: 10px 14px;
          min-width: 35px;
          cursor: pointer;
          border-radius: 0px;

          border-left: 1px solid #c9c9c9;
          border-collapse: collapse;

          &:first-child {
            border-left: 0px;
            border-right: 1px solid #c9c9c9;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        }

        .stepper-input__content {
          font-family: $regulerFont;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          padding: 8px 26px;

          color: $color-Black;
          width: 100%;
          text-align: center;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .select__control {
        background-color: $color-Light-grey;
        border-radius: 8px;
        max-height: 36px;
        max-width: 400px;

        .select__single-value {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__placeholder {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__input-container {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__indicator-separator {
          width: 0px;
        }

        .select__dropdown-indicator {
          padding: 10px;
        }
      }

      .select__control--is-disabled .select__placeholder {
        opacity: 0.6;
      }

      .select__menu-portal {
        z-index: 99;
      }
    }

    .calendar-list-button {
      background-color: $color-White;
      min-width: 294px;
      justify-content: start;
      font-size: 22.4px;
      font-family: $primaryFont;
      color: $color-Black;
      line-height: 31.36px;
      text-transform: capitalize;
      padding-bottom: 13px;
    }

    .selected-calendar-list {
      color: $color-Accent-1;
    }

    .event-cal-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1279px) {
        display: block;

        .date-sec {
          margin-top: 10px;
        }
      }

      .filter-sec {
        display: flex;

        @media (max-width: 959px) and (min-width: 799px) {
          display: grid;
          grid-template-columns: 50% 50%;
          gap: 4px;

          .select-wrap {
            max-width: 100%;
            display: block;

            .series-select {
              min-width: 100%;
            }

            .select__control {
              max-width: 100%;
            }
          }
        }

        @media (max-width: 659px) {
          display: grid;
          grid-template-columns: 50% 50%;
          gap: 4px;

          .select-wrap {
            max-width: 100%;
            display: block;

            .series-select {
              min-width: 100%;
            }

            .select__control {
              max-width: 100%;
            }
          }
        }
      }

      .date-sec {
        display: flex;

        @media (max-width: 959px) and (min-width: 799px) {
          display: grid;
          grid-template-columns: auto auto;
          gap: 4px;

          .details-date-picker {
            max-width: 100%;
            margin: 0;
          }
        }

        @media (max-width: 659px) {
          display: grid;
          grid-template-columns: auto auto;
          gap: 4px;

          .details-date-picker {
            max-width: 100%;
            margin: 0;
          }
        }
      }

      .select-wrap {
        max-width: 151.8px;
        margin: 0;
        margin-right: 4px;
      }

      .select-wrap:last-child {
        margin-right: 0;
      }

      .details-day-date-picker {
        max-width: 270px !important;
      }

      .details-date-picker {
        max-width: 171px;
        margin: 0px 4px 0px 0px;

        // button {
        //   padding: 0;
        //   width: 21px;
        //   height: 21px;
        // }

        input {
          padding: 11px 0 11px 8px;

          &::placeholder {
            font-size: 14px;
            font-family: $regulerFont;
            color: $color-Black;
            line-height: 19px;
          }
        }
      }

      .details-date-picker:last-child {
        margin: 0;
      }
    }
  }

  .event-calendar-bottom {
    padding: 32px 64px 34px 33px;
    margin-top: 24px;
    background-color: #ffffff;

    @media (max-width: 1259px) {
      padding: 22px;
    }

    @media (max-width: 759px) {
      padding: 12px;
    }
  }
}

.calendar-list {
  .list-item {
    min-width: 294px;
    font-size: 16px;
    font-family: $regulerFont;
    color: $color-Black;
    line-height: 21px;
    cursor: pointer;

    .list-icon {
      min-width: 0;
      padding-right: 9px;
    }
  }
}

.no-data-available {
  font-family: $regulerFont;
  color: $color-gray-dark;
  font-size: 22.4px;
  line-height: 31.36px;
}

.fc-h-event {
  background-color: #ffffff;
  border: 0;
}

.event-fullcalendar-day {
  cursor: pointer;
  background-color: #ffffff;

  .event-fullcalendar-title {
    font-size: 14px;
    font-family: $regulerFont;
    font-weight: 600;
    color: $color-Black;
    line-height: 19px;
    white-space: wrap;
    padding-bottom: 9px;

    @media (max-width: 759px) {
      font-size: 9px;
      line-height: 12px;
    }
  }

  .event-fullcalendar-date {
    font-size: 11.42px;
    font-family: $regulerFont;
    color: $color-Black;
    line-height: 15px;
    white-space: wrap;

    @media (max-width: 759px) {
      font-size: 9px;
      line-height: 12px;
    }
  }

  .event-calender-more {
    font-size: 11.42px;
    font-family: $regulerFont;
    color: #1a73e8;
    line-height: 14px;

    @media (max-width: 759px) {
      font-size: 9px;
      line-height: 12px;
    }
  }
}