@import "../../../../assets/scss/variable.scss";

.plan-details-wrap {

    .membership-card-data {
        background-image: url("../../../../assets/images/memberBG.png");
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 8px;

        .membership-plan {
            padding: 29px 26px 67px;

            @media (max-width:799px) {
                padding: 15px 12px;
            }

            .plan-title {
                font-size: 22.4px;
                line-height: 27px;
                font-weight: 500;
                font-family: $regulerFont;
                letter-spacing: 0px;
                color: $color-White;
                margin-bottom: 31px;
                text-transform: capitalize;

                @media (max-width:799px) {
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 12px;
                }
            }

            .plan-title-box {
                padding: 17px 20px 16px;
                background-color: #ffffff40;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 35px;

                @media (max-width:799px) {
                    padding: 15px;
                    margin-bottom: 15px;
                }

                .bronze-wrap {
                    background: linear-gradient(180deg, rgba(213, 189, 161, 1) 0%, rgba(190, 133, 101, 1) 15%, rgba(185, 121, 61, 1) 30%, rgba(185, 121, 61, 1) 70%, rgba(241, 187, 140, 1) 100%);
                    color: $color-White;
                    border-radius: 17px;
                    font-size: 14px;
                    line-height: 19px;
                    font-family: $peckhamPressTrial;
                    font-weight: 400;
                    letter-spacing: 0px;
                    text-transform: capitalize;
                    padding: 2px 12px 4px;
                    text-align: center;

                    @media (max-width:799px) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

                .plan-text-details {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: $regulerFont;
                    letter-spacing: 0px;
                    color: $color-White;
                    margin-top: 5px;

                    @media (max-width:799px) {
                        font-size: 11.42px;
                        line-height: 14px;
                    }
                }

                .price-wrap {

                    .price {
                        font-size: 31.36px;
                        line-height: 43.9px;
                        font-family: $regulerFont;
                        color: $color-White;
                        font-weight: 700;
                        letter-spacing: 0px;

                        @media (max-width:799px) {
                            font-size: 27px;
                            line-height: 32px;
                        }

                        .suffix {
                            font-size: 16px;
                            line-height: 19px;
                            font-weight: 400;
                            text-transform: lowercase;

                            @media (max-width:799px) {
                                font-size: 11.42px;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }

            .plan-info {
                .des-wrap {
                    .des-box {
                        display: flex;
                        // align-items: flex-start;
                        column-gap: 9px;

                        @media (max-width:799px) {
                            column-gap: 6px;
                        }

                        .tic-box {
                            @media (max-width:799px) {
                                width: 18px;
                                height: 18px;
                                min-width: 18px;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        .des {
                            font-size: 16px;
                            line-height: 22.4px;
                            font-weight: 400;
                            font-family: $regulerFont;
                            color: $color-White;
                            letter-spacing: 0px;

                            @media (max-width:799px) {
                                font-size: 12px;
                                line-height: 14px;
                            }
                        }

                        &:not(:last-child) {
                            margin-bottom: 18px;

                            @media (max-width:799px) {
                                margin-bottom: 9px;
                            }
                        }
                    }
                }
            }

            .show-more-btn-wrap {
                text-align: right;
                margin-top: 10px;

                .show-more-btn {

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        column-gap: 3px;
                        font-size: 12px;
                        line-height: 15px;
                        font-weight: 400;
                        color: $color-White;
                        font-family: $regulerFont;
                        letter-spacing: 0px;
                        cursor: pointer;

                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }

                }
            }
        }
    }

}