@import "../../../../assets/scss/variable.scss";

.about-sec {
  // margin: 0px 220px 0px 50px;

  @media (max-width: 1250px) {
    margin: 0px 50px 0px 50px;
  }
  @media (max-width: 630px) {
    margin: 33px 12px 0px 12px;
  }
  .joined-txt {
    margin-top: 4px;
    color: #989898;
    font-size: 16px;
    line-height: 22.4px;
    font-weight: 400;
    font-family: $regulerFont;
  }
  .mt-27 {
    margin-top: 27px;
  }

  .details-sec {
    // margin: 0px 50px 0px 50px;
    margin-top: 27px;

    .detail-title {
      color: #003764;
      font-size: 14px;
      line-height: 22.4px;
      font-weight: 600;
      font-family: $regulerFont;
    }

    .details {
      display: flex;
      //   justify-content: space-between;
      justify-content: start;
      align-items: center;
      margin-top: 17px;
      padding-bottom: 9px;
      border-bottom: 1px solid #e7e9ec;

      .left {
        width: 50%;
        color: #191919;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        font-family: $regulerFont;
      }

      .right {
        width: 50%;
        color: #191919;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        font-family: $regulerFont;
      }
    }
  }
}
