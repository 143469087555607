@import '../../../../assets/scss/variable.scss';

.Event-details {
  padding: 0 34px 34px 18px;

  @media (max-width:799px) {
    padding: 0px 12px 34px 12px;
  }

  .event-details-head {
    display: flex;
    justify-content: space-between;

    .event-details-left {
      max-width: 70%;

      .event-title {
        font-size: 43.9px;
        font-family: VeneerClean-Soft !important;
        color: $color-Black;
        line-height: 61.47px;
        font-weight: 400;

        @media (max-width:799px) {
          font-size: 31.36px;
          line-height: 43.9px;
        }
      }

      .event-text {
        font-size: 22.4px;
        font-family: VeneerClean-Soft !important;
        color: $color-Black;
        line-height: 30px;
        font-weight: 400;


      }
    }

    @media (max-width: 1299px) {
      //and (min-width: 991px)
      display: block;

      .event-details-left {
        max-width: 100%;
      }
    }

    @media (max-width: 599px) {
      display: block;

      .event-details-left {
        max-width: 100%;
      }
    }
  }

  .calendar-list-button {
    background-color: $color-White;
    min-width: 219px;
    justify-content: start;
    font-size: 16px;
    font-family: $primaryFont;
    color: $color-Accent-1;
    line-height: 21px;
    text-transform: capitalize;
    font-weight: 400;
    border: 1px solid $color-Accent-1;
    border-radius: 8px;
    padding: 11px 24px 12px;

    @media (max-width: 599px) {
      margin-top: 16px;
    }

    @media (max-width: 1299px) {
      margin-top: 16px;
    }
  }

  .selected-calendar-list {
    color: $color-Accent-1;
  }

  .event-calendar-table-design {
    padding-top: 21px;

    .event-table {
      .event-table-head {
        background-color: $color-Light-grey;
        text-transform: none;
        padding: 0;

        .event-table-row {
          padding: 0;
          border: 1px solid #d4d6d8;

          .event-table-cell {
            padding: 6px 0 10px 0;
            font-family: VeneerClean-Soft !important;
            font-size: 22.4px;
            line-height: 31.36px;
            font-weight: 400;
            color: $color-Black;
            text-transform: capitalize !important;
          }

          .event-table-cell {
            width: 30%;
            padding: 6px 0 10px 0;
          }

          .event-table-cell:first-child {
            width: 40%;
            padding: 6px 0 10px 21px;
          }

          @media (max-width: 599px) {
            .event-table-cell {
              width: 100%;
            }

            .event-table-cell:first-child {
              width: 100%;
            }
          }

          //   @media (max-width: 759px) {
          //     padding: 12px;
          //   }
        }
      }

      .event-table-body {
        .event-table-row {
          border: 1px solid #d4d6d8;

          .event-table-cell {
            padding: 18px 0 13px 0px;

            .cell-text {
              font-family: $regulerFont;
              font-size: 16px;
              line-height: 21px;
              font-weight: 400;
              color: $color-Black;
            }

            .mb-8 {
              margin-bottom: 8px;
            }

            .cell-text-bold {
              font-weight: 600;
            }

            .cell-text-blue {
              color: $color-Accent-1;
            }
          }

          .event-table-cell {
            width: 30%;
          }

          .event-table-cell:first-child {
            width: 40%;
            padding: 18px 0 13px 21px;
          }

          @media (max-width: 599px) {
            .event-table-cell {
              width: 100%;
            }

            .event-table-cell:first-child {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .News-wrap {
    margin-top: 31px;

    .news-heading {
      font-size: 22.4px;
      line-height: 30px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-Black;
      position: relative;
      margin-bottom: 27px;
      cursor: pointer;

      .right-arrow {
        margin-left: 10px;
      }

      &::after {
        content: '';
        background-color: #4455c7;
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
      }
    }

    .related-event {
      display: flex;
      padding: 12px 0 9px;
      cursor: pointer;

      .event-data {
        padding-right: 15px;
        width: 98%;

        .event-title {
          font-size: 16px;
          font-family: $primaryFont;
          color: $color-gray-dark;
          line-height: 21px;
          font-weight: 400;
        }

        .event-text {
          font-size: 11.42px;
          font-family: $primaryFont;
          color: $color-gray-dark;
          line-height: 15px;
          font-weight: 400;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .related-event:first-child {
      padding-top: 18px;
    }
  }
}

.add-calendar-list {
  .MuiPaper-root {
    margin-top: 5px;
  }

  .list-item {
    cursor: pointer;
    padding: 11px 12px;

    .MuiTypography-root {
      min-width: 219px;
      font-size: 16px;
      font-family: $primaryFont;
      color: $color-Black;
      line-height: 21px;
      font-weight: 400;
    }

    .list-text {
      margin: 0;
    }
  }

  .active {
    background-color: #e7e9ec;
  }
}