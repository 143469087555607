@import "../../../../assets/scss/variable.scss";

.tipping-comp-card-wrap {
  background-color: $color-White;
  border-radius: 8px;
  box-shadow: 0px 1px 9px 0px #00000033;
  max-width: 904px;

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  @media (max-width: 1279px) {
    max-width: 100%;
  }

  .tipping-comp-top-header {
    padding: 13px 18px 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-Accent-1;

    @media (max-width: 999px) {
      padding: 14px 15px 6px;
    }

    .comp-header-text {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      font-family: $regulerFont;
      color: $color-Black;
      letter-spacing: 0px;

      @media (max-width: 999px) {
        font-size: 11.42px;
        line-height: 14px;
      }

      .dark-color {
        font-weight: 600;
      }
    }
  }

  .comp-odds-tips-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-sec {
      display: flex;
      align-items: center;
      width: 50%;
    }

    .left-sec-end {
      justify-content: flex-end;

      @media (max-width: 999px) {
        flex-direction: column-reverse !important;
      }
    }

    .blue-score {
      background-color: $color-Primary;
      width: 52px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      @media (max-width: 999px) {
        position: absolute;
        top: 21px;
        min-width: 32px;
        height: 32px;
      }

      .score {
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        color: $color-White;
        text-align: center;

        @media (max-width: 999px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    .check-box-sec {
      .tip-comp-checkbox {
        padding: 0px 9px;
        height: 40px;
        width: 40px;

        @media (max-width: 999px) {
          height: 28px;
          width: 28px;
          padding: 0px 6px;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .team-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
      width: 150px;

      @media (max-width: 999px) {
        width: initial;
      }

      .team-img-wrap {
        width: 65px;
        height: 65px;
        border-radius: 50%;

        @media (max-width: 999px) {
          width: 50px;
          height: 50px;
        }

        .team-img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: contain;
        }
      }

      .marign-right-mr {
        @media (max-width: 999px) {
          margin-right: auto;
        }
      }

      .margin-left-ml {
        @media (max-width: 999px) {
          margin-left: auto;
        }
      }
    }

    .comp-name-tip-wrap {
      display: flex;
      align-items: center;
      height: 40px;

      @media (max-width: 599px) {
        width: 100%;
        justify-content: flex-end;
      }

      .team-name {
        width: 115px;
        text-align: center;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: $color-Black;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        @media (max-width: 999px) {
          font-size: 14px;
          line-height: 18px;
        }

        @media (max-width: 599px) {
          min-width: 90px;
          max-width: 90px;
          width: fit-content;
        }
      }

      .blackbook-icon {
        .smartboook-icon-box {
          margin: 0px;
          min-width: 34px;
        }
      }

      .comp-smartbook-tooltip {
        width: 185px;
        transform: translateX(-118%);
        left: 122px;
        top: 36px;

        @media only screen and (max-width: 599px) {
          left: 110px;
        }

        .comp-smartbook-tooltip-arrow {
          left: initial;
          right: 50px;
          transform: rotate(540deg);
          top: -9px;

          @media only screen and (max-width: 599px) {
            right: 37px;
          }
        }
      }
      .hometeam_tooltip {
        left: 225px;
        .comp-smartbook-tooltip-arrow {
          left: 30px;
          right: initial;
        }
      }
    }

    .flex-rr {
      flex-direction: row-reverse;
    }

    .team-wrap-details {
      padding: 15px 0px;
      display: flex;
      align-items: center;
      column-gap: 28px;
      width: 100%;
      background-color: #f2f2f2;

      @media (max-width: 999px) {
        padding: 12px 0px;
        flex-direction: column;
        row-gap: 9px;
        position: relative;
      }
    }

    .dark {
      background-color: #dee5eb;
    }

    .left-padding {
      padding-left: 25px;

      @media (max-width: 999px) {
        padding-left: 12px;

        .blue-score {
          right: 18px;
        }
      }
    }

    .right-padding {
      padding-right: 25px;

      @media (max-width: 999px) {
        padding-right: 12px;

        .blue-score {
          left: 18px;
        }
      }
    }
  }

  .margin-sec-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
  }

  .margin-sec {
    text-align: center;

    .margin-txt {
      color: $color-Black;
      font-size: 16px;
      line-height: 21px;
      font-weight: 500;
      font-family: $regulerFont;
    }
  }

  .counter-score-sec {
    padding: 9px 0px 9px;
    max-width: 183px;
    width: 100%;

    .counter-input {
      display: flex;
      border-radius: 8px;
      max-height: 36px;
      border: 1px solid #c9c9c9;
      overflow: hidden;

      .counter-input__button {
        padding: 10px 10px;
        cursor: pointer;
        min-width: 36px;
        color: $color-Black;
        border-left: 0px;
        border-radius: 0px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;

        @media (max-width: 999px) {
          min-width: 28px;
        }

        .MuiButton-root {
          min-width: 36px !important;
        }

        @media (max-width: 540px) {
          font-size: 12px;
        }
      }

      .plus-btn {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }

      .counter-input__content {
        border-left: 1px solid #c9c9c9;
        border-right: 1px solid #c9c9c9;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        padding: 8px 26px;

        color: $color-Black;
        width: 100%;
        text-align: center;
      }

      .margin-textfield {
        .MuiInputBase-root {
          height: 36px;

          input {
            padding: 9px 10px;
            text-align: center;
            border-left: 1px solid #c9c9c9;
            border-right: 1px solid#c9c9c9;
          }

          fieldset {
            border: none;
          }
        }
      }

      @media only screen and (max-width: 479px) {
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }
  }

  .win-indication-relative {
    position: relative;
  }

  .win-indication-wrap {
    display: flex;
    align-items: center;
    position: absolute;
    column-gap: 12.3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 999px) {
      top: 40px;
    }

    span {
      font-size: 22.4px;
      line-height: 28px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-Primary;
      letter-spacing: 0px;
      min-width: 40px;

      @media (max-width: 999px) {
        font-size: 16px;
        line-height: 23px;
        min-width: 29px;
      }
    }

    #progress {
      height: 85px;
      width: 85px;
      place-items: center;
      border-radius: 50%;

      @media (max-width: 999px) {
        height: 50px;
        width: 50px;
      }

      #progress-value {
        height: calc(100% - 14px);
        width: calc(100% - 14px);
        border-radius: 50%;

        @media (max-width: 999px) {
          height: calc(100% - 8px);
          width: calc(100% - 8px);
        }
      }

      .stats {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 18px;
        line-height: 23px;
        font-weight: 400;
        font-family: $primaryFont;
        color: #426b8c;
        letter-spacing: 0px;

        @media (max-width: 999px) {
          font-size: 11px;
          line-height: 14px;
        }
      }
    }
  }
}
