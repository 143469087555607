@import "../../../assets/scss/variable.scss";

.RW-wrap {
  background-color: #fbfbfb;

  .RW-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .page-deatils-wrap {
    padding: 30px 18px 18px 18px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 799px) {
      padding: 19px 12px 12px 12px;
    }

    .website-content-wrap {
      margin-top: 21px;
      display: flex;
      align-items: flex-start;
      column-gap: 27px;
      padding-bottom: 18px;
      border-bottom: 1px solid #d4d6d8;

      &:first-child {
        margin-top: 0px;
      }

      @media (max-width: 940px) {
        display: block;
      }

      .img-sec {
        height: 183px;
        width: 300px;
        border-radius: 8px;

        @media (max-width: 400px) {
          width: 100%;
        }

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
          border-radius: 8px;

          @media (max-width: 400px) {
            // width: 100%;
            // height: 100%;
            // object-fit: fill;
          }
        }
      }

      .txt-sec {
        width: 70%;

        @media (max-width: 940px) {
          margin-top: 18px;
          width: 100%;
        }

        h1 {
          font-weight: 600 !important;
          font-size: 16px !important;
          color: #4455c7 !important;
          line-height: 22.4px !important;
          font-family: $regulerFont !important;
        }

        p {
          span {
            font-weight: 400 !important;
            font-size: 16px !important;
            color: #191919 !important;
            line-height: 20px !important;
            font-family: $regulerFont !important;
          }
        }

        ul {

          // list-style: square !important;
          li {
            span {
              font-weight: 400 !important;
              font-size: 16px !important;
              color: #191919 !important;
              line-height: 20px !important;
              font-family: $regulerFont !important;
            }
          }
        }

        .title {
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          color: #4455c7;
          line-height: 22.4px;
          font-family: $regulerFont;
        }

        .des-box {
          margin-top: 8px;

          .description {
            font-weight: 400;
            font-size: 16px;
            color: #191919;
            line-height: 20px;
            font-family: $regulerFont;

            .read-more-button {
              background-color: #007bff;
              color: #fff;
              border: none;
              padding: 5px 10px;
              cursor: pointer;
              position: absolute;
              bottom: 0;
              right: 0;
              margin: 10px;
              outline: none;
              font-size: 14px;

              &:hover {
                background-color: #0056b3;
              }
            }
          }

          .suneditor-dec {
            table {
              // border: 1px solid #e7e9ec;
              overflow: hidden;
              border-radius: 8px;
              width: 100%;

              // text-align: left;
              thead {
                width: 100%;

                tr {
                  border-top: 1px solid #003764;

                  th {
                    // border-top: 1px solid #003764;
                    // border-bottom: 1px solid #003764;
                    font-size: 22.4px;
                    font-family: $primaryFont;
                    text-transform: uppercase;
                    padding: 9px 0px;

                    line-height: 28px;
                    font-weight: 400;
                    color: $color-Black;
                    letter-spacing: 0px;
                    background-color: #e7e9ec;

                    @media (max-width: 1000px) {
                      font-size: 16px;
                      padding: 4px 0px;
                      line-height: 15px;
                    }

                    @media (max-width: 799px) {
                      font-size: 14px;
                      padding: 4px 0px;
                      line-height: 15px;
                    }
                  }
                }
              }

              tbody {
                width: 100%;

                tr {
                  // border-bottom: 1px solid #e7e9ec;

                  td {
                    padding: 9px 21px;
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 400;
                    color: $color-Black;
                    font-family: $regulerFont;
                    letter-spacing: 0px;
                    border-bottom: 1px solid $color-Light-grey;

                    @media (max-width: 1000px) {
                      font-size: 14px;
                      padding: 2px 12px;
                      line-height: 15px;
                    }

                    @media (max-width: 799px) {
                      // font-size: 10px;
                      padding: 2px 6px;
                      line-height: 15px;
                    }

                    @media (max-width: 599px) {
                      font-size: 10px;
                      padding: 2px 6px;
                      line-height: 15px;
                    }
                  }

                  .runner-details {
                    h6 {
                      font-size: 16px;
                      line-height: 19px;
                      font-weight: 600;
                      color: $color-Black;
                      font-family: $regulerFont;
                    }
                  }

                  .odds-details {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  }
                }
              }
            }

            .se-image-container {
              figure {
                margin: 0 !important;
              }
            }

            .se-video-container {
              width: unset !important;

              figure {
                margin: 0 !important;
                padding-bottom: unset !important;
                width: unset !important;
                height: auto !important;

                iframe {
                  width: 100% !important;
                }
              }
            }
          }

          .read-more-button {
            // background-color: #007bff;
            text-transform: capitalize;
            color: #4455c7;
            border: none;
            padding: 0;
            cursor: pointer;
            // position: absolute;
            // bottom: 0;
            // right: 0;
            // margin: 10px;
            outline: none;
            font-size: 14px;

            &:hover {
              background-color: transparent;
            }
          }

          .learn-more {
            text-align: end;
            text-transform: capitalize;
            color: #4455c7;
            border: none;
            padding: 0;
            cursor: pointer;
            outline: none;
            text-decoration: underline;
            font-size: 14px;

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }

    .pagination-section {
      display: flex;
      justify-content: center;
      margin-top: 50px;

      .MuiPagination-root {
        .MuiPagination-ul {
          .MuiButtonBase-root {
            width: 36px;
            height: 36px;

            svg {
              width: 24px;
              height: 24px;
            }

            .MuiTouchRipple-root {
              display: none;
            }
          }

          .MuiPaginationItem-root {
            font-size: 18px;
            line-height: 21px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
            border-radius: 50%;
          }
        }
      }
    }
  }
}