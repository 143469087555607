@import "../../../assets/scss/variable.scss";

.messages-wrap {
  background-color: $color-off-White;

  .messages-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .page-deatils-wrap {
    margin-top: 18px;
    padding: 18px 18px 21px 18px;

    @media (max-width: 1150px) {
      //   overflow: scroll;
      padding: 16px 12px 18px 12px;
    }

    .tab-sec {
      align-items: start;
      column-gap: 18px;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: 1279px) {
        // display: block;
      }

      @media (max-width: 1023px) {
        // display: flex;
        // text-align: center;
        // max-width: 580px;
        // width: 96%;
      }

      .tabs-titles {
        max-width: 350px;
        width: 100%;
        padding: 18px 18px 25px 18px;
        border-radius: 8px;
        background-color: #e6eefa;

        @media (max-width: 1250px) {
          max-width: 250px;
          padding: 12px 12px 20px 12px;
        }
        @media (max-width: 1080px) {
          max-width: 200px;
          padding: 12px 8px 16px 8px;
        }
        .tabs {
          width: 100%;
          .MuiTab-root {
            //   display: none;
            // background-color: $color-White;
            color: $color-Black !important;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            font-family: $regulerFont;
            // padding: 0px 20px;
            align-items: start;
            text-transform: capitalize;

            .MuiTab-wrapper {
              align-items: unset !important;
            }
          }

          .Mui-selected {
            color: $color-White !important;
            background-color: $color-Primary;
            border: 1px solid $color-Primary;
            border-radius: 8px;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            font-family: $regulerFont;
            text-transform: capitalize;
          }

          .MuiTabs-indicator {
            display: none;
          }

          .MuiTabs-flexContainer {
            flex-wrap: wrap;
          }

          .avatar-sec {
            height: 45px;
            width: 45px;
            border-radius: 50%;
            .avatar {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          .tab-title {
            border-radius: 8px;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            font-family: $regulerFont;

            @media (max-width: 1080px) {
              font-size: 12px;
            }
          }
          .tab {
            max-width: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            // width: 30%;
            color: #0832a7;
            font-weight: 700;
            font-size: 14px;
            min-height: 59px;

            @media (max-width: 1279px) {
              display: flex;
              text-align: center;
            }
          }
        }
      }

      .tab-panel {
        width: 70%;
        text-align: left;
        border-radius: 8px;
        background-color: $color-White;
        box-shadow: 10px 10px 5px 0px #0000000f;
        -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
        -moz-box-shadow: 10px 10px 5px 0px #0000000f;
        // padding-left: 50px;
        // padding-top: 10px;
        @media (max-width: 1080px) {
          width: 80%;
        }

        .MuiBox-root {
          padding: 0;
        }

        .d-flex {
          display: flex !important;
        }
        .messages-sec {
          height: 354px;
          //   display: flex;
          overflow-y: auto;
          padding: 0px 18px 0px 18px;
          .messages-container {
            flex: 1;
            // padding: 10px;
            // height: 354px;
            max-height: max-content;
            align-self: end;

            &.user-message {
              background-color: #4caf50;
              color: $color-White;
              align-self: flex-end;
            }

            &.bot-message {
              background-color: $color-White;
              color: $color-Black;
              align-self: flex-start;
            }

            .day-devider {
              display: flex;
              align-items: center;
              column-gap: 13px;
              margin-top: 14px;
              .devider {
                height: 1px;
                width: 100%;
                background-color: $color-Light-grey;
              }

              .day {
                max-width: 110px;
                width: 100%;
                font-size: 11.42px;
                font-weight: 400;
                line-height: 15px;
                font-family: $regulerFont;
                border-color: $color-grey-Accent-1;

                @media (max-width: 460px) {
                  font-size: 10.7px;
                }
              }
            }

            .message {
              margin: 5px;
              // padding: 8px;
              border-radius: 5px;
              .message-sec {
                display: flex;
                align-items: center;
                column-gap: 9px;

                // overflow-y: scroll;
                .profile-pic {
                  height: 45px;
                  width: 45px;
                  border-radius: 50%;

                  // @media (max-width: 1300px) {
                  //   right: 10px;
                  //   top: 20%;
                  // }

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }

                .msg-time {
                  margin-top: 18px;

                  .align-end {
                    text-align: end !important;
                  }
                  .time {
                    margin-top: 3px;
                    font-size: 11.42px;
                    font-weight: 400;
                    line-height: 15px;
                    font-family: $regulerFont;
                    border-color: $color-grey-Accent-1;
                  }
                  .chat {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22.4px;
                    font-family: $regulerFont;
                    border-color: $color-Black;
                  }
                  .user-message-txt {
                    max-width: 345px;
                    // width: 100%;
                    border: 1px solid $color-grey;
                    border-top-right-radius: 8px;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    padding: 11px;
                    overflow-wrap: break-word;
                  }
                  .bot-message-txt {
                    max-width: 345px;
                    // width: 100%;
                    border: 1px solid $color-grey;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    padding: 11px;
                    overflow-wrap: break-word;

                    @media (max-width: 460px) {
                      max-width: 251px;
                    }
                  }
                }
              }

              .bot-message-sec {
                flex-direction: row-reverse !important;
                justify-content: end !important;
              }
            }
          }
        }
        .send-box {
          padding: 18px;
          //   background-color: #d4d6d8;
          display: flex;
          align-items: center;
          column-gap: 10px;
          .text-field {
            width: 100%;

            .MuiInputBase-input {
              padding: 10px 10px;
              // max-width: 439px;
              // width: 100%;
              min-height: 25px;
              font-family: $regulerFont;
              font-size: 16px;
              line-height: 20px;
            }

            .MuiInputBase-root {
              background-color: $color-White !important;
              border-radius: 8px;
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              display: none;
            }

            .Mui-focused {
              .MuiOutlinedInput-notchedOutline {
                border-color: $color-Accent-1;
                border-width: 1;
              }
            }

            .MuiOutlinedInput-adornedEnd {
              justify-content: space-between;
            }
          }

          .details-textarea-field {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            font-family: $regulerFont;
            padding: 13px 11px;
            border-radius: 8px;
            border-color: $color-grey;

            border: 1px solid $color-Accent-1;

            @media (max-width: 799px) {
              width: 96% !important;
              // height: 174px !important;
              margin-top: 5px;
            }

            @media (max-width: 539px) {
              font-size: 15px;
            }

            @media (max-width: 439px) {
              width: 91% !important;
            }

            &:focus-visible {
              outline: none;
            }
          }

          .send-btn {
            max-width: 86px;
            width: 100%;
            padding: 11px 25px 12px 25px;
            background-color: $color-Accent-1;
            color: $color-White;
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            font-family: $regulerFont;
            border-radius: 8px;
            text-transform: capitalize;
            cursor: pointer;
            // @media (max-width: 579px) {
            //   font-size: 11.42px;
            // }
          }
        }
      }
    }

    .mobile-message-sec {
      margin-top: 11px;
      display: flex;
      align-items: start;
      column-gap: 12px;
      width: 100%;
      padding-bottom: 9px;
      border-bottom: 1px solid $color-Light-grey;

      &:first-child {
        margin-top: 0px;
      }
      .avatar-sec {
        width: 14%;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        .avatar {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .user-msg-info-sec {
        width: 86%;

        .tipper-name {
          color: $color-Black;
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          font-family: $regulerFont;
        }
        .last-msg {
          margin-top: 4px;
          color: $color-Black;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          font-family: $regulerFont;
        }
        .date {
          color: $color-grey-Accent-1;
          font-weight: 400;
          font-size: 11.42px;
          line-height: 15px;
          font-family: $regulerFont;
        }
      }
    }
  }
}
