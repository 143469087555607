@import "../../../../../../../assets/scss/variable.scss";

.horseprofile-wrap {
  .career-stats-wrap {
    padding: 19px 18px 25px;
    margin-bottom: 18px;
    background-color: $color-White;

    @media (max-width: 799px) {
      padding: 0px 0px 9px;
    }

    h1 {
      font-size: 22.4px;
      line-height: 31.36px;
      font-weight: 400;
      font-family: VeneerClean-Soft !important;
      color: $color-Black;
    }

    .detail-label {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;

      @media (max-width: 479px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .detail-info {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;

      @media (max-width: 479px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .horse-profile-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $color-Accent-1;
      border-collapse: collapse;

      .horse-performance {
        display: flex;

        div {
          margin-left: 35px;
        }
      }

      @media (max-width: 799px) {
        margin: 0px 12px;
      }
    }

    .horse-performance {
      @media (max-width: 1024px) {
        display: flex;
        justify-content: space-between;
        padding: 10px 12px;
        margin: 9px 0px;
        background-image: linear-gradient(to right, $color-Accent-1, #222b64);
        color: $color-White;

        .detail-label {
          font-size: 15px;
          line-height: 16px;
          font-weight: 600;
        }

        .detail-info {
          font-size: 15px;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }

    .career-stats {
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      padding-top: 18px;

      @media (max-width: 1024px) {
        padding-top: 0px;
        grid-template-columns: auto auto auto;
      }

      @media (max-width: 799px) {
        padding: 0px 0px 9px;
        border-bottom: 1px solid #e7e9ec;
        margin: 0 12px;
      }

      @media (max-width: 379px) {
        grid-template-columns: auto auto;
      }
    }

    .horses-header {
      padding-top: 34px;
      border-bottom: 1px solid $color-Accent-1;

      @media (max-width: 799px) {
        padding-top: 18px;
        margin: 0px 12px;
      }
    }

    .stats-des {
      line-height: 17px;
      font-size: 14px;
      color: #8d8d8d;
      margin-top: 18px;

      @media (max-width: 799px) {
        padding: 9px 12px 0px;
      }
    }
  }
}

.horse-strap-wrap {
  // .horse-strap-wrap-1 {
  //   padding: 19px 18px 31px;
  //   background-color: $color-White;
  //   box-shadow: 0px 3px 9px 0px #0000000d;
  //   margin-bottom: 18px;

  //   @media (max-width: 799px) {
  //     padding: 0px 12px 9px;
  //   }
  // }

  // .horse-strap-wrap-2 {
  //   padding: 19px 18px 31px;
  //   background-color: $color-White;
  //   box-shadow: 0px 3px 9px 0px #0000000d;
  //   margin-bottom: 18px;

  //   @media (max-width: 799px) {
  //     padding: 0px 12px 9px;
  //   }
  // }
  .profile-details-wrap-1 {
    padding: 20px 18px 31px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000001f;
    margin-bottom: 18px;

    @media (max-width: 799px) {
      padding: 0px 12px 9px;
    }
  }

  .profile-details-wrap-2 {
    padding: 20px 18px 31px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000001f;
    margin-bottom: 18px;

    @media (max-width: 799px) {
      padding: 0px 12px 9px;
    }
  }
}

.blackbook-icon-wrap {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #4455c7;

  .player-tab-detail {
    border-bottom: none !important;
  }
}

.black-book-desktop {
  @media (max-width:799px) {
    display: none;
  }
}

.black-book-mobile {
  display: none !important;

  @media (max-width:799px) {
    display: block;
    text-align: end;
  }
}