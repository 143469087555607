@import "../../../../assets/scss/variable.scss";

.allracing-wrap {
  padding: 45px 0px 0px 0px;

  h2 {
    text-align: center;
    margin: 40px 0px 50px 0px;
    font-size: 43px;
    font-weight: normal;
  }

  h5 {
    font-size: 24px;
    text-align: center;
    margin: 15px 0px;
  }

  .bredcrumn-wrap {
    margin-bottom: 3px;

    li.MuiBreadcrumbs-separator {
      color: $color-Black;
    }

    li {
      font-size: 11.42px;
      line-height: 14px;

      p {
        font-size: 11.42px;
        line-height: 14px;
        color: $color-Accent-1;
        text-transform: uppercase;
      }

      a {
        color: $color-Black;
        font-size: 11.42px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }
  }

  .sport-tab {
    padding: 19px 18px 9px 18px;
    box-shadow: 0px 3px 9px 0px #0000000d;
    background: $color-White;

    .racing-tab-detail {
      border-bottom: 2px solid #4455c7;


      .MuiButtonBase-root.active {
        color: $color-Accent-1;
      }

      .MuiButtonBase-root {
        font-size: 16px;
        font-family: VeneerClean-Soft !important;
        color: $color-Black;
        line-height: 23px;
      }

      .MuiTab-root {
        min-width: 120px;
        opacity: 1;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: $color-Primary;
      }
    }

    .Filteritemlist-wrap {
      // display: flex;
      // align-items: center;
      // margin-top: 9px;

      // @media (max-width: 560px) {
      //   flex-direction: column;
      //   align-items: flex-start;
      // }

      .filter-list-racing {
        display: flex;
        align-items: center;
        min-height: 40px;
      }

      .Filteritemlist-racing {
        display: flex;
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        li {
          margin-right: 14px;

          label {
            display: flex;
            column-gap: 5.2px;
            font-size: 12px;
            line-height: 15px;
          }

          .MuiButtonBase-root {
            padding: 0px;
          }
        }
      }

      .Filteritemlist-datepicker {
        display: contents;

        .details-search-picker {
          @media (max-width: 560px) {
            min-width: 365px;
          }
        }

        .MuiFormControl-marginNormal {
          margin: 0px;

          @media (max-width: 560px) {
            margin-top: 9px;
          }
        }

        .MuiOutlinedInput-input {
          padding: 10.5px 14px;

          @media (max-width: 560px) {
            padding: 13px 14px;
          }
        }
      }
    }
  }

  .track-info {
    padding: 4px 33px 12px;

    ul {
      list-style-type: none;
      padding: 0px;
      margin: 0px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 25px;
      align-items: center;
    }

    li {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding-bottom: 6px;
    }
  }

  .live-date-filter-tab-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0px 0px;

    @media (max-width: 1160px) {
      flex-direction: column-reverse;
      row-gap: 12px;
    }

    .live-tab {
      width: 100%;

      .live-tab-details {
        padding: 0px;

        .MuiTab-textColorInherit {
          border: 2px solid #c9c9c9;
          color: #989898;
          min-height: 45px;

          @media (max-width: 539px) {
            min-height: inherit;
            height: 35px;
          }
        }

        .active {
          color: $color-White;
          background: #fc4714;
          border: 2px solid transparent;

          font-weight: 600;
        }

        .MuiTab-root {
          min-width: max-content;
          width: 90px;

          @media (max-width: 799px) {
            width: auto;
            padding: 6px 8px;
          }
        }

        .MuiButtonBase-root {
          font-size: 14px;
          line-height: 16px;
          font-family: $regulerFont !important;
          font-weight: 400 !important;

          @media (max-width: 539px) {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }

    .date-selected-wrap {
      width: 100%;
      max-width: 270px;

      @media (max-width: 1160px) {
        max-width: none;
      }

      .MuiFormControl-root {
        margin: 0px;

        @media (max-width: 1160px) {
          width: 100%;
        }
      }

      .MuiInputBase-root {
        padding-right: 0px;
        border-radius: 5px;
        font-size: 14px;
        line-height: 16px;
        font-family: $regulerFont;
        font-weight: 400;
        color: #999999;

        @media (max-width: 539px) {
          font-size: 12px;
          line-height: 14px;
        }

        .MuiInputBase-input {
          padding: 13px 0px 12px 8px;

          // @media (max-width: 539px) {
          //   padding: 10px 0px 9px 8px;
          // }
        }

        .MuiButtonBase-root {
          margin: 0px;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: #c9c9c9;
          border-width: 2px;
        }

        &:hover {
          border-color: #c9c9c9;
        }
      }

      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-width: 2px;
        }
      }
    }

    #date-picker-inline::placeholder {
      color: #999999;
      opacity: 1;
    }
  }

  .state-tab-details {
    padding: 18px 0px;
    min-height: 38px;

    @media (max-width: 799px) {
      padding: 9px 0px;
      min-height: 32px;
    }

    .MuiTab-textColorInherit {
      color: $color-Black;
      border: 2px solid $color-Primary;
      margin: 0px 12px;
      border-radius: 8px;
      opacity: 1;
      min-height: 38px;
      padding: 9px 10px;

      @media (max-width: 799px) {
        margin: 0 9px;
        padding: 6px 12px;
        min-height: 32px;
      }
    }

    .active {
      color: $color-White;
      background: $color-Primary;
    }

    .MuiTabs-indicator {
      background: none;
    }

    .MuiTab-textColorInherit:first-child {
      margin-left: 0px;
    }

    .MuiTab-root {
      min-width: initial;
      width: 106px;
    }

    .MuiButtonBase-root {
      font-size: 16px;
      line-height: 20px;
      font-family: $primaryFont;
    }

    .Mui-disabled {
      opacity: 1;
    }

    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        .MuiBox-root:last-child {
          button {
            margin: 0px;
          }
        }
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
}

@media only screen and (max-width: 479px) {
  .allracing-wrap {
    padding: 22px 0px 0px 0px;

    .MuiTypography-h1 {
      font-size: 31.36px;
      line-height: 43.9px;
    }

    .sport-tab {
      padding: 9px 12px 11px 12px;
    }

    .track-info {
      padding: 0px 12px;
    }
  }
}