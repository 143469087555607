@import "../../../../assets/scss/variable.scss";

.homepage-wrap {
  .component-wrap {
    margin: 32px 0px 50px;

    @media (max-width: 479px) {
      margin: 12px 0px 30px;
    }

    .component-header {
      font-size: 31.36px;
      line-height: 40px;
      font-family: $primaryFont;
      color: $color-Primary;
      font-weight: 400;
      // margin: 0px 0px 9px;
      margin: 0px;

      @media (max-width: 779px) {
        font-size: 22.4px;
        line-height: 28px;
      }
    }

    .race-tab-label {
      font-size: 16px;
      line-height: 20px;
      color: $color-Accent-1;
      font-family: $primaryFont;
      display: inline-block;
      padding-top: 9px;

      @media (max-width: 799px) {
        padding-top: 6px;
      }

      &::after {
        content: "";
        display: block;
        height: 2px;
        width: 100%;
        background-color: $color-Primary;
      }
    }

    .sport-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 18px;
      margin-top: 21px;

      &:not(:last-child) {
        margin-bottom: 27px;
      }

      @media (max-width: 1023px) {
        grid-template-columns: auto;
        row-gap: 18px;
      }

      @media (max-width: 799px) {
        margin-top: 9px;
      }

      .sport-inner {
        border: 1px solid $color-Light-grey-1;
        background: $color-White;
        border-radius: 8px;
        box-shadow: 10px 10px 5px 0px #0000000f;
        -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
        -moz-box-shadow: 10px 10px 5px 0px #0000000f;

        @media (max-width: 799px) {
          width: 98% !important;
        }

        .racing-list {
          padding: 0px 0px 13px;
          margin-top: 13px;

          @media (max-width: 479px) {
            margin-top: 0px;
          }

          .sport-title {
            padding: 9px 13px;
            background-color: $color-White;
            border-radius: 8px 8px 0px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 3px;
            border-bottom: 2px solid $color-Primary;

            span {
              font-size: 11.42px;
              line-height: 14px;
              color: #191919;
              font-weight: 600;
            }

            .sport-title-name {
              display: flex;
              align-items: center;
              font-size: 16px;
              line-height: 20px;
              font-family: $primaryFont;
              color: $color-Primary;
              font-weight: 400;

              img {
                margin-right: 7px;
              }

              .sport-icon {
                display: flex;
                margin-right: 6px;
                width: 22px;
                height: 22px;

                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .see-all {
              font-size: 14px;
              line-height: 18px;
            }

            a {
              color: #4455c7;
              text-decoration: underline;
              font-weight: 600;
            }
          }

          .racing-content {
            padding: 9px 13px;
          }
        }
      }

      .teamsport-inner {
        height: calc(100% - 28px);
      }

      .racing-content {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .racing-content:not(:last-child) {
        padding-bottom: 9px;
        border-bottom: 1px solid #e7e9ec;
      }

      .racing-content:not(:last-child) {
        padding-bottom: 9px;
        border-bottom: 1px solid #e7e9ec;
      }

      .racing-content:not(:first-child) {
        padding-top: 9px;
      }

      .event-name {
        padding-left: 9px;
        font-size: 16px;
        line-height: 19px;
        font-weight: normal;

        @media (max-width: 1279px) {
          font-size: 14px;
          line-height: 17px;
        }

        @media (max-width: 1023px) {
          font-size: 16px;
          line-height: 19px;
        }

        @media (max-width: 799px) {
          font-size: 14px;
          line-height: 17px;
        }
      }

      .country-name {
        margin-left: 5px;
        font-size: 11.42px;
        line-height: 14px;
        font-family: $regulerFont;
        padding-top: 5px;
      }

      .allsports_racing_timer {
        margin-left: auto;
        cursor: pointer;
        color: #fff;
        border-radius: 3px;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        padding: 3px;
        height: auto;
        min-width: 55px;

        .MuiChip-label {
          padding: 0px;
        }
      }

      .bg-orange {
        background: #fc4714;
      }

      .sport-shadow {
        padding: 13px 13px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: calc(100% - 50px);
      }

      .sqare-chip {
        background: #4455c7;
      }
    }
  }

  .upcoming-racing-slider {
    .slick-arrow {
      width: 16px;
      height: 16px;

      svg {
        width: 28px;
        height: 28px;

        path {
          fill: #989898;
        }
      }

      &::before {
        content: "";
        display: none;
      }
    }

    .slick-prev {
      @media (max-width: 799px) {
        left: -5px;
        bottom: -30px;
        top: auto;
      }
    }

    .slick-next {
      @media (max-width: 799px) {
        right: 0px;
        bottom: -30px;
        top: auto;
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 99;
    }

    .slick-dots {
      bottom: -25px;

      li {
        width: 8px;
        height: 8px;

        button {
          width: 8px;
          height: 8px;

          &::before {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            color: transparent;
            background-color: $color-Accent-1;
            opacity: 0.5;
          }
        }
      }

      .slick-active {
        button {
          &::before {
            transform: scale(1.5);
            opacity: 1;
            background-color: #4455c7;
          }
        }
      }
    }
  }

  .upcoming-content-wrap {
    @media (max-width: 799px) {
      margin-bottom: 55px;
    }
  }

  .upcoming-fixture-result-wrap {
    @media (max-width: 799px) {
      margin-bottom: 55px;
    }
  }

  .custom-sports-tab {
    padding: 9px 0px 21px;

    @media (max-width: 799px) {
      width: calc(100% - 5px);
      margin: 0px auto;
      padding: 6px 0px 9px;
    }

    // @media (max-width: 599px) {
    //   padding: 9px 12px 12px;
    // }

    .custom-tab-detail {
      // border-bottom: 2px solid #4455c7;
      position: relative;
      padding: 0px 20px;
      min-height: 22px;

      @media (max-width: 599.95px) {
        .MuiTabs-scrollButtonsDesktop {
          display: block;
        }
      }

      .MuiTabs-scrollButtons {
        position: absolute;
      }

      .MuiTabs-scrollButtons:first-of-type {
        left: 5px;

        @media (max-width: 799px) {
          left: 0px;
        }
      }

      .MuiTabs-scrollButtons:last-of-type {
        right: 0px;
      }

      .MuiTabs-scroller {
        .MuiTabs-indicator {
          display: none;
        }

        .active {
          border-bottom: 3px solid $color-Primary;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .tab-label {
        display: flex;
        align-items: center;

        .label-name {
          font-size: 16px;
          font-family: VeneerClean-Soft !important;
          color: #191919;
          line-height: 20px;
          font-weight: 400;
        }

        .label-icon {
          margin-left: 3px;
          display: flex;
          align-items: center;

          svg {
            line {
              stroke: $color-Black;
            }

            circle {
              stroke: $color-Black;
            }

            #Rectangle_18598 {
              stroke: $color-Black;
            }
          }
        }
      }

      .active {
        .label-name {
          color: $color-Accent-1;
        }

        .label-icon {
          svg {
            #Group_40284 {
              path {
                stroke: $color-Accent-1;
                fill: transparent;
              }
            }

            #Group_40777 {
              #Path_32557 {
                stroke: $color-Accent-1;
              }

              #Path_32560 {
                fill: $color-Accent-1;
              }

              #Path_32561 {
                stroke: $color-Accent-1;
              }

              #Path_32562 {
                fill: $color-Accent-1;
              }

              #Path_32564 {
                fill: $color-Accent-1;
              }

              #Path_32566 {
                fill: $color-Accent-1;
              }
            }

            #Group_34085 {
              path {
                fill: $color-Accent-1;
              }
            }

            line {
              stroke: $color-Accent-1;
            }

            circle {
              stroke: $color-Accent-1;
            }

            #Rectangle_18598 {
              stroke: $color-Accent-1;
            }
          }
        }
      }

      .MuiBox-root {
        min-width: max-content;
      }

      .MuiTab-root {
        min-width: max-content;
        min-height: 22px;
        padding: 0px 9px;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: #003764;
      }

      .MuiTouchRipple-root {
        display: none;
      }

      .MuiTabScrollButton-root.Mui-disabled {
        opacity: 0.2;
      }

      .MuiTabScrollButton-root {
        width: 8px;

        @media (max-width: 799px) {
          width: 15px;
        }
      }
    }
  }

  .dark-sport-tab {
    padding: 9px 0px;

    @media (max-width: 799px) {
      width: calc(100% - 30px);
      margin: 0px auto;

      .MuiTabScrollButton-root {
        width: 15px !important;
      }

      .MuiTabs-scrollButtons:first-of-type {
        left: 5px !important;
      }

      .MuiTabs-scrollButtons:last-of-type {
        right: 5px !important;
      }
    }

    .custom-tab-detail {
      position: static;
      padding: 0px;
    }

    .custom-tab-detail .tab-label .label-name {
      color: $color-White;
    }

    .custom-tab-detail .MuiTabs-scroller .active {
      border-bottom: 3px solid $color-White;
    }

    .custom-tab-detail .active .label-name {
      color: $color-White;
    }
  }

  .sport-slider-wrap {
    @media (max-width: 799px) {
      margin-bottom: 35px;
    }

    .racing-slick-slider {
      width: 95%;
      margin: 0px auto;

      @media (max-width: 799px) {
        width: 100%;
      }

      .slick-arrow {
        width: 16px;
        height: 16px;

        svg {
          width: 28px;
          height: 28px;

          path {
            fill: #989898;
          }
        }

        &::before {
          content: "";
          display: none;
        }
      }

      .slick-prev {
        left: -34px;

        @media (max-width: 1023px) {
          left: -20px;
        }

        @media (max-width: 799px) {
          left: -4px;
          bottom: -32px;
          top: auto;
        }
      }

      .slick-next {
        right: -24px;

        @media (max-width: 1023px) {
          right: -15px;
        }

        @media (max-width: 799px) {
          right: 2px;
          bottom: -32px;
          top: auto;
        }
      }

      .slick-dots {
        bottom: -25px;

        li {
          width: 10px;
          height: 10px;

          @media (max-width: 799px) {
            width: 7px;
            height: 7px;
          }

          button {
            width: 10px;
            height: 10px;

            @media (max-width: 799px) {
              width: 7px;
              height: 7px;
            }

            &::before {
              width: 10px;
              height: 10px;
              border: 1px solid #4455c7;
              border-radius: 50%;
              color: transparent;
              opacity: 1;

              @media (max-width: 799px) {
                width: 7px;
                height: 7px;
              }
            }
          }
        }

        .slick-active {
          button {
            &::before {
              opacity: 1;
              background-color: #4455c7;
            }
          }
        }
      }

      .slick-list {
        padding-top: 9px;
      }
    }

    .racing-slider-details {
      background-color: $color-White;
      padding: 0px;
      border-radius: 8px;
      margin: 0px 7.5px;
      height: 200px;
      // overflow: hidden;
      border: 1px solid $color-Light-grey;

      @media (max-width: 479px) {
        margin: 0px 2.5px;
      }

      .teamdetail-odds-wrap {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        min-height: 128px;
        position: relative;

        @media (max-width: 799px) {
          padding: 12px;
          min-height: 136px;
        }

        .icon-odds-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 6px;

          .icon-wrap {
            width: 60px;
            height: 60px;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 50%;
            }
          }

          .current-best-odds-wrap {
            // padding: 0px 26px;
            align-items: center;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .current-best-odds-value {
              display: block;
              background-color: $color-Lavender;
              padding: 3.5px 0px;
              width: 70px;
              margin: 0px auto;
              border-radius: 6px 6px 0px 0px;
              text-align: center;
            }

            .current-best-odds-icon {
              border-radius: 0px 0px 6px 6px;
              width: 70px;
              margin: 0px auto;

              .currentbest-bookmaker-thumb {
                margin: 0px auto;
                border-radius: 0px 0px 6px 6px;
                cursor: pointer;
                height: 100%;
                object-fit: contain;
                width: 100%;
                max-width: none;
              }
            }

            .active-odd-style {
              background-color: $color-Primary;

              span,
              .odds {
                color: $color-White;
              }
            }
          }
        }

        .team-details-container {
          padding: 10px 0px;
          display: flex;
          align-items: center;
          row-gap: 6px;
          flex-direction: column;
          width: 156px;

          @media (max-width: 799px) {
            width: calc(100% - 184px);
          }

          .tournament-indicator {
            .tournament-name {
              text-align: center;
              background-color: $color-Accent-1;
              border-radius: 23px;
              color: #ffffff;
              font-size: 14px;
              line-height: 18px;
              font-weight: 400;
              font-family: $primaryFont;
              padding: 2px 6px;
              display: block;
              width: max-content;
            }
          }

          .event-name {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            font-family: $regulerFont;
            text-align: center;
            color: $color-Black;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            @media (max-width: 799px) {
              font-size: 14px;
              line-height: 17px;
            }
          }

          .date-time,
          .compare-odds {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            font-family: $regulerFont;
            text-align: center;
            color: $color-Black;
          }

          .compare-odds {
            font-weight: 600;
            color: $color-Accent-1;
            text-decoration: underline;
          }
        }

        .live-indicator {
          padding: 3px 11px;
          background-color: $color-Negative;
          color: $color-White;
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
          border-radius: 3px;
          position: absolute;
          top: 0px;
          left: 50%;
          right: 50%;
          transform: translate(-50%, -50%);
          width: 25px;
        }
      }

      .golf-teamdetail-odds-wrap {
        justify-content: space-around;
      }

      .result-wrap {
        height: 41px;

        .featured-sport-score {
          min-height: 41px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          border-radius: 0px 0px 8px 8px;
          overflow: hidden;
          background-color: $color-Primary;
          color: $color-White;

          .score-details {
            width: 48%;
            display: flex;
            align-items: center;
            justify-content: center;

            padding: 8px 6px;

            @media (max-width: 799px) {
              padding: 7.5px 4px;
            }

            .score {
              display: flex;
              align-items: center;
              column-gap: 9px;

              .team-score {
                font-size: 18px;
                line-height: 23px;
                font-weight: 400;
                color: $color-White;
                font-family: $primaryFont;

                span {
                  font-size: 18px;
                  line-height: 23px;
                  font-weight: 400;
                  color: $color-White;
                  font-family: $primaryFont;
                }

                @media (max-width: 799px) {
                  font-size: 12px;
                  line-height: 15px;
                }
              }

              .win-icon {
                width: 7px;
                height: 11px;
                display: flex;

                svg {
                  width: 100%;
                  height: 100%;
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }

    .sport-noresult-slider-details {
      height: 160px;

      // height: 210px;
      @media (max-width: 799px) {
        height: 150px;
        // height: 200px;
      }
    }

    .result-detail {
      .no-result-odds-wrap {
        min-height: 169px;
      }
    }
  }

  .single-slide {
    .racing-slick-slider {
      .racing-slider-details {
        max-width: 413px;
        width: 100%;

        @media (max-width: 799px) {
          max-width: none;
          width: auto;
        }
      }
    }
  }

  .nodata-slider {
    background-color: $color-White;
    padding: 18px 12px;
    border-radius: 8px;
    margin: 0px 7.5px;
    text-align: center;
    border: 1px solid $color-Light-grey;

    @media (max-width: 799px) {
      padding: 12px;
    }
  }

  .podcasts-deatils-wrap {
    @media (max-width: 799px) {
      padding: 18px 12px;
    }
  }

  .f-16 {
    font-size: 16px;
    line-height: 20px;
    font-family: $regulerFont;
    color: $color-Black;
  }
}

.featured-fixture-slider-wrap {
  background-image: url("../../../../assets/images/featured-sport-slider-bg.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  border-radius: 8px;
  padding: 18px 29px 52px;
  margin-bottom: 33px;
  position: relative;

  @media (max-width: 799px) {
    margin: 0px -12px 12px;
    padding: 9.5px 12px 44px;
    border-radius: 0px;
  }

  .slider-data-container {
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .home-slider-header {
    font-size: 31.36px;
    line-height: 40px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-White;

    // margin-bottom: 15px;
    @media (max-width: 799px) {
      font-size: 22.4px;
      line-height: 28px;
    }
  }

  .racing-slick-slider {
    width: 100%;
    margin: 0px auto;

    .slick-arrow {
      width: 16px;
      height: 16px;

      svg {
        width: 28px;
        height: 28px;

        path {
          fill: #989898;
        }
      }

      &::before {
        content: "";
        display: none;
      }
    }

    .slick-prev {
      left: -34px;

      @media (max-width: 799px) {
        left: -4px;
        bottom: -32px;
        top: auto;
      }
    }

    .slick-next {
      right: -24px;

      @media (max-width: 799px) {
        right: 2px;
        bottom: -32px;
        top: auto;
      }
    }

    .slick-dots {
      bottom: -25px;

      li {
        width: 10px;
        height: 10px;

        @media (max-width: 799px) {
          width: 7px;
          height: 7px;
        }

        button {
          width: 10px;
          height: 10px;

          @media (max-width: 799px) {
            width: 7px;
            height: 7px;
          }

          &::before {
            width: 10px;
            height: 10px;
            border: 1px solid #4455c7;
            border-radius: 50%;
            color: transparent;
            opacity: 1;

            @media (max-width: 799px) {
              width: 7px;
              height: 7px;
            }
          }
        }
      }

      .slick-active {
        button {
          &::before {
            opacity: 1;
            background-color: #4455c7;
          }
        }
      }
    }

    .slick-list {
      padding-top: 18px;
    }
  }

  .racing-slider-details {
    background-color: $color-Light-grey;
    padding: 0px;
    border-radius: 8px;
    margin: 0px 7.5px;
    height: 210px;
    // overflow: hidden;
    border: 1px solid $color-Light-grey;

    @media (max-width: 479px) {
      margin: 0px 2.5px;
    }

    .teamdetail-odds-wrap {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      min-height: 128px;
      position: relative;

      @media (max-width: 799px) {
        padding: 12px;
        min-height: 136px;
      }

      .icon-odds-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 6px;

        .icon-wrap {
          width: 60px;
          height: 60px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 50%;
          }
        }

        .current-best-odds-wrap {
          // padding: 0px 26px;
          align-items: center;
          display: flex;
          justify-content: center;
          flex-direction: column;

          .current-best-odds-value {
            display: block;
            background-color: $color-Lavender;
            padding: 3.5px 0px;
            width: 70px;
            margin: 0px auto;
            border-radius: 6px 6px 0px 0px;
            text-align: center;
          }

          .current-best-odds-icon {
            border-radius: 0px 0px 6px 6px;
            width: 70px;
            margin: 0px auto;

            .currentbest-bookmaker-thumb {
              margin: 0px auto;
              border-radius: 0px 0px 6px 6px;
              cursor: pointer;
              height: 100%;
              object-fit: contain;
              width: 100%;
              max-width: none;
            }
          }

          .active-odd-style {
            background-color: $color-Primary;

            span,
            .odds {
              color: $color-White;
            }
          }
        }
      }

      .team-details-container {
        padding: 10px 0px;
        display: flex;
        align-items: center;
        row-gap: 6px;
        flex-direction: column;
        width: 156px;

        @media (max-width: 799px) {
          width: calc(100% - 184px);
        }

        .tournament-indicator {
          .tournament-name {
            text-align: center;
            background-color: $color-Accent-1;
            border-radius: 23px;
            color: #ffffff;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            font-family: $primaryFont;
            padding: 2px 6px;
            display: block;
            width: max-content;
          }
        }

        .event-name {
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          font-family: $regulerFont;
          text-align: center;
          color: $color-Black;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          @media (max-width: 799px) {
            font-size: 14px;
            line-height: 17px;
          }
        }

        .date-time,
        .compare-odds {
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          font-family: $regulerFont;
          text-align: center;
          color: $color-Black;
        }

        .compare-odds {
          font-weight: 600;
          color: $color-Accent-1;
          text-decoration: underline;
        }
      }

      .live-indicator {
        padding: 3px 11px;
        background-color: $color-Negative;
        color: $color-White;
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        border-radius: 3px;
        position: absolute;
        top: 0px;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
        width: 25px;
      }
    }

    .result-wrap {
      height: 52px;

      .featured-sport-score {
        min-height: 52px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 2px;
        border-radius: 0px 0px 8px 8px;
        overflow: hidden;

        @media (max-width: 799px) {
          min-height: 47px;
        }

        .score-details {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 8px 6px;
          background-color: $color-Primary;
          row-gap: 3px;
          min-height: 34px;
          color: $color-White;

          @media (max-width: 799px) {
            padding: 7.5px 4px;
          }

          .score {
            .team-score {
              font-size: 14px;
              line-height: 17px;
              font-weight: 500;
              color: $color-White;

              @media (max-width: 799px) {
                font-size: 12px;
                line-height: 15px;
              }
            }
          }

          .score-team {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            color: $color-White;
            opacity: 0.8;
          }
        }
      }
    }
  }

  .sport-noresult-slider-details {
    height: 160px;

    // height: 210px;
    @media (max-width: 799px) {
      height: 150px;
      // height: 200px;
    }
  }

  .nodata-feature {
    color: $color-White;
    padding: 18px 12px;
    border-radius: 8px;
    margin: 0px 7.5px;
    text-align: center;
    border: 1px solid $color-White;
    width: 100%;

    @media (max-width: 799px) {
      padding: 12px;
    }
  }

  .featured-loader {
    .MuiCircularProgress-root {
      color: $color-White !important;
    }
  }

  .MuiTabScrollButton-root {
    svg {
      fill: $color-White;
    }
  }

  .MuiTabs-scrollButtons:first-of-type {
    @media (max-width: 799px) {
      left: -2px !important;
    }
  }
}

.single-slide {
  .racing-slick-slider {
    .racing-slider-details {
      max-width: 413px;
      width: 100%;

      @media (max-width: 799px) {
        max-width: none;
        width: auto;
      }
    }
  }
}