@import "../../../../../../assets/scss/variable.scss";


.landing-fixtures-results-details-wrap {

    .view-all-wrap {
        padding: 9px;
    }

    .view-all-fixture {
        width: 100%;
        background-color: $color-Accent-1;
        border-radius: 8px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: $color-White;
        padding: 12px 0px 11px 0px;
        text-transform: capitalize;

    }

    .upcoming-fixture-result-wrap {
        padding: 9px;

        @media (max-width:1080px) {
            max-height: calc(100vh - 360px);
            height: auto;
            overflow-y: auto;
        }
    }

    .sport-slider-wrap {


        @media (max-width: 799px) {
            margin-bottom: 35px;
        }

        .racing-slider-details {
            background-color: $color-White;
            padding: 0px;
            border-radius: 8px;
            height: 200px;
            border: 1px solid $color-Light-grey;
            margin-bottom: 12px;

            @media (max-width: 479px) {
                margin: 0px 0px 12px;
            }

            .teamdetail-odds-wrap {
                padding: 15px;
                display: flex;
                justify-content: space-between;
                min-height: 128px;
                position: relative;

                @media (max-width: 799px) {
                    padding: 12px;
                    min-height: 136px;
                }

                .icon-odds-wrap {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    row-gap: 6px;

                    .icon-wrap {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            border-radius: 50%;
                        }
                    }

                    .current-best-odds-wrap {
                        // padding: 0px 26px;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;

                        .current-best-odds-value {
                            display: block;
                            background-color: $color-Lavender;
                            padding: 3.5px 0px;
                            width: 70px;
                            margin: 0px auto;
                            border-radius: 6px 6px 0px 0px;
                            text-align: center;
                        }

                        .current-best-odds-icon {
                            border-radius: 0px 0px 6px 6px;
                            width: 70px;
                            margin: 0px auto;

                            .currentbest-bookmaker-thumb {
                                margin: 0px auto;
                                border-radius: 0px 0px 6px 6px;
                                cursor: pointer;
                                height: 100%;
                                object-fit: contain;
                                width: 100%;
                                max-width: none;
                            }
                        }

                        .active-odd-style {
                            background-color: $color-Primary;

                            span,
                            .odds {
                                color: $color-White;
                            }
                        }
                    }
                }

                .team-details-container {
                    padding: 10px 0px;
                    display: flex;
                    align-items: center;
                    row-gap: 6px;
                    flex-direction: column;
                    width: 156px;

                    @media (max-width: 799px) {
                        width: calc(100% - 184px);
                    }

                    .tournament-indicator {
                        .tournament-name {
                            text-align: center;
                            background-color: $color-Accent-1;
                            border-radius: 23px;
                            color: #ffffff;
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: 400;
                            font-family: $primaryFont;
                            padding: 2px 6px;
                            display: block;
                            width: max-content;
                        }
                    }

                    .event-name {
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 600;
                        font-family: $regulerFont;
                        text-align: center;
                        color: $color-Black;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;

                        @media (max-width: 799px) {
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }

                    .date-time,
                    .compare-odds {
                        font-size: 11.42px;
                        line-height: 14px;
                        font-weight: 400;
                        font-family: $regulerFont;
                        text-align: center;
                        color: $color-Black;
                    }

                    .compare-odds {
                        font-weight: 600;
                        color: $color-Accent-1;
                        text-decoration: underline;
                    }
                }

                .live-indicator {
                    padding: 3px 11px;
                    background-color: $color-Negative;
                    color: $color-White;
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 600;
                    border-radius: 3px;
                    position: absolute;
                    top: 0px;
                    left: 50%;
                    right: 50%;
                    transform: translate(-50%, -50%);
                    width: 25px;
                }
            }

            .golf-teamdetail-odds-wrap {
                justify-content: space-around;
            }

            .result-wrap {
                height: 41px;

                .featured-sport-score {
                    min-height: 41px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    border-radius: 0px 0px 8px 8px;
                    overflow: hidden;
                    background-color: $color-Primary;
                    color: $color-White;

                    .score-details {
                        width: 48%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        padding: 8px 6px;

                        @media (max-width: 799px) {
                            padding: 7.5px 4px;
                        }

                        .score {
                            display: flex;
                            align-items: center;
                            column-gap: 9px;

                            .team-score {
                                font-size: 18px;
                                line-height: 23px;
                                font-weight: 400;
                                color: $color-White;
                                font-family: $primaryFont;

                                span {
                                    font-size: 18px;
                                    line-height: 23px;
                                    font-weight: 400;
                                    color: $color-White;
                                    font-family: $primaryFont;
                                }

                                @media (max-width: 799px) {
                                    font-size: 12px;
                                    line-height: 15px;
                                }
                            }

                            .win-icon {
                                width: 7px;
                                height: 11px;
                                display: flex;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }

        .sport-noresult-slider-details {
            height: 160px;

            // height: 210px;
            @media (max-width: 799px) {
                height: 150px;
                // height: 200px;
            }
        }

        .result-detail {
            .no-result-odds-wrap {
                min-height: 169px;
            }
        }
    }

    .single-slide {
        .racing-slick-slider {
            .racing-slider-details {
                max-width: 413px;
                width: 100%;

                @media (max-width: 799px) {
                    max-width: none;
                    width: auto;
                }
            }
        }
    }

    .nodata-slider {
        background-color: $color-White;
        padding: 18px 12px;
        border-radius: 8px;
        margin: 0px 7.5px;
        text-align: center;
        border: 1px solid $color-Light-grey;

        @media (max-width: 799px) {
            padding: 12px;
        }
    }
}