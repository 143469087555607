@import "../../../assets/scss/variable.scss";

.page-title-wrap {
  .layout-header {
    padding: 45px 18px 0px;

    @media (max-width: 799px) {
      padding: 22px 12px 0px;
    }

    .page-bredcrumn-wrap {
      overflow-x: scroll;

      .bredcrumn-wrap {
        margin-bottom: 3px;

        @media only screen and (max-width: 599px) {
          margin-bottom: 5px;
        }

        li.MuiBreadcrumbs-separator {
          color: $color-Black;

          @media (max-width: 799px) {
            margin-left: 4px;
            margin-right: 4px;
          }
        }

        .breadcrumb {
          @media (max-width: 439px) {
            .MuiBreadcrumbs-ol {
              flex-wrap: nowrap;
            }
          }
        }

        li {
          font-size: 11.42px;
          line-height: 14px;

          p {
            font-size: 11.42px;
            line-height: 14px;
            color: $color-Accent-1;
            text-transform: uppercase;
            width: 210px;
          }

          a {
            color: $color-Black;
            font-size: 11.42px;
            line-height: 14px;
            text-transform: uppercase;
          }
        }
      }
    }

    .page-bredcrumn-wrap::-webkit-scrollbar {
      height: 0px;
    }

    .page-bredcrumn-wrap::-webkit-scrollbar-thumb {
      display: none;
    }

    h1 {
      line-height: 56px;
      color: $color-Black;

      @media only screen and (max-width: 599px) {
        font-size: 31.36px;
        line-height: 40px;
      }
    }
  }
}

.sport-tab {
  padding: 19px 18px 9px 18px;
  box-shadow: 0px 3px 9px 0px #0000000d;
  background: $color-White;

  @media (max-width: 660px) {
    padding: 9px 12px 12px;
  }

  .sport-tab-detail {
    border-bottom: 2px solid #4455c7;

    .active {
      .MuiTab-wrapper {
        color: $color-Accent-1;
      }
    }

    .MuiTab-wrapper {
      font-size: 16px;
      font-family: VeneerClean-Soft !important;
      color: $color-Black;
      line-height: 23px;
    }

    .MuiTab-root {
      min-width: 120px;
      opacity: 1;
    }

    .MuiTabs-indicator {
      height: 3px;
      color: $color-Primary;
    }
  }

  .sport-match-filter {
    padding-top: 9px;

    .sport-match-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 880px) {
        flex-direction: column-reverse;
      }

      .sport-tab-detail {
        border-bottom: none;
        align-items: center;

        @media (max-width: 880px) {
          width: 100%;
        }

        .MuiBox-root {
          &:not(:last-child) {
            margin-right: 15px;
          }

          @media (max-width: 880px) {
            width: 100%;
          }
        }

        .MuiButtonBase-root {
          border: 2px solid #003764;
          color: #003764;
          padding: 9px 30px;
          border-radius: 8px;
          min-height: 38px;

          @media (max-width: 880px) {
            min-width: 100%;
          }

          @media (max-width: 660px) {
            padding: 9px 38px;
          }

          @media (max-width: 389px) {
            padding: 9px 22px;
          }

          .MuiTab-wrapper {
            color: #003764;
          }
        }

        .active {
          background-color: #003764;

          .MuiTab-wrapper {
            color: $color-White;
          }
        }

        .MuiTabs-indicator {
          display: none;
        }
      }
    }

    .Filteritemlist-datepicker {
      @media (max-width: 880px) {
        margin-bottom: 18px;
        width: 100%;
      }

      .details-search-picker {
        @media (max-width: 880px) {
          min-width: 50%;
        }

        @media (max-width: 439px) {
          min-width: 100%;
        }

        // @media (max-width:560px) {
        //     min-width: 365px;
        // }

        // @media (max-width:389px) {
        //     min-width: 300px;
        // }
      }

      .MuiFormControl-marginNormal {
        margin: 0px;

        @media (max-width: 560px) {
          margin-top: 9px;
        }
      }

      .MuiOutlinedInput-input {
        padding: 10.5px 14px;

        @media (max-width: 560px) {
          padding: 13px 14px;
        }
      }
    }
  }
}

// sport tabs details css

.sport-match-table-wrap {
  // padding: 34px 33px 0px;
  padding: 34px 0px 0px;
  background-color: $color-White;
  box-shadow: 0px 3px 9px 0px #0000000d;

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  .table-header-wrap {
    // display: flex;
    // justify-content: space-between;
    padding: 0px 33px;

    .herder-border {
      border-bottom: 1px solid $color-Accent-1;
      width: 100%;
    }

    h1 {
      font-size: 22.4px;
      line-height: 31.36px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-Black;

      @media only screen and (max-width: 599px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .sport-match-table {
    .MuiTableCell-root {
      border: none;
    }
    .time-indicator {
      display: flex;
      align-items: center;
      .sport-indicator-btn {
        margin-left: 10px;
        display: block;
        width: fit-content;
        text-align: center;
      }
    }
    .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        font-family: $regulerFont;
        color: $color-Black;
        padding: 14px 0px 19px;
        text-align: center;

        @media (max-width: 1300px) {
          font-size: 14px;
        }

        .live-btn {
          max-width: 48px;
          border-radius: 3px;
          background-color: #d84727;
          color: $color-White;
          padding: 3px 11px;
          text-transform: uppercase;
        }
      }
    }

    .MuiTableCell-body {
      text-align: center;
      font-family: $regulerFont;
      font-size: 11.42px;
      font-weight: 400;
      line-height: 14px;
      color: $color-Black;
      padding: 18px 0px 18.5px;
    }

    .table-head-left {
      text-align: left;
      width: 270px;

      @media (max-width: 1300px) {
        width: 200px;
      }
    }

    .MuiTableBody-root {
      .teamsports-tablerow {
        position: relative;

        &::after {
          content: "";
          background-color: $color-grey;
          height: 1px;
          width: 80%;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 auto;
        }
      }

      .team-wrap-cell {
        .team-wrap {
          display: flex;
          align-items: center;

          .team-img {
            border-radius: 50%;
            margin-right: 15px;
            width: 45px;
            height: 45px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .team-name {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .team-event {
              font-size: 16px;
              line-height: 20px;
              font-family: $primaryFont;
              font-weight: 400;
              color: $color-Black;
            }

            .team-number {
              font-size: 11.42px;
              font-family: $regulerFont;
              font-weight: 400;
              line-height: 14px;
              color: #5d5d5d;
            }
          }
        }
      }
    }

    .sport-result-show {
      background-color: #e5eaef;

      .MuiTableCell-body {
        text-align: left;
        padding: 10px 0px 9px;
        font-size: 16px;
        line-height: 19px;
        font-family: $regulerFont;
        font-weight: 600;
        color: $color-Black;

        @media (max-width: 1300px) {
          font-size: 14px;
        }

        .result-grey {
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          color: #5d5d5d;
        }
      }
    }

    .border-right {
      border-right: 0.6px solid #d4d6d8;
      padding-right: 20px !important;
    }
  }
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.pl-33 {
  padding-left: 33px !important;

  @media (max-width: 799px) {
    padding-left: 12px !important;
  }
}

.pr-33 {
  padding-right: 33px !important;

  @media (max-width: 799px) {
    padding-right: 12px !important;
  }
}

.text-center {
  text-align: center !important;
}

// betting trend css

.betting-trend-table-wrap {
  // padding: 34px 33px 0px;
  padding: 34px 0px 0px;
  background-color: $color-White;
  box-shadow: 0px 3px 9px 0px #0000000d;
  margin-bottom: 18px;

  .table-header-wrap {
    padding: 0px 33px;
    margin-bottom: 18px;

    @media (max-width: 799px) {
      padding: 0px 12px;
    }

    @media (max-width: 599px) {
      margin-bottom: 10px;
    }

    .herder-border {
      border-bottom: 1px solid $color-Accent-1;
      width: 100%;
    }

    h1 {
      font-size: 22.4px;
      line-height: 31.36px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-Black;

      @media only screen and (max-width: 599px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    h6 {
      font-size: 16px;
      line-height: 19px;
      font-family: $regulerFont;
      font-weight: 600;
      color: $color-Black;
      margin-bottom: 4px;
      margin-top: 15px;

      @media (max-width: 599px) {
        font-size: 11px;
        line-height: 14px;
        margin-top: 9px;
      }
    }

    span {
      font-size: 11.42px;
      line-height: 14px;
      font-family: $regulerFont;
      font-weight: 400;
    }
  }

  .table-wrap {
    position: relative;
  }

  .details-table {
    // padding: 0px 33px;
    width: auto;

    .table-wrap {
      .MuiTableCell-head {
        padding: 8px 0px 6px;
        border-top: 1px solid #e7e9ec;
        border-bottom: 1px solid #989898;
        text-align: center;
      }

      .MuiTableCell-body {
        padding: 30px 0px 22px;
        border: none;
        text-align: -webkit-center;

        @media (max-width: 599px) {
          padding: 22px 0px;
        }
      }

      .MuiTableRow-head :first-child {
        text-align: left;
      }

      .bet-type-name {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-Black;
        margin-bottom: 4px;

        @media (max-width: 599px) {
          font-size: 11px;
          line-height: 14px;
        }
      }

      .bet-type {
        font-size: 11.42px;
        line-height: 14px;
        font-weight: 400;
        font-family: $regulerFont;
        color: #5d5d5d;
      }

      .team-wrap-cell {
        .team-wrap {
          display: flex;
          align-items: center;

          .team-img {
            border-radius: 50%;
            margin-right: 15px;
            width: 45px;
            height: 45px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .team-name {
            .team-event {
              font-size: 16px;
              line-height: 20px;
              font-family: $primaryFont;
              font-weight: 400;
              color: $color-Black;
            }
          }
        }
      }

      .bet-number {
        padding: 7px 15px;
        border: 1px solid #d6d9f3;
        border-radius: 8px;
        width: 40px;
        height: 52px;
        align-items: center;
        justify-content: center;

        span {
          font-size: 16px;
          font-family: $regulerFont;
          line-height: 19px;
          font-weight: 400;
        }

        .bet-number-1 {
          color: $color-Black;
        }

        .bet-number-2 {
          color: #5d5d5d;
        }
      }

      .bet-number-orange {
        background-color: rgba(255, 198, 154, 0.2);
        border-color: rgba(252, 71, 20, 0.2);
      }

      .opposite-team-row {
        background-color: #eeefff;

        .opposite-team-cell {
          background-color: #eeefff;
        }
      }
    }
  }

  .arrow-wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0px;
    top: 2px;
    z-index: 99;

    .arrow {
      background-color: #707070;
      width: 32px;
      height: 26px;

      .MuiSvgIcon-root {
        width: 32px;
        height: 26px;
        color: #ffffff;
      }
    }

    .disable {
      background-color: #d8d8d8;
      width: 32px;
      height: 26px;

      .MuiSvgIcon-root {
        width: 32px;
        height: 26px;
        color: #ffffff;
      }
    }
  }

  .MuiTooltip-popper {
    top: 0px !important;
  }
}

.btn-blue {
  padding: 6px 12px;
  background-color: #4455c7;
  color: $color-White;
  font-size: 12px;
  line-height: 15px;
  font-family: $regulerFont;
  font-weight: 400;
  border-radius: 15px;
  width: 30px;
}

.btn-gray {
  padding: 6px 12px;
  background-color: #e7e9ec;
  color: #989898;
  font-size: 12px;
  line-height: 15px;
  font-family: $regulerFont;
  font-weight: 400;
  border-radius: 15px;
  width: 30px;
}

// Mobile NBA Matchups

.mobile-sport-match {
  .mobile-sport-wrap {
    .sport-tab {
      margin-bottom: 9px;

      .sport-tab-detail {
        border-color: #dededf;

        .MuiBox-root {
          width: 50%;
        }

        .MuiTab-root {
          min-width: 100%;
          opacity: 1;
        }
      }
    }

    .mobile-table-wrap {
      background-color: $color-White;
      box-shadow: 0px 3px 9px 0px #0000000d;
      margin-bottom: 18px;

      .mobile-table {
        .MuiTableCell-head {
          padding: 12px;
          border-bottom: 0.2px solid #d4d6d8;
          font-size: 11px;
          line-height: 14px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
          text-align: center;
        }

        .MuiTableRow-head :first-child {
          text-align: left;
          width: 100px;

          @media (max-width: 439px) {
            width: 80px;
          }
        }

        .MuiTableCell-body {
          padding: 18px 12px;
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          font-family: $regulerFont;
          color: #5d5d5d;
          text-align: center;
          border: none;
          @media (max-width: 479px) {
            padding: 18px 10px;
          }
        }
        .time-indicator-wrap {
          display: flex;
          flex-direction: column;
          .sport-indicator-btn {
            padding: 2px;
            margin-top: 2px;
            width: fit-content;
            display: block;
          }
        }
        .team-wrap-cell {
          .team-wrap {
            display: flex;
            align-items: center;

            .team-img {
              border-radius: 50%;
              margin-right: 15px;
              width: 45px;
              height: 45px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .team-name {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .team-event {
                font-size: 16px;
                line-height: 20px;
                font-family: $primaryFont;
                font-weight: 400;
                color: $color-Black;
              }

              .team-number {
                font-size: 11.42px;
                font-family: $regulerFont;
                font-weight: 400;
                line-height: 14px;
                color: #5d5d5d;
              }
            }
          }
        }

        .border-right {
          border-right: 0.6px solid #d4d6d8;
        }

        .live-btn {
          max-width: 48px;
          border-radius: 3px;
          background-color: #d84727;
          color: $color-White;
          padding: 3px 11px;
          text-transform: uppercase;
        }

        .mobile-win-details {
          align-items: center;
        }

        .number-bm {
          margin-bottom: 6px;
        }

        .mobile-sport-result {
          background-color: #e5eaef;

          .MuiTableCell-body {
            padding: 9px 12px;
            font-size: 11.42px;
            line-height: 14px;
            font-family: $regulerFont;

            .font-dark {
              font-weight: 600;
              color: $color-Black;
            }

            .result-grey {
              font-weight: 400;
              color: #5d5d5d;
            }
          }
        }
      }
    }
  }
}
