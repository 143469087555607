@import "../../../../assets/scss/variable.scss";


.view-page-wrap {

    .teamsport-header-details {
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000000d;
        margin-top: 0px;
    }

    .view-round-wrap {
        padding: 19px 33px 18px;
        background-color: $color-Black;

        .round-details-wrap {
            display: flex;
            align-items: center;
            column-gap: 44px;
            text-transform: uppercase;
            margin-bottom: 18px;

            .round-name {
                padding: 7px 6px 6px;
                font-size: 16px;
                line-height: 19px;
                font-weight: 500;
                font-family: $regulerFont;
                color: $color-White;
            }

            .active {
                background-color: $color-Accent-1;
            }
        }

        .round-details {

            .see-also-text {
                font-size: 16px;
                line-height: 19px;
                font-weight: 500;
                font-family: $regulerFont;
                color: $color-White;
                margin-bottom: 3px;
            }

            .round-result {
                font-size: 11.42px;
                line-height: 14px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-White;
            }
        }
    }

    .round-view-details {
        margin-top: 18px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000000d;
        padding-bottom: 34px;
        margin-bottom: 27px;

        @media (max-width:799px) {
            padding-bottom: 12px;
            margin-bottom: 25px;
        }

        .details-header {
            padding: 18px 64px 34px 29px;
            box-shadow: none;

            @media (max-width:799px) {
                padding: 18px 12px 16px;
            }
        }

        .info-wrap {
            display: flex;
            align-items: self-start;
            column-gap: 35px;

            @media (max-width:799px) {
                flex-direction: column;
                padding: 0px 12px;
            }

            &:not(:last-child) {
                margin-bottom: 9px;
            }

            .match-info-box {
                padding: 7px 0px 6px;
                min-width: 136px;
                text-align: center;
                background-color: #D4D6D8;
                color: $color-Black;
                font-size: 16px;
                line-height: 19px;
                font-weight: 500;
                font-family: $regulerFont;
                text-transform: uppercase;

                @media (max-width:799px) {
                    min-width: none;
                    width: 100%;
                    margin-bottom: 12px;
                }
            }
        }

        .tab-details-grid {
            display: flex;
            flex-wrap: wrap;
            column-gap: 45px;
            row-gap: 15px;

            @media (max-width:799px) {
                display: grid;
                grid-template-columns: auto auto auto;
                column-gap: 18px;
                margin-bottom: 15px;
                width: 100%;
            }

            .details-box {
                max-width: fit-content;
                width: 100%;

                @media (max-width:799px) {
                    max-width: fit-content;
                }

                .details-text {
                    font-size: 13px;
                    line-height: 16px;
                    font-family: $regulerFont;
                    color: $color-Black;
                    font-weight: 400;

                    @media (max-width:799px) {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }

                .bold {
                    font-weight: 600;
                }

                .link-color {
                    color: #1A73E8;
                }
            }
        }

        .team-info-grid {
            @media (max-width:799px) {
                margin-bottom: 0px;
            }
        }
    }

    .round-full-details {

        box-shadow: 0px 3px 9px 0px #0000000d;

        .round-grid {
            display: grid;
            grid-template-columns: 40% 20% 40%;

            @media (max-width:799px) {
                grid-template-columns: 35% 30% 35%;
            }
        }

        .cricket-round-grid {
            grid-template-columns: 35.5% 29% 35.5%;

            @media (max-width:799px) {
                grid-template-columns: 25% 50% 25%;
            }
        }

        .text-align-end {
            text-align: end;
        }

        .text-align-center {
            text-align: center;
        }

        .text-align-start {
            text-align: start;
        }

        .flex-direction-column {
            flex-direction: column;
        }

        .text-result {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
        }

        .light-blue {
            color: #1A73E8;
        }

        .mb-19 {
            margin-bottom: 19px;
        }

        .match-name-wrap {
            background-color: #003763;
            padding: 18px 12px;
            margin-bottom: 18px;

            .samll-text {
                font-size: 22.4px;
                line-height: 31.36px;
                font-weight: 400;
                font-family: $primaryFont;
                color: $color-White;

                @media (max-width:799px) {
                    font-size: 16px;
                    line-height: 22.4px;
                }
            }

            .big-text {
                font-size: 43.9px;
                line-height: 61.47px;
                font-weight: 400;
                font-family: $primaryFont;
                color: $color-White;

                @media (max-width:799px) {
                    font-size: 31.36px;
                    line-height: 43.9px;
                }
            }

            .match-number {
                justify-content: center;
                column-gap: 8px;
            }
        }

        .result-header {
            padding: 19px 0px 18px;
            border-top: 1px solid #C9C9C9;
            border-bottom: 1px solid #C9C9C9;

            .header-name {
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 700;
                font-family: $regulerFont;
                color: #003764;
            }
        }

        .round-result-deatils {
            padding: 19px 12px 18px;

            .match-number {
                justify-content: space-around;
                width: 80%;

                @media (max-width:799px) {
                    width: 70%;
                }
            }
        }
    }

    .view-tab-details {
        .MuiBox-root {
            width: 50%;

            .MuiButtonBase-root {
                min-width: -webkit-fill-available;
                opacity: 1;
                line-height: 19px;
                font-size: 16px;
                font-weight: 500;
                color: #003763;
                font-family: $regulerFont;

                .MuiTouchRipple-root {
                    display: none;
                }
            }
        }

        .MuiTabs-indicator {
            background-color: #003763;
        }
    }

    .match-details {
        margin-top: 18px;

        .extra-wrap {
            display: flex;
            align-items: center;

            .extra-runs {
                margin-left: 8px;
            }
        }

        .bowling-wrap {
            margin-top: 18px;
        }

    }
}