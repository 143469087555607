@import "../../../../assets/scss/variable.scss";

.age-verfication-modal {
    .MuiDialog-paper {
        max-width: 749px;
        width: 100%;
        border-radius: 8px;

        .age-verfication-modal-details {
            padding: 0px;
            overflow: hidden;

            .age-verification-popup-wrap {
                display: flex;
                align-items: flex-start;



                .left-popup-side {
                    width: 100%;
                    max-width: 319px;
                    height: 376px;

                    @media (max-width:799px) {
                        display: none;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .right-popup-side {
                    padding: 40px 45px;
                    text-align: center;

                    @media (max-width:799px) {
                        width: 100%;
                        padding: 32px 18px 30px;
                    }

                    .confirm-text {
                        font-size: 22.4px;
                        line-height: 22.4px;
                        font-weight: 400;
                        font-family: $primaryFont;
                        letter-spacing: 0px;
                        margin: 22px 0px 33px;
                        color: $color-Black;

                        @media (max-width:799px) {
                            margin: 18px 0px 33px;
                        }
                    }

                    .age-error-msg {
                        font-size: 16px;
                        line-height: 22.4px;
                        font-weight: 400;
                        font-family: $regulerFont;
                        letter-spacing: 0px;
                        color: $color-Negative;
                        margin-bottom: 12.5px;

                        @media (max-width:799px) {
                            font-size: 14px;
                            line-height: 19.6px;
                        }
                    }

                    .age-btn-wrap {


                        @media (max-width:799px) {
                            display: flex;
                            align-items: center;
                            flex-direction: row-reverse;
                            column-gap: 10px;
                            margin-bottom: 18px;
                        }
                    }

                    .age-pop-btn {
                        width: 100%;
                        border-radius: 8px;
                        padding: 13px 0px 12px;
                        box-shadow: none;
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        font-family: $regulerFont;
                        text-transform: capitalize;
                        border: 1px solid transparent;
                        margin-bottom: 18px;

                        @media (max-width:799px) {
                            font-size: 14px;
                            line-height: 16px;
                            margin-bottom: 0px;
                        }
                    }

                    .yes-btn {
                        background-color: $color-Accent-1;
                        color: $color-White;
                        border: 1px solid transparent;
                    }

                    .no-btn {
                        border-color: $color-Accent-1 ;
                        color: $color-Accent-1;

                        &:hover {
                            background-color: transparent;
                        }
                    }

                    .agree-text {
                        font-size: 16px;
                        line-height: 22.4px;
                        font-weight: 400;
                        font-family: $regulerFont;
                        letter-spacing: 0px;
                        color: $color-Black;

                        @media (max-width:799px) {
                            font-size: 14px;
                            line-height: 19.6px;
                        }

                        .link-text {
                            a {
                                color: $color-Accent-1;
                            }
                        }
                    }
                }

                .msg-is-available {
                    padding: 33.5px 45px 18px;

                    @media (max-width:799px) {
                        width: 100%;
                        padding: 32px 18px 18px;
                    }

                    .confirm-text {
                        margin: 22px 0px 5.1px;
                    }
                }
            }
        }
    }
}