@import "../../../assets/scss/variable.scss";

.user-messages-wrap {
  background-color: #fbfbfb;

  .user-messages-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .page-deatils-wrap {
    padding: 18px 18px 21px 18px;

    @media (max-width: 1150px) {
      //   overflow: scroll;
      padding: 16px 12px 18px 12px;
    }

    .old-message-box {
      padding: 0px 33px 0px 7px;
      .old-message-txt {
        // text-align: start;
        text-transform: capitalize;
        color: #4455c7;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        text-decoration: underline;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        font-family: $regulerFont;
        border-color: #191919;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .d-flex {
      display: flex !important;
    }
    .messages-sec {
      height: 354px;
      //   display: flex;
      overflow-y: auto;
      //   padding: 0px 18px 0px 18px;
      .messages-container {
        flex: 1;
        // padding: 10px;
        // height: 354px;
        max-height: max-content;
        align-self: end;

        &.user-message {
          background-color: #4caf50;
          color: $color-White;
          align-self: flex-end;
        }

        &.bot-message {
          background-color: #ddd;
          color: $color-Black;
          align-self: flex-start;
        }

        .day-devider {
          display: flex;
          align-items: center;
          column-gap: 13px;
          margin-top: 14px;
          .devider {
            height: 1px;
            width: 100%;
            background-color: #e7e9ec;
          }

          .day {
            max-width: 110px;
            width: 100%;
            font-size: 11.42px;
            font-weight: 400;
            line-height: 15px;
            font-family: $regulerFont;
            border-color: #989898;

            @media (max-width: 460px) {
              font-size: 10.7px;
            }
          }
        }

        .message {
          margin: 5px;
          // padding: 8px;
          border-radius: 5px;
          .message-sec {
            display: flex;
            align-items: center;
            column-gap: 9px;

            // overflow-y: scroll;
            .profile-pic {
              height: 45px;
              width: 45px;
              border-radius: 50%;

              // @media (max-width: 1300px) {
              //   right: 10px;
              //   top: 20%;
              // }

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .msg-time {
              margin-top: 18px;

              .align-end {
                text-align: end !important;
              }
              .time {
                margin-top: 3px;
                font-size: 11.42px;
                font-weight: 400;
                line-height: 15px;
                font-family: $regulerFont;
                border-color: #989898;
              }
              .chat {
                font-size: 16px;
                font-weight: 400;
                line-height: 22.4px;
                font-family: $regulerFont;
                border-color: #191919;
              }
              .user-message-txt {
                max-width: 345px;
                // width: 100%;
                border: 1px solid #d4d6d8;
                border-top-right-radius: 8px;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                padding: 11px;
                overflow-wrap: break-word;
              }
              .bot-message-txt {
                max-width: 345px;
                // width: 100%;
                border: 1px solid #d4d6d8;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                padding: 11px;
                overflow-wrap: break-word;

                @media (max-width: 460px) {
                  max-width: 251px;
                }
              }
            }
          }

          .bot-message-sec {
            flex-direction: row-reverse !important;
            justify-content: end !important;
          }
        }
      }
    }
    .send-box {
      padding: 4px 0px 0px 0px;
      //   background-color: #d4d6d8;
      display: flex;
      align-items: end;
      column-gap: 10px;
      .text-field {
        width: 100%;

        .MuiInputBase-input {
          padding: 10px 10px;
          // max-width: 439px;
          // width: 100%;
          min-height: 25px;
          font-family: $regulerFont;
          font-size: 16px;
          line-height: 20px;
        }

        .MuiInputBase-root {
          background-color: $color-White !important;
          border-radius: 8px;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          display: none;
        }

        .Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: #4455c7;
            border-width: 1;
          }
        }

        .MuiOutlinedInput-adornedEnd {
          justify-content: space-between;
        }
      }

      .details-textarea-field {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        font-family: $regulerFont;
        padding: 13px 11px;
        border-radius: 8px;
        border-color: #d4d6d8;

        border: 1px solid #4455c7;

        @media (max-width: 799px) {
          width: 96% !important;
          // height: 174px !important;
          margin-top: 5px;
        }

        @media (max-width: 539px) {
          font-size: 15px;
        }

        @media (max-width: 439px) {
          width: 91% !important;
        }

        &:focus-visible {
          outline: none;
        }
      }

      .send-btn {
        max-width: 86px;
        width: 100%;
        padding: 11px 25px 12px 25px;
        background-color: #4455c7;
        color: $color-White;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        cursor: pointer;
        // @media (max-width: 579px) {
        //   font-size: 11.42px;
        // }
      }
    }
  }
}
