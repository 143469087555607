@import "../../../../../assets/scss/variable.scss";


.landing-home-right-wrap {


    .right-side-wrap {
        border-radius: 8px;
        box-shadow: 0px 1px 9px 0px #0000003b;
        background-color: $color-White;
        margin-bottom: 27px;

        .right-side-title-wrap {
            background: linear-gradient(180deg, rgba(0, 55, 100, 0.9) 0%, rgba(9, 11, 13, 1) 100%);
            padding: 8px 18px;
            border-radius: 8px 8px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .right-side-title {
                font-size: 31.36px;
                line-height: 40px;
                font-family: $primaryFont;
                font-weight: 400;
                color: $color-White;
            }

            .right-updated-text {
                font-size: 12px;
                line-height: 15px;
                font-weight: 400;
                font-family: $regulerFont;
                letter-spacing: 0px;
                color: $color-Light-grey;
            }

            .view-all-text {
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                font-family: $regulerFont;
                letter-spacing: 0px;
                color: $color-White;
                text-transform: capitalize;
                cursor: pointer;
                display: flex;
                align-items: center;

                .text-underline {
                    text-decoration: underline;
                }
            }
        }
    }
}

.tab-container {
    padding: 9px;

    .tab-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-Light-grey;
        border-radius: 8px;
        padding: 4px;

        .tab-details {
            width: 100%;
            text-align: center;
            padding: 6px 0px;
            cursor: pointer;

            .tab-text {
                margin: 0px;
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 600;
                font-family: $regulerFont;
                letter-spacing: 0px;
                color: $color-Black;
            }
        }

        .tab-active {
            background: linear-gradient(180deg, rgba(68, 85, 199, 1) 0%, rgba(63, 72, 146, 1) 100%);
            border-radius: 8px;

            .tab-text {
                color: $color-White;
            }
        }
    }

    .tab-wrap-bottom {
        margin-bottom: 6px;
    }
}

.table-container-details {
    .MuiTableContainer-root {
        max-height: 735px;
        height: auto;
        overflow-y: auto;


        @media (max-width:1080px) {
            max-height: calc(100vh - 325px);
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        .MuiTable-root {
            .text-align-center {
                justify-content: center;
            }


            .MuiTableHead-root {

                .MuiTableCell-root {
                    padding: 6px 7px;
                    font-size: 11.42px;
                    line-height: 14px;
                    font-weight: 600;
                    font-family: $regulerFont;
                    letter-spacing: 0px;
                    color: $color-White;
                    background-color: #00223D;

                    .sorting-cell {
                        display: flex;
                        align-items: center;
                        column-gap: 4px;
                        cursor: pointer;

                        .sort-icon-wrap {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            row-gap: 2px;

                            span {
                                line-height: 0px;
                            }

                            .down-arrow {
                                transform: rotate(180deg);
                            }
                        }

                        .asc-sort {
                            path {
                                fill: #4455c7;
                            }
                        }

                        .desc-sort {
                            path {
                                fill: #4455c7;
                            }
                        }
                    }
                }
            }

            .MuiTableBody-root {

                .MuiTableCell-body {
                    padding: 6px 9px;
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 400;
                    font-family: $regulerFont;
                    letter-spacing: 0px;
                    color: $color-Black;
                    background-color: $color-White;

                    .team-wrap {
                        display: flex;
                        align-items: center;
                        column-gap: 4px;

                        .team-image-wrap {
                            width: 30px;
                            min-width: 30px;
                            height: 30px;
                            border-radius: 50%;

                            .team-image {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                object-fit: contain;
                            }
                        }

                        .team-name {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            font-weight: 500;
                            font-family: $regulerFont;
                            letter-spacing: 0px;
                            color: $color-Black;
                        }
                    }
                }
            }

            .rnk-cell {
                width: 35px;
            }

            .team-cell {
                left: 34px;
            }
        }
    }
}