@import "../../../assets/scss/variable.scss";

.allracing-wrap {
  padding: 45px 0px 0px 0px;

  h2 {
    text-align: center;
    margin: 40px 0px 50px 0px;
    font-size: 43px;
    font-weight: normal;
  }

  .disable-state {
    .select__placeholder {
      opacity: 0.4;
    }
  }

  h5 {
    font-size: 24px;
    text-align: center;
    margin: 15px 0px;
  }

  .bredcrumn-wrap {
    margin-bottom: 3px;

    li.MuiBreadcrumbs-separator {
      color: $color-Black;
    }

    li {
      font-size: 11.42px;
      line-height: 14px;

      p {
        font-size: 11.42px;
        line-height: 14px;
        color: $color-Accent-1;
        text-transform: uppercase;
      }

      a {
        color: $color-Black;
        font-size: 11.42px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }
  }

  .title-btn-sec {
    display: flex;
    justify-content: space-between;
    max-width: calc(100% - 206px);
    align-items: center;

    .btn-sec {
      display: flex;
      justify-content: space-between;

      .follow-btn {
        padding: 11px 26px 13px 26px;
        background-color: #4455c7;
        color: $color-White;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        cursor: pointer;
        @media (max-width: 579px) {
          font-size: 11.42px;
        }
      }

      .ml-20 {
        margin-left: 21px;
      }
      .unfollow-btn {
        padding: 11px 26px 13px 28px;
        background-color: #fff;
        color: #4455c7;
        border: 1px solid #4455c7;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        cursor: pointer;
        @media (max-width: 579px) {
          font-size: 11.42px;
        }
      }
    }
  }

  .follower-sec {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .ml-15 {
      margin-left: 15px;

      @media (max-width: 500px) {
        margin-left: 7px;
      }
    }
    .detail {
      color: #989898;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      font-family: $regulerFont;

      @media (max-width: 560px) {
        font-size: 14px;
      }
    }
  }

  .profile-btn-sec {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 39px;
    background-color: $color-White;
    padding: 18px 12px 18px 12px;
    margin-bottom: 18px;

    @media (max-width: 799px) {
      margin-top: 60px;
    }
    @media (max-width: 540px) {
      margin-top: 39px;
    }

    .follower-profile-sec {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .profile-pic {
        height: 206px;
        width: 206px;
        border-radius: 50%;
        margin-top: -60px;

        @media (max-width: 560px) {
          height: 160px;
          width: 160px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .mb-0 {
        margin-bottom: 0px !important;
      }

      .mt-11 {
        margin-top: 11px;
      }
    }

    .btn-sec {
      display: flex;
      justify-content: start;
      flex-direction: column;
      // width: 30%;
      width: 48%;

      .w-121 {
        max-width: 121px !important;
      }
      .w-115 {
        max-width: 115px !important;
      }
      .w-150 {
        max-width: 174px !important;
      }

      .follow-btn {
        padding: 11px 26px 13px 26px;
        background-color: #4455c7;
        color: $color-White;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        cursor: pointer;
        // @media (max-width: 579px) {
        //   font-size: 11.42px;
        // }
      }

      .mt-12 {
        margin-top: 12px;
      }

      .unfollow-btn {
        padding: 11px 26px 13px 26px;
        background-color: #fff;
        color: #4455c7;
        border: 1px solid #4455c7;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        cursor: pointer;
        // @media (max-width: 579px) {
        //   font-size: 11.42px;
        // }
      }
    }
  }

  .mobile-tipper-rating-sec {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 10px 10px 5px 0px #0000000f;
    -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
    -moz-box-shadow: 10px 10px 5px 0px #0000000f;
    padding: 8px 12px;
    align-items: end;
    max-width: 366px;
    margin-top: 15px;
    margin: 15px 12px;

    .left-sec {
      // display: flex;
      // align-items: center;

      // @media (max-width: 1250px) {
      //   display: block;
      // }

      .border-left {
        border-left: 1px solid #e8eaec !important;
        @media (max-width: 1250px) {
          border-left: 0 !important;
        }
      }
      .rating-sec {
        // padding: 11px 15px;
        display: flex;
        align-items: center;

        @media (max-width: 1250px) {
          padding: 0 !important;
        }
        .title {
          color: #191919;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 400;
          font-family: $regulerFont;
          @media (max-width: 579px) {
            font-size: 11.42px;
          }
        }
        .rating {
          margin-left: 18px;
          color: #1a73e8;

          .MuiRating-icon {
            font-size: 16px;
            margin-right: 6.4px;
          }
        }
      }
    }

    .rate-user-sec {
      padding-right: 15px;
      .learn-more {
        text-align: start;
        text-transform: capitalize;
        color: #4455c7;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        text-decoration: underline;
        font-size: 14px;

        &:hover {
          background-color: transparent;
        }

        @media (max-width: 579px) {
          font-size: 12px;
        }
      }
    }

    // .learn-more-box {
    //       margin-top: 6px;
    //       // padding: 11px 33px 0px 5px;

    //     }
  }

  .sport-tab {
    position: relative;
    padding: 19px 18px 9px 18px;
    box-shadow: 0px 3px 9px 0px #0000000d;
    background: $color-White;

    .racing-tab-detail {
      border-bottom: 2px solid #4455c7;

      .active {
        .MuiTab-wrapper {
          color: $color-Accent-1;
        }
      }

      .MuiTab-wrapper {
        font-size: 16px;
        font-family: VeneerClean-Soft !important;
        color: $color-Black;
        line-height: 23px;
      }

      .MuiTab-root {
        min-width: 120px;
        opacity: 1;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: $color-Primary;
      }
    }

    .podcasts-tab-details {
      padding: 18px 0px;
      min-height: 38px;

      @media (max-width: 799px) {
        padding: 9px 0px;
        min-height: 32px;
      }

      // .MuiTabScrollButton-root {
      //   // border: 2px solid $color-Primary;
      // }

      .MuiTab-textColorInherit {
        color: $color-Black;
        border: 2px solid $color-Primary;
        margin: 0px 12px;
        border-radius: 8px;
        opacity: 1;
        min-height: 38px;
        padding: 9px 10px;

        @media (max-width: 799px) {
          margin: 0 9px;
          padding: 6px 12px;
          min-height: 32px;
        }
      }

      .active {
        color: $color-White;
        background: $color-Primary;
      }

      .MuiTabs-indicator {
        background: none;
      }

      .MuiTab-textColorInherit:first-child {
        margin-left: 0px;
      }

      .MuiTab-root {
        // min-width: initial;
        min-width: -webkit-fill-available;
        width: 102px;
        margin-right: 0px !important;
      }

      .MuiTab-wrapper {
        font-size: 16px;
        line-height: 20px;
        font-family: $primaryFont;
      }

      .Mui-disabled {
        opacity: 1;
      }

      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          display: flex;
          column-gap: 10px;
          .MuiBox-root {
            max-width: 124px;
            width: 100%;
            align-items: center;
            column-gap: 6px;
            @media (max-width: 1250px) {
              max-width: -webkit-fill-available;
              width: 50%;
            }

            &:last-child {
              button {
                margin: 0px;
              }
            }

            .MuiButtonBase-root {
              @media (max-width: 1250px) {
                max-width: 100%;
                width: 100%;
                margin-right: 0px !important;
              }
            }
          }
        }
      }

      .MuiTouchRipple-root {
        display: none;
      }

      .tabicon-label-wrap {
        display: flex;
        // align-items: center;
        justify-content: center;

        @media (max-width: 1250px) {
          column-gap: 10px;
        }

        .hometab-icon {
          margin-right: 4px;

          span {
            display: flex;
          }
        }

        .hometab-label {
          font-size: 16px;
          line-height: 23px;
          color: $color-Primary;
          font-family: $primaryFont;
        }

        .active-hometab-label {
          color: $color-White;
        }
      }
    }

    // .Filteritemlist-wrap {
    //   display: flex;
    //   align-items: center;
    //   margin-top: 9px;

    //   @media (max-width: 560px) {
    //     flex-direction: column;
    //     align-items: flex-start;
    //   }

    //   .filter-list-racing {
    //     display: flex;
    //     align-items: center;

    //     .horse-select {
    //       max-width: 257px;
    //       margin-right: 4px;
    //       width: 100%;

    //       @media only screen and (max-width: 1040px) {
    //         margin-right: 0px;
    //         max-width: none;
    //       }

    //       @media (max-width: 799px) {
    //         max-width: none;
    //       }

    //       @media only screen and (max-width: 479px) {
    //         grid-column-start: 1;
    //         grid-column-end: 4;
    //       }
    //     }

    //     .period-select {
    //       max-width: 257px;
    //       margin-right: 4px;
    //       width: 100%;

    //       @media only screen and (max-width: 1040px) {
    //         margin-right: 0px;
    //         max-width: none;
    //       }

    //       @media (max-width: 799px) {
    //         max-width: none;
    //       }

    //       @media only screen and (max-width: 479px) {
    //         grid-column-start: 1;
    //         grid-column-end: 2;
    //       }
    //     }

    //     .period-select-1 {
    //       max-width: 257px;
    //       margin-right: 4px;
    //       width: 100%;

    //       @media only screen and (max-width: 1040px) {
    //         display: none;
    //       }
    //     }
    //   }

    //   .Filteritemlist-racing {
    //     display: flex;
    //     list-style-type: none;
    //     padding: 0px;
    //     margin: 0px;

    //     li {
    //       margin-right: 14px;

    //       label {
    //         display: flex;
    //         column-gap: 5.2px;
    //         font-size: 12px;
    //         line-height: 15px;
    //       }

    //       .MuiButtonBase-root {
    //         padding: 0px;
    //       }
    //     }
    //   }

    //   .Filteritemlist-datepicker {
    //     display: contents;

    //     .details-search-picker {
    //       @media (max-width: 560px) {
    //         min-width: 365px;
    //       }
    //     }

    //     .MuiFormControl-marginNormal {
    //       margin: 0px;

    //       @media (max-width: 560px) {
    //         margin-top: 9px;
    //       }
    //     }

    //     .MuiOutlinedInput-input {
    //       padding: 10.5px 14px;

    //       @media (max-width: 560px) {
    //         padding: 13px 14px;
    //       }
    //     }
    //   }
    // }
    .Marketplace-select-wrap {
      // padding: 18px;
      padding-top: 18px;
      background-color: $color-White;
      // box-shadow: 0px 3px 9px 0px #0000000d;

      @media (max-width: 799px) {
        // padding: 12px;
      }

      .filter-type-select {
        max-width: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 12px;

        @media only screen and (max-width: 1040px) {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 12px;
          row-gap: 12px;
        }

        // @media only screen and (max-width: 479px) {
        //   grid-template-columns: auto auto;
        //   column-gap: 0px;
        // }

        .horse-select {
          max-width: 257px;
          margin-right: 4px;
          width: 100%;

          @media only screen and (max-width: 1040px) {
            margin-right: 0px;
            max-width: none;
          }

          @media (max-width: 799px) {
            max-width: none;
          }

          @media only screen and (max-width: 479px) {
            grid-column-start: 1;
            grid-column-end: 4;
          }
        }

        .period-select {
          max-width: 257px;
          margin-right: 4px;
          width: 100%;

          @media only screen and (max-width: 1040px) {
            margin-right: 0px;
            max-width: none;
          }

          @media (max-width: 799px) {
            max-width: none;
          }

          @media only screen and (max-width: 479px) {
            grid-column-start: 1;
            grid-column-end: 2;
          }
        }

        .period-select-1 {
          max-width: 257px;
          margin-right: 4px;
          width: 100%;

          @media only screen and (max-width: 1040px) {
            display: none;
          }
        }

        .period-select-2 {
          @media only screen and (max-width: 1320px) {
            display: none;
          }
        }

        .bet-type-select {
          max-width: 257px;
          margin-right: 4px;
          width: 100%;

          @media only screen and (max-width: 1040px) {
            margin-right: 0px;
            max-width: none;
          }

          @media (max-width: 799px) {
            max-width: none;
          }

          @media only screen and (max-width: 479px) {
            grid-column-start: 3;
            grid-column-end: 4;
          }
        }

        .rank-by-select {
          max-width: 257px;
          margin-right: 4px;
          width: 100%;

          @media only screen and (max-width: 1040px) {
            margin-right: 0px;
            max-width: none;
          }

          @media (max-width: 799px) {
            max-width: none;
          }

          @media only screen and (max-width: 479px) {
            grid-column-start: 1;
            grid-column-end: 2;
          }
        }

        .show-select {
          max-width: 257px;
          margin-right: 4px;
          width: 100%;

          @media only screen and (max-width: 1040px) {
            margin-right: 0px;
            max-width: none;
          }

          @media (max-width: 799px) {
            max-width: none;
          }

          @media only screen and (max-width: 479px) {
            grid-column-start: 3;
            grid-column-end: 4;
          }
        }

        .Entry-select {
          max-width: 152px;
          margin-right: 4px;
          min-width: 152px;

          @media only screen and (max-width: 1023px) {
            margin-right: 0px;
            max-width: none;
          }

          @media (max-width: 799px) {
            max-width: none;
          }

          @media only screen and (max-width: 479px) {
            grid-column-start: 1;
            grid-column-end: 4;
          }
        }

        .country-select {
          // min-width: 170px;
          // max-width: 170px;
          min-width: 192px;
          max-width: 192px;
          margin-right: 4px;

          @media only screen and (max-width: 1023px) {
            margin-right: 0px;
            max-width: none;
          }

          @media (max-width: 799px) {
            max-width: none;
          }

          @media only screen and (max-width: 479px) {
            grid-column-start: 1;
            grid-column-end: 2;
          }
        }

        .State-select {
          min-width: 152px;
          max-width: 170px;
          margin-right: 4px;

          @media only screen and (max-width: 1023px) {
            margin-right: 0px;
            max-width: none;
          }

          @media (max-width: 799px) {
            max-width: none;
          }

          @media only screen and (max-width: 479px) {
            grid-column-start: 2;
            grid-column-end: 4;
          }
        }
      }

      .selling-checkbox {
        margin: 0px;

        @media (max-width: 1023px) {
          margin-top: 12px;
        }

        .MuiButtonBase-root {
          padding: 0px 0px 0px 8px;
          margin-right: 9px;

          @media (max-width: 1023px) {
            padding: 0px;
          }
        }

        .MuiTypography-root {
          font-size: 12px;
          line-height: 15px;
          font-weight: 400;
          font-family: $regulerFont;
          letter-spacing: 0px;
          color: #003764;
        }
      }

      .tip-text {
        margin-top: 19px;

        @media (max-width: 799px) {
          margin-top: 15px;
        }

        .text {
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          font-family: $regulerFont;
          color: #989898;
          letter-spacing: 0px;

          @media (max-width: 799px) {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }

      .select__control {
        background-color: $color-Light-grey;
        border-radius: 8px;
        max-height: 36px;

        .select__single-value {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__placeholder {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__input-container {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__indicator-separator {
          width: 0px;
        }

        .select__dropdown-indicator {
          padding: 10px;
        }
      }

      .select__menu-portal {
        z-index: 99;
      }

      .select__menu,
      .react-select__menu {
        .select__menu-list,
        .react-select__menu-list {
          .select__option,
          .react-select__option {
            cursor: pointer;
            font-size: 16px;
            color: $color-Black;
            font-family: $regulerFont;
            line-height: 19px;
            padding: 11px;

            &.select__option--is-focused {
              background-color: #d4d6d8;
              color: #000;
            }

            &.select__option--is-selected {
              background-color: #d4d6d8;
              color: $color-Black;
            }
          }
        }

        .select__menu-list,
        .react-select__menu-list {
          .select__group {
            .select__group-heading {
              margin-bottom: 0.5rem;
              color: green;
              font-weight: bolder;
              font-size: inherit;
            }
          }
        }
      }
    }
    .profile-pic {
      position: absolute;
      height: 206px;
      width: 206px;
      border-radius: 50%;
      right: 17px;
      top: -38px;

      // @media (max-width: 1300px) {
      //   right: 10px;
      //   top: 20%;
      // }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .send-msg-btn-sec {
    padding-left: 50px;
    margin-top: 40px;
    display: flex;
    max-width: calc(100% - 255px);
    // max-width: calc(100% - 212px);
    // max-width: 846px;
    width: 100%;
    justify-content: start;
    align-items: center;
    column-gap: 20px;

    @media (max-width: 1350px) {
      justify-content: start;
    }

    .tipper-rating-sec {
      // margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 668px;
      width: 100%;
      border-radius: 8px;
      // padding: 11px 15px;
      box-shadow: 10px 10px 5px 0px #0000000f;
      -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
      -moz-box-shadow: 10px 10px 5px 0px #0000000f;

      @media (max-width: 1250px) {
        padding: 8px 12px;
        align-items: end;
        max-width: 366px;
        margin-top: 15px;
      }

      .left-sec {
        display: flex;
        align-items: center;

        @media (max-width: 1250px) {
          display: block;
        }

        .border-left {
          border-left: 1px solid #e8eaec !important;
          @media (max-width: 1250px) {
            border-left: 0 !important;
          }
        }
        .rating-sec {
          padding: 11px 15px;
          display: flex;
          align-items: center;

          @media (max-width: 1250px) {
            padding: 0 !important;
          }
          .title {
            color: #191919;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 400;
            font-family: $regulerFont;
            @media (max-width: 1350px) {
              font-size: 14px;
            }
          }
          .rating {
            margin-left: 18px;
            color: #1a73e8;

            .MuiRating-icon {
              font-size: 16px;
              margin-right: 6.4px;
            }
          }
        }
      }

      .rate-user-sec {
        padding-right: 15px;
        .learn-more {
          text-align: start;
          text-transform: capitalize;
          color: #4455c7;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
          text-decoration: underline;
          font-size: 14px;

          &:hover {
            background-color: transparent;
          }
        }
      }

      // .learn-more-box {
      //       margin-top: 6px;
      //       // padding: 11px 33px 0px 5px;

      //     }
    }
    .unfollow-btn {
      // margin-top: 11px;
      padding: 11px 26px 13px 28px;
      background-color: #fff;
      color: #4455c7;
      border: 1px solid #4455c7;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $regulerFont;
      border-radius: 8px;
      text-transform: capitalize;
      cursor: pointer;
      @media (max-width: 1350px) {
        font-size: 14px;
        padding: 11px 20px 13px 22px;
      }
      @media (max-width: 579px) {
        font-size: 11.42px;
      }
    }
  }

  .page-deatils-wrap {
    padding: 27px 50px 18px 50px;

    @media (max-width: 799px) {
      padding: 18px 12px 27px 12px;
    }
  }
  .track-info {
    padding: 4px 33px 12px;

    ul {
      list-style-type: none;
      padding: 0px;
      margin: 0px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 25px;
      align-items: center;
    }

    li {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding-bottom: 6px;
    }
  }
}

@media only screen and (max-width: 479px) {
  .allracing-wrap {
    padding: 22px 0px 0px 0px;

    .MuiTypography-h1 {
      font-size: 31.36px;
      line-height: 43.9px;
    }

    .sport-tab {
      padding: 9px 12px 11px 12px;
    }

    .track-info {
      padding: 0px 12px;
    }
  }
}
