@import "../../../../assets/scss/variable.scss";

.sport-tips-details-wrap {
  background-color: $color-off-White;

  .sport-tips-details-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    .title-btn-box {
      display: flex;
      align-items: center;
      justify-content: start;
      column-gap: 17px;

      @media only screen and (max-width: 799px) {
        display: block;
      }

      h1 {
        line-height: 56px;
        color: $color-Black;

        @media only screen and (max-width: 599px) {
          font-size: 31.36px;
          line-height: 40px;
        }
      }

      .chip-sec {
        text-align: center;
        max-width: max-content;
        background-color: $color-Accent-1;
        border-radius: 23px;
        padding: 0px 4px 0px 4px;

        //   margin-left: 23px;
        .chip-txt {
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-White;
          font-size: 16px;
          line-height: 22.4px;
          padding: 2px 3px;

          @media (max-width: 580px) {
            font-size: 11.42px;
            line-height: 17px;
          }
        }
      }
    }

    .page-bredcrumn-wrap {
      overflow-x: scroll;

      .bredcrumn-wrap {
        margin-bottom: 3px;
        width: max-content;

        li.MuiBreadcrumbs-separator {
          color: $color-Black;
        }

        li {
          font-size: 11.42px;
          line-height: 14px;

          p {
            font-size: 11.42px;
            line-height: 14px;
            color: $color-Accent-1;
            text-transform: uppercase;
          }

          a {
            color: $color-Black;
            font-size: 11.42px;
            line-height: 14px;
            text-transform: uppercase;
          }
        }
      }
    }

    .page-bredcrumn-wrap::-webkit-scrollbar {
      height: 0px;
    }

    .page-bredcrumn-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .page-deatils-wrap {
    // background-color: $color-White;

    .tip-content-box {
      padding: 33px;
      background-color: $color-White;

      @media (max-width: 799px) {
        padding: 21px 12px 27px 12px;
      }

      .title {
        margin-top: 18px;
        font-size: 22.4px;
        line-height: 31.36px;
        color: $color-Black;
        font-weight: 600;
        font-family: $regulerFont;

        @media (max-width: 560px) {
          font-size: 16px;
          line-height: 22.4px;
        }
      }

      .time {
        font-size: 16px;
        line-height: 22.4px;
        color: #5d5d5d;
        font-weight: 400;
        font-family: $regulerFont;

        @media (max-width: 560px) {
          font-size: 12px;
          line-height: 15px;
        }
      }

      .des {
        margin-top: 18px;
        font-size: 16px;
        line-height: 20px;
        color: $color-Black;
        font-weight: 400;
        font-family: $regulerFont;
      }

      .mt-0 {
        margin-top: 0 !important;
      }

      .txt-uppercase {
        text-transform: uppercase;
      }

      .odds-btn-box {
        // margin-top: 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 799px) {
          display: block;
        }

        // .odd-title{
        //     margin-top: 18px;
        // font-size: 22.4px;
        // line-height: 31.36px;
        // color: $color-Black;
        // font-weight: 600;
        // font-family: $regulerFont;
        // }
        .see-odds-button {
          text-transform: capitalize;
          color: $color-Accent-1;
          border: none;
          padding: 0;
          cursor: pointer;
          text-decoration: underline;
          // position: absolute;
          // bottom: 0;
          // right: 0;
          // margin: 10px;
          outline: none;
          font-size: 14px;

          &:hover {
            background-color: transparent;
          }

          .MuiButton-endIcon {
            margin: 0px;
          }

          @media (max-width: 560px) {
            font-size: 12px;

            .MuiButton-endIcon {
              margin-left: 0px;
            }
          }
        }
      }
    }

    .table-box-shadow {
      // box-shadow: 0px 1px 9px 0px #0000002e;

      box-shadow: 10px 10px 5px 0px #0000000f;
      -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
      -moz-box-shadow: 10px 10px 5px 0px #0000000f;
    }

    .border-gray {
      border: 1px solid $color-Light-grey;
    }

    .bg-color-Light-grey {
      background-color: $color-Light-grey;
    }

    .international-table-container {
      //   border-radius: 8px;

      .international-table {
        border-collapse: unset;

        .title-height {
          @media (max-width: 560px) {
            height: 90%;
          }
        }

        .font-16 {
          font-size: 16px !important;
        }

        .MuiTableRow-head {
          .MuiTableCell-head {
            // padding: 17px 21px;
            padding: 2px;
          }

          .title-padding {
            padding: 8px;

            @media (max-width: 560px) {
              padding: 0px;
            }
          }

          .in-head {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            color: $color-Primary;
            font-family: $regulerFont;
            letter-spacing: 0px;
            text-transform: uppercase;
            text-align: center;
          }

          .title-start {
            padding: 12px 21px 12px 33px;
            text-align: start;

            @media (max-width: 1500px) {
              padding: 12px 21px;
            }

            @media (max-width: 560px) {
              padding: 10px;
            }
          }

          .in-head-txt {
            text-transform: uppercase;
            font-size: 12px;
            line-height: 21px;
            font-weight: 600;
            color: $color-Accent-1;
            font-family: $regulerFont;
            letter-spacing: 0px;
          }
        }

        .detail-flex {
          display: flex !important;
          align-items: center !important;
          column-gap: 9px;
        }

        .max-word {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100px;
        }

        .text-center {
          text-align: center;
        }

        .MuiTableCell-body {
          // padding: 12px 21px;
          padding: 12px 21px 12px 33px;
          font-size: 14px;
          line-height: 19px;
          font-weight: 400;
          color: $color-Black;
          font-family: $regulerFont;
          letter-spacing: 0px;
          border-bottom: 1px solid $color-Light-grey;
          background-color: $color-White;

          @media (max-width: 1500px) {
            padding: 12px 21px;
          }

          @media (max-width: 560px) {
            padding: 10px 10px;
          }

          .team-img-wrap {
            width: 36px;
            height: 36px;
            border-radius: 50%;

            .team-img {
              border-radius: 50%;
              width: 36px;
              height: 36px;
              object-fit: contain;
            }

            @media (max-width: 939px) {
              margin: 0px auto;
            }
          }

          // &:last-child {
          //   border-bottom: 0 !important;
          // }
        }

        .link-blue {
          color: $color-Accent-1;
        }

        .tipper-name {
          width: 200px;

          @media (max-width: 1250px) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 180px;
            // width: calc(100% - 36px);
          }

          @media (max-width: 560px) {
            // width: 100px;
            width: 150px;
          }
        }

        .winner-team-img {
          width: 45px;
          height: 45px;
          object-fit: contain;
        }
      }
    }

    .sport-expert-table-wrap {
      display: flex;
      position: relative;
      column-gap: 2px;
      .sport-expert-table-container {
        overflow-y: hidden;
        .sport-expert-table {
          .MuiTableRow-head {
            .MuiTableCell-head {
              padding: 0px 18px;
              font-size: 12px;
              line-height: 15px;
              color: $color-Primary;
              font-weight: 600;
              letter-spacing: 0px;
              text-align: center;
              background-color: #e8eaec;
              text-transform: uppercase;
              height: 100px;
            }
            .font-16 {
              font-size: 16px;
              line-height: 20px;
            }
          }
          .MuiTableBody-root {
            .MuiTableCell-body {
              padding: 3px;
              height: 45px;
              text-align: center;
            }
          }
        }
      }
      .sport-expert-left {
        width: 350px;
        box-shadow: inset -2px 0px 6px 0px #ebebeb;
        p {
          margin: 0px;
        }
        .sport-expert-table {
          box-shadow: inset -2px 0px 6px 0px #ebebeb;
        }
        @media (max-width: 799px) {
          width: 250px;
        }
      }
      .sport-expert-right {
        box-shadow: -2px 0px 3px 0px #dddddd;
        width: calc(100% - 350px);
        @media (max-width: 799px) {
          width: calc(100% - 250px);
        }
        .sport-expert-table-container {
          .overlay::after {
            content: "";
            position: absolute;
            width: 30px;
            height: 100%;
            z-index: 9;
            right: 0;
            background: transparent
              linear-gradient(90deg, #ffffff00 0%, #ffffff 60%) 0% 0% no-repeat
              padding-box;
          }

          .arrow-wrap {
            display: flex;
            position: absolute;
            top: 45px;
            z-index: 99;
            justify-content: space-between;
            flex-direction: row;
            width: calc(100% - 322px);

            .table-arrow {
              width: 28px;
              height: 20px;
              background-color: transparent;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }

            .disable {
              opacity: 0.5;
            }
            .arrow-left {
              transform: rotate(90deg);
            }
            .arrow-right {
              transform: rotate(270deg);
            }
          }
        }
        .MuiTableCell-head {
          min-width: 135px;
          .expert-teamname {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
        .winner-team-image-wrap {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          margin: 0px auto;
          .winner-team-img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: contain;
          }
        }
      }
    }
    .mt-0 {
      margin-top: 0px !important;
    }

    .related-tips-box {
      margin-top: 60px;
      padding: 0px 33px 18px 33px;

      @media (max-width: 799px) {
        padding: 0px 12px 18px 12px;
      }

      .title {
        margin-top: 18px;
        font-size: 22.4px;
        line-height: 31.36px;
        color: $color-Black;
        font-weight: 600;
        font-family: $regulerFont;

        @media (max-width: 404px) {
          font-size: 20.4px !important;
        }
      }

      .racing-slider-wrap {
        height: 130px;
        margin-top: 33px;
        background-image: none !important;
        // background-repeat: no-repeat;
        // background-position: top center;
        // background-size: cover;
        border-radius: 8px;
        // padding: 15px 33px 43px;
        padding: 0px 33px 0px;

        .home-slider-header {
          font-size: 31.36px;
          line-height: 40px;
          font-weight: 400;
          font-family: $primaryFont;
          text-align: center;
          color: $color-White;
        }

        .racing-slick-slider {
          // max-width: 692px;
          // width: 100%;
          max-width: unset !important;
          width: 100%;
          margin: 0px auto;
          // height: 246px;

          .slick-arrow {
            width: 16px;
            height: 16px;

            svg {
              width: 24px;
              height: 24px;

              path {
                fill: #989898;
              }
            }

            &::before {
              content: "";
              display: none;
            }
          }

          .slick-prev {
            left: -34px;
            padding-bottom: 46px;

            @media (max-width: 799px) {
              left: -20px;
            }
          }

          .slick-next {
            right: -34px;
            padding-bottom: 46px;

            @media (max-width: 799px) {
              right: -20px;
            }
          }

          .slick-dots {
            bottom: -25px;

            li {
              width: 10px;
              height: 10px;

              @media (max-width: 799px) {
                width: 7px;
                height: 7px;
              }

              button {
                width: 10px;
                height: 10px;

                @media (max-width: 799px) {
                  width: 7px;
                  height: 7px;
                }

                &::before {
                  width: 10px;
                  height: 10px;
                  border: 1px solid $color-Accent-1;
                  border-radius: 50%;
                  color: transparent;
                  opacity: 1;

                  @media (max-width: 799px) {
                    width: 7px;
                    height: 7px;
                  }
                }
              }
            }

            .slick-active {
              button {
                &::before {
                  opacity: 1;
                  background-color: $color-Accent-1;
                }
              }
            }
          }
        }

        .racing-slider-details {
          background-color: unset !important;
          padding: 0px !important;
          border-radius: 8px;
          margin: 0px 7.5px;
          height: 180px !important;

          @media (max-width: 600px) {
            padding: 9px;
            height: 180px !important;
          }

          .slider-header-wrap {
            padding-bottom: 7px;
            border-bottom: 1px solid $color-Accent-1;

            @media (max-width: 799px) {
              flex-direction: column;
            }

            .event-name-deatils {
              @media (max-width: 799px) {
                width: 100%;
                column-gap: 5px;
                justify-content: space-between;
              }

              .sport-icon {
                margin-right: 3px;
                width: 22px;
                height: 22px;

                svg {
                  width: 100%;
                  height: 100%;
                }
              }

              .race-img {
                margin-right: 3px;

                img {
                  width: 27px;
                  height: 27px;
                }
              }

              .event-name {
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                font-family: $primaryFont;
                color: $color-Primary;
              }

              .country-name {
                font-size: 11.42px;
                line-height: 20px;
                font-family: $regulerFont !important;
              }

              .runner-name {
                text-align: center;
                margin-left: 12px;
                background-color: $color-Accent-1;
                border-radius: 3px;
                color: #ffffff;
                font-size: 12px;
                line-height: 15px;
                font-weight: 600;
                font-family: $regulerFont;
                padding: 3px 9px;
              }

              .tournament-name {
                text-align: center;
                margin-left: 12px;
                background-color: $color-Accent-1;
                border-radius: 23px;
                color: #ffffff;
                font-size: 12px;
                line-height: 15px;
                font-weight: 600;
                font-family: $regulerFont;
                padding: 3px 6px;

                @media (max-width: 799px) {
                  margin-left: 0px;
                }
              }
            }

            .event-time-distance {
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-gray-dark;
              display: flex;
              align-items: center;
              column-gap: 5px;

              @media (max-width: 799px) {
                width: 100%;
                justify-content: space-between;
                margin-top: 3px;
              }
            }

            .divider {
              @media (max-width: 799px) {
                display: none;
              }
            }
          }

          .slider-event-race-deatils {
            padding-top: 12px;

            &:not(:last-child) {
              padding-bottom: 9px;
              border-bottom: 1px solid $color-grey;

              @media (max-width: 799px) {
                padding-bottom: 9px;
              }
            }

            .details-race {
              .race-name {
                font-size: 12px;
                line-height: 15px;
                font-weight: 600;
                font-family: $regulerFont;
                color: #000000;
              }

              .race-deatils {
                font-size: 11.42px;
                line-height: 14px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-Black;
              }

              .deatils-1 {
                column-gap: 26px;

                @media (max-width: 799px) {
                  column-gap: 12px;
                }
              }
            }

            .best-value {
              display: flex;
              flex-direction: column;
              padding: 6px 0px;
              width: 56px;
              text-align: center;
              background-color: #d6d9f3;
              border-radius: 6px;
              box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.16);

              .best {
                font-size: 11.42px;
                line-height: 14px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-Black;
                text-transform: uppercase;
              }

              .best-odds {
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                font-family: $regulerFont;
                color: $color-Black;
              }
            }
          }

          .sport-deatils {
            justify-content: space-around;
            padding-top: 25px;
            column-gap: 5px;

            @media (max-width: 799px) {
              padding-top: 12px;
            }

            &:not(:last-child) {
              padding-bottom: 18px;
              border-bottom: 1px solid $color-grey;

              @media (max-width: 799px) {
                padding-bottom: 12px;
              }
            }

            .sport-team-wrap {
              column-gap: 21px;

              @media (max-width: 799px) {
                flex-direction: column;
                column-gap: 0px;
                row-gap: 9px;
              }
            }

            .sport-team-home {
              @media (max-width: 799px) {
                flex-direction: column-reverse;
              }
            }

            .flag-wrap {
              @media (max-width: 799px) {
                display: flex;
                align-items: center;
                column-gap: 6px;
              }

              .flag-img {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                object-fit: contain;
                margin: 0 auto;

                @media (max-width: 799px) {
                  width: 28px;
                  height: 28px;
                }

                img {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                }
              }
            }

            .away-flag-wrap {
              @media (max-width: 799px) {
                flex-direction: row-reverse;
              }
            }

            .team-vs {
              font-size: 22.4px;
              line-height: 30px;
              font-weight: 400;
              font-family: $primaryFont;
              color: $color-Black;
            }

            .team-name {
              font-size: 16px;
              line-height: 20px;
              font-weight: 400;
              font-family: $primaryFont;
              color: $color-Black;

              @media (max-width: 799px) {
                font-size: 14px;
                line-height: 18px;
              }
            }

            .awayteam-name {
              @media (max-width: 799px) {
                text-align: right;
              }
            }
          }

          .tip-card-box {
            cursor: pointer;
            border-radius: 8px;
            background-color: $color-White;
            // box-shadow: 0px 3px 9px 0px #0000000d;
            box-shadow: 10px 10px 5px 0px #0000000f;
            -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
            -moz-box-shadow: 10px 10px 5px 0px #0000000f;
            border: 1px solid transparent;

            &:hover {
              border: 1px solid $color-Accent-1;
            }

            &:active {
              border: 1px solid $color-Accent-1;
            }

            .img-box {
              width: 100%;
              height: 172px;
              border-top-right-radius: 8px;
              border-top-left-radius: 8px;

              .sport-img {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }
            }

            .content-box {
              padding: 15px;

              .chip-sec {
                text-align: center;
                max-width: max-content;
                background-color: $color-Accent-1;
                border-radius: 23px;
                padding: 0px 4px 0px 4px;

                //   margin-left: 23px;
                .chip-txt {
                  font-weight: 400;
                  font-family: "VeneerClean-Soft" !important;
                  color: $color-White;
                  font-size: 16px;
                  line-height: 22.4px;
                  padding: 2px 3px;

                  @media (max-width: 580px) {
                    font-size: 11.42px;
                    line-height: 17px;
                  }
                }
              }

              .title {
                margin-top: 12px;
                font-size: 22.4px;
                line-height: 31.36px;
                color: $color-Black;
                font-weight: 600;
                font-family: $regulerFont;

                max-width: 98%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
              }

              .time {
                margin-top: 12px;
                font-size: 16px;
                line-height: 22.4px;
                color: $color-gray-dark;
                font-weight: 400;
                font-family: $regulerFont;
              }
            }
          }
        }

        .sport-slider-details {
          .slider-event-race-deatils {
            height: calc(210px - 54px);

            @media (max-width: 799px) {
              height: calc(238px - 54px);
            }
          }
        }

        .live-wrap {
          margin-top: 12px;
          display: block;

          .live-indicator {
            padding: 3px 11px;
            background-color: $color-Negative;
            color: $color-White;
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;
            border-radius: 3px;
            margin-right: 13px;
          }
        }

        .featured-sport-score {
          display: flex;
          align-items: center;
          justify-content: space-around;
          background-color: $color-Primary;
          height: calc(100% - 150px);
          margin: 0px -21px;
          border-radius: 0px 0px 8px 8px;

          @media (max-width: 799px) {
            height: calc(100% - 175px);
            margin: 0px -9px;
          }

          .score-details {
            display: flex;
            align-items: center;
            flex-direction: column;

            .score {
              font-size: 22.4px;
              line-height: 28px;
              font-weight: 400;
              font-family: $primaryFont;
              color: $color-White;
              display: flex;
              align-items: center;
              column-gap: 5px;

              @media (max-width: 799px) {
                font-size: 16px;
                line-height: 20px;
              }

              .score-icon {
                width: 25px;
                height: 25px;
                display: block;

                @media (max-width: 799px) {
                  width: 18px;
                  height: 18px;
                }

                svg {
                  width: 100%;
                  height: 100%;

                  path {
                    fill: $color-White;
                  }
                }
              }
            }

            .desh {
              color: $color-White;
            }

            .score-team {
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-White;
            }
          }
        }

        .single-silder {
          position: relative;
          cursor: pointer;
          // margin: 0px 7.5px;
          height: 246px;
          overflow: hidden;

          .slider-img {
            max-width: 692px;
            width: 100%;
            height: 100%;
            max-height: 246px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
              object-fit: contain;
            }
          }

          .news-tag {
            padding: 3px 6px;
            background-color: $color-Secondary;
            color: $color-White;
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            font-family: $regulerFont;
            max-width: fit-content;
            position: absolute;
            left: 12px;
            top: 13px;
            text-transform: capitalize;
          }

          .details-back {
            position: absolute;
            bottom: 0px;
            left: 0px;
            background: linear-gradient(
              to bottom,
              rgba (255 255 255 / 0%),
              #3e3e3e21,
              #b3b3b3b8
            );
            width: -webkit-fill-available;

            .slider-news-deatils {
              padding: 21px 12px 9px;

              .title-news {
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 600;
                font-family: $regulerFont;
                color: $color-White;
                margin-bottom: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;

                @media (max-width: 799px) {
                  font-size: 11.42px;
                  line-height: 14px;
                }
              }

              p {
                font-size: 11.42px;
                line-height: 14px;
                font-weight: 400;
                font-family: $regulerFont;
                color: #c9c9c9;
              }
            }
          }
        }
      }
    }

    .nodata {
      width: 300px;
      margin: 0px auto;
      padding: 40px 0px;
      font-family: $regulerFont;
      text-align: center;
    }
  }
}
