@import "../../../assets/scss/variable.scss";

.right-sidebarcontent {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  // .news-wrap {
  //   padding: 18px 21.9px 100px 22px;

  //   h4 {
  //     margin: 0px 0px 28px 0px;
  //     text-align: left;
  //     font-size: 22.4px;
  //     font-weight: normal;
  //     line-height: 36.36px;
  //     color: #191919;
  //     font-family: $primaryFont;
  //   }

  //   .tool {
  //     .MuiTooltip-popper {
  //       background-color: #e6eefa;
  //     }
  //   }

  //   h5 {
  //     text-align: left;
  //     margin: 0px 0px 6px 0px;
  //     font-size: 16px;
  //     color: #191919;
  //     line-height: 19px;
  //     // white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 2;
  //     /* number of lines to show */
  //     line-clamp: 2;
  //     -webkit-box-orient: vertical;
  //     cursor: pointer;
  //   }

  //   .news-meta {
  //     text-align: left;
  //     font-size: 11.42px;
  //     text-transform: capitalize;
  //     line-height: 14px;
  //     color: #5d5d5d;
  //     margin-bottom: 12px;
  //   }

  //   p {
  //     font-size: 16px;
  //     text-align: left;
  //     color: #191919;
  //     line-height: 19px;
  //     margin: 0px 0px 10px 0px;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 4;
  //     /* number of lines to show */
  //     line-clamp: 4;
  //     -webkit-box-orient: vertical;
  //   }

  //   a {
  //     text-align: left;
  //     display: block;
  //     font-size: 11.42px;
  //     line-height: 14px;
  //     color: #4455c7;
  //     text-decoration: underline;
  //   }

  //   .news-content {
  //     margin-bottom: 38px;
  //   }
  // }

  .News-wrap {
    padding: 29px 16px 29px;

    .news-heading {
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-Black;
      position: relative;
      margin-bottom: 21px;
      cursor: pointer;
      letter-spacing: 0px;

      .right-arrow {
        margin-left: 10px;
      }

      &::after {
        content: "";
        background-color: #4455c7;
        height: 1px;
        width: 95%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
      }
    }

    .bookmakers-heading {
      margin-top: 18px;
    }

    .news-details {
      width: 192px;
      height: 114px;
      position: relative;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .news-img {
        max-width: 192px;
        max-height: 114px;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .news-text {
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 11px 5px 4px;
        background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0%),
            rgba(143, 143, 143, 46%),
            rgba(34, 34, 34, 87%));
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        width: -webkit-fill-available;

        a {
          font-size: 11.42px;
          line-height: 14px;
          font-family: $regulerFont;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }

    .related-event {
      display: flex;
      padding: 12px 0 9px;
      cursor: pointer;

      .event-data {
        // padding-right: 15px;
        width: 90%;

        .event-title {
          font-size: 16px;
          font-family: $primaryFont;
          color: $color-gray-dark;
          line-height: 21px;
          font-weight: 400;
        }

        .event-text {
          font-size: 11.42px;
          font-family: $primaryFont;
          color: $color-gray-dark;
          line-height: 15px;
          font-weight: 400;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .related-event:first-child {
      padding-top: 18px;
    }
  }

  .top-bookmaker-wrap {
    .top-bookmaker-heading {
      text-align: center;
      font-size: 11.42px;
      line-height: 14px;
      font-weight: 700;
      color: #d6d9f3;
      background: #003764;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .top-bookmaker-detail-wrap {
      display: flex;
      align-items: center;
      margin: 9px 0px;
      box-shadow: 0px 3px 9px 0px #0000000d;
      border-radius: 0px 3px 3px 0px;

      .top-bookmaker-left {
        width: 80px;
        height: 80px;
        background-color: $color-Black;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .top-bookmaker-right {
        width: calc(100% - 80px);
        height: 80px;
        background: #ffffff;
        position: relative;
        border-radius: 0px 3px 3px 0px;

        .top-bookmaker-right-details-wrap {
          padding: 6px;

          .top-bookmaker-description {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 600;
            font-family: $regulerFont;
            color: $color-Black;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }

          .claim-btn {
            font-size: 12px;
            padding: 3px 20px;
            line-height: 15px;
            font-weight: 600;
            border-radius: 3px;
            background-color: $color-Black;
            color: $color-White;
            position: absolute;
            display: block;
            bottom: 6px;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .gabling-wrap {
    background-color: white;
    padding: 30px 12px;

    .gabling-header {
      font-family: "Arial" !important;
      text-align: center;
      font-size: 11.42px;
      line-height: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .gabling-discription {
      font-size: 7px;
      line-height: 10px;
      font-weight: 400;
      font-family: "Arial" !important;
      text-align: center;
      margin-top: 4px;

      span {
        font-family: "Arial" !important;
      }

      .responsible-gambling-helpline-no,
      .responsible-gambling-logo {
        color: $color-Primary;
        text-decoration: underline;
        font-family: "Arial" !important;
      }
    }
  }
}

.featured-video-modal {
  max-width: 560px;
  width: 100%;

  .MuiDialog-paper {
    padding: 0px;
  }

  .dialog-content-box {
    .dialog-close {
      padding: 0px;
      z-index: 1;
    }
  }

  .video-secton {
    max-height: 315px;
    height: 100%;
  }
}