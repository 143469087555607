@import "../../../../../../assets/scss/variable.scss";

.player-tab-details {
    .MuiTab-root {
        width: max-content !important;
    }
}

.teamsport-header-details {
    margin-top: 12px;
}

.player-career-table {
    .data-table {
        border: 1px solid #D4D6D8;
        box-shadow: 0px 0px 6px 0px #0000000d;
    }
}

.player-games-wrap {
    background-color: $color-White;
    box-shadow: 0px 0px 6px 0px #0000000d;
}