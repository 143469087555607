@import "../../../../assets/scss/variable.scss";

.event-date-time {
  .event-date-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #4455c7;

    @media (max-width: 559px) {
      display: block;
      border-bottom: 0;

      .date-text {
        border-bottom: 1px solid #4455c7;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .button-section {
        padding-top: 6px;
        display: flex;
        justify-content: space-between;

        button {
          border: 0 !important;
        }
      }

      .news-Pagination {
        padding-top: 50px;
      }
    }

    .date-text {
      font-family: $primaryFont;
      font-size: 22.4px;
      color: $color-Black;
      line-height: 31.36px;
      font-weight: 400;
    }

    .button-section {
      padding-bottom: 6px;

      button {
        padding: 4px 12px 5px;
        border: 1px solid #d4d6d8;
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        color: $color-Black;
        text-transform: capitalize;
        border-collapse: collapse;
      }

      .previous-button {
        border-radius: 5px 0 0 5px;
      }

      .next-button {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  .event-calendar-table-design {
    padding-top: 34px;

    .event-table {
      .event-table-head {
        background-color: $color-Light-grey;
        text-transform: none;
        padding: 0;

        .event-table-row {
          padding: 0;

          .event-table-cell {
            padding: 6px 0 6px 21px;
            font-family: VeneerClean-Soft !important;
            font-size: 22.4px;
            line-height: 31.36px;
            font-weight: 400;
            color: $color-Black;
            text-transform: none;
          }

          .event-table-cell:first-child {
            width: 60%;
          }

          .event-table-cell:last-child {
            width: 40%;
            padding: 6px 0 6px 0;
          }
        }
      }

      .event-table-body {
        .event-table-row {
          .event-table-cell {
            padding: 18px 0 17px 21px;

            .cell-text {
              font-family: $regulerFont;
              font-size: 16px;
              line-height: 21px;
              font-weight: 400;
              color: $color-Black;
            }
          }

          .event-table-cell:first-child {
            width: 70%;
          }

          .event-table-cell:last-child {
            padding: 18px 0 17px 0;
            width: 30%;
          }
        }
      }
    }
  }

  .news-Pagination {
    display: flex;
    justify-content: center;
    padding-top: 60px;

    @media (max-width:799px) {
      padding-top: 50px;
    }

    ul {
      li {
        margin-right: 4px;

        @media (max-width: 379px) {
          margin-right: 0px;
        }

        .MuiPaginationItem-root {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          width: 36px;
          height: 36px;
          border-radius: 50%;

          @media (max-width: 799px) {
            font-size: 14px;
            width: 24px;
            height: 24px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
            width: 22px;
            height: 22px;
          }

          svg {
            width: 36px;
            height: 36px;
            font-size: 18px;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }
          }
        }

        .Mui-selected {
          background-color: #d4d6d8;
          width: 45px;
          height: 45px;

          @media (max-width: 799px) {
            font-size: 14px;
            width: 24px;
            height: 24px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
}